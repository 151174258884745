import PropType from 'prop-types';
import { Autolinker, Card } from 'core/components';
import { isEmpty, isPresent } from 'core/utils';
import AssignmentCardHeader from 'modules/assignments/AssignmentCardHeader';
import LearningObjectives from 'modules/assignments/objectives/LearningObjectives';
import Attachments from 'modules/attachments/Attachments';
import './assignmentSubcard.scss';
import { useSchoolConfig } from "core/hooks";
import OneNote from 'modules/assignments/OneNote';

const AssignmentSubcard = ({
  attachments,
  children,
  childrenWithHeader,
  content,
  date,
  level,
  onenote,
  objectives,
  onEditButtonClicked,
  onAttachmentDelete,
  showAssignmentObjectives,
  showEditButton,
  showEmoji,
  user,
}) => {
  const { learningObjectiveLabel } = useSchoolConfig();

  return (
    <>
      {childrenWithHeader && (
        <Card className='mt-4 mb-3 py-3 px-custom' cardBodyClassName="px-0">
          <div className='d-flex flex-column'>
            <AssignmentCardHeader
              user={user}
              date={date}
              showEditButton={showEditButton}
              onEditButtonClicked={onEditButtonClicked}
              showEmoji={showEmoji}
              level={level}
            />
            <div className='mt-3'>
              {children}
            </div>
          </div>
        </Card>
      )}

      {(!childrenWithHeader && children) && (
        <Card className='mt-4 mb-3 py-2'>
          {children}
        </Card>
      )}
      {(isEmpty(children) && !childrenWithHeader) && (
        <Card className="mt-4 mb-3 py-3" cardBodyClassName="px-0">
          <div className="d-flex flex-column">
            <AssignmentCardHeader
              user={user}
              date={date}
              showEditButton={showEditButton}
              onEditButtonClicked={onEditButtonClicked}
              className="px-custom"
            />

            <Autolinker content={content} className="pt-2 pb-2 px-custom" />
            {(isPresent(objectives) && showAssignmentObjectives) &&
              <LearningObjectives items={objectives} sectionTitle={learningObjectiveLabel} />}

            {isPresent(attachments) && (
              <>
                <div className='mt-2 px-custom'>
                  <Attachments attachments={attachments} onDelete={onAttachmentDelete} />
                </div>
              </>
            )}
            {
              onenote && onenote.trim() !== "" &&
              <div className="card-footer bg-white mt-3 ms-3 me-3">
                <OneNote onenote_url={onenote} allowDelete={false} />
              </div>
            }
          </div>
        </Card>
      )}
    </>
  )
};

AssignmentSubcard.defaultProp = {
  childrenWithHeader: false,
  attachments: [],
  children: undefined,
  content: '',
  date: '',
  level: 3,
  objectives: {},
  onAttachmentDelete: undefined,
  onEditButtonClicked: undefined,
  showAssignmentObjectives: false,
  showEditButton: false,
  showEmoji: false,
  user: {},
};

AssignmentSubcard.propType = {
  childrenWithHeader: PropType.bool,
  attachments: PropType.array,
  children: PropType.node,
  content: PropType.string,
  date: PropType.string,
  level: PropType.number,
  objectives: PropType.shape({}),
  onAssignmentDelete: PropType.func,
  onEditButtonClicked: PropType.func,
  showAssignmentObjectives: PropType.bool,
  showEditButton: PropType.bool,
  showEmoji: PropType.bool,
  user: PropType.shape({}),
};

export default AssignmentSubcard;
