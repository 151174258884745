import PropTypes from "prop-types";
import { createAssignmentTemplate } from "./action";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "core/components/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

const CopyAndEditButton = ({ template }) => {
  const [copyButtonLoading, setCopyButtonLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const copyAndEditTemplate = async () => {
    try {
      setCopyButtonLoading(true);

      const query = { assignment_id: template.id, assignment_type: 'ms_teams', visibility: template.visibility, source: 'template' }
      const response = await dispatch(createAssignmentTemplate(query))
      enqueueSnackbar('Template was copied', { variant: 'success' });
      navigateTo(`/assignments_templates/${response.id}/edit`);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setCopyButtonLoading(false);
    }
  }

  return (
    <Button
      disabled={copyButtonLoading}
      color="secondary"
      loading={copyButtonLoading}
      outline={true}
      withLoader={true}
      loaderColor="#6c757d"
      onClick={copyAndEditTemplate}
    >
      <FontAwesomeIcon className="me-2" icon={faPencil} />
      Copy & edit
      <div className="ms-2 px-2 py-1 badge badge-soft-dark">{template.copies}</div>
    </Button>
  );
};

CopyAndEditButton.propTypes = {
  template: PropTypes.shape({}).isRequired
};

export default CopyAndEditButton;