import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert, Button } from "core/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import * as api from './api';
import { handleApiError } from "../../core/utils";
import { useSnackbar } from "notistack";
import fileDownload from "js-file-download";

const UploadStudentModal = ({ isOpen, onClose, onUpload, ...otherProps }) => {
  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const AlertContent = () => {
    return (
      <div>
          <FontAwesomeIcon icon={faWarning} className="text-warning me-2 font-size-17" />
          The students list will be completely replaced by students in the csv file.
      </div>
    );
  };

  const handleDownloadSample = async () => {
    try {
      setDownloading(true);
      const response = await api.studentsCsvSample();
      fileDownload(response, 'students_csv_template.csv')
    } catch (e) {
      const formattedError = handleApiError(e);
      enqueueSnackbar(formattedError.message, { variant: 'error' });
    } finally {
      setDownloading(false);
    }
  };

  const toBase64 = async (file) => {
    const reader = new FileReader();
    return new Promise(resolve => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    })
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleUpload = async () => {
    try {
      setUploading(true)
      const base64File = await toBase64(file)
      const response = await api.studentsCsvUpload({file: base64File})
      onUpload(response.data)
      enqueueSnackbar('Students list has been updated', { variant: 'success' });
    } catch (e) {
      const formattedError = handleApiError(e);
      enqueueSnackbar(formattedError.message, { variant: 'error' });
    } finally {
      setUploading(false)
    }
  };

  return (
    <Modal isOpen={isOpen} {...otherProps}>
      <ModalHeader charcode="x" tag="h4">Upload Students</ModalHeader>
      <ModalBody>
        <Alert text={<AlertContent />} allowDismiss={false} type={"warning"} />
        <div className="mb-3">
          <label htmlFor="formFile" className="form-label">Select csv file</label>
          <input className="form-control" type="file" id="formFile" accept="text/csv" onChange={handleFileChange} />
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-start justify-content-sm-end">
        <Button className="text-danger me-2" color="light" onClick={onClose} disabled={downloading}>Cancel</Button>
        <Button
          color="nurture-green"
          className="me-2"
          onClick={handleDownloadSample}
          loading={downloading}
          disabled={downloading}
          withLoader={true}
          loaderText="Downloading"
        >
          Download Sample
        </Button>
        <Button
          loading={uploading}
          disabled={uploading || file == null}
          withLoader={true}
          loaderText="Uploading"
          onClick={handleUpload}
        >
          Upload
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UploadStudentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
};

export default UploadStudentModal;