import React, { useEffect, useState } from "react";

// import '../assignment_templates.scss';
import { Button } from "core/components";
import TripleResponse from "assets/images/triple_response.svg";
import HighConfidence from "assets/images/emoji_icons/high.svg";
import { assignmentPublishFeedback } from "../../action";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useSnackbar } from 'notistack';

const StudentReflectionModal = ({ assignment, isOpen, onClose, onPublished }) => {
  const [publishing, setPublishing] = useState(false)

  const footerButtonText = publishing ? 'Publishing...' : 'Publish Feedback'
  const dispatch = useDispatch();
  const [studentReflection, setStudentReflection] = useState('');
  const onEmojiResponseClick = () => setStudentReflection('emoji_response');
  const onTripleResponseClick = () => setStudentReflection('triple_response');
  const { enqueueSnackbar } = useSnackbar();
  const closeModal = () => {
    onClose();
  };

  const publishFeedback = async () => {
    try {
      setPublishing(true);
      const payload = { student_reflection: studentReflection };
      await dispatch(assignmentPublishFeedback(assignment.id, payload));
      onPublished();
      enqueueSnackbar('Your feedback has been published', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setPublishing(false);
    }
  }


  useEffect(() => {
    setStudentReflection('emoji_response');
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      size="lg"
      className="modal assignment-templates-modal modal-fullscreen-md-down modal-dialog-scrollable"
    >
      <ModalBody className="modal-body ms-3 me-3">
        <div className='mb-4'>
          <div className="text-nurture-purple">
            <h4 className="mt-4">How do you want students to reflect on your feedback?</h4>
          </div>
          <div className="text-muted mb-3 mt-2">
            Students will have to reflect on your feedback to unlock their grade
          </div>
          <div className='mb-3'>
            <label className={`btn ${studentReflection === 'emoji_response' ? "border-nurture-purple highlight" : " border"} submission-type-option`} onClick={onEmojiResponseClick} >
              <div className="position-absolute right-align mt-3">
                <input
                  name='student_reflection'
                  value={'emoji_response'}
                  type="radio"
                  className="form-check-input active"
                  aria-label="Radio button for following text input"
                  defaultChecked
                />
              </div>
              <div className="row justify-content-md-left">
                <div className="col-md-2 my-auto">
                  <img src={HighConfidence} alt="High Confidence Logo" className="img-fluid" width="70" height="70" />
                </div>
                <div className="col-md-8 mt-3">
                  <div className="d-flex">
                    <h5>Emoji Response</h5>
                  </div>
                  <div className="d-flex">
                    <div className="point-size text-muted mb-2 ms-1 text-start">
                      Students choose how they felt about their feedback with an emoji and can add an additional comment if they want.
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div >
          <div>
            <label className={`btn ${studentReflection === 'triple_response' ? "border-nurture-purple highlight" : "border"} submission-type-option`} onClick={onTripleResponseClick}>
              <div className="position-absolute right-align mt-3">
                <input
                  name='student_reflection'
                  value={'triple_response'}
                  type="radio"
                  className="form-check-input"
                  aria-label="Radio button for following text input"
                />
              </div>
              <div className="row justify-content-md-left">
                <div className="col-md-2 my-auto">
                  <img src={TripleResponse} alt="Triple Response" className="img-fluid" width="70" height="70" />
                </div>
                <div className="col-md-8 mt-3">
                  <div className="d-flex">
                    <h5 className="text-start">3-2-1 Response</h5>
                  </div>
                  <div className="d-flex">
                    <div className="point-size text-muted mb-2 text-start">
                      Students answer 3 things they learned, 2 things they found interesting and 1 question they are left with.
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div >
        </div >
      </ModalBody>
      <ModalFooter className="modal-footer">
        <button type="button" onClick={closeModal} className="btn btn-light text-danger" data-bs-dismiss="modal">Cancel</button>
        <Button
          type="button"
          disabled={publishing}
          onClick={publishFeedback}
          loaderText='Saving'>
          {footerButtonText}
        </Button>
      </ModalFooter>

    </Modal>
  );
}

export default StudentReflectionModal;
