import PropTypes from "prop-types";
import { pluralize, userFullName } from "core/utils";
import React from "react";
import { Avatar } from "core/components";
import { useSchoolConfig } from "core/hooks";
import CheckmarkPurple from "assets/images/general_icons/check_mark_purple.svg";

const RubricTable = ({ assignment, showStudents, hidePoints, showScoreSelection }) => {
  const rubrics = assignment?.rubrics
  const assignmentObjectives = assignment?.assignment_objectives

  const { learningObjectiveLabel } = useSchoolConfig();

  const isCellSelected = (assignmentObjectiveId, cellId) => {
    const row = assignment?.user_assignment?.rubric_grades.find(item => item.ms_teams_assignment_objective_id === assignmentObjectiveId);
    if (!row) return false

    return row.mst_rubric_cell_id === cellId
  }

  return (
    <div className="table-responsive">
      <table className="table rounded table-bordered table-nurture-striped">
        <thead>
        <tr>
          <th className="p-3" style={{minWidth: '250px', maxWidth: '250px'}}>{learningObjectiveLabel}</th>
          {
            rubrics?.[0].columns.map((column) => {
              return (
                <th className="p-3" style={{minWidth: '250px', maxWidth: '250px'}} key={column.id}>
                  <div className="d-flex justify-content-between">
                    <span>{column.name}</span>
                    {
                      !hidePoints &&
                        <span
                          title={`${column.point} ${pluralize('point', column.point)}`}
                          className="badge p-2 bg-nurture-navy-blue text-dark ms-2 text-white">
                        { column.point } {pluralize('point', column.point)}
                      </span>
                    }
                  </div>
                </th>
              )
            })
          }
        </tr>
        </thead>
        <tbody>
        {
          rubrics?.map((rubric, index) => {
            return (
              <tr className="p-3">
                <td className="font-weight-600" style={{minWidth: '250px', maxWidth: '250px'}}>
                  { rubric['Learning Outcome'] }
                </td>
                {
                  rubric.columns.map(column => {
                    return (
                      <td
                        className={`p-3 ${showScoreSelection ? 'selectable-student' : ''} ${showScoreSelection && isCellSelected(rubric.id, column.cell_id) ? 'selected' : ''}`}
                        style={{maxWidth: '250px'}}
                      >
                        {
                          showScoreSelection && isCellSelected(rubric.id, column.cell_id) &&
                            <div className="mb-2">
                              <div className="badge badges badge-nurture-purple rounded-5 py-2 px-3 font-size-16">
                                <div className="d-flex align-items-center">
                                  <img src={CheckmarkPurple} alt="check mark" className="me-2" />
                                  { column.name }
                                </div>
                              </div>
                            </div>
                        }
                        <span>{column.value}</span>
                        {
                          showStudents &&
                            <>
                              <br /><br />
                              <div className="avatar-group d-sm-flex flex-wrap">
                                {
                                  !!assignmentObjectives?.[index]?.['rubrics_grades'] &&
                                  assignmentObjectives[index]['rubrics_grades'].map(grade => {
                                    const user = grade.user
                                    if (user != null && grade.cell_id === column.cell_id) {
                                      return <Avatar img={user.avatar_url} size="xs" title={userFullName(user)} key={user.id} />
                                    }
                                  })
                                }
                              </div>
                            </>

                        }
                      </td>
                    )
                  })
                }
              </tr>
            )
          })
        }
        </tbody>
      </table>
    </div>
  );
}

RubricTable.defaultProps = {
  showStudents: false,
  hidePoints: false,
  showScoreSelection: false,
}

RubricTable.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
  showStudents: PropTypes.bool,
  hidePoints: PropTypes.bool,
  showScoreSelection: PropTypes.bool,
}

export default RubricTable;