import React, { useEffect, useState } from 'react';
import './submissionType.scss';
import MobileLogo from "assets/images/mobile.svg";
import Check from "assets/images/check.svg";
import Hand from "assets/images/hand_submission.svg";
import Vector from "assets/images/vector.svg";
import PropTypes from "prop-types";

const mapSubmissionTypes = {
  "online_submission": 1,
  "paper_submission": 2,
};

const SubmissionType = ({ register, submissionType }) => {
  const [submissionTypeNumber, setSubmissionTypeNumber] = useState(0);
  const onOnlineSubmissionTypeClick = () => setSubmissionTypeNumber(1);
  const onPaperSubmissionTypeClick = () => setSubmissionTypeNumber(2);

  useEffect(() => {
    setSubmissionTypeNumber(mapSubmissionTypes[submissionType]);
  }, [submissionType]);

  return (
    <div className='mb-4'>
      <div className="">
        <h3 className="font-size-17 font-weight-600 mb-3">How do you want students to submit their work?</h3>
      </div>
      <div className="text-muted mb-3 mt-2">
        Students can submit digitally through Nurture in both cases.
      </div>
      <div className='mb-3'>
        <label className={`btn ${submissionTypeNumber === 1 ? "border-primary highlight" : " border"} submission-type-option`} onClick={onOnlineSubmissionTypeClick} >
          <div className="position-absolute right-align mt-3">
            <input
              {...register("submission_type")}
              value={'online_submission'}
              type="radio"
              className="form-check-input active"
              aria-label="Radio button for following text input"
            />
          </div>
          <div className="row justify-content-md-left">
            <div className="col-md-2 my-auto">
              <img src={MobileLogo} alt="Mobile Logo" className="img-fluid" width="80" height="80" />
            </div>
            <div className="col-md-8 mt-3">
              <div className="d-flex mb-2">
                <h5>Submit online through Nurture</h5>
              </div>
              <div className="d-flex">
                <img src={Check} alt="Check" width="20" height="20" />
                <div className="point-size text-muted mb-2 ms-1 text-start">
                  Students have to upload an attachment of their work and give you their confidence score.
                </div>
              </div>
              <div className="d-flex">
                <img src={Check} alt="Check" width="20" height="20" />
                <div className="point-size text-muted mb-2 ms-1 text-start">
                  Student work is available to go back over Nurture anytime.
                </div>
              </div>
              <div className="d-flex">
                <img src={Check} alt="Check" width="20" height="20" />
                <div className="point-size text-muted mb-2 ms-1 text-start">
                  Students reflect on their feedback to unlock their grade.
                </div>
              </div>
            </div>
          </div>
        </label>
      </div >
      <div>
        <label className={`btn ${submissionTypeNumber === 2 ? "border-primary highlight" : "border"} submission-type-option`} onClick={onPaperSubmissionTypeClick}>
          <div className="position-absolute right-align mt-3">
            <input
              {...register("submission_type")}
              value={'paper_submission'}
              type="radio"
              className="form-check-input"
              aria-label="Radio button for following text input"
            />
          </div>
          <div className="row justify-content-md-left">
            <div className="col-md-2 my-auto">
              <img src={Hand} alt="Hand Submission" className="img-fluid" width="80" height="80" />
            </div>
            <div className="col-md-8 mt-3">
              <div className="d-flex mb-2">
                <h5 className="text-start">Allow paper submission or online through Nurture</h5>
              </div>
              <div className="d-flex">
                <div className="vector-style me-1">
                  <img src={Vector} alt="Vector" width="17" height="17" />
                </div>
                <div className="point-size text-muted mb-2 text-start">
                  Students only have to give you their confidence score and a comment if they want to.
                </div>
              </div>
              <div className="d-flex">
                <div className="vector-style me-1">
                  <img src={Vector} alt="Vector" width="17" height="17" />
                </div>
                <div className="point-size text-muted mb-2 text-start">
                  Work may not be available in Nurture if they submit a paper copy.
                </div>
              </div>
              <div className="d-flex">
                <img src={Check} alt="Check" width="20" height="20" />
                <div className="point-size text-muted mb-2 ms-1 text-start">
                  Students reflect on their feedback to unlock their grade.
                </div>
              </div>
            </div>
          </div>
        </label>
      </div >
    </div>
  );
};

SubmissionType.defaultProps = {
  submissionType: 'online_submission',
};

SubmissionType.propTypes = {
  register: PropTypes.func.isRequired,
  submissionType: PropTypes.string,
};

export default SubmissionType;
