import React, {useState} from "react";
import {Button, MarkdownEditorField} from "../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalBody } from "reactstrap";
import {useSnackbar } from "notistack";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {aiFeedback} from "./action";

const NurtureAIFeedback = ({ aiRequestId }) => {
  const [showModal, setShowModal] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [feedbackComment, setFeedbackComment] = useState(null);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const FEEDBACKS = { positive: 'positive_feedback', negative: 'negative_feedback' }

  const toggle = () => setShowModal(!showModal);
  const handleFeedbackCommentChanged = (value) => setFeedbackComment(value);

  const initiateFeedback = (feedback) => {
    setFeedback(feedback);
    setShowModal(true);
    return

    // Send feedback data in background
    const data = { feedback: feedback, open_ai_request_id: aiRequestId };
    dispatch(aiFeedback(data));
  };

  const submitFeedBack = async () => {
    try {
      setFeedbackLoading(true);
      const data = { feedback: feedback, feedback_comment: feedbackComment, open_ai_request_id: aiRequestId };
      // await dispatch(aiFeedback(data));
      enqueueSnackbar('Thank you for your feedback', { variant: 'success' });
      setShowModal(false)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setFeedbackLoading(false);
    }
  };

  return (
    <div className="nurture-ai-feedback d-flex align-items-center">
      {
        (feedback === FEEDBACKS.positive || feedback === null) &&
          <Button color="transparent" className="text-muted" onClick={() => initiateFeedback(FEEDBACKS.positive)}>
            <FontAwesomeIcon icon={faThumbsUp} />
          </Button>
      }

      {
        (feedback === FEEDBACKS.negative || feedback === null) &&
          <Button color="transparent" className="text-muted" onClick={() => initiateFeedback(FEEDBACKS.negative)}>
            <FontAwesomeIcon icon={faThumbsDown} />
          </Button>
      }

      <Modal isOpen={showModal} toggle={toggle} zIndex={102}>
        <ModalBody>
          <div className="d-flex align-items-center mb-3">
            <div className={`me-2 d-flex align-items-center justify-content-center feedback ${feedback}`}>
              { feedback === FEEDBACKS.positive && <FontAwesomeIcon icon={faThumbsUp} /> }
              { feedback === FEEDBACKS.negative && <FontAwesomeIcon icon={faThumbsDown} /> }
            </div>
            <div className="font-size-15">Provide additional feedback</div>
          </div>
          <div className="mb-3">
            <MarkdownEditorField
              className='markdown'
              maxHeight="100px"
              defaultValue={feedbackComment}
              onChange={handleFeedbackCommentChanged}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              outline={true}
              loading={feedbackLoading}
              withLoader={true}
              loaderText="Submitting"
              loaderColor="#35294b"
              disabled={feedbackLoading}
              onClick={submitFeedBack}
            >
              Submit feedback
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

NurtureAIFeedback.defaultProps = {
  aiRequestId: null,
};

NurtureAIFeedback.propTypes = {
  aiRequestId: PropTypes.string,
};

export default NurtureAIFeedback;