import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormGroup, FormText, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "core/components/Button";
import React, { useEffect, useState } from "react";
import { TextInput } from "core/form/fields";
import PropTypes from "prop-types";
import * as api from './api';
import { useSnackbar } from "notistack";
import { handleApiError } from "core/utils";

const AddStudentModal = ({ isOpen, onClose, onAdd, ...otherProps }) => {
  const [submitting, setSubmitting] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    'first_name': yup.string().required('The student firstname is required').nullable(),
    'last_name': yup.string().required('The student lastname is required').nullable(),
    'email': yup.string().required('The student email is required').nullable(),
  });

  const { handleSubmit, formState: { errors }, register, reset } = useForm({ resolver: yupResolver(schema) });

  const handleAddStudent = async (data) => {
    try {
      setSubmitting(true);
      const response = await api.studentCreate(data)
      onAdd(response.data);
      enqueueSnackbar('Student was added successfully', { variant: 'success' });
    } catch (e) {
      const formattedError = handleApiError(e);
      enqueueSnackbar(formattedError.message, { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen]);

  return (
      <Modal size="lg" isOpen={isOpen} {...otherProps}>
        <form onSubmit={handleSubmit(handleAddStudent)}>
          <ModalHeader charcode="x" tag="h4">Add Student</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-6">
                <FormGroup>
                  <TextInput
                    label="First Name"
                    placeholder="First Name"
                    error={!!errors?.first_name?.message}
                    {...register('first_name')}
                    required
                  />
                  {errors?.first_name && (
                    <p className="mt-1">
                      <FormText color="danger">
                        {errors.first_name.message}
                      </FormText>
                    </p>
                  )}
                </FormGroup>
              </div>

              <div className="col-lg-6">
                <FormGroup>
                  <TextInput
                    label="Last Name"
                    placeholder="Last Name"
                    error={!!errors?.first_name?.message}
                    {...register('last_name')}
                    required
                  />
                  {errors?.last_name && (
                    <p className="mt-1">
                      <FormText color="danger">
                        {errors.last_name.message}
                      </FormText>
                    </p>
                  )}
                </FormGroup>
              </div>
            </div>

            <FormGroup>
                <TextInput
                  label="Email"
                  placeholder="Email"
                  error={!!errors?.email?.message}
                  {...register('email')}
                  required
                />
                {errors?.email && (
                  <p className="mt-1">
                    <FormText color="danger">
                      {errors?.email.message}
                    </FormText>
                  </p>
                )}
              </FormGroup>
          </ModalBody>
          <ModalFooter>
          <Button color="light" onClick={onClose} disabled={submitting}>Cancel</Button>{' '}
          <Button
            type="submit"
            withLoader={true}
            loading={submitting}
            loaderText="Submitting"
            disabled={submitting}>
            Add Student
          </Button>
        </ModalFooter>
        </form>
      </Modal>
  );
};

AddStudentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddStudentModal;