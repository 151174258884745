export const ASSIGNMENTS_LOAD_START   = 'ASSIGNMENTS_LOAD_START';
export const ASSIGNMENTS_LOAD_SUCCESS = 'ASSIGNMENTS_LOAD_SUCCESS';
export const ASSIGNMENTS_LOAD_ERROR   = 'ASSIGNMENTS_LOAD_ERROR';
export const ASSIGNMENT_CREATE_START = 'ASSIGNMENT_CREATE_START';
export const ASSIGNMENT_CREATE_SUCCESS = 'ASSIGNMENT_CREATE_SUCCESS';
export const ASSIGNMENT_CREATE_ERROR = 'ASSIGNMENT_CREATE_ERROR';
export const ASSIGNMENT_UPDATE_START = 'ASSIGNMENT_UPDATE_START';
export const ASSIGNMENT_ASSIGN_START = 'ASSIGNMENT_ASSIGN_START';
export const ASSIGNMENT_ASSIGN_SUCCESS = 'ASSIGNMENT_ASSIGN_SUCCESS';
export const ASSIGNMENT_DELETE_SUCCESS = 'ASSIGNMENT_DELETE_SUCCESS';

export const ASSIGNMENT_ATTACHMENT_CREATE_SUCCESS = 'ASSIGNMENT_ATTACHMENT_CREATE_SUCCESS';
export const ASSIGNMENT_ATTACHMENT_DELETE_SUCCESS = 'ASSIGNMENT_ATTACHMENT_DELETE_SUCCESS';

export const ASSIGNMENT_LOAD_START = 'ASSIGNMENT_LOAD_START';
export const ASSIGNMENT_LOAD_SUCCESS = 'ASSIGNMENT_LOAD_SUCCESS';
export const ASSIGNMENT_LOAD_ERROR = 'ASSIGNMENT_LOAD_ERROR';

export const STUDENT_ASSIGNMENTS_LOAD_START = 'STUDENT_ASSIGNMENTS_LOAD_START';
export const STUDENT_ASSIGNMENTS_LOAD_SUCCESS = 'STUDENT_ASSIGNMENTS_LOAD_SUCCESS';
export const STUDENT_ASSIGNMENTS_LOAD_ERROR = 'STUDENT_ASSIGNMENTS_LOAD_ERROR';
