import PropType from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import * as assignmentSelector from "modules/assignments/selectors";

import { AssignmentCard, Loader } from "core/components";
import { allAssignments } from "./action";
import {useEffect, useState} from "react";

const AssignmentsList = ({ assignments }) => {
  const [publishedAssignments, setPublishedAssignments] = useState([]);
  const [draftAssignments, setDraftAssignments] = useState([]);
  const [scheduledAssignments, setScheduledAssignments] = useState([]);

  const dispatch = useDispatch();
  const meta = useSelector(assignmentSelector.meta);
  const canLoadMore = meta.current_page < meta.total_pages;
  const user = JSON.parse(sessionStorage.getItem('user'));
  const isTeacher = user?.role === 'teacher';

  const formatParams = (page = null) => ({
    channelId: sessionStorage.getItem('channelId'),
    tenantId: sessionStorage.getItem('tenantId'),
    page,
  });

  const fetchMoreAssignments = async (next_page = meta.next_page) => {
    try {
      const params = formatParams(next_page);

      await dispatch(allAssignments(params));
    } catch (e) {
      // Show the user an error
    }
  };


  // const studentAssignments = async (studentId) => {
  //   try {
  //     const params = {
  //       ...formatParams(),
  //       studentId,
  //     };
  //
  //     return await dispatch(allStudentAssignments(params));
  //   } catch (e) {
  //   }
  // };

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center mb-4">
        <Loader size="25px" loading={true} color="#2c7be5" speedMultiplier={1} />
      </div>
    );
  };

  useEffect(() => {
    let published = [], draft = [], scheduled = [];
    assignments.map(assignment => {
      if (assignment.status === 'published') published.push(assignment);
      if (assignment.status === 'draft') draft.push(assignment);
      if (assignment.status === 'scheduled') scheduled.push(assignment);
    })

    setPublishedAssignments(() => published);
    setDraftAssignments(() => draft);
    setScheduledAssignments(() => scheduled);
  }, [assignments])

  return (
    <div className="h-100">
      {
        draftAssignments.length > 0 && isTeacher &&
        <div className='mb-5'>
          <div style={{color: '#344054'}} className='font-size-18 font-weight-600 mb-3'>Draft Assessments</div>
          <div className="row">
            {draftAssignments.map(assignment => (
              <AssignmentCard
                key={assignment.id}
                assignment={assignment}
                isDraft={true}
              />
            ))}
          </div>
        </div>
      }

      {
        scheduledAssignments.length > 0 && isTeacher &&
        <div className='mb-5'>
          <div style={{color: '#344054'}} className='font-size-18 font-weight-600 mb-3'>Scheduled Assessments</div>
          <div className="row">
            {scheduledAssignments.map(assignment => (
              <AssignmentCard
                key={assignment.id}
                assignment={assignment}
                isScheduled={true}
              />
            ))}
          </div>
        </div>
      }

      {
        publishedAssignments.length > 0 &&
        <div>
          {
            isTeacher &&
            <div style={{color: '#344054'}} className='font-size-18 font-weight-600 mb-3'>Published Assessments</div>
          }

          <InfiniteScroll next={fetchMoreAssignments} hasMore={canLoadMore} loader={<Loading/>} dataLength={assignments.length}>
            <div className="row">
              {publishedAssignments.map(assignment => (
                <AssignmentCard
                  key={assignment.id}
                  assignment={assignment}
                />
              ))}
            </div>
          </InfiniteScroll>
        </div>
      }
    </div>
  );
};

AssignmentsList.propType = {
  assignments: PropType.array.isRequired,
};

export default AssignmentsList;
