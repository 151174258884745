import NurtureLogo from 'assets/images/nurture_logo.svg';
import InfoIcon from 'assets/images/general_icons/info.svg';
import {autolinker, fileTypeIcon, markdownToHtml} from "core/utils";
import {useSchoolConfig} from "../../../core/hooks";

const assignmentHtml = async (assignment) => {
  return `
    <!DOCTYPE html>
    <html lang="en">
      <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <link rel="stylesheet" href="https://fonts.cdnfonts.com/css/cerebri-sans" />
          <style>
            .body {
              font-family: "Cerebri Sans", sans-serif;
              font-size: 15.5px;
              padding: 0 50px;
              margin: 0;
              font-weight: normal;
              line-height: 1.5;
              color: #12263F;
              text-align: left;
            }
            
            .assignment-name {
              font-weight: 600;
              font-size: 20px;
            }
            
            .confidence-score {
              padding: 20px;
              display: flex;
              border: solid 1.5px #B1C2D9;
              border-radius: 10px;
              align-items: center;
            }
            
            .attachment {
              padding: 20px;
              display: flex;
              border: solid 1.5px #B1C2D9;
              border-radius: 10px;
              align-items: center;
              background-color: #F9FBFD;
            }
            
            .grading {
              padding: 20px;
              display: flex;
              border: solid 1.5px #B1C2D9;
              border-radius: 10px;
              align-items: center;
              background-color: #F9FBFD;
            }
           
            .d-flex {
              display: flex;
            }
           
            .py-3 {
              padding-top: 1rem!important;
              padding-bottom: 1rem!important;
            }
            
            .px-3 {
              padding-right: 1rem!important;
              padding-left: 1rem!important;
            }
            .mb-4 {
                margin-bottom: 2rem!important;
            }
            .mb-3 {
                margin-bottom: 1rem!important;
            }
            .mb-2 {
                margin-bottom: 0.5rem!important;
            }
            .mt-2 {
                margin-top: 0.5rem!important;
            }
            .mt-4 {
                margin-top: 1.5rem!important;
            }
            .align-items-center {
                align-items: center!important;
            }
            .me-3 {
              margin-right: 1rem!important;
            }
            .me-2 {
              margin-right: 0.5rem!important;
            }
            .rounded {
              border-radius: 0.375rem;
            }
            .avatar-sm {
                width: 2.5rem;
                height: 2.5rem;
                font-size: 0.8333333333rem;
            }
            .avatar {
                position: relative;
                display: inline-block;
                width: 3rem;
                height: 3rem;
                font-size: 1rem;
            }
            .rounded-circle {
                border-radius: 50%!important;
            }
            .bg-secondary {
                --bs-bg-opacity: 1;
                background-color: rgb(108,117,125)!important;
            }
            .avatar-title {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                line-height: 0;
                background-color: #B1C2D9;
                color: #FFFFFF;
            }
            
            table {
                caption-side: bottom;
                border-collapse: collapse;
                width: 100%;
                margin-bottom: 1rem;
                color: #212529;;
                vertical-align: top;
                border-color: #dee2e6;;
            }
            
            .table-responsive {
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
            }
            
            .table-nurture-striped th {
              background-color: #EDF2F9;;
            }
          
            .table-nurture-striped tr:nth-child(even) td {
              background-color: #EDF2F9;;
            }
        
            .table-nurture-striped tr:nth-child(odd) td {
              background-color: #D2DDEC !important;
            }
            
            /*table, tr {*/
            /*  overflow-y: visible;*/
            /*  overflow-x: visible;*/
            /*}*/
            
            tr, table, div, img {
              page-break-inside: auto;
              page-break-before: avoid;
            }
          </style>
      </head>
      <body>
      <div class="body">
         <div class="assignment-name mb-4">
           ${assignment.name}
         </div>
         
         ${studentConfidence()}
         
         <div style="margin-top: 50px;">
           <div style="margin-bottom: 10px"><strong>Assessment Description</strong></div>  
           <div>${markdownToHtml(autolinker(assignment.description))}</div>
         </div>
         
         <div style="margin-top: 50px;">
           <div style="margin-bottom: 10px"><strong>Learning Objectives</strong></div>  
           ${assignmentObjectives(assignment.assignment_objectives)}
         </div>
         
         <div class="html2pdf__page-break"></div>
         
         <div>
           ${await attachments(assignment.attachments)}
         </div>
         
         <div style="margin-top: 50px;">
           <div style="margin-bottom: 10px"><strong>Grading</strong></div>  
           ${grading(assignment)}
         </div>
         
         ${Rubrics(assignment)}           
      </div>
    </body>
    </html>
  `;
};

const assignmentObjectives = (objectives) => {
  let result = '';

  objectives.forEach((objective, index) => (
    result += `
      <div class='d-flex align-items-center mt-2 mb-3 px-3 py-3 rounded'
           style="background-color: #E4EBF6">
        <div class='me-3'>
          <div class='avatar avatar-sm'>
            <div class='avatar-title font-size-md rounded-circle bg-secondary'>${index + 1}</div>
          </div>
        </div>
        <div class='col p-0 font-size-16 mb-3'>${objective.name}</div>
      </div>
    `
  ))

  return result;
};

const isImage = (attachment) => {
  return attachment.name.endsWith('.jpg') ||
    attachment.name.endsWith('.jpeg') ||
    attachment.name.endsWith('.svg') ||
    attachment.name.endsWith('.png');
}

/**
 * Converts an image URL to base64
 * @param url
 * @returns {Promise}
 */
function toDataURL(url) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = function () {
      reject({
        statusText: xhr.statusText
      });
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

const attachments = async (attachments) => {
  let result = '';

  if (attachments?.length > 0) {
    result += '<div style="margin-bottom: 10px"><strong>Attachments</strong></div>'
  }

  const imageAttachments = attachments.filter((attachment) => isImage(attachment));
  const nonImageAttachments = attachments.filter(attachment => !isImage(attachment));

  for (let i = 0; i < imageAttachments.length; i++) {
    const attachment = imageAttachments[i];
    const url = await toDataURL(attachment.view_url)
    result += `
      <div style="margin-top: 30px" class="mb-3">
        <img width="300" src=${url} >
        <figcaption><i><small>${attachment.name}</small></i></figcaption>
      </div>
    `
  }

  if (nonImageAttachments.length > 0) {
    result += `
      <div class="mt-4">
        The following attachments can be viewed / downloaded on the Nurture app.
      </div>
    `
  }
  for (let i = 0; i< nonImageAttachments.length; i++) {
    const attachment = nonImageAttachments[i];
    const filePlaceholder = fileTypeIcon(attachment.name);

    result += `
      <div class="attachment mb-3">
        <img src=${filePlaceholder} alt="NurtureLogo">
        <div style="margin-left: 20px">${attachment.name}</div>
      </div>
    `;
  }

  return result;
};

const grading = (assignment) => {
  const marksDisplay = {
    marks: 'Marks',
    rubrics: 'Rubric',
    percentage: 'Percentage'
  }

  let result = `<div class="grading"><img src=${InfoIcon} >`;

  if (assignment.graded) {
    result += `
      <div style="margin-left: 20px">
        This assessment is graded using <strong>'${marksDisplay[assignment.grade_display_to_students]}'</strong>. &nbsp;
        The maximum marks available is <strong>${assignment.max_marks}</strong>
      </div>
    `
  } else {
    result += `<div style="margin-left: 20px">This assessment is not graded.</div>`
  }

  result += `</div>`

  return result;
}

/**
 * This method is intentionally started with a capital letter because we are using a hook inside of it
 * This is to trick react to assume that this is a component since it starts with a capital letter
 * @param assignment
 * @returns {string}
 * @constructor
 */
const Rubrics = (assignment) => {
  const { learningObjectiveLabel } = useSchoolConfig();

  if (!assignment.rubrics) return '';

  let result = `
    <div class="html2pdf__page-break"></div>
    
    <div>
      <div style="margin-bottom: 200px"><strong>Rubrics</strong></div>
      <div style="display: flex; align-items: center; justify-content: center">
        <table style="font-size: 13px; transform: rotate(90deg)" class="table rounded table-bordered table-nurture-striped">
          <thead>
          <tr>
            <th class="p-3" style="min-width: 150px; max-width: 150px;">${learningObjectiveLabel}</th>
  `

  const rubrics = assignment.rubrics
  const rubricColumns = rubrics[0].columns

  for (let i = 0; i < rubricColumns.length; i++) {
    const column = rubricColumns[i];

    result += `
      <th style="min-width: 150px; max-width: 150px; padding: 0.5rem" >
        <div class="d-flex" style="justify-content: space-between">
          <span>${column.name}</span>
        </div>
      </th>
    `
  }

  result += `</tr></thead><tbody>`

  for (let i = 0; i < rubrics.length; i++) {
    const rubric = rubrics[i];
    result += `
      <tr style="padding: 0.5rem">
        <td style="min-width: 150px; max-width: 150px; padding: 0.5rem">
          <strong>${ rubric['Learning Outcome'] }</strong>
        </td>
    `

    for (let j = 0; j < rubric.columns.length; j++) {
      const column = rubric.columns[j]

      result += `
        <td style="min-width: 150px; max-width: 150px; padding: 0.5rem">
          <span>${column.value}</span>
       </td>   
      `
    }

    result += `</tr>`
  }

  result += `</tbody></table></div></div>`


  return result;
};

const studentConfidence = () => {
  return `
    <div>
      <div class="confidence-score">
        <img src=${NurtureLogo} alt="NurtureLogo">
        <div style="margin-left: 20px">
          <div>How confident were you on this assessment? Let your teacher know!</div>
          <div>Log into the Nurture app and add your confidence score to this assessment!</div>
        </div>
      </div>
    </div>   
  `;
}

export default assignmentHtml;
