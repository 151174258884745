import * as t from './actionTypes';

const INITIAL_STATE = {
    loading: false,
    filestack: {},
};

const attachmentsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case t.ATTACHMENTS_FILESTACK_LOAD_START:
        case t.ATTACHMENTS_CREATE_START:
        case t.ATTACHMENTS_DELETE_START:
        case t.ATTACHMENTS_SHOW_START:
            return { ...state, loading: true };

        case t.ATTACHMENTS_FILESTACK_SUCCESS:
            return {
                ...state,
                loading: false,
                filestack: action.filestack,
            };

        default:
            return state;
    }
};

export default attachmentsReducer;
