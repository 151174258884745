import React, {Fragment} from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import Button from "core/components/Button";
import { useNavigate } from "react-router-dom";

const CancelModal = ({ show, onDelete, assignment, context, onClose, onSaveDraft, ...otherProps }) => {
  const navigateTo = useNavigate();

  const new_assignment_or_draft = context === 'assignment' && assignment?.status === 'draft';
  const text = new_assignment_or_draft ?
    'Do you want to save this assignment before exiting?' :
    'You have unsaved changes. Do you want to exit?';

  const goBack = () => navigateTo(-1);

  const closeModal = () => {
    onClose();
  };

  const saveDraft = () => onSaveDraft();

  return (
    <Modal isOpen={show} zIndex={21800000000} {...otherProps}>
      <ModalHeader charcode="x" tag="h5">Confirm Action</ModalHeader>
      <ModalBody>{text}</ModalBody>
      <ModalFooter>
        {
          new_assignment_or_draft &&
            <Fragment>
              <Button outline={true} color="nurture-orange" onClick={goBack} disabled={otherProps.submitting}>Exit without saving</Button>
              <Button
                color="nurture-purple"
                onClick={saveDraft}
                disabled={otherProps.submitting}
                withLoader
                loading={otherProps.submitting}
                loaderText="Saving">
                Save as Draft
              </Button>
            </Fragment>
        }

        {
          !new_assignment_or_draft &&
          <Fragment>
            <Button outline={true} color="nurture-orange" onClick={goBack}>Exit without updating</Button>
            <Button color="nurture-purple" onClick={closeModal}>Continue editing</Button>
          </Fragment>
        }
      </ModalFooter>
    </Modal>
  );
}

CancelModal.defaultProps = {
  context: 'assignment',
  onClose: undefined,
};

CancelModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
  context: PropTypes.string,
  assignment: PropTypes.any.isRequired
}

export default CancelModal;
