import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import TextHeader from "core/components/TextHeader";
import { Button } from "core/components";
import GroupFeedback from "./index";
import {useEffect, useRef, useState} from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { assignmentObjectiveUpdate, assignmentShowSuccess } from "../../action";
import { updateArray } from "core/utils/array";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

const GroupFeedbackModal = ({ show, toggle, selectedIndex, assignment, assignmentObjective }) => {
  console.log(assignmentObjective)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)

  const modalRef = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    'answer_correctness': yup.string(),
    'student_understanding': yup.string(),
    'action_feedback': yup.string(),
    'process_feedback': yup.string(),
    'next_steps': yup.string(),
    'general_feedback': yup.string(),
  });

  const form = useForm({ resolver: yupResolver(schema) });

  const { handleSubmit, reset } = form;

  const onSubmit = async (data) => {
    try {
      setSubmitButtonDisabled(true);
      const response = await assignmentObjectiveUpdate(assignment.id, assignmentObjective.id, data);

      /** Update the assignment state to reflect the change in assignment objective **/
      const assignmentObjectivesClone = [...assignment.assignment_objectives];
      const updatedAssignmentObjectives = updateArray(assignmentObjectivesClone, response);
      const updatedAssignment = { ...assignment, assignment_objectives: updatedAssignmentObjectives };
      dispatch(assignmentShowSuccess(updatedAssignment));
      enqueueSnackbar('Your feedback has been submitted', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setSubmitButtonDisabled(false);
    }
  }

  useEffect(() => {
    reset();
  }, [show])

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      modalClassName="fixed-right"
      className="modal-dialog-vertical"
      zIndex={2000000001}
    >
      <ModalHeader toggle={toggle} className="card-header" tag='div'>
        <TextHeader>Learning Objective Feedback</TextHeader>
      </ModalHeader>
      <ModalBody id="assignmentModalBody">
        <div ref={modalRef} />
        <GroupFeedback selectedIndex={selectedIndex} assignment={assignment} assignmentObjective={assignmentObjective} form={form} />
      </ModalBody>
      <ModalFooter className="d-block">
        <div className="d-grid">
          <Button withLoader className="py-2 justify-content-center" disabled={submitButtonDisabled} type="button" color="nurture-purple" onClick={handleSubmit(onSubmit)}>
            {
              assignmentObjective?.feedback_objectives?.length && assignmentObjective?.feedback_completed ? 'Update Feedback' : 'Create Feedback'
            }
          </Button>
        </div>
      </ModalFooter >
    </Modal >
  );
};

GroupFeedbackModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  assignmentObjective: PropTypes.any.isRequired,
  assignment: PropTypes.any.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

export default GroupFeedbackModal;