import React, { Fragment } from "react";
import { ClipLoader, FadeLoader, PropagateLoader, RingLoader } from "react-spinners";
import PropTypes from "prop-types";

const Loader = ({color, loading, size, type, ...rest}) => {
  return (
    <Fragment>
      {
        type === 'clip' &&
        <ClipLoader
          size={size}
          color={color}
          loading={loading}
          {...rest}
        />
      }

      {
        type === 'propagate' &&
          <PropagateLoader
            size={size}
            color={color}
            loading={loading}
            {...rest}
          />
      }

      {
        type === 'fade' &&
        <FadeLoader
          color={color}
          loading={loading}
          {...rest}
        />
      }

      {
        type === 'ring' &&
          <RingLoader
            color={color}
            loading={loading}
            {...rest}
          />
      }
    </Fragment>
  )
};

Loader.defaultProps = {
  color: '#ffffff',
  loading: false,
  size: '18px',
  type: 'clip',
};

Loader.propTypes = {
  color: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['clip', 'propagate', 'fade', 'ring']),
};

export default Loader;
