import CheckMarkNurturePurple from "assets/images/general_icons/check_mark_nurture_purple.svg";
import React from "react";

const FeedbackPublishedText = () => {
  return (
    <div className="mb-4">
      <div className="d-flex align-items-center">
        <img width={25} src={CheckMarkNurturePurple} />
        <div className="ms-1 text-nurture-purple font-size-20">Feedback has been published.</div>
      </div>

      <hr className="light-divider" />
    </div>
  )
};

export default FeedbackPublishedText;