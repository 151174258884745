import TeamsAuthenticate from './TeamsAuthenticate';
import BrowserAuthenticate from './BrowserAuthenticate';
import {envVariables, teamsEnv} from "../../core/utils";
import {useEffect} from "react";

const Authenticate = () => {
  const { tenantId, channelId, token } = teamsEnv;

  const setup = async () => {
    window.location.replace(`${envVariables.apiBase}/i/teams/${tenantId}/${channelId}`);
  };

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      window.location.replace(`/?tenant_id=${tenantId}&channel_id=${channelId}`);
    } else {
      setup();
    }
  }, []);

  return (
    <div>
    </div>
  );
};

export default Authenticate;
