import React from 'react';
import PropTypes from 'prop-types';

const AlertDismissible = ({ onDismiss, text, type, allowDismiss }) => (
  <div className={`alert alert-${type} ${allowDismiss ? 'alert-dismissible fade show' : '' }`} role="alert">
    {text}
    {
      allowDismiss &&
        <button
          onClick={onDismiss}
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close" />
    }
  </div>
);

AlertDismissible.defaultProps = {
  type: 'info',
  onDismiss: undefined,
  allowDismiss: true
};

AlertDismissible.propTypes = {
  onDismiss: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'dark', 'light', 'danger', 'info', 'success', 'warning']),
  allowDismiss: PropTypes.bool
};

export default AlertDismissible;
