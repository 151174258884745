import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { Button, Loader, EditableTable } from "core/components";
import { NurtureAIFeedback, CurriculumBoundarySwitch, referencesText, NoMatchModal, CurriculumBoundaryReferences } from 'core/components/nurtureAI'
import { useDispatch } from "react-redux";
import { generateRubrics } from "./action";
import { useSnackbar } from "notistack";
import { useSchoolConfig } from "core/hooks";
import {isPresent, pluralize, pushDataSubscriber, pusher} from "core/utils";
import { mdTableToJson } from "../editableTable/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {v4 as uuidv4} from "uuid";
import ConfirmModal from "../modals/Confirm";
import env_variables from "../../utils/env_variables";
import metricService from "../../../lib/metricService";
import {resetStartTime, setStartTime, streamResponse, trackDuration} from "./helpers";
import {Label} from "reactstrap";
import RubricsDropdown from "./RubricsDropdown/RubricsDropdown";
import RubricsCriteriaLabelsCount from "./RubricsCriteriaLabelsCount";


const NurtureAIRubricsGenerator = ({ onSuggest, assignment, learningObjectives }) => {
  const { learningObjectiveLabel, curriculumBoundaryEnabled: curriculumBoundaryFeatureEnabled, customRubricsEnabled } = useSchoolConfig();

  const STATES = { hint: 1, working: 2, suggestion: 3 };
  const LOADING_TEXTS = ['Making coffee', 'Generating Rubrics', 'Almost done']

  const [state, setState] = useState(curriculumBoundaryFeatureEnabled ? STATES.hint : STATES.working);
  const [curriculumBoundaryEnabled, setCurriculumBoundaryEnabled] = useState(false)
  const [suggested, setSuggested] = useState([]);
  const [extraTexts, setExtraTexts] = useState('');
  const [references, setReferences] = useState({});
  const [aiRequestId, setAiRequestId] = useState(null);
  const [loadingText, setLoadingText] = useState(LOADING_TEXTS[0]);
  const [showNoMatchModal, setShowNoMatchModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [labelsCount, setLabelsCount] = useState(5);
  const [criteriaLabel, setCriteriaLabel] = useState('custom_criteria');

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  pusher.connection.bind('error', function(error) {
    console.error('Pusher error:', error);
  });


  const toggleLoadingText = () => {
    setLoadingText(LOADING_TEXTS[0]);
    let currentIndex = 0
    return setInterval(() => {
      if (currentIndex < LOADING_TEXTS.length - 1) {
        setLoadingText(LOADING_TEXTS[currentIndex + 1])
        currentIndex += 1
      }
    }, 3000);
  }

  const handleCurriculumBoundaryChanged = (e) => {
    setCurriculumBoundaryEnabled(!curriculumBoundaryEnabled)
  }

  const generateHints = () => {
    let base = ''

    learningObjectives.forEach((item, index) => {
      base += `${index + 1}. ${item.name} \n`
    });

    return base;
  };

  const generateSuggestionsAsync = async () => {
    if (learningObjectives.filter(item => !!item.name).length <= 0) {
      enqueueSnackbar(`Rubrics cannot be generated on empty ${pluralize(learningObjectiveLabel).toLowerCase()}`, { variant: 'error' });
      return
    }

    const intervalId = toggleLoadingText();
    setState(STATES.working)

    try {
      const requestId = uuidv4().replace(/-/g, '');
      // const channelName = `rubricsGeneration-${requestId}`;
      // const pusherChannel = pusher.subscribe(channelName);

      const requestData = {
        hint: generateHints(),
        assignment_id: assignment?.id,
        rubrics: true,
        curriculumn_boundary_enabled: curriculumBoundaryEnabled,
        labelsCount: labelsCount,
        criteriaLabel: criteriaLabel,
        // request_id: requestId,
        // channel_name: channelName,
        // request_id: requestId,
        // channel_name: channelName,
        run_in_background: true
      };

      // return;
      setStartTime();
      const response =  await dispatch(generateRubrics(requestData))
      const threadId = response.thread_id;
      console.log(response)
      // const pushDataAttributes = { channel: pusherChannel, requestId, channelName, event: 'rubricsData' };
      // const aggregatedData = await pushDataSubscriber(pushDataAttributes);

      streamResponse(`thread_id=${threadId}&rubrics=true`, handleStreamEvent);

      clearInterval(intervalId);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      setState(STATES.suggestion)
      resetStartTime();

      metricService.track({ event: 'ai_failed', properties: { scope: 'rubrics_generation' } });

      clearInterval(intervalId);
    }
  };

  const generateSuggestions = generateSuggestionsAsync;


  const handleStreamEvent = async (event) => {
    const messageEvent = JSON.parse(event.data);

    switch (messageEvent.event) {
      case 'assistant.rubrics':
        console.log(messageEvent);
        handleAiSuggestion(messageEvent.data);
        break;

      case 'thread.error':
        handleErrorEvent(messageEvent);
        break;

      case 'thread.run.failed':
        handleErrorEvent(messageEvent);
        break;
    }
  }

  const handleErrorEvent = (event) => {
    enqueueSnackbar('Error occurred while generating rubrics', { variant: 'error' });
    setState(STATES.showHint)
    resetStartTime();
    metricService.track({ event: 'ai_failed', properties: { scope: 'rubrics_generation' } });
  }

  const handleAiSuggestion = (aggregatedData) => {
    const rubricsToJson = mdTableToJson(aggregatedData.comment, learningObjectives)
    setSuggested(() => rubricsToJson)
    setAiRequestId(aggregatedData.id);
    if (aggregatedData.no_curriculum_match) {
      setShowNoMatchModal(true);
      setState(STATES.hint);
    } else if (isPresent(aggregatedData.references)) {
      setReferences(() => aggregatedData.references)
      setExtraTexts(`References: pages ${referencesText(aggregatedData.references)} from the uploaded document`)
      setState(STATES.suggestion);
    } else {
      setState(STATES.suggestion);
    }

    trackDuration('rubrics_generation');
    metricService.track({ event: 'ai_success', properties: { scope: 'rubrics_generation' } });
  }

  const handleSuggest = () => {
    if (typeof onSuggest === 'function') {
      onSuggest(suggested);
      enqueueSnackbar('The suggestions has been applied', { variant: 'success' })
    }
  };

  const tryAgain = () => {
    setShowNoMatchModal(false);
    setReferences([]);
    setExtraTexts('');
    setCriteriaLabel('custom_criteria')

    if (!curriculumBoundaryFeatureEnabled && !customRubricsEnabled) generateSuggestions();
    else {
      setState(STATES.hint)
    }
  };

  useEffect(() => {
    if (!curriculumBoundaryFeatureEnabled && !customRubricsEnabled) generateSuggestions();
  }, [])

  return (
    <div>
      {
        state === STATES.hint && (curriculumBoundaryFeatureEnabled) &&
          <>
            {
              curriculumBoundaryFeatureEnabled &&
              <>
                {
                  customRubricsEnabled &&
                  <div>
                    <div className="mb-3">
                      <RubricsDropdown onSelect={(value) => setCriteriaLabel(value)}/>
                    </div>

                    {
                      criteriaLabel === 'custom_criteria' &&
                      <div>
                        <RubricsCriteriaLabelsCount onSetCount={(value) => setLabelsCount(value)}/>
                      </div>
                    }
                  </div>
                }

                <CurriculumBoundarySwitch
                  id="rubrics-curriculum-boundary-switch"
                  labelClass="text-muted"
                  checked={curriculumBoundaryEnabled}
                  onCurriculumBoundarySwitchChanged={handleCurriculumBoundaryChanged}/>

                <div className="mt-4 border-top pt-3 text-end">
                  <Button color="light" onClick={generateSuggestions}>Generate Rubrics ✨</Button>
                </div>

                <NoMatchModal
                  context="rubrics"
                  show={showNoMatchModal}
                  onContinue={() => setState(STATES.suggestion)}
                  closeModal={() => setShowNoMatchModal(false)}
                />
              </>
            }
          </>
      }

      {
        state === STATES.working &&
        <div>
          <div className="d-flex justify-content-center mb-3">
            <Loader type="ring" loading={true} color="#6CBDBF" />
          </div>
          <div className="font-size-15 text-muted mb-3 text-center">{loadingText}...</div>
        </div>
      }

      {
        state === STATES.suggestion &&
        <div>
          <div className="mb-4">
            <label className="form-label text-muted">Suggestion</label>
            <div className="card">
              <div className="table-responsive">
                <EditableTable defaultData={suggested} learningObjectives={learningObjectives} onUpdated={(data) => setSuggested(data)} />
              </div>
            </div>

            {
              !!extraTexts && curriculumBoundaryFeatureEnabled && Object.keys(references).length <= 0 &&
              <div className="alert alert-light p-2 mt-2 border-none">
                <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
                <small>{extraTexts}</small>
              </div>

            }

            {/*References*/}
            {
              Object.keys(references).length > 0 && curriculumBoundaryFeatureEnabled &&
                <CurriculumBoundaryReferences id="rubricReference" extraTexts={extraTexts} references={references} />
            }
          </div>

          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="mb-2">
              <NurtureAIFeedback aiRequestId={aiRequestId} />
            </div>
            <div>
              <Button color="transparent" className="me-2 text-white" onClick={tryAgain}>Retry</Button>
              <Button color="light" onClick={() => setShowConfirmModal(true)}>Use Suggestion</Button>
            </div>
          </div>

          <ConfirmModal
            show={showConfirmModal}
            onConfirm={handleSuggest}
            title="Replace?"
            text="This will replace the entire table, are you sure you want to continue?"
            toggle={() => setShowConfirmModal(!showConfirmModal)}
          />
        </div>
      }
    </div>
  );
}

NurtureAIRubricsGenerator.defaultProps = {
  onSuggest: null,
  learningObjectives: [],
};

NurtureAIRubricsGenerator.propTypes = {
  onSuggest: PropTypes.func,
  assignment: PropTypes.any.isRequired,
  learningObjectives: PropTypes.any,
};

export default NurtureAIRubricsGenerator;