import { Switch } from "core/form/fields";
import React from "react";
import PropTypes from "prop-types";

const LanguageSupportSwitch = ({ checked, label, labelClass, id, onLanguageSwitchChanged, overrideClasses }) => {
  return (
    <div className="mb-3 mt-4">
      <div className={overrideClasses}>
        <Switch
          id={id}
          labelClass={labelClass}
          label={label}
          checked={checked}
          value={5}
          onClick={onLanguageSwitchChanged}
        />
      </div>
    </div>
  );
};

LanguageSupportSwitch.defaultProps = {
  id: 'language-support',
  labelClass: '',
  overrideClasses: 'form-check form-switch ps-3'
}

LanguageSupportSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onLanguageSwitchChanged: PropTypes.func.isRequired,
  id: PropTypes.string,
  labelClass: PropTypes.string,
  overrideClasses: PropTypes.string,
  label: PropTypes.string.isRequired,
}

export default LanguageSupportSwitch;
