import "easymde/dist/easymde.min.css";
import PropTypes from 'prop-types';
import {useEffect, useMemo, useRef, useState} from "react";
import SimpleMdeReact from "react-simplemde-editor";
import { autoEmbed, autolinker, markdownToHtml } from "core/utils";

const MarkdownEditorField = ({ className, initialValue, error, maxHeight, streamable, onChange, ...rest }) => {
  const [value, setValue] = useState('');
  const editorRef = useRef(null);

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
  };

  const options = useMemo(() => {
    return {
      toolbar: [],
      previewRender: (content) => markdownToHtml(autolinker(autoEmbed(content))),
      spellChecker: false,
      maxHeight: maxHeight,
    }
  }, []);

  useEffect(() => {
    setValue(initialValue)

    // if (editorRef.current && streamable) {
    //   // const editorElement = editorRef.current;
    //   const scrollbar = document?.querySelector('.CodeMirror-vscrollbar');
    //   // Scroll the editor to the bottom
    //   if (scrollbar) scrollbar.scrollTop = scrollbar.scrollHeight
    // }
  }, [initialValue]);

  return (
    <div className={`${error ? 'error' : ''}`}>
      <SimpleMdeReact
        ref={editorRef}
        options={options}
        className={className}
        value={value}
        placeholder="Write something here..."
        onChange={handleChange}
        {...rest}
      />
    </div>
  );
};

MarkdownEditorField.defaultProps = {
  error: false,
  initialValue: '',
  maxHeight: '200px',
  streamable: false,
};

MarkdownEditorField.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  maxHeight: PropTypes.string,
  streamable: PropTypes.bool,
};


export default MarkdownEditorField;
