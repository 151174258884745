import { createRequest, composeRequestConfig } from 'core/utils/axios/api';
import { teamsEnv } from 'core/utils';

export const update = (attachmentId, payload) => {
  const url = `api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/annotations/${attachmentId
}`;
  const config = composeRequestConfig({
    url,
    payload: { data: payload },
    method: 'patch',
  });

  return createRequest(config);
};
