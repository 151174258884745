const envVariables = {
  apiBase: process.env.REACT_APP_API_BASE,
  appBaseUrl: process.env.REACT_APP_APP_BASE_URL,
  intercom: {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  },
  pusher: {
    app_id: process.env.REACT_APP_PUSHER_APP_ID,
    key: process.env.REACT_APP_PUSHER_KEY,
    secret: process.env.REACT_APP_PUSHER_SECRET,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER
  },
  ai_processing: process.env.REACT_APP_AI_PROCESSING || 'async',
  microsoftTeams: {
    clientId: process.env.REACT_APP_MS_TEAMS_CLIENT_ID,
  },
};
export default envVariables;