import jwt_decode from "jwt-decode";

const isJwtExpired = (token) => {  
  if (typeof (token) !== 'string' || !token) return true;
  try {
    const { exp } = jwt_decode(token);

    const currentTime = new Date().getTime() / 1000;

    return currentTime > exp;

  } catch (error) {

    return true;

  }
};

export default isJwtExpired;
