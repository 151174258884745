import React from "react";
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

const SelectField = React.forwardRef(({ label, options, required, className, error, ...otherProps }, ref) => {
  return (
    <>
      <Label htmlFor={label}>{label} {required && <span className="required">*</span>}</Label>
      <select
        className={`form-select ${className} ${error ? 'error' : ''}`}
        ref={ref}
        {...otherProps}
      >
        {options.map(option =>
          <option key={option.value} value={option.value}>{option.label}</option>
        )}
      </select>
    </>
  );
});

SelectField.defaultProps = {
  required: false,
  error: false,
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })).isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
};

export default SelectField;
