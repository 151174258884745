import React from "react";
import { Container } from "reactstrap";
const Switch = React.forwardRef(({ label, labelClass, id, ...rest }, ref) => (
  <div className="ps-3">
    <div className="form-check form-switch switch">
      <input style={{minWidth: '50px'}} className="form-check-input me-2 mt-0" type="checkbox" role="switch" id={id} ref={ref} {...rest} />
      <label className={`form-check-label ${labelClass}`} htmlFor={id}>{label}</label>
    </div>
  </div>
));

Switch.defaultProps = {
  id: 'customSwitch',
  labelClass: 'text-muted'
}

export default Switch;
