import AssignmentDatesInfo from "modules/assignments/AssignmentDatesInfo";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useAssignmentInfo} from "core/hooks";
import PropTypes from "prop-types";
import StudentAssessmentStatusBadges from "./StudentAssessmentStatusBadges";
import {Emoji} from "../index";
import {getMinWidth} from "ka-table/Utils/CellResizeUtils";

const StudentAssignmentCard = ({ assignment }) => {
  const navigateTo = useNavigate();
  const sessionUser = JSON.parse(sessionStorage.getItem('user'));
  const role = sessionUser?.role;

  const {
  } = useAssignmentInfo(assignment);


  const navigateToShow = () => navigateTo(`/assignments/${assignment.id}`);

  const StudentGrade = () => {
    if (!assignment.graded) return <div className="font-size-24">N/A</div>
    if (!assignment.grade) return <div className="font-size-24">?</div>

    const gradePercentage = Math.round(parseFloat((assignment.grade / assignment.max_marks) * 100));
    const gradeTypes = {
      rubrics: <><span className="font-size-24">{assignment.grade}</span><span className="font-size-15">/{assignment.max_marks}</span></>,
      marks: <><span className="font-size-24">{assignment.grade}</span><span className="font-size-15">/{assignment.max_marks}</span></>,
      percentage: <div className="font-size-24">{gradePercentage}%</div>
    };

    return gradeTypes[assignment.grade_display_to_students];
  }

  const isReviewed = assignment.user_assignment_status === 'reviewed'
  let buttonText = assignment.feedback_published_at && !assignment.reflection_completed_at && isReviewed ? 'Unlock Grade' : 'Open';
  if (buttonText === 'Unlock Grade' && !assignment.graded) buttonText = 'View Feedback'

  const buttonClass = buttonText === 'Unlock Grade' ? 'new-green-btn' : 'new-purple-btn'

  return (
    <div className="col-12">
      <div className="card mb-4 AssignmentCard">
        <div className="card-body p-0">
          <div className="row align-items-end px-2 px-sm-3 py-3">
            {/*Left*/}
            {/*Assessment title and status badges*/}
            <div className="col-xl-7 col-lg-6 col-md-6">
              <div className='font-size-20 font-weight-600 mb-3'>
                {assignment.name}
              </div>
              <div className="d-lg-flex flex-lg-wrap align-items-lg-center mb-3 mb-lg-0">
                <div>
                  <StudentAssessmentStatusBadges
                    dueAt={assignment.due_at}
                    studentSubmittedAt={assignment.submitted_at}
                    feedbackPublishedAt={assignment.feedback_published_at}
                    reflectionCompletedAt={assignment.reflection_completed_at}
                    isReviewed={isReviewed}
                  />
                </div>

                <div>
                  <AssignmentDatesInfo color='#667085' assignment={assignment} />
                </div>
              </div>
            </div>

            <div className="row align-items-end col-xl-5 col-lg-6 col-md-6">
              {/*Middle*/}
              <div className="d-flex align-items-end col-lg-8 mb-3 mb-sm-0">
                {/*Students Confidence*/}
                <div className="me-5 merriweather-font">
                  <div
                    className="font-size-24 font-weight-400 metrics-value">
                    {
                      !assignment.student_confidence && <div>?</div>
                    }

                    {
                      assignment.student_confidence &&
                        <div className="d-flex">
                          <Emoji level={assignment.student_confidence.level} />
                          <div className="font-size-14 ms-1">{assignment.student_confidence.text.split(' ')[0]}</div>
                        </div>
                    }
                  </div>
                  <div className="small-texts metric-label mt-2">Confidence</div>
                </div>
                {/*Students Grade*/}
                <div className="merriweather-font">
                  <div
                    className="font-size-24 font-weight-400 metrics-value">
                    {
                      assignment.reflection_completed_at && <StudentGrade />
                    }

                    {
                      !assignment.reflection_completed_at && '?'
                    }
                  </div>
                  <div className="small-texts metric-label mt-2">Grade</div>
                </div>
              </div>

              {/*Right*/}
              <div className="d-lg-flex ms-lg-0 justify-content-lg-end col-lg-4">
                <div className="">
                  <button className={`btn ${buttonClass} d-block w-100 d-lg-inline-block`} style={{minWidth: '135px'}}
                          onClick={navigateToShow}>{buttonText}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

StudentAssignmentCard.propType = {
  assignment: PropTypes.shape({}).isRequired,
};

export default StudentAssignmentCard;
