import NurtureLogo from 'assets/images/nurture_logo.svg';
import InfoIcon from 'assets/images/general_icons/info.svg';
import {autolinker, fileTypeIcon, isPresent, learningObjectivesHelper, markdownToHtml, userFullName} from "core/utils";
import {useSchoolConfig} from "../../../core/hooks";

const submissionHtml = async (submission, assignment) => {
  return `
    <!DOCTYPE html>
    <html lang="en">
      <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <link rel="stylesheet" href="https://fonts.cdnfonts.com/css/cerebri-sans" />
          <style>
            .body {
              font-family: "Cerebri Sans", sans-serif;
              font-size: 15.5px;
              padding: 0 60px;
              margin: 0;
              font-weight: normal;
              line-height: 1.5;
              color: #12263F;
              text-align: left;
            }
            
            .heading {
              font-size: 18px;
            }
            
            .assignment-name {
              font-weight: 600;
              font-size: 20px;
            }
            
            .confidence-score {
              padding: 20px;
              display: flex;
              border: solid 1.5px #B1C2D9;
              border-radius: 10px;
              align-items: center;
            }
            
            .attachment {
              padding: 20px;
              display: flex;
              border: solid 1.5px #B1C2D9;
              border-radius: 10px;
              align-items: center;
              background-color: #F9FBFD;
            }
            
            .card {
                margin-bottom: 1.5rem;
                box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
                background-clip: border-box;
                border: 1px solid #EDF2F9;
                border-radius: 0.5rem;
            }
            
            .grading {
              padding: 20px;
              display: flex;
              border: solid 1.5px #B1C2D9;
              border-radius: 10px;
              align-items: center;
              background-color: #F9FBFD;
            }
            
            .bg-nurture-orange-light {
                background-color: #FDEBEF !important;
            }
            
            .bg-nurture-orange {
              background-color: #ef674a !important;
            }
            
            .bg-nurture-green-variant {
              background-color:#6cddc9 !important;;
            }
            
            .bg-nurture-light-green-variant{ 
              background-color:#e6fcf3 !important;
            }
           
            .d-flex {
              display: flex;
            }
            
            .justify-content-between {
              justify-content: space-between;
            }
            
            .flex-grow-1 {
              flex-grow: 1;
            }
            
            .border-bottom {
                border-bottom: 1px solid #dee2e6; !important;
            }
           
            .py-3 {
              padding-top: 1rem!important;
              padding-bottom: 1rem!important;
            }
            
            .px-0 {
              padding-left: 0;
              padding-right: 0;
            }
            .px-3 {
              padding-right: 1rem!important;
              padding-left: 1rem!important;
            }
            .pb-3 {
              padding-bottom: 1rem !important;
            }
            .mb-4 {
                margin-bottom: 2rem!important;
            }
            .mb-3 {
                margin-bottom: 1rem!important;
            }
            .mb-2 {
                margin-bottom: 0.5rem!important;
            }
            .mt-2 {
                margin-top: 0.5rem!important;
            }
            .mt-4 {
                margin-top: 1.5rem!important;
            }
            .align-items-center {
                align-items: center !important;
            }
            .me-3 {
              margin-right: 1rem!important;
            }
            .me-2 {
              margin-right: 0.5rem!important;
            }
            .rounded {
              border-radius: 0.375rem;
            }
            .avatar-sm {
                width: 2.5rem;
                height: 2.5rem;
                font-size: 0.8333333333rem;
            }
            .avatar {
                position: relative;
                display: inline-block;
                width: 3rem;
                height: 3rem;
                font-size: 1rem;
            }
            .rounded-circle {
                border-radius: 50%!important;
            }
            .bg-secondary {
                --bs-bg-opacity: 1;
                background-color: rgb(108,117,125)!important;
            }
            .avatar-title {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                line-height: 0;
                background-color: #B1C2D9;
                color: #FFFFFF;
            }
            
            .font-weight-600 {
              font-weight: 600;
            }
            
            .border-bottom {
              border-bottom: 1px solid #dee2e6 !important;
            }
            
            table {
                caption-side: bottom;
                border-collapse: collapse;
                width: 100%;
                margin-bottom: 1rem;
                color: #212529;;
                vertical-align: top;
                border-color: #dee2e6;;
            }
            
            .table-responsive {
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
            }
            
            .table-nurture-striped th {
              background-color: #EDF2F9;;
            }
          
            .table-nurture-striped tr:nth-child(even) td {
              background-color: #EDF2F9;;
            }
        
            .table-nurture-striped tr:nth-child(odd) td {
              background-color: #D2DDEC !important;
            }
            
            /*table, tr {*/
            /*  overflow-y: visible;*/
            /*  overflow-x: visible;*/
            /*}*/
            
            tr, table, div, img {
              page-break-inside: auto;
              page-break-before: avoid;
            }
          </style>
      </head>
      <body>
      <div class="body">
        <div class="d-flex justify-content-between align-items-center">
          <div class="heading me-2">
            <strong style="font-weight: 600">${userFullName(submission?.user)}</strong> feedback for: ${assignment.name}       
          </div>
          <img width="90" src=${NurtureLogo} alt="NurtureLogo">
        </div>
        
        ${personalFeedback(submission)}       
        
        <div class="html2pdf__page-break"></div>
        
        ${await learningOutcomesThatNeedsFeedback(submission, assignment)}
        
        <div class="html2pdf__page-break"></div>
        
        ${learningOutcomesThatDoNotNeedFeedback(submission, assignment)}
        
        <div style="margin-top: 50px;">
           <div style="margin-bottom: 10px"><strong>Grading</strong></div>  
           ${grading(submission, assignment)}
        </div>
        
        ${Rubrics(assignment)}
        
        ${studentReflection()}
        
        <div class="html2pdf__page-break"></div>
        
        ${threeTwoOne(assignment)}
        
        ${studentThoughts()}
      </div>
    </body>
    </html>
  `;
};

const personalFeedback = (submission) => {
  return `
    <div style="margin-top: 30px">
      <div style="font-size: 17px; font-weight: bold" class="mb-3">Personal Feedback</div>
      <div>${markdownToHtml(autolinker(submission?.teacher_feedback?.personal_feedback))}</div>
    </div>
  `;
}

const learningOutcomesThatNeedsFeedback = async (submission, assignment) => {
  if (assignment.rubrics) return '';

  const { objectivesThatNeedsFeedback } = learningObjectivesHelper;

  const assignment_objectives = assignment?.assignment_objectives.map((item, index) => ({...item, position: index + 1})) || []
  const objectivesWithFeedback = objectivesThatNeedsFeedback(assignment_objectives, submission) || [];
  let result = ''

  if (objectivesWithFeedback?.length > 0) {
    result += '<div style="margin-top: 10px">'
    result += '<div style="font-size: 17px; font-weight: bold" class="mb-3">Learning outcomes <u>you did not achieve and need feedback on:</u></div>'

    for (let i = 0; i < objectivesWithFeedback.length; i++) {
      const objective = objectivesWithFeedback[i];

      result += `
        <div class="card bg-nurture-orange-light" style="margin-top: 20px">
          <div class="card-header px-0 bg-nurture-orange-light border-bottom">
            <div class='d-flex align-items-center mt-2 mb-3 px-3 rounded'>
              <div class='me-3'>
                <div class='avatar avatar-sm text-white'>
                  <div class='avatar-title font-size-md rounded-circle bg-nurture-orange'>${i + 1}</div>
                </div>
              </div>
              <div class='font-size-16 fs-6 flex-grow-1'>${objective.name}</div>
            </div>
          </div>
        `;

      if (objective.feedback?.action_feedback || objective.feedback?.process_feedback || !!objective.feedback?.next_steps || !!objective.feedback?.general_feedback) {
        result += `
          <div class="card-body">
            <div class="mb-2 font-weight-600">How did you do and how was your process</div>
            <p>${objective.feedback?.action_feedback || 'No feedback'}</p>
        `;

        if (objective.feedback.process_feedback) {
          result += `
          <div class="mb-2 font-weight-600">How was your process</div>
          <p>${objective.feedback?.process_feedback}</p>
        `;
        }

        result += `
          <div class="mb-2 font-weight-600">What are the next steps?</div>
          <p>${objective.feedback?.next_steps || 'No feedback'}</p>
        `;

        // result += `
        //   <div class="mb-2 font-weight-600">General feedback:</div>
        // `;
        //
        // if (!!objective.feedback?.general_feedback) {
        //   result += `<div>${markdownToHtml(autolinker(objective.feedback.general_feedback))}</div>`
        // } else {
        //   result += `<div>No additional feedback</div>`
        // }

        result += `</div>`

        // if (isPresent(objective.attachments)) {
        //   result += `
        //     <div class="card-footer">
        //       ${await attachments(objective.attachments)}
        //     </div>
        //   `;
        // }
      }

      result += '</div>'
    }

    result += '</div>'
  }

  return result;
}

const learningOutcomesThatDoNotNeedFeedback = (submission, assignment) => {
  if (assignment.rubrics) return '';

  const { objectivesThatDoNotNeedFeedback } = learningObjectivesHelper;

  const assignment_objectives = assignment?.assignment_objectives.map((item, index) => ({...item, position: index + 1})) || []
  const objectivesWithoutFeedback = objectivesThatDoNotNeedFeedback(assignment_objectives, submission) || [];

  let result = ''

  if (objectivesWithoutFeedback?.length > 0) {
    result += '<div style="margin-top: 50px">'
    result += '<div style="font-size: 17px; font-weight: bold" class="mb-3">Learning outcomes you achieved and do not need feedback on:</div>'

    for (let i = 0; i < objectivesWithoutFeedback.length; i++) {
      const objective = objectivesWithoutFeedback[i];

      result += `
        <div class='d-flex align-items-center mt-2 mb-3 px-3 py-3 rounded bg-nurture-light-green-variant'>
          <div class='me-3'>
            <div class='avatar avatar-sm'>
              <div class='avatar-title font-size-md rounded-circle bg-nurture-green-variant'>${objective.position}</div>
            </div>
          </div>
          <div class='font-size-16 flex-grow-1'>${objective.name}</div>
        </div>
      `;
    }

    result += '</div>'
  }

  return result;
}

const studentReflection = () => {
  return `
    <div style="margin-top: 50px;">
       <div style="margin-bottom: 8px"><strong>Student Reflection</strong></div>  
       <div class="text-muted" style="margin-bottom: 15px">How do you feel about your feedback?</div>
       
       <div class="d-flex">
        <div style="width: 33.33%">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="">
            <label class="form-check-label">😥 Disappointed</label>
          </div>
        </div>  
        
        <div style="width: 33.33%">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="">
            <label class="form-check-label">🙂 Neutral</label>
          </div>
        </div>  
        
        <div style="width: 33.33%">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="">
            <label class="form-check-label">😎 Happy</label>
          </div>
        </div>  
      </div>
     </div>
  `;
}

const studentThoughts = () => {
  return `
    <div class="mb-2 mt-3">What did you think about your feedback?</div>
    <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom pb-3"></div>
    <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom pb-3"></div>
    <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom pb-3"></div>
    <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom pb-3"></div>
    <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom pb-3"></div>
    <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom pb-3"></div>
    <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom pb-3"></div>
    <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom pb-3"></div>
  `;
}

const threeTwoOne = (assignment) => {
  if (assignment?.student_reflection !== 'triple_response') return '';

  return `
    <div style="margin-bottom: 25px; margin-top: 50px">
      <div class="mb-2">What are the three things you still don't know about this assignment?</div>
      <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom text-muted pb-3">1.</div>
      <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom text-muted pb-3">2.</div>
      <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom text-muted pb-3">3.</div>
    </div>
    
    <div style="margin-bottom: 25px">
      <div class="mb-2">List two things you learned from this assignment or the feedback that surprised you</div>
      <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom text-muted pb-3d">1.</div>
      <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom text-muted pb-3">2.</div>
    </div>
    
    <div style="margin-bottom: 25px">
      <div class="mb-2">Is there one thing you feel you need to practice/repeat before another assignment like this?</div>
      <div style="padding-top: 13px; height: 30px; width: 100%" class="border-bottom text-muted pb-3">1.</div>
    </div>
  `
}

const isImage = (attachment) => {
  return attachment.name.endsWith('.jpg') ||
    attachment.name.endsWith('.jpeg') ||
    attachment.name.endsWith('.svg') ||
    attachment.name.endsWith('.png');
}

/**
 * Converts an image URL to base64
 * @param url
 * @returns {Promise}
 */
function toDataURL(url) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = function () {
      reject({
        statusText: xhr.statusText
      });
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

const attachments = async (attachments) => {
  let result = '';

  if (attachments?.length > 0) {
    result += '<div class="html2pdf__page-break"></div>'
    result += '<div style="margin-bottom: 10px; margin-top: 50px"><strong>Attachments</strong></div>'
  }

  const imageAttachments = attachments.filter((attachment) => isImage(attachment));
  const nonImageAttachments = attachments.filter(attachment => !isImage(attachment));

  for (let i = 0; i < imageAttachments.length; i++) {
    const attachment = imageAttachments[i];
    const url = await toDataURL(attachment.view_url)
    result += `
      <div style="margin-top: 50px; margin-bottom: 50px" class="mb-3">
        <img width="400" src=${url} >
        <figcaption><i><small>${attachment.name}</small></i></figcaption>
      </div>
    `
  }

  if (nonImageAttachments.length > 0) {
    result += `
      <div class="html2pdf__page-break"></div>
      <div class="mt-4">
        The following attachments can be viewed / downloaded on the Nurture app.
      </div>
    `
  }
  for (let i = 0; i< nonImageAttachments.length; i++) {
    const attachment = nonImageAttachments[i];
    const filePlaceholder = fileTypeIcon(attachment.name);

    result += `
      <div class="attachment mb-3">
        <img src=${filePlaceholder} alt="NurtureLogo">
        <div style="margin-left: 20px">${attachment.name}</div>
      </div>
    `;
  }

  return result;
};

const grading = (submission, assignment) => {
  let result = `<div class="grading"><img src=${InfoIcon} >`;

  if (assignment.graded) {
    result += `
      <div style="margin-left: 30px">Grade: ${submission.grade} / ${assignment.max_marks}</div>
    `
  } else {
    result += `<div style="margin-left: 20px">This assessment is not graded.</div>`
  }

  result += `</div>`

  return result;
}

/**
 * This method is intentionally started with a capital letter because we are using a hook inside of it
 * This is to trick react to assume that this is a component since it starts with a capital letter
 * @param assignment
 * @returns {string}
 * @constructor
 */
const Rubrics = (assignment) => {
  const { learningObjectiveLabel } = useSchoolConfig();

  if (!assignment.rubrics) return '';

  let result = `
    <div class="html2pdf__page-break"></div>
    
    <div>
      <div style="margin-bottom: 200px"><strong>Rubrics</strong></div>
      <div style="display: flex; align-items: center; justify-content: center">
        <table style="font-size: 13px; transform: rotate(90deg)" class="table rounded table-bordered table-nurture-striped">
          <thead>
          <tr>
            <th class="p-3" style="min-width: 150px; max-width: 150px;">${learningObjectiveLabel}</th>
  `

  const rubrics = assignment.rubrics
  const rubricColumns = rubrics[0].columns

  for (let i = 0; i < rubricColumns.length; i++) {
    const column = rubricColumns[i];

    result += `
      <th style="min-width: 150px; max-width: 150px; padding: 0.5rem" >
        <div class="d-flex" style="justify-content: space-between">
          <span>${column.name}</span>
        </div>
      </th>
    `
  }

  result += `</tr></thead><tbody>`

  for (let i = 0; i < rubrics.length; i++) {
    const rubric = rubrics[i];
    result += `
      <tr style="padding: 0.5rem">
        <td style="min-width: 150px; max-width: 150px; padding: 0.5rem">
          <strong>${ rubric['Learning Outcome'] }</strong>
        </td>
    `

    for (let j = 0; j < rubric.columns.length; j++) {
      const column = rubric.columns[j]

      result += `
        <td style="min-width: 150px; max-width: 150px; padding: 0.5rem">
          <span>${column.value}</span>
       </td>   
      `
    }

    result += `</tr>`
  }

  result += `</tbody></table></div></div>`

  result += '<div class="html2pdf__page-break"></div>'


  return result;
};

const studentConfidence = () => {
  return `
    <div>
      <div class="confidence-score">
        <img src=${NurtureLogo} alt="NurtureLogo">
        <div style="margin-left: 20px">
          <div>How confident were you on this assessment? Let your teacher know!</div>
          <div>Log into the Nurture app and add your confidence score to this assessment!</div>
        </div>
      </div>
    </div>   
  `;
}

export default submissionHtml;
