import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './header.scss';
import { useSchoolConfig } from "../../core/hooks";
import {useNavigate} from "react-router-dom";
import HeaderNew from "./HeaderNew/HeaderNew";
import HeaderWeb from "./HeaderWeb";
import HeaderMobile from "./HeaderMobile";

const Header = ({ name, role, activeTab, onTabChanged }) => {
  const navigateTo = useNavigate();


  const newAssignment = () => navigateTo('/assignments/new');

  return (
    <div>
      <div className="d-none d-md-block">
        <HeaderWeb name={name} role={role} activeTab={activeTab} onTabChanged={onTabChanged} />
      </div>

      <div className="d-md-none">
        <HeaderMobile name={name} role={role} activeTab={activeTab} onTabChanged={onTabChanged} />
      </div>
    </div>
  );
};

Header.defaultProps = {
  name: undefined,
  role: undefined,
  activeTab: '',
  onTabChanged: undefined,
};

Header.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  activeTab: PropTypes.string,
  onTabChanged: PropTypes.func,
};

export default Header;
