import EmptyAssignmentImage from "assets/images/illustrations/empty_assignments.png";
import TextHeader from "core/components/TextHeader";

const EmptyFeedback = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-md-4">
            <img src={EmptyAssignmentImage} alt="Empty" className="img-fluid" />
          </div>
          <div className="col-md-8">
            <TextHeader>Pending Teacher Review</TextHeader>
            <p className="text-muted mb-0">
              Your submission is pending teacher correction & feedback. We will notify
              you once your teacher reviews your work.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmptyFeedback;