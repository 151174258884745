import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const CurriculumBoundaryReferences = ({ id, references, extraTexts }) => {
  return (
    <div className="alert alert-light p-2 border-none">
      <div className="accordion curriculum-references" id={id}>
        <div className="accordion-item border-none bg-transparent">
          <div className="accordion-header" id={`${id}-header`}>
              <small>
                <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
                {extraTexts}. &nbsp;
                <span
                  className="p-0 shadow-none display-inline cursor-pointer no-icon-accordion-button text-decoration-underline"
                  data-bs-toggle="collapse"
                  data-bs-target={`#${id}-collapsable`} aria-expanded="true" aria-controls={`${id}-collapsable`}
                >
                  View references
                </span>
              </small>
          </div>
          <div id={`${id}-collapsable`} className="accordion-collapse collapse" aria-labelledby={`${id}-header`}
               data-bs-parent="#accordionExample">
            <div className="accordion-body">
              {
                Object.keys(references).map(attachmentName => (
                  <div className="mb-3">
                    <div className="mb-2 text-muted">
                      <small>
                        <FontAwesomeIcon icon={faFile} className="me-2" />
                        { attachmentName }
                      </small>
                    </div>
                    <ul className="list-group list-group-flush rounded">
                      {
                        references[attachmentName].map((reference, index) => {
                          return (
                            <li className="list-group-item p-3 p-sm-4">
                              <small>
                                <strong>Page {reference.page}:</strong> {reference.text}
                              </small>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CurriculumBoundaryReferences.defaultProps = {
  references: [],
  extraTexts: '',
  id: 'referencesAccordion'
}

CurriculumBoundaryReferences.propTypes = {
  references: PropTypes.array,
  extraTexts: PropTypes.string,
  id: PropTypes.string
}

export default CurriculumBoundaryReferences;