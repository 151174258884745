import PropTypes from 'prop-types';
import emptyImage from 'assets/images/illustrations/empty_assignments.png';
import PendingFeedback from 'assets/images/illustrations/pending-feedback.svg';
import { Header as SectionHeader} from 'core/components';

const PendingReview = ({ title, subtitle }) =>  (
  <div className="card">
    <div className="card-body">
      <div className="row align-items-center">
        <div className="col-md-4">
          <img src={PendingFeedback} alt="empty page icon" className="img-fluid" />
        </div>

        <div className="col-md-8">
          <SectionHeader
            title={title}
            subtitle={subtitle}
          />
        </div>
      </div>
    </div>
  </div>
);

PendingReview.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default PendingReview;
