import React, { useState } from "react";
import PropTypes from 'prop-types';
import { faXmark, faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { numberToHumanSize, isPresent, validPdfTronFormat, fileTypeIcon, downloadAttachment, truncate } from "core/utils";
import { AttachmentPreviewer, DeleteModal } from "core/components/modals";
import { attachmentDelete, attachmentShow, download } from "./actions";
import { useDispatch } from "react-redux";
import './attachment.scss';
import { useSnackbar } from 'notistack';

const Attachment = ({ attachment, onDelete, allowDelete, allowDownload, allowView, ...rest }) => {
  const [modifiedImage, setModifiedImage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [downloadButton, setDownloadButton] = useState({ disabled: false, url: '' });
  const [viewButton, setViewButton] = useState({ text: 'Open', disabled: false });
  const { enqueueSnackbar } = useSnackbar();

  const { id, name, show_delete_button, size } = attachment;
  const dispatch = useDispatch();
  const attachmentName = attachment.name.toLowerCase();
  const filePlaceholder = fileTypeIcon(attachmentName);

  const teacherMadeSubmissionForStudent = attachment.teacher_uploaded
  let user = sessionStorage.getItem('user');
  user = user && JSON.parse(sessionStorage.getItem('user'));
  const userRole = user && user.role;

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleOnView = async () => {
    setViewButton({ text: 'Loading', disabled: true });
    try {
      const response = await dispatch(attachmentShow(attachment.id));
      setModifiedImage(response);
      setShowModal(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setViewButton({ text: 'Open', disabled: false });
    }
  };

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const deletedAttachment = await dispatch(attachmentDelete(id));
      if (typeof onDelete === "function") onDelete(deletedAttachment);
      setShowDeleteModal(false);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setDeleting(false);
    }
  };

  const getDownloadLink = async id => dispatch(download(id));

  const handleDownload = async () => {
    setDownloadButton({ disabled: true, url: '' });
    try {
      const response = await getDownloadLink(id);
      setDownloadButton({ disabled: false, url: response.view_url });
      downloadAttachment(response.view_url, name);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setDownloadButton({ disabled: false, url: '' });
    }
  };

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const showDeleteConfirmation = () => setShowDeleteModal(true);

  return (<>
    <DeleteModal
      show={showDeleteModal}
      onDelete={handleDelete}
      toggle={toggleDeleteModal}
      submitting={deleting}
    />

    {isPresent(modifiedImage) && (
      <AttachmentPreviewer
        attachment={modifiedImage}
        show={showModal}
        toggle={toggleModal}
      />
    )}

    <div className="card mb-0 mt-3 border" {...rest}>
      <div className="card-body">
        {
          teacherMadeSubmissionForStudent && userRole !== 'teacher' &&
          <div className="badge bg-nurture-green mb-3">Teacher submitted for you</div>
        }
        <div className="d-flex justify-content-between">
          <div className="flex-grow-1 pt-2">
            <div className="d-flex">
              <div className="me-4">
                <img src={filePlaceholder} height={40} alt=""/>
              </div>

              <div className="d-flex flex-column flex-grow-1">
                <h6 className="mb-0 fw-1 truncate" title={name}>{truncate({string: name, length: 20})}</h6>
                <div className="d-flex flex-grow-1">
                  <small className="text-muted mb-0 mt-1">
                    {numberToHumanSize(size)}
                  </small>
                  {attachment.annotated && (
                    <span className="badge bg-info annotation">Annotated</span>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-3 d-flex flex-wrap">
              {
                allowDownload &&
                  <button
                    type="button"
                    className="d-none d-md-inline-block me-2 btn btn-outline-nurture-purple w-100 w-sm-200px"
                    onClick={handleDownload}
                    disabled={downloadButton.disabled}
                  >
                    Download
                    <FontAwesomeIcon icon={faDownload} className="ms-2" />
                  </button>
              }

              {validPdfTronFormat(name) && allowView && (
                <button
                  type="button"
                  onClick={handleOnView}
                  className="btn btn-nurture-purple w-100 w-sm-200px"
                  disabled={viewButton.disabled}
                >
                  {viewButton.text}
                  <FontAwesomeIcon icon={faEye} className="ms-2" />
                </button>
              )}
            </div>
          </div>

          {
            (allowDelete !== false && show_delete_button) &&
            <div className="col-auto pt-4">
              <button type="button" className="btn btn-link text-danger" onClick={showDeleteConfirmation}>
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  </>
  );
};

Attachment.defaultProps = {
  allowDelete: true,
  allowDownload: true,
  allowView: true,
  onDelete: () => undefined,
  attachment: {}
}

Attachment.propTypes = {
  onDelete: PropTypes.func,
  allowDelete: PropTypes.bool,
  allowDownload: PropTypes.bool,
  allowView: PropTypes.bool,
  attachment: PropTypes.shape({}).isRequired,
};

export default Attachment;
