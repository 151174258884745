import { Alert, Button } from "core/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {Fragment, useState} from "react";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { envVariables } from "core/utils";
import { useSchoolConfig } from "core/hooks";
import {useSnackbar} from "notistack";

const PermissionAlert = () => {
  const tenantId = sessionStorage.getItem('tenantId')
  const channelId = sessionStorage.getItem('channelId');
  const sessionUser = JSON.parse(sessionStorage.getItem('user'))
  const { msTeamsPermissionMissing } = useSchoolConfig();
  const showAlert = msTeamsPermissionMissing &&
    sessionUser?.role === 'teacher' &&
    !sessionUser?.email.match(/^(admin@m)(\w+)(\.onmicrosoft\.com)$/) // temporarily disable permissions alert for microsoft admins

  const clientId = envVariables.microsoftTeams.clientId;
  const redirectUri = `${envVariables.appBaseUrl}`;
  const scopes = 'https://graph.microsoft.com/.default';

  const { enqueueSnackbar } = useSnackbar();

  const handleConsent = () => {
    const url = `https://login.microsoftonline.com/${tenantId}/v2.0/adminconsent?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=12345&channelId=${channelId}&tenant_id=${tenantId}`;
    const consentWindow = window.open(url, 'consentTab');

    window.addEventListener(
      'message',
      (event) => {
        if (event.origin !== envVariables.appBaseUrl) {
          return; // Security check
        }
        if (event.data === 'consent-granted') {
          consentWindow.close();
          window.location.reload();
        } else if (event.data === 'consent-denied') {
          enqueueSnackbar(
            'An error occurred while granting consent. Please try again or reach out to your administrator for assistance.',
            { variant: 'error' },
          );
        }
      },
      false,
    );
  };

  const AlertContent = () => {
    return (
      <div className="d-md-flex justify-content-md-between flex-wrap">
        <span className="flex-grow-1">
          <FontAwesomeIcon icon={faWarning} className="text-warning me-1 font-size-17" />
          Nurture doesn’t have admin permission to read your class list. Features are limited.
        </span>
        <Button className="btn-sm mx-2" onClick={handleConsent}>Grant admin access</Button>
      </div>
    )
  }

  return (
    <Fragment>
      {
        showAlert &&
          <div className="mb-3">
            <Alert allowDismiss={false} text={<AlertContent/>} type="info" />
          </div>
      }
    </Fragment>
  )
};

export default PermissionAlert;