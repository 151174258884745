import { combineReducers } from 'redux';
import authReducer from 'core/channel/reducer';
import assignmentsReducer from "modules/assignments/reducer";
import attachmentsReducer from "modules/attachments/reducer";
import assignmentTemplatesReducer from "modules/assignmentTemplates/reducer";
import graphReducer from "modules/graph/reducer";

const createAppReducer = combineReducers({
  session: authReducer,
  assignments: assignmentsReducer,
  attachments: attachmentsReducer,
  assignmentTemplates: assignmentTemplatesReducer,
  graph: graphReducer,
});

export default createAppReducer;
