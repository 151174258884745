import { Button } from "core/components";
import AddStudentModal from "./AddStudentModal";
import React, { useState } from "react";
import UploadStudentModal from "./UploadStudentsModal";

const Buttons = ({ onAdd, onUpload }) => {
  const [showStudentModal, setShowStudentModal] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)

  const handleAddStudent = (data) => {
    onAdd(data)
    setShowStudentModal(false);
  };

  const handleUploadStudents = (data) => {
    onUpload(data)
    setShowUploadModal(false);
  }

  return (
    <div className="d-flex justify-content-sm-end flex-wrap my-5">
      <Button
        className="me-3"
        type="button"
        onClick={() => setShowStudentModal(true)}
      >
        Add Student
      </Button>
      <Button
        type="button"
        color="nurture-orange"
        onClick={() => setShowUploadModal(true)}
        outline={true}
      >
        Upload CSV
      </Button>

      <AddStudentModal
        isOpen={showStudentModal}
        onClose={() => setShowStudentModal(false)}
        onAdd={handleAddStudent}
      />

      <UploadStudentModal
        isOpen={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onUpload={handleUploadStudents}
      />
    </div>
  );
};

export default Buttons;