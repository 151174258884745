import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

const TextInput = React.forwardRef(({ label, required, labelClass, error, ...otherProps }, ref) => (
  <>
    {label && (
      <Label className={labelClass}>{label} {required && <span className="required">*</span>}</Label>
    )}
    <input
      className={`form-control ${error ? 'error' : ''}`}
      ref={ref}
      {...otherProps}
    />
  </>
));

TextInput.defaultProps = {
  required: false,
  label: undefined,
  labelClass: '',
  error: false,
};

TextInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  labelClass: PropTypes.string,
  error: PropTypes.bool,
};

export default TextInput;
