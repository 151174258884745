import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import "./index.scss";
import $ from 'jquery'; // The RangeSlider component is based off from IonRangeSlider, it needs jQuery to work

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: "production",
    dsn: "https://5c0011d6904a471fbcc57203d77d3dc8@o1018463.ingest.sentry.io/6633401",
    integrations: [new BrowserTracing()],
  
    tracesSampleRate: 0.25,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
