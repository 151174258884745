import { createRequest, composeRequestConfig } from 'core/utils/axios/api';
import { teamsEnv } from 'core/utils';

export const verifyAuth = params => {
  let url = `/api/v1/ms_teams/${params.tenantId}/channels/${params.channelId}`;
  if (params.verify_token) {
    url += '?verify_token=true'
  }
  const config = composeRequestConfig({ url });

  return createRequest(config);
};

export const updateChannel = payload => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}`;
  const config = composeRequestConfig({ url, payload, method: 'patch' });

  return createRequest(config);
};

export const teacherRequest = payload => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/teacher_request`;
  const config = composeRequestConfig({ url, payload, method: 'post' });

  return createRequest(config);
};

export const deleteCache = () => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/cache/delete`;
  const config = composeRequestConfig({ url, method: 'post' });

  return createRequest(config);
}
