import * as t from './actionTypes';

const INITIAL_STATE = {
  loading: false,
  authenticated: false,
  error: null,
  currentUser: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.AUTH_LOAD_START:
      return { ...state, loading: true };

    case t.AUTH_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticated: true,
        currentUser: action.currentUser,
      };

    case t.AUTH_LOAD_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  };
};

export default authReducer;
