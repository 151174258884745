import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = React.forwardRef(({ label, value, labelClass, className, ...otherProps }, ref) => (
  <>
    <div className="form-check">
      <input
        className={`form-check-input ${className}`}
        value={value}
        type="radio"
        id={label}
        ref={ref}
        {...otherProps}
      />
      <label className={`form-check-label ${labelClass} ${otherProps.error ? 'text-danger' : ''}`} htmlFor={label}>{label}</label>
    </div>
  </>
));

RadioButton.defaultProps = {
  className: '',
  labelClass: '',
};

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
};

export default RadioButton;
