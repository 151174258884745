import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'core/components';
import LoginImage from 'assets/images/illustrations/clip-sign-up.png';
import { useDispatch } from 'react-redux';

import { verifyAuthToken } from 'core/channel/action';

import { isJwtExpired, isPresent } from 'core/utils';
import { login } from './loginPopup';
import './manualLogin.scss';

const ManualLogin = () => {
  const user = sessionStorage.getItem('user');
  const sessionToken = localStorage.getItem('token');

  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(
    () => {
      const authExpired = isJwtExpired(sessionToken);

      if(!authExpired && isPresent(user)) navigate(state?.path || '/tab');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  );

  const verifyAuth = async () => dispatch(verifyAuthToken());

  const loginSuccess = async () => {
    try {
      await verifyAuth();
      navigate(state?.path || '/tab', { replace: true });
    } catch (error) {
      console.error('TOKEN ERROR', error);
    }
  };

  const handleLogin = async () => {
    try {
      await login(loginSuccess);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="empty-container d-flex flex-column align-items-center">
      <div className="login-image-container">
        <img src={LoginImage} alt="Login Page" className="img-fluid" />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center ">
        <h2 className="mb-2">
          Login to continue...
        </h2>

        <p className="text-muted">
          Seems like we don't have all the permission that we need. Let's fix that.
        </p>
        <Button
          variant="secondary"
          onClick={() => handleLogin()}
        >
          Login with Microsoft Teams
        </Button>
      </div>
    </div>
  );
};

export default ManualLogin;
