import PropTypes from "prop-types";
import './theAssignment.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { Header, Autolinker, CardWithAccordion, Avatar, Button } from "core/components";
import { NavLink } from "react-router-dom";
import Attachment from "modules/attachments/Attachment";
import TextHeader from "core/components/TextHeader";
import { useSchoolConfig } from "core/hooks";
import { OneNote } from '../OneNote';
import {Fragment, useState} from "react";
import {userFullName} from "core/utils";
import TimeFromNow from "core/components/TimeFromNow";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import html2pdf from "html2pdf.js/src";
import assignmentHtml from "./assignmentPdf";
import {useSnackbar} from "notistack";

const TheAssignment = ({ assignment, isTeacher, openReviewedCard }) => {
  const { learningObjectiveLabel } = useSchoolConfig();
  const { user, updated_at, description, assignment_objectives, show_assignment_objectives, attachments, onenote_url } = assignment;
  const [printing, setPrinting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handlePrint = async () => {
    try {
      const opt = {
        margin: [10, 0],
        filename: `${assignment.name}.pdf`,
        image: { type: 'pdf', quality: 0.98 },
        html2canvas:  { scale: 2, useCORS: true, dpi: 192 },
        enableLinks: true,
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        // jsPDF: { format: 'letter', orientation: 'portrait' }
      };
      setPrinting(true)
      const htmlContent = await assignmentHtml(assignment)
      html2pdf().from(htmlContent).set(opt).save();
    } catch (e) {
      enqueueSnackbar('Error occurred while downloading assessment', { variant: 'error' })
    } finally {
      setPrinting(false)
    }
  }

  const Base = () => {
    return (
      <div className={`card ${isTeacher ? '' : 'silent-card'}`}>
        <div className="card-body">
          <div className="mb-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="mx-0 row align-items-center">
                  <div className="col-auto px-0">
                    <Avatar img={user?.avatar_url} size="md" />
                  </div>
                  <div className="col" style={{ marginLeft: '-0.375rem' }}>
                    <TextHeader className="mb-1">{userFullName(user)}</TextHeader>
                    <TimeFromNow time={updated_at} />
                  </div>
                </div>
              </div>
              {
                isTeacher &&
                <div className="col-auto">
                  {/* /assignments/:assignmentId/edit */}
                  <NavLink className="btn btn-sm btn-outline-nurture-purple me-2" to="edit">
                    <FontAwesomeIcon className="me-2" icon={faEdit} />
                    Edit
                  </NavLink>

                  <Button withLoader={true} loading={printing} loaderText={'Downloading'} color="nurture-green" className="btn-sm" onClick={handlePrint}>
                    <FontAwesomeIcon className="me-2" icon={faPrint} />
                    Download as PDF
                  </Button>
                </div>
              }
            </div>
          </div>
          <div>
            <Autolinker content={description} />

            {
              isTeacher && assignment_objectives?.length && show_assignment_objectives &&
              <div className="assignment_objectives_list mt-3">
                <TextHeader className="fw-600">{learningObjectiveLabel}</TextHeader>
                {
                  assignment_objectives.map((objective, index) => (
                    <div className="row mt-2 align-items-center" key={index}>
                      <div className="col-auto">
                        <div className="avatar avatar-xs">
                          <div className="avatar-title font-size-xs rounded-circle bg-secondary-soft text-secondary">
                            {index + 1}
                          </div>
                        </div>
                      </div>
                      <div className="col p-0">
                        {objective.name}
                      </div>
                    </div>
                  ))
                }
              </div>
            }
          </div>
        </div>
        {
          !!attachments?.length &&
          <div className="card-footer bg-white mt-3">
            <div className="card-columns">
              {
                attachments.map((attachment, index) => (
                  <Attachment attachment={attachment} key={index} allowDelete={false} />
                ))
              }
            </div>
          </div>
        }

        {
          onenote_url && onenote_url.trim() !== "" &&
          <div className="card-footer bg-white mt-3">
            <div className="card-columns">
              <OneNote onenote_url={onenote_url} allowDelete={false} />
            </div>
          </div>

        }
      </div>
    );
  }

  return (
    <div className="TheAssignment mt-4">
      {
        isTeacher &&
          <Fragment>
            {/*<Header title="The Assessment" subtitle="Be sure to download all provided attachments for your work." />*/}
            <Base />
          </Fragment>
      }

      {
        !isTeacher &&
          <CardWithAccordion defaultState={openReviewedCard ? 'closed' : 'open'} id="TheAssignment" title="The Assessment">
            <Base />
          </CardWithAccordion>
      }


    </div>
  );
};

TheAssignment.defaultProps = {
  assignment: {},
  openReviewedCard: false
};

TheAssignment.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
  isTeacher: PropTypes.bool.isRequired,
  openReviewedCard: PropTypes.bool
};

export default TheAssignment;
