import EmptySearch from "assets/images/illustrations/empty_search.png"

const EmptyList = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="">
          <div className="text-center">
            <img src={EmptySearch} alt="Empty" className="img-fluid" width={500} />
          </div>
          <div className="text-center">
            <h3>There are no students uploaded yet</h3>
            <p className="text-muted">
              There are no students that has been manually added to this classroom.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default EmptyList;