import Attachment from "./Attachment";
import PropType from "prop-types";

const Attachments = ({ attachments, ...rest }) =>
  attachments.map(attachment =>
    <Attachment
      key={attachment.id}
      attachment={attachment}
      allowDelete={rest?.allowDelete || false}
      {...rest}
    />
  );

Attachments.propType = {
  attachments: PropType.array,
};

export default Attachments;
