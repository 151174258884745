import { Button } from "core/components";
import React, { Fragment } from "react";
import { envVariables } from "core/utils";
import { useSchoolConfig } from "core/hooks";
import { useSnackbar } from "notistack";

const PermissionButton = () => {
  const tenantId = sessionStorage.getItem('tenantId')
  const sessionUser = JSON.parse(sessionStorage.getItem('user'))
  const { msTeamsPermissionMissing } = useSchoolConfig();
  const showAlert = msTeamsPermissionMissing && sessionUser?.role === 'student'
  const { enqueueSnackbar } = useSnackbar();

  const clientId = envVariables.microsoftTeams.clientId;
  const redirectUri = `${envVariables.appBaseUrl}`;
  const scopes = 'https://graph.microsoft.com/.default';

  const handleConsent = () => {
    const url = `https://login.microsoftonline.com/${tenantId}/v2.0/adminconsent?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=12345`;
    const consentWindow = window.open(url, 'consentTab');

    window.addEventListener(
      'message',
      (event) => {
        if (event.origin !== envVariables.appBaseUrl) {
          return; // Security check
        }
        if (event.data === 'consent-granted') {
          consentWindow.close();
          window.location.reload();
        } else if (event.data === 'consent-denied') {
          enqueueSnackbar(
            'An error occurred while granting consent. Please try again or reach out to your administrator for assistance.',
            { variant: 'error' },
          );
        }
      },
      false,
    );
  };

  return (
    <Fragment>
      {
        showAlert &&
        <div className="mb-3">
          <Button color="transparent" className="btn btn-sm btn-link" onClick={handleConsent}>
            Grant admin permission
          </Button>
        </div>
      }
    </Fragment>
  )
};

export default PermissionButton;