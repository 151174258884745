import { Button } from "core/components";
import React, {Fragment, useEffect, useState} from "react";
import { envVariables, pusher } from "core/utils";
import { useSchoolConfig } from "core/hooks";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from 'uuid';
import {FormGroup, FormText, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {SelectField, TextInput} from "core/form/fields";
import {ABILITY, AGES, SUBJECTS} from "modules/channelOnboarding/static";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { teacherRequest } from "../channel/action";
import successIcon from "assets/images/general_icons/success_green_checkmark.svg"
import errorIcon from "assets/images/general_icons/error_icon.svg"

const PermissionButton = () => {
  const STATES = { initial: 'intitial', success: 'success', failure: 'failure' }

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('You have been successfully added as a teacher');
  const [state, setState] = useState(STATES.initial);
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    'first_name': yup.string(),
    'last_name': yup.string(),
    'email': yup.string().nullable(),
    'school_name': yup.string().required('This field cannot be empty').nullable(),
  });

  const sessionUser = JSON.parse(sessionStorage.getItem('user'))
  const { msTeamsPermissionMissing } = useSchoolConfig();
  const showAlert = msTeamsPermissionMissing && sessionUser?.role === 'student'

  const defaultValues = {
    first_name: sessionUser.first_name || '',
    last_name: sessionUser.last_name || '',
    email: sessionUser.email || '',
  };

  const { control, register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const toggleModal = () => {
    if (loading) return

    setShowModal(!showModal)

    if (state === STATES.success) {
      // reload the page
      window.location.reload();
    }
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await dispatch(teacherRequest(data));
      setState(STATES.success);
      setMessage('Your have been updated to a teacher account. You can now create and issue assignments to the classroom.');
    } catch (e) {
      setState(STATES.failure)
      setMessage(e.message || 'Something went wrong while trying to add you as a teacher')
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!showModal) {
      reset();
      setState(STATES.initial);
    }
  }, [showModal]);

  return (
    <Fragment>
      {
        showAlert &&
        <div className="">
          <Button onClick={() => setShowModal(true)}>
            Are you a teacher?
          </Button>

          <Modal isOpen={showModal}>
            <form className="mb-0" onSubmit={handleSubmit(onSubmit)}>
              {
                state === STATES.initial &&
                  <ModalHeader toggle={toggleModal} charcode="x" tag="h4">Request Teacher Permissions</ModalHeader>
              }
              <ModalBody>
                {
                  state === STATES.initial &&
                    <Fragment>
                      <p className="mb-3">
                        Are you a teacher? Request teacher permissions to be able to create assignments
                        and issue them to your classroom.
                      </p>

                      {/* First Name */}
                      <FormGroup>
                        <TextInput
                          label="First Name"
                          control={control}
                          error={!!errors.first_name?.message}
                          {...register('first_name')}
                          readOnly
                        />
                        {errors.first_name && (
                          <p className="mt-1">
                            <FormText color="danger">
                              {errors.first_name.message}
                            </FormText>
                          </p>
                        )}
                      </FormGroup>

                      {/* Last Name */}
                      <FormGroup>
                        <TextInput
                          label="Last Name"
                          control={control}
                          error={!!errors.last_name?.message}
                          {...register('last_name')}
                          readOnly
                        />
                        {errors.last_name && (
                          <p className="mt-1">
                            <FormText color="danger">
                              {errors.last_name.message}
                            </FormText>
                          </p>
                        )}
                      </FormGroup>

                      {/* Email */}
                      <FormGroup>
                        <TextInput
                          label="Your Work Email"
                          control={control}
                          error={!!errors.email?.message}
                          {...register('email')}
                          readOnly
                        />
                        {errors.email && (
                          <p className="mt-1">
                            <FormText color="danger">
                              {errors.email.message}
                            </FormText>
                          </p>
                        )}
                      </FormGroup>

                      {/* School Name */}
                      <FormGroup>
                        <TextInput
                          label="Name of School"
                          control={control}
                          error={!!errors.school_name?.message}
                          {...register('school_name')}
                          readonly
                          required
                        />
                        {errors.school_name && (
                          <p className="mt-1">
                            <FormText color="danger">
                              {errors.school_name.message}
                            </FormText>
                          </p>
                        )}
                      </FormGroup>
                    </Fragment>
                }

                {
                  state !== STATES.initial &&
                    <Fragment>
                      <div className="d-flex justify-content-center mb-4">
                        <img width="120"
                             className="img-fluid"
                             src={state === STATES.success ? successIcon : errorIcon}
                             alt={state === STATES.success ? "Success Icon" : "Failure Icon"} />
                      </div>
                      <div className="text-center alert alert-light mt-2" role="alert">
                        { message }
                      </div>
                    </Fragment>
                }
              </ModalBody>
              <ModalFooter>
                {
                  state === STATES.initial &&
                    <Button
                      type="submit"
                      withLoader={true}
                      loading={loading}
                      loaderText="Requesting"
                      disabled={loading}>
                      Request Access
                    </Button>
                }

                {
                  state !== STATES.initial &&
                  <Button type="button" onClick={toggleModal}>
                    Continue
                  </Button>
                }
              </ModalFooter>
            </form>

          </Modal>
        </div>
      }
    </Fragment>
  )
};

export default PermissionButton;