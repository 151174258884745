import TextHeader from "core/components/TextHeader";
import Selectric from "core/components/Selectric";
import { FEEDBACK_PRESETS, FIELDS } from "./constants";
import { isEmpty, truncate } from "core/utils";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { MarkdownEditorField, NurtureAI, NurtureAIGroupFeedbackComment } from "core/components";
import { useSchoolConfig } from "core/hooks";

const Step1 = ({ setValue, getValues, assignmentObjective, aiSuggestions }) => {
  const [actionFeedback, setActionFeedback] = useState('');
  const [processFeedback, setProcessFeedback] = useState('');
  const [nextSteps, setNextSteps] = useState('');

  const { nurtureAIEnabled } = useSchoolConfig();

  const map = {
    action_feedback: [actionFeedback, setActionFeedback],
    process_feedback: [processFeedback, setProcessFeedback],
    next_steps: [nextSteps, setNextSteps],
  }

  const formatPresets = (options) => {
    return options.map(option => {
      return {value: option, label: truncate({string: option, length: 300})};
    });
  };

  const feedbackDefault = (type, value) => {
    return FEEDBACK_PRESETS[type][parseInt(value) - 1]
  }

  const handlePresetChange = (e) => {
    if (window.confirm("Are you sure you would like to update the field with this new template? Any edits to the field will be overwritten.")) {
      setValue(e.name, e.value)
      map[e.name][1](e.value)
    }
  }

  useEffect(() => {
    const presentFormValue = getValues();

    let action_feedback = presentFormValue.action_feedback ||
      assignmentObjective?.feedback?.action_feedback
    if (action_feedback === undefined) action_feedback = feedbackDefault('action_feedback', 3)

    let next_steps = presentFormValue.next_steps ||
      assignmentObjective?.feedback?.next_steps
    if (next_steps === undefined) next_steps = feedbackDefault('next_steps', assignmentObjective?.feedback?.student_confidence || 3)

    if (isEmpty(presentFormValue.action_feedback)) setValue('action_feedback', action_feedback)
    // if (isEmpty(presentFormValue.process_feedback)) setValue('process_feedback', process_feedback)
    if (isEmpty(presentFormValue.next_steps)) setValue('next_steps', next_steps)

    setActionFeedback(action_feedback)
    // setProcessFeedback(process_feedback)
    setNextSteps(next_steps)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (name, value) => {
    setValue(name, value);
    map[name][1](value)
  }

  useEffect(() => {
    if (aiSuggestions?.next_steps) handleChange('next_steps', aiSuggestions?.next_steps)
    if (aiSuggestions?.action_feedback) handleChange('action_feedback', aiSuggestions?.action_feedback)
  }, [aiSuggestions])

  return (
    <div>
      {
        FIELDS.map((field, index) => (
          <div className="card py-3 mb-4" key={index}>
            <div className="card-header">
              <div className="row">
                <div className="col">
                  <TextHeader><label>{ field.label }</label></TextHeader>
                  <small className="form-text text-muted mt-2">{ field.hint }</small>
                </div>
              </div>
            </div>
            <div className="card-body">
              <MarkdownEditorField
                className='markdown'
                initialValue={map[field.name][0]}
                onChange={(value) => handleChange(field.name, value)}
              />

              {/*{*/}
              {/*  nurtureAIEnabled &&*/}
              {/*  <NurtureAI buttonText="Suggest Comment" className="mt-2" subtitle="Get help and ideas for your comment">*/}
              {/*    <NurtureAIGroupFeedbackComment*/}
              {/*      assignmentObjectiveId={assignmentObjective.id}*/}
              {/*      hint={field.ai_hint}*/}
              {/*      onSuggest={(comment) => handleChange(field.name, comment)} />*/}
              {/*  </NurtureAI>*/}
              {/*}*/}
            </div>
          </div>
        ))
      }
    </div>
  );
}

Step1.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  assignmentObjective: PropTypes.shape({}).isRequired
};

export default Step1;
