import {useDispatch, useSelector} from "react-redux";
import * as graphSelector from "../graph/selectors";
import {fetchGraphData} from "../graph/action";
import React, {Fragment, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import AssignmentError from "../assignments/AssignmentError";
import {useForm} from "react-hook-form";
import {Avatar, Avatars, Loader} from "core/components";
import {round} from "lodash";
import GraphSkeletalLoader from "../graph/skeletalLoaders/GraphSkeletalLoader";
import {displayConfidence, isPresent, userFullName} from "core/utils";
import {avatarUrls, classroomUsersFirstNames, remainingItemsCount, studentsHash} from "../graph/utils";
import {FormGroup} from "reactstrap";
import {SelectField} from "core/form/fields";
import {buttonHash} from "../assignments/constants";
import {MixedChart} from "core/components/graphs";
import {ExportJsonCsv} from "react-export-json-csv";

const AVATAR_URLS_LENGTH = 4;

const Dashboard = () => {
  const [error, setError] = useState(null);
  const [count, setCount] = useState('all');
  const [studentId, setStudentId] = useState(null);
  const [graphFilterLoading, setGraphFilterLoading] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [currentStudent, setCurrentStudent] = useState({});
  const [assignmentInfo, setAssignmentInfo] = useState([]);
  const graphLoading = useSelector(graphSelector.loading);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const students = useSelector(graphSelector.students);
  const data = useSelector(graphSelector.data);

  const sessionUser = JSON.parse(sessionStorage.getItem('user'));
  const role = sessionUser?.role;
  const isTeacher = role === 'teacher';

  const classroomLevel = !studentId || studentId === 'all';
  const headers = [
    {
      key: 'name',
      name: 'Name',
    },
    {
      key: 'grade',
      name: 'Grade',
    },
    {
      key: 'confidence',
      name: 'Confidence',
    },
  ]

  const { register } = useForm({});

  const fetchAnalytics = async () => {
    try {
      await dispatch(fetchGraphData());
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setError(error.message)
    }
  };

  const filterGraph = async () => {
    const params = {
      count: count === 'all' ? null : count,
      studentId,
    };

    setGraphFilterLoading(true);
    return await dispatch(fetchGraphData(params, studentId === 'all'));
  };

  const handleFilterButtonClick = async (newCount) => {
    setCount(newCount);
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  useEffect(() => {
    setGraphData(data);
  }, [data]);

  useEffect(() => {
    filterGraph()
      .then(res => {
        setGraphData(res.data.assignment_data);

        if (studentId && studentId !== 'all') {
          setCurrentStudent(res.data.users);
        } else if (!isTeacher) {
          setCurrentStudent(JSON.parse(sessionStorage.getItem('user')))
        }
      })
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setGraphFilterLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, studentId]);

  useEffect(() => {
    setAssignmentInfo([])
    if (Object.keys(graphData).length > 0) {
      Object.keys(graphData.labels).map((key, index) =>
        setAssignmentInfo(assignmentInfo => [...assignmentInfo, { name: graphData.labels[index], grade: graphData.grade_list[index], confidence: graphData.confidence_list[index] }])
      )
    }
  }, [graphData]);

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center mb-4">
        <Loader size="25px" loading={true} color="#2c7be5" speedMultiplier={1} />
      </div>
    );
  };

  const {
    confidence_average: confidenceAverage,
    engagement_score: engagementScore,
    grades_average: gradesAverage,
  } = graphData;

  const formattedConfidence = round(parseFloat(confidenceAverage), 1);
  const formattedGrade = round(parseFloat(gradesAverage), 1);

  return (
    <>
      {
        error &&
        <AssignmentError errorMessage={error} />
      }

      {
        !error &&
        <>
          {graphLoading ? <GraphSkeletalLoader /> : isPresent(graphData) && (
            <div className='card mb-5 pt-3'>
              <div className='card-header pb-0'>
                <div className='row mb-4 px-sm-3'>
                  <div className='d-flex flex-wrap justify-content-center justify-content-sm-start col-md-9 px-0 px-sm-2'>
                    <div className='me-1 me-sm-3'>
                      {classroomLevel && isTeacher ? <Avatars urls={avatarUrls(students, AVATAR_URLS_LENGTH)} remainingItemsCount={remainingItemsCount(students, AVATAR_URLS_LENGTH)} /> :
                        <Fragment>
                          <div className="d-sm-none"><Avatar size="md" img={currentStudent.avatar_url} /></div>
                          <div className="d-none d-sm-block"><Avatar size="lg" img={currentStudent.avatar_url} /></div>
                        </Fragment>}
                    </div>
                    <div className='d-flex flex-column pt-1'>
                      {classroomLevel && isTeacher ? (
                        <>
                          <span className='fs-5 mb-0 pb-0'>All Students</span>
                          <span className='text text-muted fs-6 mt-0 pt-0'>
                        {classroomUsersFirstNames(students, AVATAR_URLS_LENGTH).firstNames} {classroomUsersFirstNames(students, AVATAR_URLS_LENGTH).extrasCount > 0 ? `, +${classroomUsersFirstNames(students, AVATAR_URLS_LENGTH).extrasCount} more` : ''}
                      </span>
                        </>
                      ) : (
                        <>
                          <span className='fs-5 mb-0 pb-0'>{userFullName(currentStudent)}</span>
                          <span className='text text-muted fs-6 mt-0 pt-0'>{currentStudent.email}</span>
                        </>
                      )}
                    </div>
                  </div>
                  {isTeacher && (
                    <div className='col-md-3'>
                      <form>
                        <FormGroup>
                          <SelectField
                            label=""
                            options={studentsHash(students)}
                            {...register('students_list')}
                            onChange={e => {
                              setStudentId(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </form>
                    </div>)}
                </div>
              </div>
              <div className='card-body px-0 px-sm-1 px-md-3'>
                <div className='d-flex flex-wrap justify-content-center justify-content-sm-start mt-2 mb-5 px-0 px-lg-4'>
                  <div>
                    {Object.keys(buttonHash).map(key =>
                      <button
                        key={key}
                        className={`btn product-dark-btn ms-1 mb-1 ${count === buttonHash[key] ? 'product-dark-color' : ''}`}
                        onClick={() => handleFilterButtonClick(buttonHash[key])}
                        disabled={graphFilterLoading}
                      >
                        {key}
                      </button>)}
                  </div>
                </div>
                <div className='row mt-2 pb-3 px-0 px-sm-4'>
                  {graphFilterLoading ? <GraphSkeletalLoader loaded={true} /> : (
                    <>
                      <div className='col-lg-9 mb-4'>
                        <MixedChart graphData={graphData} />
                        <div className='text-center mt-2'>
                          Assignments
                        </div>
                        {isTeacher && (
                          <div className='d-flex'>
                            <ExportJsonCsv headers={headers} className="btn btn-outline-nurture-purple btn-sm" items={assignmentInfo}>Export as CSV</ExportJsonCsv>
                          </div>
                        )}
                      </div>
                      <div className='d-flex flex-column col-lg-3'>
                        <div className='d-flex flex-column align-items-center mb-2'>
                          <div className='d-flex align-items-center justify-content-center blue-background circle text-center mb-2'>
                            <span className='fs-3 fw-bold'>{engagementScore}</span>
                          </div>
                          <h4 className='fs-5 fw-bold'>Engagement Score</h4>
                        </div>
                        <div className='small-div p-3 text-left mb-2'>
                          <small className='text-muted small-text mb-2'>AVERAGE CONFIDENCE</small>
                          <div className='fs-5'>{formattedConfidence}% {displayConfidence(formattedConfidence)}</div>
                        </div>
                        <div className='small-div p-3 text-left'>
                          <small className='text-muted small-text mb-2'>AVERAGE GRADE</small>
                          <div className='fs-5'>{formattedGrade}%</div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      }
    </>
  )
}

export default Dashboard;