import React from "react";
import PropTypes from "prop-types";
import EmptyPage from "./EmptyPage";
import AssignmentsList from './assignments/AssignmentsList';
import { isPresent } from "core/utils";

const Home = ({ authed, assignments, role }) => (
  <div>
    {isPresent(assignments) ?
      <AssignmentsList assignments={assignments} /> : <EmptyPage role={role} authed={authed} />}
  </div>
);

Home.propTypes = {
  authed: PropTypes.bool.isRequired,
  assignments: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
};

export default Home;
