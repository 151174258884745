import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const fromNow = date => {
  // IOS devices does not recognize dates separated with -, so we will separate our dates with / which will be
  // recognized by but IOS and other devices
  if (typeof date === 'string') date = date.replace(/-/g, '/')
  return dayjs(date).fromNow();
}
const format = ({ date, format }) => {
  // IOS devices does not recognize dates separated with -, so we will separate our dates with / which will be
  // recognized by but IOS and other devices
  if (typeof date === 'string') date = date.replace(/-/g, '/')
  return dayjs(date).format(format);
}

const isBefore = (date, relativeDate) => {
  if (typeof date === 'string') date = date.replace(/-/g, '/')
  return dayjs(date).isBefore(relativeDate)
}

const dateUtils = {
  fromNow,
  format,
  isBefore,
}
export default dateUtils;
