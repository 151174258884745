import {useState} from "react";

const RubricsCriteriaLabelsCount = ({ onSetCount }) => {
  const [points, setPoints] = useState(5); // Initial value set to 5
  const maxPoints = 15;
  const minPoints = 3;
  const learningOutcomes = 3; // Assume learning outcomes are fixed

  const incrementPoints = () => {
    if (points < maxPoints) {
      setPoints((prevPoints) => {
        const result = parseInt(prevPoints) + 1
        onSetCount(result)
        return result
      });
    }
  };

  const decrementPoints = () => {
    if (points > minPoints) {
      setPoints((prevPoints) => {
        const result =  parseInt(prevPoints) - 1
        onSetCount(result)
        return result;
      });
    }
  };

  const handleInputChange = (e) => {
    setPoints(e.currentTarget.value);
    onSetCount(e.currentTarget.value);
  }

  return (
    <div>
      <div className="text-white">No. of criteria labels</div>
      <div className="d-flex">
        <button onClick={decrementPoints} type='button' className="btn bg-white">-</button>
        <input type='number' value={points} onChange={handleInputChange} style={{width: '75px'}} className="form-control mx-1" />
        <button onClick={incrementPoints} type='button' className="btn bg-white">+</button>
      </div>
    </div>
  );
}

export default RubricsCriteriaLabelsCount;