import {Modal, ModalBody} from "reactstrap";
import StudentsInClassroomIllustration from "assets/images/illustrations/students_in_classroom.svg";
import Button from "core/components/Button";
import React, { useState } from "react";
import PropTypes from "prop-types";
import {isPresent} from "core/utils";
import {updateChannel} from "core/channel/action";
import {useSnackbar} from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SaveFormModal = ({ show, closeModal, getValues, destinationUrl, ...otherProps }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const handleSave = async () => {
    try {
      const data = getValues()
      setLoading(true);
      const requestPayload = { ...data };
      if (isPresent(requestPayload.other_subject)) requestPayload.subject = requestPayload.other_subject;

      await dispatch(updateChannel(requestPayload));
      enqueueSnackbar('Your details have been saved successfully', { variant: 'success' });
      navigateTo(destinationUrl);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal size='lg' isOpen={show} zIndex={21800000000} {...otherProps}>
      <ModalBody>
        <div className="p-3">
          <h4 className="text-center border-bottom mb-4 pb-3">Save classroom settings?</h4>
          <div className="text-center mb-4">
            <img src={StudentsInClassroomIllustration} className="img-fluid" />
          </div>
          <p className="text-center">
            It looks like you have unsaved changes to your classroom settings do you want to save them now?
          </p>
          <div className="mt-4 d-flex justify-content-center">
            <Button className='me-2' color="transparent" className="text-danger" onClick={() => closeModal()}>No, go back</Button>
            <Button
              withLoader={true}
              loading={loading}
              disabled={loading}
              loaderText="Saving"
              onClick={handleSave}>
              Yes, save my settings
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

SaveFormModal.propTypes = {
  show: PropTypes.bool.isRequired
};

export default SaveFormModal;