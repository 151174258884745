const useAssignmentInfo = (assignment) => {
  const reviewedUserAssignments = assignment?.user_assignments?.filter((userAssignment) => userAssignment.status === 'reviewed') || [];
  const submittedUserAssignments = assignment?.user_assignments?.filter((userAssignment) => userAssignment.submitted_at) || [];
  const reflectionUserAssignments = assignment?.user_assignments?.filter((userAssignment) => userAssignment.reflection_submitted_at) || [];
  const publishedUserAssignments = assignment?.feedback_published_at ? reviewedUserAssignments.length : 0;
  const totalUserAssignments = assignment?.user_assignments?.length || 0;

  const confidenceSum = assignment?.user_assignments?.reduce((acc, userAssignment) => acc + userAssignment?.student_submission?.confidence?.level || 0, 0)
  const userAssignmentsWithConfidence = assignment?.user_assignments?.filter((userAssignment) => userAssignment?.student_submission?.confidence?.level) || []
  const averageConfidence = userAssignmentsWithConfidence.length ? (confidenceSum / userAssignmentsWithConfidence.length).toFixed() : 0

  const gradedUserAssignments = assignment?.user_assignments?.filter((userAssignment) => userAssignment?.grade) || [];
  const gradeSum = gradedUserAssignments.reduce((acc, userAssignment) => acc + userAssignment?.grade || 0, 0)
  const averageGrade = gradedUserAssignments.length ? (gradeSum / gradedUserAssignments.length).toFixed() : '-'

  return {
    reviewedUserAssignments,
    submittedUserAssignments,
    reflectionUserAssignments,
    publishedUserAssignments,
    totalUserAssignments,
    averageConfidence,
    averageGrade
  }
}

export default useAssignmentInfo