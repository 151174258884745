import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faEye, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {
  faEllipsisV,
  faLink
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteAssignmentTemplate } from "../action";
import { useSnackbar } from "notistack";
import { Avatar } from "core/components";
import { pluralize, userFullName } from "core/utils";
import { DeleteModal } from "core/components/modals";
import { useCopyToClipboard, useSchoolConfig } from "core/hooks";
import CheckMarkLightGreen from "assets/images/general_icons/check_mark_light_green.svg";
import XInCircleRed from "assets/images/general_icons/x_in_circle_red.svg";
import CopyAndEditButton from "../CopyAndEditButton";
import UseTemplateButton from "../UseTemplateButton";

const AssignmentTemplatesCard = ({ assignmentTemplate, onSelect }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState(false);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const codeRef = useRef();
  const copyCodeToClipboard = useCopyToClipboard(codeRef);
  const { learningObjectiveLabel } = useSchoolConfig();

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const handleCopyToClipboard = async () => {
    await copyCodeToClipboard(assignmentTemplate.sharable_code)
    enqueueSnackbar('Your code has been copied', { variant: 'success' });
  };

  const handleTemplateDelete = async () => {
    try {
      setDeletingTemplate(true);
      await dispatch(deleteAssignmentTemplate(assignmentTemplate.id));
      enqueueSnackbar('Assignment template was deleted', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setDeletingTemplate(false);
      setShowDeleteModal(false);
    }
  }

  const navigateToEdit = () => navigateTo(`/assignments_templates/${assignmentTemplate.id}/edit`);

  const handleView = (template, checkCanView = false) => {
    if (checkCanView && !template.can_view) return;

    onSelect(template.id);
  }

  const userOwns = assignmentTemplate?.user_owns
  const showCopyLinkButton = userOwns && assignmentTemplate?.visibility === 'public';
  const publicTemplate = assignmentTemplate?.visibility === 'public';

  return (
    <>
      <div className="card assignment-templates-card py-4 mb-4">
        <div className="card-header d-md-flex justify-content-md-between px-md-5 pt-3 flex-wrap">
          <div className="mb-3">
            <div className="title font-size-20"><span className="grey-text">{userFullName(assignmentTemplate.user)}</span> / {assignmentTemplate.name}</div>

            {
              !!assignmentTemplate.copied_from &&
                <div className="mb-4">
                  <span className="dark-grey-text">Copied & edited from:</span> &nbsp;
                  <span className={`${assignmentTemplate.copied_from.can_view ? 'link-text' : ''}`} onClick={() => handleView(assignmentTemplate.copied_from, true)}>{assignmentTemplate.copied_from.user} / {assignmentTemplate.copied_from.name}</span>
                </div>
            }

            <div className="d-flex align-items-center flex-wrap mt-4">
              <div className="me-4">
                <div className="d-flex align-items-center">
                  <div><img width="20" src={CheckMarkLightGreen} /></div>
                  <div className="ms-2">
                    {assignmentTemplate.assignment_objectives?.length || 0}&nbsp;
                    {pluralize(learningObjectiveLabel, assignmentTemplate.assignment_objectives?.length || 0)}
                  </div>
                </div>
              </div>

              <div className="me-4">
                <div className="d-flex align-items-center">
                  <div><img width="20" src={!!assignmentTemplate.rubrics_present ? CheckMarkLightGreen : XInCircleRed} /></div>
                  <div className="ms-2">
                    {
                      !!assignmentTemplate.rubrics_present ?
                        'Rubrics is included' :
                        'Rubrics is not included'
                    }
                  </div>
                </div>
              </div>

              <div className="me-4">
                <div className="d-flex align-items-center">
                  <div><img width="20" src={assignmentTemplate.feedback_present ? CheckMarkLightGreen : XInCircleRed} /></div>
                  <div className="ms-2">
                    {
                      assignmentTemplate.feedback_present ?
                        'Feedback is included' :
                        'Feedback not included'
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="mb-4">
              <div className="font-size-12 grey-text mb-2">TEMPLATE CREATOR</div>
              <div className="d-flex align-items-center">
                <Avatar img={assignmentTemplate.user?.avatar_url} size="sm" />
                <div className="ms-1">
                  <div className="font-size-14">{userFullName(assignmentTemplate.user)}</div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="font-size-12 grey-text mb-1">NO. OF USES</div>
              <div className="font-size-20">{assignmentTemplate.total_times_copied}</div>
            </div>
          </div>
        </div>

        <div className="card-body px-md-5">
          <div className="row">
            <div className="col-lg-5 col-xl-4 mb-3">
              <CopyAndEditButton template={assignmentTemplate} />
            </div >
            <div className="col-lg-7 col-xl-8">
              <div className="text-sm-center text-lg-end">
                <button
                  type="button"
                  className="btn btn-outline-secondary me-2 mb-2"
                  onClick={() => handleView(assignmentTemplate)}
                >
                  <FontAwesomeIcon icon={faEye} className="me-1" />
                  View
                </button>

                {
                  showCopyLinkButton &&
                    <input className="hidden-text" ref={codeRef} type="text" value={assignmentTemplate.sharable_code} />
                }
                <UseTemplateButton template={assignmentTemplate} />
                {
                  !!userOwns &&
                  <div className="dropdown d-inline-block">
                    <button type="button" id={`options_${assignmentTemplate?.id}`} className="btn btn-outline-light text-black-50" data-bs-toggle="dropdown">
                      <FontAwesomeIcon className="d-inline-block" icon={faEllipsisV} />
                    </button>
                    <ul className="dropdown-menu border-none shadow-sm" aria-labelledby={`options_${assignmentTemplate?.id}`}>
                      <li><a className="dropdown-item cursor-pointer" onClick={navigateToEdit}><FontAwesomeIcon icon={faEdit} className="me-1" />Edit</a></li>
                      <li><a className="dropdown-item cursor-pointer" onClick={() => setShowDeleteModal(true)}><FontAwesomeIcon icon={faTrashAlt} className="me-1" />Delete</a></li>
                      {
                        publicTemplate &&
                        <li><a className="dropdown-item cursor-pointer" onClick={handleCopyToClipboard}><FontAwesomeIcon icon={faLink} className="me-1" />Copy Code</a></li>
                      }
                    </ul>
                  </div>
                }
              </div>
            </div>
          </div >
        </div >
      </div >
      <DeleteModal
        show={showDeleteModal}
        onDelete={handleTemplateDelete}
        toggle={toggleDeleteModal}
        text="Are you sure you want to delete this assessment template?"
        submitting={deletingTemplate} />
    </>
  );
};

AssignmentTemplatesCard.defaultProps = {
  assignmentTemplate: undefined,
  user: undefined,
}

AssignmentTemplatesCard.propTypes = {
  assignmentTemplate: PropTypes.shape({}),
  user: PropTypes.shape({}),
};

export default AssignmentTemplatesCard;
