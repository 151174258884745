import * as t from './actionTypes';
import { combineReducers } from 'redux';
import omit from 'lodash/omit';

const loading = (state = false, action) => {
  switch (action.type) {
    case t.ASSIGNMENT_TEMPLATES_LOAD_START:
      return true;
    case t.ASSIGNMENT_TEMPLATES_LOAD_SUCCESS:
    case t.ASSIGNMENT_TEMPLATES_LOAD_ERROR:
      return false;
    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case t.ASSIGNMENT_TEMPLATES_LOAD_START:
      return {};
    case t.ASSIGNMENT_TEMPLATES_CREATE_SUCCESS:
      return {
        ...state,
        [action.assignmentTemplate.id]: action.assignmentTemplate,
      };
    case t.ASSIGNMENT_TEMPLATES_LOAD_SUCCESS:
      return action.assignmentTemplates.reduce(
        (map, assignmentTemplates) => ({
          ...map,
          [assignmentTemplates.id]: assignmentTemplates,
        }), state,
      );
    case t.ASSIGNMENT_TEMPLATES_DELETE_SUCCESS:
      const clone = {...state};
      return omit(clone, action.assignmentTemplateId);
    default:
      return state;
  }
};

const meta = (state = {}, action) => {
  switch (action.type) {
    case t.ASSIGNMENT_TEMPLATES_LOAD_ERROR:
    case t.ASSIGNMENT_TEMPLATES_LOAD_START:
      return {};

    case t.ASSIGNMENT_TEMPLATES_LOAD_SUCCESS:
      return {...state, ...action.meta};
    default:
      return state;
  }
}

const error = (state = null, action) => {
  switch (action.type) {
    case t.ASSIGNMENT_TEMPLATES_LOAD_ERROR:
      return action.error;

    case t.ASSIGNMENT_TEMPLATES_LOAD_SUCCESS:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  byId,
  error,
  meta,
});
