import React from "react";
import PropTypes from 'prop-types';
import { Button } from "reactstrap";
import { Loader } from "core/components";

const CustomButton = ({ children, color, outline, type, withLoader, loading, loaderText, className, loaderColor, ...rest }) => {
  const nurturePinkClass = color === 'nurture-pink' && !outline ? 'btn-nurture-pink' : '';
  const nurtureGreenClass = color === 'nurture-green' && !outline ? 'btn-nurture-green': '';
  const nurturePurpleClass = color === 'nurture-purple' && !outline ? 'btn-nurture-purple': '';
  const nurtureOrangeClass = color === 'nurture-orange' && !outline ? 'btn-nurture-orange': '';

  const nurtureClasses = `${nurturePinkClass} ${nurtureGreenClass} ${nurturePurpleClass} ${nurtureOrangeClass}`;

  return (
    <Button
      {...rest}
      className={`${withLoader ? 'd-inline-flex align-items-center' : ''} ${className} ${nurtureClasses}`.trim()}
      outline={outline}
      type={type}
      color={color}
    >
      {withLoader && loading && loaderText ? loaderText : children}
      {withLoader && <Loader className="ms-2" color={loaderColor} loading={loading} />}
    </Button>
  );
}


CustomButton.defaultProps = {
  type: 'button',
  outline: false,
  color: 'nurture-purple',
  withLoader: false,
  loading: false,
  className: '',
  loaderText: undefined,
  loaderColor: '#ffffff',
};

const nurtureColors = ['nurture-pink', 'nurture-green', 'nurture-purple', 'nurture-orange'];

CustomButton.propTypes = {
  type: PropTypes.string,
  outline: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'dark', 'light', 'danger', 'info', 'success', 'warning', 'white', ...nurtureColors]),
  withLoader: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  loaderText: PropTypes.string,
  loaderColor: PropTypes.string,
}

export default CustomButton;
