import React, {useEffect, useState} from 'react';
import { FormGroup, FormText } from 'reactstrap';
import Button from "core/components/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSchoolConfig } from "core/hooks";
import { SelectField, TextInput } from "core/form/fields";
import {ABILITY, AGES, AUSTRAILIA_AGES, COUNTRIES, SUBJECTS, UK_AGES, UK_COUNTRIES} from "./static";
import {filestack, isPresent} from "core/utils";
import {useDispatch, useSelector} from "react-redux";
import { updateChannel } from "core/channel/action";
import { useSnackbar } from "notistack";
import * as attachmentSelector from "../attachments/selectors";
import { attachmentCreate } from "../attachments/actions";
import { teamsEnv } from 'core/utils';
import Attachment from "../attachments/Attachment";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import SaveFormModal from "./saveFormModal";
import {removeItem, updateArray} from "../../core/utils/array";

// The pulse is used to know if we are trying to navigate away from this page
const ChannelOnboarding = ({ pulse, destinationUrl }) => {
  const { user, curriculumBoundaryEnabled, channelOnboarding  } = useSchoolConfig();
  const data = channelOnboarding?.data || {}

  let oldCountry = data.country;
  const defaultAgeOptions = (country) => {
    if (country === 'Australia') return AUSTRAILIA_AGES
    else if (UK_COUNTRIES.includes(country)) return UK_AGES
    return  AGES
  }

  const [loading, setLoading] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [agesOptions, setAgesOptions] = useState(defaultAgeOptions(data.country));
  const [agesLabel, setAgesLabel] = useState(data.country === 'Australia' ? 'What year is this group of students?' : 'What is the age group of your students?');
  const [curriculumAttachments, setCurriculumAttachments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigateTo = useNavigate();

  const filestackCredentials = useSelector(attachmentSelector.filestackCredentials);
  const context = searchParams.get('context') || 'update';

  const text = {
    assignment_creation: 'To get started, let us gather some information to make the Nurture experience personalised for you and your students.',
    update: 'Customise the Nurture experience for you and your students.'
  }

  const schema = yup.object().shape({
    'subject': yup.string().required('This field cannot be empty').nullable(),
    'age_group': yup.string().required('This field cannot be empty').nullable(),
    'average_reading_age': yup.string().nullable(),
    'country': yup.string().nullable(),
    'student_ability': yup.string().required('This field cannot be empty').nullable(),
    'other_subject': yup.string().nullable()
      .when('subject', {
        is: 'Other',
        then: yup.string().required('This field cannot be empty').nullable()
      }),
  });

  const defaultValues = {
    subject: data.subject || '',
    age_group: data.age_group || '',
    average_reading_age: data.average_reading_age || '',
    student_ability: data.student_ability || '',
    country: data.country || '',
    other_subject: data.other_subject || '',
  };

  const { control, register, trigger, handleSubmit, watch, getValues, setValue, reset, formState: { errors, isDirty, isValid } } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const uploadAttachment = payload => dispatch(attachmentCreate(payload));

  // There can be only 10 curriculum files uploadable for a channel
  const maxFileUploadable = 10 - curriculumAttachments.length
  const onUploaderButtonClick = () => {
    if (maxFileUploadable <= 0) {
      enqueueSnackbar('You cannot upload more than 10 files', { variant: 'error' })
      return
    }

    const filePicker = filestack(filestackCredentials, handleUploadSuccess, [], maxFileUploadable, enqueueSnackbar);
    filePicker.open();
  };

  const handleUploadSuccess = async file => {
    const payload = { channel_id: teamsEnv.channelId, ...file };

    try {
      setUploading(true)
      const uploadedFile = await uploadAttachment(payload);
      setCurriculumAttachments((prev) => {
        const result = [...prev, uploadedFile]
        console.log(result)
        sessionStorage.setItem('user', JSON.stringify(channelAttachmentData(result)))
        return result
      });
      enqueueSnackbar('Attachment uploaded successfully', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' })
    } finally {
      setUploading(false);
    }
  };

  const handleAttachmentDelete = async (deleted) => {
    const updatedArray = removeItem([...curriculumAttachments], deleted)

    setCurriculumAttachments(() => updatedArray);
    sessionStorage.setItem('user', JSON.stringify(channelAttachmentData(updatedArray)))
  }

  const channelAttachmentData = (attachments) => {
    const userCopy = { ...user }
    userCopy.channel_onboarding.data['curriculum_attachments'] = attachments
    return userCopy
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const requestPayload = { ...data };
      if (isPresent(requestPayload.other_subject)) requestPayload.subject = requestPayload.other_subject;

      await dispatch(updateChannel(requestPayload));
      enqueueSnackbar('Your details have been saved successfully', { variant: 'success' });
      if (context === 'assignment_creation') navigateTo('/assignments/new');
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value, name, type) => {
    if (name === 'subject' && type === 'change') {
      const show = value.subject.toLowerCase() === 'other';
      setShowOther(show);

      if (!show) setValue('other_subject', '');
    }

    if (name === 'country' && type === 'change') {
      if (value[name] === 'Australia') {
        setAgesOptions(AUSTRAILIA_AGES);
        setAgesLabel('What year is this group of students?')
        if (oldCountry !== 'Australia') {
          setValue('age_group', '')
          setValue('average_reading_age', '')
        }
        oldCountry = 'Australia'
      } else if (UK_COUNTRIES.includes(value[name])) {
        setAgesOptions(UK_AGES);
        setAgesLabel('What year is this group of students?');
        if (!UK_COUNTRIES.includes(oldCountry)) {
          setValue('age_group', '')
          setValue('average_reading_age', '')
        }
        oldCountry = value[name]
      } else {
        setAgesOptions(AGES);
        setAgesLabel('What is the age group of your students?')
        if (oldCountry === 'Australia') {
          setValue('age_group', '')
          setValue('average_reading_age', '')
        }
        oldCountry = value[name]
      }
    }

    if (value[name]) trigger(name) // Validate the field
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => handleChange(value, name, type));
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    reset();
    const subjects = SUBJECTS.map((item) => item.value)

    if (isPresent(data.subject) && !subjects.includes(data.subject)) {
      setShowOther(true)
      setValue('subject', 'Other');
      setValue('other_subject', data.subject);
    }

    if (isPresent(data.curriculum_attachments)) {
      setCurriculumAttachments(() => data.curriculum_attachments);
    }
  }, []);

  useEffect(async () => {
    if (pulse > 0) {
      await trigger(); // Trigger validation on the form

      if (isDirty && isValid) {
        // show save form
        setShowSaveModal(true)
      }
    }
  }, [pulse])

  return (
    <div className="row">
      <div className="col-xl-7 col-lg-8">
        <form className="mb-0" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 border-bottom">
            <h4 className='text-nurture-purple font-weight-500'>Classroom Details</h4>
            <p className="mb-2">{ text[context] }</p>
          </div>

          {/*Subjects*/}
          <FormGroup>
            <SelectField
              label="What subject is this specific team's channel for?"
              control={control}
              options={SUBJECTS}
              error={!!errors.subject?.message}
              {...register('subject')}
              required
            />
            {errors.subject && (
              <p className="mt-1">
                <FormText color="danger">
                  {errors.subject.message}
                </FormText>
              </p>
            )}
          </FormGroup>

          {
            showOther &&
            <FormGroup>
              <TextInput
                placeholder="Subject"
                control={control}
                error={!!errors.other_subject?.message}
                {...register('other_subject')}
              />
              {errors.other_subject && (
                <p className="mt-1">
                  <FormText color="danger">
                    {errors.other_subject.message}
                  </FormText>
                </p>
              )}
            </FormGroup>
          }

          {/* Country */}
          <FormGroup>
            <SelectField
              label="Country (Optional)"
              options={COUNTRIES}
              error={!!errors.country?.message}
              {...register('country')}
            />
            {errors.country && (
              <p className="mt-1">
                <FormText color="danger">
                  {errors.country.message}
                </FormText>
              </p>
            )}
          </FormGroup>

          {/*Age group*/}
          <FormGroup>
            <SelectField
              label={agesLabel}
              options={agesOptions}
              error={!!errors.age_group?.message}
              {...register('age_group')}
              required
            />
            {errors.age_group && (
              <p className="mt-1">
                <FormText color="danger">
                  {errors.age_group.message}
                </FormText>
              </p>
            )}
          </FormGroup>

          {/*Student Ability*/}
          <FormGroup>
            <SelectField
              label="What is the ability of this class group?"
              options={ABILITY}
              error={!!errors.student_ability?.message}
              {...register('student_ability')}
              required
            />
            {errors.student_ability && (
              <p className="mt-1">
                <FormText color="danger">
                  {errors.student_ability.message}
                </FormText>
              </p>
            )}
          </FormGroup>

          {/*Average reading age*/}
          <FormGroup>
            <SelectField
              label="What is the average reading age of this group (Optional)"
              options={agesOptions}
              error={!!errors.average_reading_age?.message}
              {...register('average_reading_age')}
            />
            <div className="alert alert-secondary mt-2 p-2">
              <small className="form-text d-block">
                <strong>Tip:</strong> this optional preference allows you to tailor the language the assistant uses. If you have  a
                group of students’ whose reading age is lower than that of their actual age, then please select a
                range below; otherwise, leave blank.
              </small>
            </div>
            {errors.average_reading_age && (
              <p className="mt-1">
                <FormText color="danger">
                  {errors.average_reading_age.message}
                </FormText>
              </p>
            )}
          </FormGroup>

          {/* Curriculum */}
          {
            curriculumBoundaryEnabled &&
            <div className='mt-5'>
              <div className="mb-4 border-bottom">
                <h4 className='text-nurture-purple font-weight-500'>Curriculum (Optional)</h4>
                <p className="mb-2">
                  Upload your Curriculum, Marking Scheme or any other document that will help the Nurture
                  Assistant create assessments that are aligned to your curriculum
                </p>
              </div>

              <div>
                {
                  isPresent(curriculumAttachments) &&
                  <div className="mb-3">
                    {
                      curriculumAttachments.map(curriculumAttachment => (
                        <Attachment attachment={curriculumAttachment}
                                    allowDownload={false}
                                    allowView={true}
                                    onDelete={handleAttachmentDelete}
                        />
                      ))
                    }
                  </div>
                }
                <Button
                  withLoader={true}
                  loading={uploading}
                  loaderText={'Uploading'}
                  disabled={uploading}
                  onClick={onUploaderButtonClick}
                >
                  Upload Curriculum
                </Button>
              </div>
            </div>
          }

          <div className="border-top mt-4 pt-4">
            {/*Alert*/}
            <div className="alert alert-secondary p-2" role="alert">
              <small className="form-text">
                <strong>Remember: </strong>
                You can come back to edit your classroom preferences at any stage in the future.
              </small>
            </div>

            <div className="d-grid gap-2 my-2">
              <Button
                type="submit"
                withLoader={true}
                loading={loading}
                loaderText="Saving in progress..."
                className="justify-content-center p-2"
                disabled={loading}>
                Save Settings
              </Button>
            </div>
          </div>
        </form>

        <SaveFormModal destinationUrl={destinationUrl} getValues={getValues} closeModal={() => setShowSaveModal(false)} show={showSaveModal} />
      </div>
    </div>
  )
};

ChannelOnboarding.defaultProps = {
  pulse: 0,
  destinationUrl: ''
}

ChannelOnboarding.propTypes = {
  pulse: PropTypes.number,
  destinationUrl: PropTypes.string
}

export default ChannelOnboarding;
