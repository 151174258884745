const statusDisplay = status => {
  const STATUSES = {
    scheduled: ['badge-soft-dark', 'Scheduled'],
    feedback_sent: ['badge-soft-success', 'Feedback Sent'],
    unassigned: ['badge-warning', 'Unassigned'],
    assigned: ['badge-soft-primary', 'Assigned'],
    pending_feedback: ['badge-warning', 'Pending Feedback'],
    pending: ['badge-warning', 'Pending'],
    overdue: ['badge-soft-danger', 'Overdue'],
    review_feedback: ['badge-soft-success', 'Review Feedback'],
    completed: ['badge-soft-success', 'Completed'],
    submitted: ['badge-soft-primary', 'Submitted'],
    draft: ['badge-soft-dark', 'Draft'],
    ready_for_feedback: ['badge-warning', 'Ready for Feedback'],
    late: ['badge-soft-danger', 'Late'],
  };

  return STATUSES[status] || 'badge-soft-secondary';
};

export default statusDisplay;
