export const emojiResponses = {
  1: ['😓', 'Disappointed'],
  2: ['🙂', 'Neutral'],
  3: ['😎', 'Happy']
};

export const confidenceOptions = {
  1: ['😓', 'Low'],
  2: ['🙂', 'Neutral'],
  3: ['😎', 'High']
};
