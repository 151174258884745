import { Modal, ModalBody } from "reactstrap";
import Button from "core/components/Button";
import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import StudentsInClassroomIllustration from 'assets/images/illustrations/students_in_classroom.svg'
import { useSchoolConfig } from "core/hooks";
import { useNavigate } from "react-router-dom";

const ChannelOnboardingModal = ({ assignmentsCount, ...otherProps }) => {
  const [show, setShow] = useState(false)
  const { channelOnboarding } = useSchoolConfig();
  const navigateTo = useNavigate();

  const navigate = () => navigateTo('?activeTab=classroom_settings&context=assignment_creation');

  useEffect(() => {
    setShow(!channelOnboarding?.completed)
  }, []);


  return (
    <Modal size='lg' isOpen={show} zIndex={21800000000} {...otherProps}>
      <ModalBody>
        <div className="p-3">
          <h4 className="text-center border-bottom mb-4 pb-3">Welcome to Nurture!</h4>
          <div className="text-center mb-4">
            <img src={StudentsInClassroomIllustration} className="img-fluid" />
          </div>
          <p className="text-center">
            Before creating your { assignmentsCount > 0 ? 'next' : 'first' } assessment, we need some info on this
            class group to make sure Nurture works best for them
          </p>
          <p className="text-center">
            You can always update this later in classroom settings.
          </p>

          <div className="d-grid mt-4">
            <Button onClick={navigate}>Okay let's go <FontAwesomeIcon className="ms-2" icon={faArrowRight} /></Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ChannelOnboardingModal.defaultProps = {
  assignmentsCount: 0
};

ChannelOnboardingModal.propTypes = {
  assignmentsCount: PropTypes.number,
}

export default ChannelOnboardingModal;