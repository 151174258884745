import { createSelector } from '@reduxjs/toolkit';

export const get = state => state.assignmentTemplates;

export const loading = createSelector(get, state => state.loading);
export const error = createSelector(get, state => state.error);
export const meta = createSelector(get, state => state.meta);
export const getAssignmentTemplateById = id => createSelector(get, state => state.byId[id]);
export const getAssignmentTemplates = createSelector(get, state => Object.values(state.byId)
  .sort((a, b) => (a.created_at < b.created_at) ? 1 : -1));
