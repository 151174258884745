import { client } from 'filestack-react';
import {getFileExtension} from "./index";

const fileTooBig = "File is too large. Please reduce the file size to under 200MB and try again.";

const filestackPicker = (filestackCredentials, handleUploadSuccess, format = [], maxFiles = 20, enqueueSnackbar = null) => {

  const filestackClient = client.init(filestackCredentials.api_key, {
    security: {
      policy: filestackCredentials.policy,
      signature: filestackCredentials.signature
    }
  });

  const options = {
    uploadInBackground: false,
    fromSources: ['local_file_system', 'video', 'audio', 'webcam', 'googledrive', 'onedrive'],
    maxFiles: maxFiles,
    onFileSelected: function(file) {
      // If you throw any error in this function it will reject the file selection.
      // The error message will be displayed to the user as an alert.
      if (file.size > 200000000) {
        typeof enqueueSnackbar  === 'function' ?
          enqueueSnackbar(fileTooBig, { variant: 'error' }) :
          alert(fileTooBig);
        throw new Error(fileTooBig);
      }

      // if (format.length > 0 && Array.isArray(format) && !format.includes(file.mimetype)) {
      //   typeof enqueueSnackbar  === 'function' ?
      //     enqueueSnackbar(`Only ${format.join(', ')} files are allowed`, { variant: 'error' }) :
      //     alert(`Only ${format} files are allowed`);
      //   throw new Error(`Only ${format} files are allowed`);
      // }

      const fileExtension = getFileExtension(file.filename);
      const allowedExtensions = ["c", "cpp", "css", "csv", "docx", "gif", "go", "html", "java", "jpeg", "jpg", "js", "json", "md", "pdf", "php", "pkl", "png", "pptx", "py", "rb", "tar", "tex", "ts", "txt", "webp", "xlsx", "xml", "zip"];
      if (!allowedExtensions.includes(fileExtension)) {
        typeof enqueueSnackbar  === 'function' ?
          enqueueSnackbar(`Only ${allowedExtensions.join(", ")} files are allowed`, { variant: 'error' }) :
          alert(`Only ${allowedExtensions.join(", ")} files are allowed`);
        throw new Error(`Only ${allowedExtensions.join(", ")} files are allowed`);
      }
    },
    onFileUploadFailed: function(file, error) {
      typeof enqueueSnackbar  === 'function' ?
        enqueueSnackbar("There was an error uploading " + file.filename + ". Try again with a lesser size or try a different file format?", { variant: 'error' }) :
        alert("There was an error uploading " + file.filename + ". Try again with a lesser size or try a different file format?");
    },
    onFileUploadFinished: function(file) {
      // Upload each file individually
      handleUploadSuccess(file)
    },
    onUploadDone: function(files){
      // All done! nothing to do here.
      // Content has been refreshed via the POST requests above
    }
  };

  return filestackClient.picker(options)
}

export default filestackPicker;
