import { createRequest, composeRequestConfig } from 'core/utils/axios/api';
import { teamsEnv } from 'core/utils';

export const getAssignmentTemplates = (query) => {
  const queryString = new URLSearchParams(query).toString();
  const url = `/api/v1/assignment_templates?${queryString}`;

  const config = composeRequestConfig({ url });
  return createRequest(config);
};

export const createAssignmentTemplate = (query) => {
  const queryString = new URLSearchParams(query).toString();
  const url = `/api/v1/assignment_templates?${queryString}`;

  const config = composeRequestConfig({ url, method: 'post' });
  return createRequest(config);
};

export const updateAssignmentTemplateFeedback = (id, payload) => {
  const url = `/api/v1/assignment_templates/${id}/update_feedback`;

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
};

export const showAssignmentTemplate = (id) => {
  const url = `/api/v1/assignment_templates/${id}`;

  const config = composeRequestConfig({ url });
  return createRequest(config);
};

export const updateAssignmentTemplate = (id, payload) => {
  const url = `/api/v1/assignment_templates/${id}`;

  const config = composeRequestConfig({ url, payload, method: 'patch' });
  return createRequest(config);
};

export const deleteAssignmentTemplate = (id) => {
  const url = `/api/v1/assignment_templates/${id}`;

  const config = composeRequestConfig({ url, method: 'delete' });
  return createRequest(config);
};

export const createAssignmentFromAssignmentTemplate = (id) => {
  const payload = { channel_id: teamsEnv.channelId, assignment_type: 'ms_teams'};
  const url = `/api/v1/assignment_templates/${id}/use_template`;

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
};
