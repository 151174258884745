import EmptyStudentReflectionImage from "assets/images/illustrations/empty_assignments.png";
import TextHeader from "core/components/TextHeader";

const EmptyStudentReflection = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-md-4">
            <img src={EmptyStudentReflectionImage} alt="Empty" className="img-fluid" />
          </div>
          <div className="col-md-8">
            <TextHeader>Pending Student Reflection</TextHeader>
            <p className="text-muted mb-0">Your feedback has not been reviewed by this student yet.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmptyStudentReflection;