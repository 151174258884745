import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {faAngleLeft, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Autolinker, Avatar} from "core/components";
import {pluralize, userFullName} from "core/utils";
import {useSchoolConfig} from "core/hooks";
import LearningObjectives from "modules/assignments/objectives/LearningObjectives";
import {showAssignmentTemplate} from "../action";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import Attachments from "modules/attachments/Attachments";
import {OneNote} from "modules/assignments/OneNote";
import RubricTable from "modules/assignments/feedback/RubricTable";
import GroupFeedBackObjectives from "modules/assignments/feedback/GroupFeedbackObjectives";
import './assignmentTemplateView.scss';
import CopyAndEditButton from "../CopyAndEditButton";
import UseTemplateButton from "../UseTemplateButton";

const AssignmentTemplateView = ({ templateId, onClose }) => {
  const [assignmentTemplateLoading, setAssignmentTemplateLoading] = useState(true);
  const [assignmentTemplate, setAssignmentTemplate] = useState({});

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { learningObjectiveLabel } = useSchoolConfig();

  const marksDisplay = {
    marks: 'Marks',
    rubrics: 'Rubric',
    percentage: 'Percentage'
  }

  const showAttachments = (!!assignmentTemplate?.onenote_url && assignmentTemplate.onenote_url.trim() !== "") ||
    !!assignmentTemplate?.attachments?.length

  const fetchAssignmentTemplate = async () => {
    try {
      const template = await dispatch(showAssignmentTemplate(templateId));
      setAssignmentTemplate(template)
      console.log(template)
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setAssignmentTemplateLoading(false)
    }
  };

  useEffect(() => {
    fetchAssignmentTemplate();
  }, []);

  return (
    <>
      {
        assignmentTemplateLoading ?
          <div>Loading...</div> :
          <div className="AssignmentTemplateView">
            <div className="main-body">
              {/*Headers*/}
              <div className="mb-4 d-sm-flex justify-content-sm-between align-items-center flex-wrap">
                <div className="font-size-16 cursor-pointer text-nurture-blue mb-3" onClick={() => onClose()}>
                  <FontAwesomeIcon icon={faAngleLeft} className="me-3" />
                  Back
                </div>

                <div className="text-nurture-purple font-size-22 mb-3 text-center text-sm-left">
                  Template preview
                </div>

                <div className="text-center text-sm-left mb-3">
                  <div className="font-size-12 text-grey mb-2">TEMPLATE CREATOR</div>
                  <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                    <Avatar img={assignmentTemplate.user?.avatar_url} size="sm" />
                    <div className="ms-1">
                      <div className="font-size-14">{userFullName(assignmentTemplate.user)}</div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Card*/}
              <div className="card">
                <div className="card-body py-5 px-3 px-md-5">
                  <div>
                    {/*Template Name*/}
                    <div className="mb-2 font-size-20">
                      {assignmentTemplate.name}
                    </div>

                    {/*Template Description*/}
                    <Autolinker className="mb-5 font-size-16" content={assignmentTemplate.description} />

                    {/*Template Learning Objectives*/}
                    <div className="assignment_objectives_list mb-5">
                      <div className="font-size-20">{learningObjectiveLabel}</div>
                      <LearningObjectives items={assignmentTemplate?.assignment_objectives} sectionTitle="Learning Objectives" withBackground />
                    </div>

                    {/*Attachments & one note url*/}
                    {
                      showAttachments &&
                      <div className="mb-5">
                        <div className="font-size-20">Attachments</div>
                        {
                          !!assignmentTemplate?.attachments?.length &&
                          <Attachments allowDelete={false} attachments={assignmentTemplate.attachments} />
                        }

                        {
                          !!assignmentTemplate?.onenote_url && assignmentTemplate.onenote_url.trim() !== "" &&
                          <OneNote onenote_url={assignmentTemplate.onenote_url} allowDelete={false} />
                        }

                      </div>
                    }

                    {/*Grading*/}
                    <div className="mb-5">
                      <div className="font-size-20 mb-3">Grading</div>
                      <div className="alert alert-light font-size-16">
                        {
                          assignmentTemplate.graded ?
                            <>
                              <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                              This assessment is graded using <strong>'{marksDisplay[assignmentTemplate.grade_display_to_students]}'</strong>. &nbsp;
                              The maximum marks available is <strong>{assignmentTemplate.max_marks}</strong>
                            </> :
                            <>
                              <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                              This assessment template is not graded.
                            </>
                        }
                      </div>

                      {
                        assignmentTemplate.rubrics_present &&
                        <div className="mt-3">
                          <RubricTable assignment={assignmentTemplate} />
                        </div>
                      }
                    </div>

                    {/*Learning Objectives Feedback*/}
                    <div className="mb-5">
                      <div className="font-size-20 mb-3">{learningObjectiveLabel} Feedback</div>
                      {
                        assignmentTemplate.feedback_present ?
                          <GroupFeedBackObjectives objectives={assignmentTemplate?.assignment_objectives} isTeacher={true} /> :
                          <div className="mt-4 no-feedback">
                            <div className="objectives">
                              {
                                assignmentTemplate.assignment_objectives.map((objective, index) => {
                                  return (
                                    <div className="card mt-3 bg-nurture-orange-light opacity-25">
                                      <div className="card-header px-0 bg-nurture-orange-light border-bottom">
                                        <div key={objective.id} className='d-flex align-items-center mt-2 mb-3 px-3 rounded'>
                                          <div className='me-3'>
                                            <div className='avatar avatar-sm text-white'>
                                              <div className='avatar-title font-size-md rounded-circle bg-nurture-orange'>{objective.position || index + 1}</div>
                                            </div>
                                          </div>
                                          <div className='font-size-16 fs-6 flex-grow-1'>{objective.name}</div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                            <div className="overlay px-0 px-sm-4">
                              {
                                assignmentTemplate.rubrics_present ?
                                  'Assessments with a Rubric cannot have learning objective feedback' :
                                  `This template does not have any ${pluralize(learningObjectiveLabel)} feedback yet.`
                              }

                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="card border-top shadow-none mb-0">
                <div className="card-body d-flex justify-content-between">
                  <CopyAndEditButton template={assignmentTemplate} />
                  <UseTemplateButton template={assignmentTemplate} />
                </div>
              </div>
            </div>
          </div>
      }
    </>
  );
};

AssignmentTemplateView.defaultProps = {
  template: {}
};

AssignmentTemplateView.propTypes = {
  template: PropTypes.shape({})
};

export default AssignmentTemplateView;