import { Button } from "core/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { userFullName } from "core/utils";
import submissionPdf from "../submissions/submissionPdf";
import html2pdf from "html2pdf.js/src";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

const DownloadFeedbackButton = ({ submission, assignment, showButton }) => {
  const [printing, setPrinting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handlePrint = async () => {
    try {
      const opt = {
        margin: [10, 0],
        filename: `${userFullName(submission?.user)}_feedback_${assignment.name}.pdf`,
        image: { type: 'pdf', quality: 0.98 },
        html2canvas:  { scale: 2, useCORS: true, dpi: 192 },
        enableLinks: true,
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        // jsPDF: { format: 'letter', orientation: 'portrait' }
      };
      setPrinting(true)
      const htmlContent = await submissionPdf(submission, assignment)
      html2pdf().from(htmlContent).set(opt).save();
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Error occurred while downloading user feedback', { variant: 'error' })
    } finally {
      setPrinting(false)
    }
  }

  return (
    <>
      {
        showButton &&
          <div className="d-flex flex-column h-100 justify-content-end">
            <Button withLoader={true} loading={printing} color="nurture-green" className="btn-sm" onClick={handlePrint}>
              <FontAwesomeIcon className="me-2" icon={faPrint} />
              Download as PDF
            </Button>
          </div>
      }
    </>

  );
};

// DownloadFeedbackButton.defaultProps = {
// };

DownloadFeedbackButton.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
  submission: PropTypes.shape({}).isRequired,
  showButton: PropTypes.bool.isRequired,
}


export default DownloadFeedbackButton;