import axios from 'axios';
const url = process.env.REACT_APP_API_URL;

// Default config options
const defaultOptions = {
  baseURL: url,
  'Content-Type': 'application/json',
  headers: {},
  timedOut: 3000,
};

// Create instance
const instance = axios.create(defaultOptions);

export default instance;
