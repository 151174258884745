import { useNavigate } from "react-router-dom";
import React, { Fragment } from "react";
import { useSchoolConfig } from "./hooks";
import Button from "./components/Button";

const NewAssignmentButton = () => {
  const { trialEnded, channelOnboarding: { completed } } = useSchoolConfig();
  const navigateTo = useNavigate();

  const navigate = () => navigateTo('/assignments/new');

  const handleClick = () => {
   navigate();
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-end">
        <div className="cursor-not-allowed mb-4">
          <Button onClick={handleClick} className={`btn btn-outline-nurture-purple ${trialEnded ? 'pointer-events-none' : ''}`} to="/assignments/new">New Assessment</Button>
        </div>
      </div>
    </Fragment>
  );
};

export default NewAssignmentButton;