import EmptyStudentReflection from "./EmptyStudentReflection";
import { isPresent, userFullName } from "core/utils";
import PropTypes from "prop-types";
import { Autolinker, Avatar, Header } from "core/components";
import TextHeader from "core/components/TextHeader";
import TimeFromNow from "core/components/TimeFromNow";
import Emoji from "core/components/emoji/Emoji";

const StudentReflection = ({ userOwns, submission, assignment }) => {
  const title = userOwns ? 'Your Reflection' : 'Student Reflection';
  const subtitle = userOwns ? 'Your reflection on the assessment feedback.' : 'Student reflection on your feedback.';
  const reflectionSummaryStartText = userOwns ? 'You were ' : `${userFullName(submission?.user)} was `;

  const emojiResponseSummary = {
    '1': 'Disappointed',
    '2': 'Neutral',
    '3': 'Happy'
  }

  return (
    <div>
      <Header title={title} subtitle={subtitle} />
      {
        isPresent(submission.student_reflection) && isPresent(submission.reflection_submitted_at) ?
          <div className="card">
            <div className="card-body pb-0">
              <div className="row">
                <div className="col-auto">
                  <Avatar img={submission?.user?.avatar_url} size="md" />
                </div>
                <div className="col ms-n2">
                  <TextHeader className="mb-1">{userFullName(submission?.user)}</TextHeader>
                  <TimeFromNow time={submission?.reflection_submitted_at} />
                </div>
              </div>
            </div>
            {
              assignment?.student_reflection === 'emoji_response' &&
              !!submission?.student_reflection?.emoji_response &&
              <div className="card-body">
                <div className="d-flex">
                  {reflectionSummaryStartText}
                  <strong className="d-flex me-1">
                    <Emoji className="mx-1" type={emojiResponseSummary[submission?.student_reflection?.emoji_response]} />
                    {emojiResponseSummary[submission?.student_reflection?.emoji_response]}
                  </strong>
                  with the feedback.
                </div>

                {
                  isPresent(submission?.student_reflection?.student_feedback_reflection_comment) &&
                  <div className="d-block mt-2 comment-body">
                    <p className="comment-text">
                      {submission?.student_reflection?.student_feedback_reflection_comment}
                    </p>
                  </div>
                }
              </div>
            }

            {
              assignment?.student_reflection === 'triple_response' &&
              <div className="card-body">
                <TextHeader className="font-weight-600">{submission.triple_response_questions.triple_response_question_three.question}</TextHeader>
                <Autolinker className="font-size-14 font-weight-100 mb-3" content={submission?.student_reflection?.triple_response_question_three} />

                <TextHeader className="font-weight-600">{submission.triple_response_questions.triple_response_question_two.question}</TextHeader>
                <Autolinker className="font-size-14 font-weight-100 mb-3" content={submission?.student_reflection?.triple_response_question_two} />

                <TextHeader className="font-weight-600">{submission.triple_response_questions.triple_response_question_one.question}</TextHeader>
                <Autolinker className="font-size-14 font-weight-100 mb-3" content={submission?.student_reflection?.triple_response_question_one} />

                {
                  submission?.student_reflection?.student_feedback_reflection_comment &&
                  <>
                    <TextHeader className="font-weight-600">Additional Comments</TextHeader>
                    <Autolinker className="font-size-14 font-weight-100" content={submission?.student_reflection?.student_feedback_reflection_comment} />
                  </>
                }
              </div>
            }

          </div> :
          <EmptyStudentReflection />
      }
    </div>
  );
}

StudentReflection.defaultProps = {
  userOwns: false,
  submission: {},
  assignment: {},
};

StudentReflection.propTypes = {
  userOwns: PropTypes.bool,
  submission: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
};

export default StudentReflection;
