import PropTypes from "prop-types";
import $ from 'jquery';
import {useEffect} from "react";
import 'selectric/public/jquery.selectric.min'

const Selectric = ({ placeholder, options, selected, onChange, ...rest }) => {
  useEffect(() => {
    $('select#selectric-select').selectric({
      onChange: onChange
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <select id="selectric-select" {...rest}>
      { !!placeholder && <option>{placeholder}</option> }
      {
        options.map((option, index) => (
          <option selected={selected === option.value} value={option.value} key={index}>{option.label}</option>
        ))
      }
    </select>
  );
}

Selectric.defaultProps = {
  options: [],
  selected: '',
  placeholder: 'Select',
  onChange: () => undefined
};

Selectric.propTypes = {
  placeholder: PropTypes.string,
  selected: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.string})),
  onChange: PropTypes.func
};

export default Selectric;