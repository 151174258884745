import * as t from '../actionTypes';
import * as api from './api';
import { handleApiError } from 'core/utils';

const submitWorkStart = () => ({
  type: t.SUBMIT_WORK_START,
});

const submitWorkSuccess = userAssignment => ({
  type: t.SUBMIT_WORK_SUCCESS,
  userAssignment,
});

export const submitAssignment = (params, payload) => async dispatch => {
  dispatch(submitWorkStart());

  try {
    const response = await api.submitWork(params, payload);
    dispatch(submitWorkSuccess(response.data));

    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};

