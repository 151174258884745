import { useSnackbar } from 'notistack';
import { Button } from "reactstrap";

const CloseSnackBar = () => {
  const { closeSnackbar } = useSnackbar();
  return (
    <Button className="btn-sm" color="light" onClick={() => closeSnackbar()}>Dismiss</Button>
  )
}

export default CloseSnackBar;