import React from "react";
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

const TextArea = React.forwardRef(({ label, required, error, ...otherProps }, ref) => (
  <>
    {label &&
      <Label htmlFor={label}>{label} {required && <span className="required">*</span>}</Label>
    }
    <textarea
      className={`form-control ${error ? 'error' : ''}`}
      ref={ref}
      {...otherProps}
    />
  </>
));

TextArea.defaultProps = {
  required: false,
  label: undefined,
};

TextArea.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default TextArea;
