import TeacherShow from "./TeacherShow";
import StudentAssignment from "../userAssignments";

const AssignmentShow = () => {
  const sessionUser = JSON.parse(sessionStorage.getItem('user'));
  const role = sessionUser?.role;

  return role === 'teacher' ? <TeacherShow /> : <StudentAssignment />;
};

export default AssignmentShow;
