import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import { dateUtils } from "core/utils";
import PropTypes from "prop-types";

const TimeFromNow = ({ time, children }) => {
  return (
    <>
      {
        !!time &&
        <span className="card-text small text-muted">
          <FontAwesomeIcon icon={faClock} />
          <time className="ms-1">{dateUtils.fromNow(time)}</time>
          {children}
        </span>
      }
    </>
  );
}

TimeFromNow.defaultProps = {
  time: undefined,
  children: undefined
}

TimeFromNow.prototypes = {
  time: PropTypes.any.isRequired,
  children: PropTypes.node
}

export default TimeFromNow;