import PropTypes from "prop-types";

import {
  ShimmerButton,
  ShimmerCategoryItem, ShimmerText,
  ShimmerTitle
} from "react-shimmer-effects";
import AssignmentDatesInfo from "../AssignmentDatesInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import {faEdit, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import React from "react";
import SeparatorIcon from "assets/images/general_icons/separator.svg";

const Separator = () => <img className="mx-2" src={SeparatorIcon} alt="Separator" />

const AssignmentsSkeletalLoader = ({ items }) => (
  <div className="container">
    {/*<div className="d-flex justify-content-end mb-4">*/}
    {/*  <ShimmerButton size="lg" />*/}
    {/*</div>*/}
    <div className="row">
      {
        items.map((item, index) => (
          <div className="col-12 mb-4" key={item}>
            {/*<div className="card">*/}
            {/*  <div className="card-body">*/}
            {/*    <ShimmerTitle line={1} variant="primary"/>*/}
            {/*    <ShimmerCategoryItem hasImage imageType="circular" imageWidth={50} imageHeight={50} title/>*/}
            {/*  </div>*/}
            {/*  <div className="card-footer">*/}
            {/*    <div>*/}
            {/*      <ShimmerButton size="md"/>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="card">
              <div className="card-body">
                <div className="row align-items-center p-3">
                  {/*Left*/}
                  <div className='col-xl-7 col-sm-12 mb-4 mb-xl-0'>
                    <div className='font-size-20 font-weight-600 mb-2'>
                      <ShimmerTitle line={1} variant="primary" />
                    </div>
                    <div>
                      <ShimmerTitle line={1} />
                    </div>
                  </div>

                  <div className="row align-items-center col-xl-5 col-sm-12">
                    {/*Middle*/}
                    <div className="d-flex col-sm-6 mb-4 mb-sm-0">
                      {/*Students Sunmitted*/}
                      <div className="me-5 merriweather-font">
                        <div
                          className="font-size-24 font-weight-400 metrics-value">
                          <ShimmerTitle />
                        </div>
                      </div>
                      {/*Students Reflected*/}
                      <div className="merriweather-font">
                        <div
                          className="font-size-24 font-weight-400 metrics-value">
                          <ShimmerTitle />
                        </div>
                      </div>
                    </div>

                    {/*Right*/}
                    <div className="d-flex justify-content-sm-end ms-xl-0 col-sm-6">
                      <div className="me-3">
                        <ShimmerButton size="md" />
                      </div>
                      <div className="dropdown d-inline-block">
                        <button type="button" id={`options_${index}`}
                                className="btn btn-outline-white text-black-50"
                                data-bs-toggle="dropdown">
                          <FontAwesomeIcon className="d-inline-block" icon={faEllipsisV}/>
                        </button>
                        <ul className="dropdown-menu border-none shadow-sm"
                            aria-labelledby={`options_${index}`}>
                          <li><a className="dropdown-item cursor-pointer"><ShimmerTitle /></a></li>
                          <li><a className="dropdown-item cursor-pointer"><ShimmerTitle /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<div className="d-flex justify-content-between p-2 px-0">*/}
                {/*  <div className="flex-grow-1">*/}
                {/*    <AssignmentCardHeader user={user} date={created_at} className="py-4 px-4" />*/}
                {/*  </div>*/}
                {/*  {isTeacher && (*/}
                {/*      <div className="dropdown d-inline-block">*/}
                {/*        <button type="button" id={`options_${assignment?.id}`} className="btn btn-outline-white text-black-50"*/}
                {/*                data-bs-toggle="dropdown">*/}
                {/*          <FontAwesomeIcon className="d-inline-block" icon={faEllipsisV}/>*/}
                {/*        </button>*/}
                {/*        <ul className="dropdown-menu border-none shadow-sm" aria-labelledby={`options_${assignment?.id}`}>*/}
                {/*          <li><a className="dropdown-item cursor-pointer" onClick={navigateToEdit}><FontAwesomeIcon*/}
                {/*              icon={faEdit} className="me-1"/>Edit</a></li>*/}
                {/*          <li><a className="dropdown-item cursor-pointer"*/}
                {/*                 onClick={() => setShowDeleteModal(true)}><FontAwesomeIcon icon={faTrashAlt}*/}
                {/*                                                                           className="me-1"/>Delete</a></li>*/}
                {/*        </ul>*/}
                {/*      </div>*/}
                {/*    )*/}
                {/*  }*/}
                {/*</div>*/}

                {/*<div className="d-flex justify-content-between py-1 px-4">*/}
                {/*  <Link to={`assignments/${assignment.id}`} className="text-decoration-none">*/}
                {/*    <h6 className="mb-0 name">{name}</h6>*/}
                {/*  </Link>*/}
                {/*  <span className='custom-badge assignment-badge-fixed-width text-end'>*/}
                {/*    <span className={`${badge} rounded`}> {badgeText} </span>*/}
                {/*  </span>*/}
                {/*</div>*/}
                {/*<div className="px-4">*/}
                {/*  {due_at && (*/}
                {/*      <small*/}
                {/*          className={`${overdue(due_at) ? 'text-danger' : 'text-muted'} fw-lighter`}>Due {dateUtils.fromNow(due_at)}</small>*/}
                {/*  )*/}
                {/*  }*/}
                {/*</div>*/}

                {/*<div className="px-4 py-2 border-bottom mt-2">*/}
                {/*  <ReactMarkdown>{description && truncate({ string: description, length: 50 })}</ReactMarkdown>*/}
                {/*</div>*/}

                {/*{isTeacher && (*/}
                {/*  <div className="d-flex  align-items-center py-3 px-4">*/}
                {/*    <small className="text-muted fw-light d-block small-text">{progress?.submissions} submitted</small>*/}
                {/*    <ProgressBar value={assignmentProgress} />*/}
                {/*  </div>*/}
                {/*)}*/}

                {/*<div className="d-flex justify-content-center align-items-center py-3 px-4 border-top">*/}
                {/*  <Link to={`/assignments/${assignment.id}`} className="link-btn btn-md w-100 rounded">*/}
                {/*    View assessment*/}
                {/*  </Link>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        ))
      }
    </div>
  </div>
);

AssignmentsSkeletalLoader.defaultProps = {
  items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
};

AssignmentsSkeletalLoader.propTypes = {
  items: PropTypes.array,
};

export default AssignmentsSkeletalLoader;
