import PropTypes from "prop-types";
import { createAssignmentFromAssignmentTemplate } from "./action";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import Button from "../../core/components/Button";

const UseTemplateButton = ({ template }) => {
  const [useButtonText, setUseButtonText] = useState('Use right now');
  const [useButtonDisabled, setUseButtonDisabled] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const createFromTemplate = async () => {
    try {
      setUseButtonText('Loading');
      setUseButtonDisabled(true);
      const response = await dispatch(createAssignmentFromAssignmentTemplate(template.id));
      enqueueSnackbar('Assessment was created', { variant: 'success' });
      navigateTo(`/assignments/${response.id}/edit`);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setUseButtonText('Use right now');
      setUseButtonDisabled(false);
    }
  }


  return (
    <Button disabled={useButtonDisabled}
            onClick={createFromTemplate}
            type="button"
            className="me-2 mb-2"
            withLoader={true}
            loading={useButtonDisabled}>
      {useButtonText}
    </Button>
  );
};

UseTemplateButton.propTypes = {
  template: PropTypes.shape({}).isRequired
};

export default UseTemplateButton;