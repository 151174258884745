import React, { useState, useEffect } from "react";

import AssignmentTemplateFilters from "./filters/AssignmentTemplateFilters";
import AssignmentTemplatesCard from "./assignmentTemplatesCard/AssignmentTemplatesCard";
import { useDispatch, useSelector } from "react-redux";
import * as assignmentTemplateSelector from "./selectors";
import { allAssignmentTemplates } from "./action";
import AssignmentError from "../assignments/AssignmentError";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import  { Loader } from "core/components";
import AssignmentTemplatesSkeletalLoader from "./skeletalLoaders/AssignmentTemplatesSkeletalLoader";
import AssignmentTemplateView from "./assignmentTemplateView/assignmentTemplateView";

const AssignmentTemplates = () => {
  const [assignmentTemplates, setAssignmentTemplates] = useState([]);
  const [query, setQuery] = useState({});
  const [viewActiveTemplate, setViewActiveTemplate] = useState(false);
  const [activeTemplateId, setActiveTemplateId] = useState(null);

  const loading = useSelector(assignmentTemplateSelector.loading);
  const assignmentTemplatesError = useSelector(assignmentTemplateSelector.error);
  const storeAssignmentTemplates = useSelector(assignmentTemplateSelector.getAssignmentTemplates);
  const meta = useSelector(assignmentTemplateSelector.meta);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const refresh = searchParams.get('refresh');

  const sessionUser = JSON.parse(sessionStorage.getItem('user'));
  const canLoadMore = meta.current_page < meta.total_pages;

  useEffect(() => {
    setAssignmentTemplates(storeAssignmentTemplates);
  }, [storeAssignmentTemplates])

  useEffect(() => {
    if (refresh !== 'false') fetchAssignmentTemplates();
  }, [])

  const fetchAssignmentTemplates = async (query = {}) => {
    try {
      setQuery(query);
      const params = {...query, request_type: 'ms_teams'}
      await dispatch(allAssignmentTemplates(params));
    } catch (e) {
      console.log(e)
    }
  }

  const Loading = () => {
    return (
      <div className="d-flex justify-content-center mb-4">
        <Loader size="25px" loading={true} color="#2c7be5" speedMultiplier={1} />
      </div>
    )
  }

  const fetchMoreAssignments = async () => {
    const params = {...query, page: meta.next_page}
    await fetchAssignmentTemplates(params)
  }

  const handleView = (templateId) => {
    setActiveTemplateId(templateId);
    setViewActiveTemplate(true);
  }

  const handleCloseView = () => {
    setViewActiveTemplate(false);
  }

  return (
    <>
      {
        !viewActiveTemplate &&
          <div className="mb-4">
            <AssignmentTemplateFilters onFilter={fetchAssignmentTemplates} />
          </div>
      }

      {
        loading ?
          <AssignmentTemplatesSkeletalLoader /> :
          assignmentTemplatesError ?
            <AssignmentError /> :
            <>
              {
                viewActiveTemplate ?
                  <AssignmentTemplateView onClose={handleCloseView} templateId={activeTemplateId} /> :
                  <InfiniteScroll next={fetchMoreAssignments} hasMore={canLoadMore} loader={<Loading/>} dataLength={assignmentTemplates.length}>
                    {
                      assignmentTemplates.map((template) => (
                        <AssignmentTemplatesCard onSelect={handleView} assignmentTemplate={template} key={template.id} user={sessionUser} />
                      ))
                    }
                  </InfiniteScroll>
              }
            </>
      }
    </>
  );
};

export default AssignmentTemplates;
