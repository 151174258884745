import PropTypes from 'prop-types';

const Divider = ({ className, ...rest }) => <div className={`border-top ${className}`} {...rest} />;

Divider.defaultProps = {
  className: '',
};

Divider.propTypes = {
  className: PropTypes.string,
};

export default Divider;
