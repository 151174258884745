import { Alert } from "core/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {Fragment} from "react";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useSchoolConfig } from "core/hooks";
import { dateUtils } from "core/utils";

const FreeTrialAlert = () => {
  const sessionUser = JSON.parse(sessionStorage.getItem('user'))
  const { trialEnds, trialEnded } = useSchoolConfig();
  const showAlert = !!trialEnds && sessionUser?.role === 'teacher'

  const AlertContent = () => {
    return (
      <div className="d-md-flex justify-content-md-between flex-wrap">
        <span className="flex-grow-1">
          <FontAwesomeIcon icon={faWarning} className="text-warning me-2 font-size-17" />
          {
            trialEnded ?
              'Your free trial has ended and you will not be able to create any more assignment. Contact support for more information.' :
              `Your free trial is going to end in ${dateUtils.fromNow(trialEnds)}.`
          }
        </span>
      </div>
    )
  }

  return (
    <Fragment>
      {
        showAlert &&
        <div className="mb-3">
          <Alert allowDismiss={false} text={<AlertContent/>} type="info" />
        </div>
      }
    </Fragment>
  )
};

export default FreeTrialAlert;