import { pusher } from "./index";

const pushDataSubscriber = ({channel, event, requestId, channelName}) => {
  return new Promise((resolve, reject) => {
    let aggregatedData = '';
    channel.bind(event, (data) => {
      if (requestId !== data.request_id) return;

      aggregatedData += data.data;

      if (data.completed) {
        // The data is sent in chunks, a completed key is marked as true once all the chunks has been sent
        aggregatedData = JSON.parse(aggregatedData)

        pusher.unsubscribe(channelName)

        return aggregatedData.error ? reject({ message: aggregatedData.error }) : resolve(aggregatedData);
      }
    });
  })
}

export default pushDataSubscriber;