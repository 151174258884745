import PropTypes from "prop-types";
import {Fragment} from "react";
import {
  ShimmerButton,
  ShimmerCategoryItem,
  ShimmerCircularImage,
  ShimmerTitle
} from "react-shimmer-effects";

const AssignmentTemplatesSkeletalLoader = ({ items }) => {
  return (
    <Fragment>
      {
        items.map((item, index) => (
          <div className="card mb-4" key={index}>
            <div className="card-header d-md-flex justify-content-md-between align-items-center px-md-5">
              <div className="mb-2 w-50">
                <ShimmerTitle line={1} variant="primary" />
              </div>
              <div className="d-flex align-items-center flex-grow-1 justify-content-end">
                <ShimmerCircularImage size={50} />
                <div className="w-25 ms-2">
                  <ShimmerTitle  line={1} variant="primary" />
                </div>
              </div>
            </div>

            <div className="card-body px-md-5">
              <div className="row">
                <div className="col-lg-7 col-xl-8 mb-3">
                  <div className="d-flex justify-content-start w-100">
                    <div className="ms-2 grey-text w-75">
                      <ShimmerCategoryItem title />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-xl-4">
                  <div className="text-sm-center text-lg-end d-flex">
                    <div className="me-2"><ShimmerButton size="md" /></div>
                    <div className="me-2"><ShimmerButton size="md" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </Fragment>
  );
};

AssignmentTemplatesSkeletalLoader.defaultProps = {
  items: [1, 2, 3, 4, 5, 6]
};

AssignmentTemplatesSkeletalLoader.propTypes = {
  items: PropTypes.array
};

export default AssignmentTemplatesSkeletalLoader;