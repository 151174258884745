import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import Button from "core/components/Button";

const DeleteModal = ({ show, onDelete, toggle, text, ...otherProps }) => (
  <Modal isOpen={show} toggle={toggle} zIndex={21800000000} {...otherProps}>
    <ModalHeader toggle={toggle} charcode="x" tag="h4">Confirm Delete</ModalHeader>
    <ModalBody>
      {text ? text : 'Are you sure you want to delete this item?'}
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle} disabled={otherProps.submitting}>Cancel</Button>{' '}
      <Button
        color="danger"
        onClick={onDelete}
        withLoader={true}
        loading={otherProps.submitting}
        loaderText="Deleting"
        disabled={otherProps.submitting}>
        Delete
      </Button>
    </ModalFooter>
  </Modal>
);

DeleteModal.defaultProps = {
  text: '',
  toggle: undefined,
};

DeleteModal.propTypes = {
  onDelete: PropTypes.func.isRequired,
  toggle: PropTypes.func,
  show: PropTypes.bool.isRequired,
  text: PropTypes.string,
}

export default DeleteModal;
