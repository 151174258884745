import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import Hand from "assets/images/hand.svg";
import Disclaimer from "assets/images/disclaimer.svg";
import './theSubmission.scss';
import {Switch} from "../../../core/form/fields";

const PaperSubmission = ({ setValue, didNotSubmit }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
    if (checked === false) {
      setValue('did_not_submit', true)
    } else {
      setValue('did_not_submit', false)
    }
  };

  useEffect(() => {
    setChecked(!!didNotSubmit);
    setValue('did_not_submit', !!didNotSubmit)
  }, [didNotSubmit]);

  return (
    <div className="mt-4 border rounded p-2 p-md-3">
      <div className="d-flex flex-wrap">
        <img src={Hand} alt="Check" width="50" height="50" />
        <div className="paper-note">
          Paper Submissions Allowed
          <div className="description">
            You allowed paper submissions for this assessment, did they submit a paper copy of their work in class? If they added attachments here, check if the attachments contain their work.
          </div>
        </div>
      </div>
      <div className="d-flex mt-4 submit-checkbox">
        <Switch
          label="Student DID NOT submit work for this assessment."
          labelClass="font-weight-100"
          checked={checked}
          value={'checked'}
          id="didNotSubmitSwitch"
          onClick={handleChange} />
      </div>
      {
        checked &&
          <div className="disclaimer d-flex mt-4">
            <div className="d-flex">
              <div className="icon">
                <img src={Disclaimer} alt="Check" width="19" height="19" />
              </div>
              <div className="notice">
                Any impact to their engagement score will be cancelled. You can change later if they provide their work for this assessment either by hand or digitally through Nurture.
              </div>
            </div>
          </div>
      }
    </div>
  )
}

PaperSubmission.propTypes = {
  setValue: PropTypes.func.isRequired
};


export default PaperSubmission;
