import { Avatar, Button, Header, Autolinker } from "core/components";
import TextHeader from "core/components/TextHeader";
import Attachment from "../../attachments/Attachment";
import PropTypes from "prop-types";
import './theSubmission.scss';
import TimeFromNow from "core/components/TimeFromNow";
import {filestack, isPresent, teamsEnv, userFullName} from "core/utils";
import Emoji from "core/components/emoji/Emoji";
import Quote from "assets/images/quote.svg";
import UploadMobile from "assets/images/upload_mobile.svg"
import PaperSubmission from "./PaperSubmission";
import OneNote from 'modules/assignments/OneNote';
import {Switch} from "../../../core/form/fields";
import React, {useEffect, useState} from "react";
import {updateOfflineSubmissionMade} from "../action";
import {useSnackbar} from "notistack";
import infoIcon from "assets/images/general_icons/info.svg";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {updateArray} from "../../../core/utils/array";
import {useDispatch, useSelector} from "react-redux";
import * as attachmentSelector from "../../attachments/selectors";
import {attachmentCreate} from "../../attachments/actions";
import { unionBy } from "lodash";
import {useSchoolConfig} from "../../../core/hooks";

const TheSubmission = ({assignmentId,  submission, userOwns, feedbackPublished, submissionType, setValue, didNotSubmit, onTeacherUploadSubmission }) => {
  const [uploading, setUploading] = useState(false);
  const [studentSubmittedCheck, setStudentSubmittedCheck] = useState(false);
  const [teacherUploadedSubmission, setTeacherUploadedSubmission] = useState([]);
  const [allAttachments, setAllAttachments] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const filestackCredentials = useSelector(attachmentSelector.filestackCredentials);

  const dispatch = useDispatch();
  const { nurtureAssistantEnabled } = useSchoolConfig();

  const studentSubmittedAttachment = (!!submission?.submitted_at) && (!!submission?.student_submission?.attachments?.length || submission?.onenote_url);

  const handleOfflineSubmissionChecked = async () => {
    try {
      const result = !studentSubmittedCheck;
      setStudentSubmittedCheck(result);

      const payload = { offline_submission_made: result }
      await updateOfflineSubmissionMade(assignmentId, submission.id, payload);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  }

  const handleDelete = (deletedAttachment) => {
    const attachmentsClone = [...allAttachments];
    const updatedAttachments = attachmentsClone?.filter((attachment) => attachment.id !== deletedAttachment.id) || []
    enqueueSnackbar('Attachment was deleted', { variant: 'success' })
    setAllAttachments(() => updatedAttachments)
  }

  const onUploaderButtonClick = () => {
    const filePicker = filestack(filestackCredentials, handleUploadSuccess);
    filePicker.open();
  };

  const handleUploadSuccess = async file => {
    const payload = { teacher_uploaded: true, user_assignment_id: submission.id, ...file };

    try {
      setUploading(true)
      const uploadedFile = await dispatch(attachmentCreate(payload));
      setTeacherUploadedSubmission((prev) => [...prev, uploadedFile]);

      setAllAttachments((prev) => [...prev, uploadedFile]);
      enqueueSnackbar('Attachment uploaded successfully', { variant: 'success' });
      onTeacherUploadSubmission();
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' })
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    setStudentSubmittedCheck(submission.offline_submission_made);

    const teacherSubmission = submission?.student_submission?.attachments?.filter((attachment) => attachment.teacher_uploaded) || []
    setTeacherUploadedSubmission(teacherSubmission);
    setAllAttachments(unionBy(teacherSubmission, submission?.student_submission?.attachments || [], 'id'))

  }, [submission]);


  return (
    <>
      {/*<Header title="Student Submission" />*/}
      <div className="card py-3">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-auto">
              <Avatar img={submission?.user?.avatar_url} size="sm" />
            </div>
            <div className="col-auto ms-n2">
              <TextHeader className="mb-1">
                {userFullName(submission?.user)}
              </TextHeader>
              <TimeFromNow time={submission.updated_at} />
            </div>

            <div className="col-auto ms-auto me-3">
              <div className={`badge ${feedbackPublished ? "text-bg-success" : "text-bg-warning"}`}>{feedbackPublished ? "Feedback Published" : "Feedback has not been published"}</div>
            </div>

            {
              userOwns && submission?.status !== 'reviewed' &&
              <div className="col-auto float-right">
                <Button outline="true" type="button" size="sm">Edit Submission</Button>
              </div>
            }
          </div>
          {
            (!!submission?.submitted_at && submission.student_submission.notes !== null) ?
              <div className="mt-3">
              <div className="font-size-13 font-weight-600">STUDENT CONFIDENCE</div>
              <div className="pl-15 pt-2 pb-2 mb-2" title={submission?.student_submission?.confidence?.text}>
                <Emoji level={submission?.student_submission?.confidence?.level} size='lg' />
              </div>
              <div className="font-size-13 font-weight-600">STUDENT COMMENTS</div>
              <div className="d-flex confidence pt-2 pb-2 mt-2">
                <img src={Quote} alt="Check" width="30" height="30" />
                <Autolinker className="student-notes" content={submission?.student_submission?.notes} />
              </div>
            </div> :
                <div className="mt-3">
                  <div className="font-size-13 font-weight-600">STUDENT CONFIDENCE</div>
                  <div className="pl-15 mt-2 text-grey align-items-center">
                    <img src={infoIcon} className="me-2" />
                    { submission?.user?.first_name || 'Student' } has not added a confidence score for this assessment
                  </div>
                  <div className="font-size-13 font-weight-600 mt-3">STUDENT COMMENTS</div>
                  <div className="d-flex mt-2 text-grey align-items-center">
                    <img src={infoIcon} className="me-2" />
                    { submission?.user?.first_name || 'Student' } has not added any comment for this assessment
                  </div>
              </div>
          }

          <>
            <div className="font-size-13 mt-3 font-weight-600">STUDENTS WORK</div>
            {
              studentSubmittedAttachment &&
                <div className="mt-3">
                {
                  allAttachments.map((attachment, index) => (
                    <Attachment
                      attachment={attachment}
                      attachable={submission}
                      allowDelete={attachment.teacher_uploaded}
                      key={attachment.id}
                      onDelete={handleDelete}
                    />
                  ))
                }
                {
                  submission?.onenote_url &&
                  <OneNote onenote_url={submission?.onenote_url} allowDelete={false} />
                }
              </div>
            }

            {
              !studentSubmittedAttachment &&
                <>
                  {
                    allAttachments?.length > 0 ?
                      <>
                        {
                          allAttachments.map((attachment, index) => (
                            <Attachment
                              attachment={attachment}
                              attachable={submission}
                              allowDelete={true}
                              key={attachment.id}
                              onDelete={handleDelete}
                            />
                          ))
                        }
                      </> :
                      <div className="d-flex pb-2 mt-2 text-grey align-items-center">
                        <img src={infoIcon} className="me-2" />
                        { submission?.user?.first_name || 'Student' } has not uploaded any attachments for this assessment
                      </div>
                  }
                </>
            }

            {
              nurtureAssistantEnabled &&
              <div className="mt-3">
                <Button
                  outline={true}
                  loading={uploading}
                  loaderText={'Uploading'}
                  disabled={uploading}
                  onClick={onUploaderButtonClick}
                >
                  <FontAwesomeIcon icon={faPlus}  /> Upload students work
                </Button>
              </div>
            }

            <div>
              {/*<div className="my-4">*/}
              {/*  <hr className="light-divider" />*/}
              {/*</div>*/}

              {/*{*/}
              {/*  submissionType === 'paper_submission' &&*/}
              {/*    <PaperSubmission setValue={setValue} didNotSubmit={didNotSubmit} />*/}
              {/*}*/}

              {/*{*/}
              {/*  submissionType !== 'paper_submission' &&*/}
              {/*    <div className="mt-4 border rounded p-2 p-md-3">*/}
              {/*      <div className="d-flex flex-wrap">*/}
              {/*        <img src={UploadMobile} />*/}
              {/*        <div className="ms-3">*/}
              {/*          <div className="font-weight-600">Digital Submissions only</div>*/}
              {/*          <small className="text-muted font-weight-100">*/}
              {/*            You selected digital submission only for this assessment. Student must upload their work in*/}
              {/*            Nurture for their submission to count towards their engagement score.*/}
              {/*          </small>*/}
              {/*        </div>*/}
              {/*      </div>*/}

              {/*      <div className="ps-3 mt-3">*/}
              {/*        <Switch*/}
              {/*          label="Student handed up work in class, outside of the Nurture app."*/}
              {/*          labelClass="font-weight-100"*/}
              {/*          checked={studentSubmittedCheck}*/}
              {/*          value={'checked'}*/}
              {/*          id="offlineSubmissionSwitch"*/}
              {/*          onClick={handleOfflineSubmissionChecked} />*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*}*/}
            </div>
          </>

        </div>
      </div>
    </>
  )
}

TheSubmission.defaultProps = {
  submission: {},
  userOwns: false
}

TheSubmission.prototypes = {
  submission: PropTypes.object.isRequired,
  userOwns: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  feedbackPublished: PropTypes.bool.isRequired,
  submissionType: PropTypes.string.isRequired,
  onTeacherUploadSubmission: PropTypes.func.isRequired,
}

export default TheSubmission;