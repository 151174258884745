import { isPresent } from 'core/utils';
import PropTypes from 'prop-types';

const NoFeedback = ({ userAssignment }) => {
  const { empty_feedback, submitted_at } = userAssignment;

  let text = null;
  if (empty_feedback && isPresent(submitted_at)) {
    text = "You don't need any feedback for this assessment. Well done! 🎉";
  } else if (empty_feedback) {
    text = "You don't have any feedback for this assessment.";
  }

  return (
    <>
      {text?.length ? (
        <div className='custom-alert alert-light mt-3' role='alert'>
          {text}
        </div>
      ): null}
    </>
  );
};

NoFeedback.propTypes = {
  userAssignment: PropTypes.shape({
    submitted_at: PropTypes.string,
    teacher_feedback: PropTypes.shape({
      personal_feedback: PropTypes.string,
      feedback_objectives: PropTypes.array,
      feedback_attachments: PropTypes.array,
    })
  }).isRequired,
};

export default NoFeedback;
