import { PublicClientApplication } from '@azure/msal-browser';
import envVariables from 'core/utils/env_variables';

const msalConfig = {
  auth: {
    clientId: envVariables.microsoftTeams.clientId,
    authority: 'https://login.microsoftonline.com/common',
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
