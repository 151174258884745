import UploadMobile from "assets/images/upload_mobile.svg";
import { Switch } from "core/form/fields";
import React, {useEffect, useState} from "react";
import {updateOfflineSubmissionMade} from "../action";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";

const DigitalSubmission = ({ submission, assignmentId }) => {
  const [studentSubmittedCheck, setStudentSubmittedCheck] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleOfflineSubmissionChecked = async () => {
    try {
      const result = !studentSubmittedCheck;
      setStudentSubmittedCheck(result);

      const payload = { offline_submission_made: result }
      await updateOfflineSubmissionMade(assignmentId, submission.id, payload);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    setStudentSubmittedCheck(submission.offline_submission_made);
  }, [submission]);

  return (
    <div className="mt-4 border rounded p-2 p-md-3">
      <div className="d-flex flex-wrap">
        <img src={UploadMobile}/>
        <div className="ms-3">
          <div className="font-weight-600">Digital Submissions only</div>
          <small className="text-muted font-weight-100">
            You selected digital submission only for this assessment. Student must upload their work in
            Nurture for their submission to count towards their engagement score.
          </small>
        </div>
      </div>

      <div className="ps-3 mt-3">
        <Switch
          label="Student handed up work in class, outside of the Nurture app."
          labelClass="font-weight-100"
          checked={studentSubmittedCheck}
          value={'checked'}
          id="offlineSubmissionSwitch"
          onClick={handleOfflineSubmissionChecked}/>
      </div>
    </div>
  );
};

DigitalSubmission.propTypes = {
  submission: PropTypes.object.isRequired,
  assignmentId: PropTypes.string.isRequired
}

export default DigitalSubmission;