import React from 'react';
import PropTypes from 'prop-types';
import { Chart as MixedChart } from 'react-chartjs-2';
import round from 'lodash/round';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

import { down, skipped, toggleLabel } from './utils';
import { truncate } from "core/utils";

Chart.register(annotationPlugin);

const formatLabels = labels => {
  labels = labels.map((label) => truncate({ string: label, length: 20 }));
  if (labels.length <= 3) return labels;

  if (labels.length <= 11) {
    const newLabels = [];

    for (let i = 1; i <= labels.length; i++) {
      newLabels.push(i);
    }

    return newLabels;
  }

  return labels;
};

const NewMixedChart = ({ graphData }) => {
  const {
    confidence_average: confidenceAverage,
    confidence_list: confidenceList,
    grade_list: gradeList,
    grades_average: gradesAverage,
    labels,
  } = graphData;

  const formattedConfidence = round(parseFloat(confidenceAverage), 1);
  const formattedGrade = round(parseFloat(gradesAverage), 1);
  const formattedLabels = formatLabels(labels);

  const annotation1 = {
    type: 'line',
    borderColor: '#FFA38F',
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 2,
    label: {
      display: (ctx) => ctx.hovered,
      content: () => `Average Confidence = ${formattedConfidence}%`,
      position: (ctx) => ctx.hoverPosition
    },
    scaleID: 'y',
    value: formattedConfidence,
    enter(ctx, event) {
      toggleLabel(ctx, event)
    },
    leave(ctx, event) {
      toggleLabel(ctx, event)
    },
  };

  const annotation2 = {
    type: 'line',
    borderColor: '#6CBDBF',
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 2,
    label: {
      display: (ctx) => ctx.hovered,
      content: () => `Average Grade = ${formattedGrade}%`,
      position: (ctx) => ctx.hoverPosition
    },
    scaleID: 'y',
    value: formattedGrade,
    enter(ctx, event) {
      toggleLabel(ctx, event)
    },
    leave(ctx, event) {
      toggleLabel(ctx, event)
    },
  };

  const tooltip = {
    yAlign: 'bottom',
    callbacks: {
      title: ctx => {
        const index = ctx[0]?.dataIndex;
        const labels = ctx[0]?.dataset.labels;

        return labels[index];
      },
    }
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        max: 100,
        beginAtZero: true,
        ticks: {
          stepSize: 20,
        }
      },
      x: {
        display: labels.length < 12,
      },
    },
    plugins: {
      annotation: {
        annotations: {
          annotation1,
          annotation2
        }
      },
      legend: {
        display: false,
      },
      tooltip,
    },
  };

  const data = {
    labels: formattedLabels,
    datasets: [
      {
        type: 'bar',
        label: 'Grade',
        data: gradeList,
        borderColor: '#6CBDBF',
        backgroundColor: '#6CBDBF',
        barThickness: 6,
        barPercentage: 0.2,
        order: 2,
        labels,
      },
      {
        type: 'line',
        label: 'Confidence',
        data: confidenceList,
        fill: false,
        borderColor: '#FFA38F',
        lineTension: 0.4,
        order: 1,
        segment: {
          borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, '#FFA38F'),
          borderDash: ctx => skipped(ctx, [6, 6]),
        },
        spanGaps: true,
        labels,
      },
    ]
  };

  return (
    <MixedChart
      options={options}
      data={data}
    />
  );
}

NewMixedChart.propTypes = {
  graphData: PropTypes.shape({}).isRequired,
};

export default NewMixedChart;
