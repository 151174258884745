
const handleApiError = (e) => {
  console.log(e)
  const data = e?.response?.data;

  if (data?.message) {
    return { message: data.message };
  }

  if (data?.error === 'not_found') {
    return { message: 'The requested resource cannot be found' };
  }

  if (data?.error === 'forbidden') {
    return { message: 'You are not authorised to perform this action, please contact the administrator' };
  }

  if (data?.error === 'unauthorized') {
    return { message: 'Your session has expired, please reload your tab to re-authenticate.' };
  }

  if (data?.error_description) {
    return { message: data.error_description };
  }

  return { message: 'Something went wrong, please try again or check your internet.' };
}

export default handleApiError;
