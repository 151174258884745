import React, { useState } from "react";
import PropTypes from "prop-types";
import {Button, Loader, MarkdownEditorField, NurtureAIFeedback} from "core/components";
import { useDispatch } from "react-redux";
import { suggestComment } from "./action";
import { useSnackbar } from "notistack";
import {v4 as uuidv4} from "uuid";
import {isPresent, pushDataSubscriber, pusher} from "../../utils";
import env_variables from "../../utils/env_variables";
import {useSchoolConfig} from "../../hooks";
import LanguageSupportSwitch from "./LanguageSupportSwitch";

const NurtureAICommentSuggestion = ({ hints, heading, onSuggest, extras }) => {
  const STATES = {showHint: 1, working: 2, suggestion: 3};
  const LOADING_TEXTS = ['Making coffee', 'Generating Feedback', 'Almost done']

  const { channelOnboarding } = useSchoolConfig();
  const languageRelated = channelOnboarding?.data?.language_related;
  const subject = channelOnboarding?.data?.subject;

  const [state, setState] = useState(STATES.showHint);
  const [suggested, setSuggested] = useState('');
  const [aiRequestId, setAiRequestId] = useState(null);
  const [loadingText, setLoadingText] = useState(LOADING_TEXTS[0]);
  const [languageSupportEnabled, setLanguageSupportEnabled] = useState(!!languageRelated)

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const toggleLoadingText = () => {
    setLoadingText(LOADING_TEXTS[0]);
    let currentIndex = 0
    return setInterval(() => {
      if (currentIndex < LOADING_TEXTS.length - 1) {
        setLoadingText(LOADING_TEXTS[currentIndex + 1])
        currentIndex += 1
      }
    }, 3000);
  }

  const generateSuggestionsSync = async (hint) => {
    const intervalId = toggleLoadingText();
    setState(STATES.working);

    try {
      const grade = extras.watch('grade')
      const dataExtras = {}
      if (!!grade) dataExtras['grade'] = grade
      if (extras.assignmentObjectives.length) dataExtras['objectives_that_needs_feedback'] = extras.assignmentObjectives
      if (isPresent(extras.rubricGrades)) dataExtras['rubricGrades'] = extras.rubricGrades
      const data = {
        hint: hint, user_assignment_id:
        extras.user_assignment_id,
        extras: dataExtras,
        language_support_enabled: languageSupportEnabled,
      }

      const aggregatedData = await dispatch(suggestComment(data))
      handleAiSuggestion( aggregatedData);
      clearInterval(intervalId);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      setState(STATES.showHint)
    } finally {
      clearInterval(intervalId);
    }
  };

  const generateSuggestionsAsync = async (hint) => {
    const intervalId = toggleLoadingText();
    setState(STATES.working);

    try {
      const requestId = uuidv4().replace(/-/g, '');
      const channelName = `commentsGeneration-${requestId}`;
      const pusherChannel = pusher.subscribe(channelName);

      const grade = extras.watch('grade');
      const dataExtras = {};
      if (!!grade) dataExtras['grade'] = grade;
      if (extras.assignmentObjectives.length) dataExtras['objectives_that_needs_feedback'] = extras.assignmentObjectives;
      if (isPresent(extras.rubricGrades)) dataExtras['rubricGrades'] = extras.rubricGrades
      const data = {
        hint: hint,
        user_assignment_id: extras.user_assignment_id,
        language_support_enabled: languageSupportEnabled,
        extras: dataExtras,
        request_id: requestId,
        channel_name: channelName,
        run_in_background: true
      };

      dispatch(suggestComment(data))
      const pushDataAttributes = { channel: pusherChannel, requestId, channelName, event: 'commentData' };
      const aggregatedData = await pushDataSubscriber(pushDataAttributes);
      handleAiSuggestion(aggregatedData);
      clearInterval(intervalId);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      setState(STATES.showHint)

      clearInterval(intervalId);
    }
  };

  const generateSuggestions = env_variables.ai_processing === 'async' ? generateSuggestionsAsync : generateSuggestionsSync;

  const handleAiSuggestion = ({ comment, id }) => {
    setSuggested(comment);
    setAiRequestId(id);
    setState(STATES.suggestion);
  }

  const handleSuggestionChange = (value) => {
    setSuggested(value);
  };

  const handleLanguageSupportSwitchChanged = (e) => {
    setLanguageSupportEnabled(!languageSupportEnabled)
  }

  const handleSuggest = () => {
    if (typeof onSuggest === 'function') onSuggest(suggested);
    enqueueSnackbar('The suggestion has been applied to the field above', { variant: 'success' });
  };

  return (
    <div>
      {
        state === STATES.showHint &&
          <div>
            {
              languageRelated &&
                <LanguageSupportSwitch
                  id="rubrics-language-support-switch"
                  label={`Give feedback entirely in subject language: ${subject}`}
                  labelClass="text-muted"
                  overrideClasses="form-check form-switch ps-0"
                  onLanguageSwitchChanged={handleLanguageSupportSwitchChanged}
                  checked={languageSupportEnabled} />
            }

            <div className="font-size-15 text-muted mb-3">{heading}</div>
            {
              hints.map((hint, index) => {
                return (
                  <Button
                    color="light"
                    className="me-2 mb-2"
                    onClick={() => generateSuggestions(hint)}
                  >{hint}</Button>
                )
              })
            }
          </div>
      }

      {
        state === STATES.working &&
        <div>
          <div className="d-flex justify-content-center mb-3">
            <Loader type="ring" loading={true} color="#6CBDBF" />
          </div>
          <div className="font-size-15 text-muted mb-3 text-center">{loadingText}...</div>
        </div>
      }

      {
        state === STATES.suggestion &&
        <div>
          <div className="mb-4">
            <label className="form-label text-muted">Suggestion</label>
            <div>
              <MarkdownEditorField
                className='markdown'
                initialValue={suggested}
                maxHeight="100px"
                onChange={handleSuggestionChange}
              />
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="mb-2">
              <NurtureAIFeedback aiRequestId={aiRequestId} />
            </div>
            <div>
              <Button color="transparent" className="me-2 text-white" onClick={() => setState(STATES.showHint)}>Retry</Button>
              <Button color="light" onClick={handleSuggest}>Use Suggestion</Button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

NurtureAICommentSuggestion.defaultProps = {
  heading: 'What would you like to say?',
  onSuggest: null,
  extras: {}
};

NurtureAICommentSuggestion.propTypes = {
  hints: PropTypes.array.isRequired,
  heading: PropTypes.string,
  onSuggest: PropTypes.func,
  extras: PropTypes.shape({})
};

export default NurtureAICommentSuggestion;