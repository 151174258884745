import mem from "mem";
import axiosPublic from "./axios_instace";
import { envVariables, teamsEnv } from "..";

const refreshTokenFn = async () => {
  const { channelId, tenantId} = teamsEnv;
  const { apiBase } = envVariables;
  const sessionToken = localStorage.getItem('token');

  try {
    const response = await axiosPublic.get(
      `api/v1/ms_teams/${tenantId}/auth/refresh_token?channel_id=${channelId}`,
      {
        headers: {
          "Authorization": `Bearer ${sessionToken}`,
        },
      }
    );
    const token = response.data.token;

    if (!token) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");

      return window.location.replace(`${apiBase}/i/teams/${tenantId}/${channelId}`);
    }

    sessionStorage.setItem("token", token);

    return token;
  } catch (error) {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");

    window.location.replace(`${apiBase}/i/teams/${tenantId}/${channelId}`);
    return { message: 'Your session has expired, redirecting you to re-authenticate' };
  }
};

const maxAge = 10000;

export default mem(refreshTokenFn, {
  maxAge,
});
