import * as api from './api';
import * as t from './actionTypes';
import { handleApiError } from "core/utils";

export const attachmentFilestackStart = () => ({
  type: t.ATTACHMENTS_FILESTACK_LOAD_START,
});

export const attachmentCreateStart = () => ({
  type: t.ATTACHMENTS_FILESTACK_LOAD_START,
});

export const attachmentDeleteStart = () => ({
  type: t.ATTACHMENTS_FILESTACK_LOAD_START,
});

export const attachmentShowStart = () => ({
  type: t.ATTACHMENTS_FILESTACK_LOAD_START,
});

export const attachmentFilestackSuccess = filestackInfo => ({
  type: t.ATTACHMENTS_FILESTACK_SUCCESS,
  filestack: filestackInfo,
});

/** This method calls the endpoint to get the filestack credentials
 * It also dispatches the credentials to the redux store
 *
 * @returns {(function(*): Promise<*>)|*}
 */
export const attachmentFilestack = () => async dispatch => {
  dispatch(attachmentFilestackStart());

  try {
    const response = await api.attachmentNew();

    dispatch(attachmentFilestackSuccess(response.data));
    return response.data;
    } catch (error) {
        return Promise.reject(handleApiError(error))
    }
};


/**
 * Creates an attachment for an attachable(Assignment|UserAssignment)
 *
 * @returns {(function(*): Promise<AxiosResponse<any>|undefined>)|*}
 */

export const attachmentCreate = (payload) => async dispatch => {
    dispatch(attachmentCreateStart());

    try {
        const response = await api.attachmentCreate(payload)
        return response.data;
    } catch (error) {
        return Promise.reject(handleApiError(error))
    }
};

/**
 * Deletes an attachment and detach from attachable(Assignment|UserAssignment)
 *
 * @returns {(function(*): Promise<AxiosResponse<any>|undefined>)|*}
 */
export const attachmentDelete = id => async dispatch => {
  dispatch(attachmentDeleteStart());

  try {
    const response = await api.attachmentDelete(id)
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error))
  }
};

/**
 * Gets more information about an attachment
 *
 * @returns {(function(*): Promise<AxiosResponse<any>|undefined>)|*}
 */
export const attachmentShow = id => async dispatch => {
  dispatch(attachmentShowStart());

  try {
    const response = await api.attachmentShow(id);
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error))
  }
};

export const getAttachable = payload => {
  if (payload.hasOwnProperty('assignment_id')) return 'assignment';

  return null;
};

export const download = attachmentId => async dispatch => {
  try {
    const response = await api.download(attachmentId);
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error))
  }
};
