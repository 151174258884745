import {useEffect, useRef, useState} from "react";
import {isPresent, randomString} from "core/utils";
import './filters.scss';
import PropTypes from "prop-types";

const FilterDropdown = ({ items, id, value, onChange }) => {
  const [activeItem, setActiveItem] = useState('');
  const elementRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    if (isPresent(value)) {
      setActiveItem(value)
    } else if (isPresent(items) && items.length > 0) {
      setActiveItem(items[0].text)
    }
  }, []);

  const handleItemChanged = (item) => {
    setActiveItem(item);
    elementRef.current.classList.toggle('show');
    buttonRef.current.classList.toggle('collapsed');
    buttonRef.current.setAttribute('aria-expanded', 'false');
    if (typeof onChange == 'function') onChange(item);
  };

  return (
    <div className="filter-dropdown mb-3 mb-md-0">
      <div className="accordion">
        <div className="accordion-item border-none">
          <h2 className="accordion-header">
            <button ref={buttonRef} className="accordion-button bg-transparent font-size-14 collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target={`#${id}`} aria-expanded="true" aria-controls={id}>
              {activeItem}
            </button>
          </h2>
          <div ref={elementRef} id={id} className="accordion-collapse collapse" aria-labelledby="headingOne"
               data-bs-parent="#accordionExample">
            <div className="accordion-body p-0">
              <div className="list-group list-group-flush">
                {
                  items.map((item, index) => (
                    <a className={`cursor-pointer list-group-item border-none list-group-item-action font-size-14 ${item.disabled ? 'disabled' : ''}`}
                       key={index}
                       onClick={() => handleItemChanged(item.text)}>
                      {item.text}
                    </a>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FilterDropdown.defaultProps = {
  items: [],
  id: randomString(8),
  value: undefined,
  onChange: undefined,
};

FilterDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({text: PropTypes.string.isRequired, disabled: PropTypes.bool})),
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default FilterDropdown;