import React, { useEffect, useState } from "react";
import Step1 from "./step1";
import {Avatars, NurtureAI, NurtureAIGroupFeedbackComment, TextHeader} from "core/components";
import {avatarUrls, remainingItemsCount} from "modules/graph/utils";

const GroupFeedback = ({ assignmentObjective, form, selectedIndex }) => {
  const [attachments, setAttachments] = useState([])
  const [aiSuggestions, setAiSuggestions] = useState({})

  const { register, setValue, getValues } = form;

  const students = assignmentObjective?.feedback_objectives.map((feedback) => feedback.user_assignment.user) || []

  useEffect(() => {
    setAttachments(assignmentObjective?.attachments || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="">
        <div className="card py-3 mb-4">
          <div className="card-body">
            <div className="row flex-wrap align-items-center mb-4">
              <div className="col-auto">
                <div className="avatar avatar-lg">
                  <div className="avatar-title avatar-light-bg rounded-circle text-dark">
                    {selectedIndex + 1}
                  </div>
                </div>
              </div>
              <div className="col ms-n2">
                <TextHeader>{assignmentObjective.name}</TextHeader>
                <div>
                  {
                    !!assignmentObjective?.feedback_objectives?.length &&
                      <>
                        <small className="text-muted small">These students will receive this feedback</small>
                        <div>
                          <Avatars imgSize="sm" urls={avatarUrls(students, 4)} remainingItemsCount={remainingItemsCount(students, 4)} />
                        </div>
                      </>
                  }
                </div>
              </div>
            </div>

            <NurtureAI buttonText="Suggest Comments" className="mt-2" subtitle="Get help and ideas for your comment">
              <NurtureAIGroupFeedbackComment
                assignmentObjectiveId={assignmentObjective.id}
                hint='comments'
                onSuggest={(comments) => setAiSuggestions(() => ({ ...comments }))} />
            </NurtureAI>
          </div>
        </div>

        <Step1
          register={register}
          setValue={setValue}
          getValues={getValues}
          assignmentObjective={assignmentObjective}
          attachments={attachments}
          setAttachments={setAttachments}
          aiSuggestions={aiSuggestions}
        />

        {/*<Step2*/}
        {/*  register={register}*/}
        {/*  setValue={setValue}*/}
        {/*  getValues={getValues}*/}
        {/*  assignmentObjective={assignmentObjective}*/}
        {/*  attachments={attachments}*/}
        {/*  setAttachments={setAttachments}*/}
        {/*/>*/}
      </div>
    </div>
  );
}

export default GroupFeedback;
