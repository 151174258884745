import AssignmentTemplatesSelf from "assets/images/illustrations/assignment_templates_self.svg";
import AssignmentTemplatesOrg from "assets/images/illustrations/assignment_templates_org.svg";
import AssignmentTemplatesNurture from "assets/images/illustrations/assignment_templates_nurture.svg";
import InfoIcon from "assets/images/general_icons/info.svg";
import Input from "../../../core/form/fields/Input";
import {useState} from "react";

const TemplateVisibilities = ({ assignmentName, selected, context, isRubrics, onChange }) => {
  const [templateName, setTemplateName] = useState(assignmentName);
  const [visibility, setVisibility] = useState(selected);
  const [saveFeedback, setSaveFeedback] = useState(false);

  const disableFeedbackSave = context === 'assignment_creation' || isRubrics
  const feedbackSaveText = isRubrics ?
    'Learning objective feedback is not available with Rubrics.' :
    'You must publish feedback on an assessment using this template to save learning objective feedback to the template too.'

  const handleVisibilityChange = (value) => {
    setVisibility(value);
    onChange(value, templateName, saveFeedback);
  }
  const handleTemplateNameChange = (e) => {
    setTemplateName(e.currentTarget.value)
    onChange(visibility, e.currentTarget.value, saveFeedback)
  }

  const handleSavedFeedbackChanged = () => {
    const value = !saveFeedback
    setSaveFeedback(value);
    onChange(visibility, templateName, value)
  }

  return (
    <div className="px-lg-4">
      {/*Save As field*/}
      {/* <div className="px-5 mb-4">*/}
      {/*  <div className="d-flex align-items-center mx-0">*/}
      {/*    <div className="me-3">Save as</div>*/}
      {/*     <div className="flex-grow-1 template-name">*/}
      {/*       <div className="input-group">*/}
      {/*         <img src={PenIcon} className="input-group-text" />*/}
      {/*        <input type="text" className="form-control" placeholder="Username" aria-label="Username"*/}
      {/*               aria-describedby="basic-addon1" />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*Question*/}
      <div className='question font-size-18 mb-4'>
        Who do you want this template to be available to?
      </div>

      {/*Cards*/}
      <div className="row mb-4">
        <div className="col-lg-4">
          <div
            className={`item-box py-4 px-3 cursor-pointer me-2 ${selected === 'private' ? 'active' : ''}`}
            onClick={() => handleVisibilityChange('private')}>
            <div className="row">
              <div className="col-3 col-lg-12">
                <div className="text-end text-lg-center mb-3"><img src={AssignmentTemplatesSelf} /></div>
              </div>
              <div className="col-9 col-lg-12 text-lg-center d-flex flex-column justify-content-center">
                <div className="item-box-title">Only me</div>
                <div className="item-box-subtitle">Just for you to use again with any of your classes.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className={`item-box py-4 px-3 cursor-pointer me-2 ${selected === 'org' ? 'active' : ''}`}
            onClick={() => handleVisibilityChange('org')}>
            <div className="row">
              <div className="col-3 col-lg-12">
                <div className="text-end text-lg-center mb-3"><img src={AssignmentTemplatesOrg} /></div>
              </div>
              <div className="col-9 col-lg-12 text-lg-center d-flex flex-column justify-content-center">
                <div className="item-box-title">My Schools / Org</div>
                <div className="item-box-subtitle">Other teachers in your school can use in their classes.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className={`item-box py-4 px-3 h-100 cursor-pointer ${selected === 'public' ? 'active' : ''}`}
               onClick={() => handleVisibilityChange('public')}>
            <div className="row">
              <div className="col-3 col-lg-12">
                <div className="text-end text-lg-center mb-3"><img src={AssignmentTemplatesNurture} /></div>
              </div>
              <div className="col-9 col-lg-12 text-lg-center d-flex flex-column justify-content-center">
                <div className="item-box-title">Nurture Space</div>
                <div className="item-box-subtitle">
                  Template is publicly available globally on Nurture
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="light-divider" />

      {/*Save Feedback*/}
      <div className="my-4">
        <div className="alert alert-light feedback-alert">
          <div className="form-check">
            <input className="form-check-input" checked={saveFeedback} onChange={handleSavedFeedbackChanged} disabled={disableFeedbackSave} type="checkbox" id="feedbackAlert" />
            <label className="form-check-label" htmlFor="feedbackAlert">
              Save learning objective feedback to template?
            </label>
          </div>
          <small className="mt-3 d-block">
            { feedbackSaveText }
          </small>
        </div>
      </div>

      <hr className="light-divider" />

      {/*Template Name*/}
      <div>
        <Input value={templateName} onChange={handleTemplateNameChange} label="Template name" />
      </div>

      {/*Info*/}
      <div className="mt-4">
        <div className="d-flex info align-items-center">
          <img src={InfoIcon} />
          <div className="info-text ms-1">Only the assessments details will become the template, no student information will be used.</div>
        </div>
      </div>
    </div>
  );
};

export default TemplateVisibilities;
