import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Col, Form, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "notistack";
import { BackButton, Button, PermissionAlert } from "core/components";
import { isEmpty, isPresent, validUrl } from "core/utils";
import DotMenu from "assets/images/general_icons/dot_menu.svg";
import Flash from "assets/images/general_icons/flash.svg";
import Bookmark from "assets/images/general_icons/bookmark.svg";
import RedBin from "assets/images/general_icons/red_bin.svg";

import "./form.scss";
import { useDispatch } from "react-redux";
import {
  assignmentAssign,
  assignmentDelete,
  assignmentUpdate,
} from "../action";
import AssignmentTemplatesModal from "modules/assignmentTemplates/assignmentTemplatesModal";
import {
  createAssignmentTemplate,
  deleteAssignmentTemplate,
  updateAssignmentTemplate,
} from "modules/assignmentTemplates/action";
import { DeleteModal } from "core/components/modals";
import AssignmentBasicsForm from "./AssignmentBasicsForm";
import AssignmentGradingForm from "./AssignmentGradingForm";
import AssignmentAssignAndSchedule from "./AssignmentAssignAndSchedule";
import CancelModal from "./CancelModal";

/**
 * @param assignment
 * @param onUpdate
 * @param content This is the resource in context, could be one of ['assignment', 'assignment_template']
 * @returns {JSX.Element}
 * @constructor
 */
const AssignmentForm = ({ assignment, onUpdate, content }) => {
  const [baseAssignmentObjectives, setBaseAssignmentObjectives] = useState([]);
  const [studentsList, setStudentsList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [draftButtonLoading, setDraftButtonLoading] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [templateCreateLoading, setTemplateCreateLoading] = useState(false);
  const [templateFor, setTemplateFor] = useState(
    content === "assignment_template" ? assignment.visibility : ""
  );
  const [templateName, setTemplateName] = useState("");
  const [templateModalState, setTemplateModalState] = useState(0);
  const [templateSharableCode, setTemplateSharableCode] = useState("");
  const [savedTemplate, setSavedTemplate] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [dropDownOpen, setDropdownOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigateTo = useNavigate();
  const toggleDropdown = () => setDropdownOpen(!dropDownOpen);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isValidOneNote = (value) => {
    if (value === null || value.trim() === "") {
      return true;
    }
    return validUrl(value) && value.includes("sharepoint.com");
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .max(256, "Name cannot exceed 256 characters.")
      .required("You must give this assessment a name.")
      .nullable(),
    description: yup
      .string()
      .required("You must give this assessment a description.")
      .nullable(),
    assignment_objectives: yup.array().of(
      yup.object().shape({
        slug: yup.mixed(),
        name: yup
          .string()
          .required("You must add at least 1 learning objective"),
      })
    ),
    rubrics: yup.array().of(yup.object()).nullable(),
    max_marks: yup
      .mixed()
      .when(
        ["graded", "grade_display_to_students"],
        (graded, grade_display_to_students) => {
          if (graded && grade_display_to_students !== "rubrics") {
            return yup
              .number()
              .required(
                "You must add maximum marks to this or else turn off grading."
              )
              .transform((value) => (isNaN(value) ? undefined : value))
              .min(1, "The minimum allowable mark is 1")
              .nullable();
          }
        }
      ),
    grade_display_to_students: yup
      .string("Please select one type of grade from the list.")
      .nullable()
      .when("graded", {
        is: true,
        then: yup
          .string()
          .required("Please select one type of grade from the list.")
          .nullable(),
      }),
    rubrics_grade_display: yup
      .string("Please select one type of grade from the list.")
      .nullable()
     .when(["graded", "grade_display_to_students"],
       (graded, grade_display_to_students) => {
         if (graded && grade_display_to_students === "rubrics") {
           return yup
             .string()
             .required(
               "Please select one type of grade from the list."
             )
             .nullable();
         }
       }),
    publish_at: yup.date().when("scheduled", {
      is: true,
      then: yup
        .date()
        .max(
          yup.ref("due_at"),
          "Schedule date should be before assignment due date"
        )
        .test("maxPublishDate", "Publish date must be at least 24 hours before due date", function(value) {
          const dueAt = this.resolve(yup.ref("due_at"));
          if (dueAt) {
            const twentyFourHoursBeforeDue = new Date(dueAt);
            twentyFourHoursBeforeDue.setHours(twentyFourHoursBeforeDue.getHours() - 24);
            return value <= twentyFourHoursBeforeDue;
          }
          return true;
        }),
    }),
    due_at: yup.date(),
    onenote_url: yup
      .string()
      .test("Valid Onenote URL", "Invalid Onenote URL", isValidOneNote)
      .nullable(),
    visibility: yup
      .string()
      .nullable()
      .when(() => {
        if (content === "assignment_template") {
          return yup
            .string()
            .required("A visibility must be selected")
            .nullable();
        }
      }),
  });

  const formConfig = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      assignment_objectives: [{ name: "" }],
    },
  });

  const { handleSubmit, watch, setValue, getValues } = formConfig;
  const assignmentName = watch("name");

  const deleteButtonText = () => {
    if (content === "assignment_template") return "Template";
    if (assignment?.status === "draft") return "Draft";
    return "Assessment";
  };

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const closeCancelModal = () => setShowCancelModal(false);

  const closeTemplateModal = () => {
    setShowTemplateModal(false);
  };

  const checkKeyDown = (e) => {
    if (e.key === "Enter" && e.target.tagName.toLowerCase() !== "textarea")
      e.preventDefault();
  };

  const onAssign = (students) => setStudentsList(students);

  const assignToStudents = async () => {
    const checkedStudents = studentsList;
    const unCheckedStudents = [];
    let activity, teams_user_ids;

    for (const id in assignment.students) {
      if (!studentsList.includes(id)) unCheckedStudents.push(id);
    }

    // If we are editing the assignment and some students were not checked, we use the unassign action to
    // unassign the students that were not checked.
    // Otherwise if it is a new assignment we use the assign action to assign the students that were checked
    if (unCheckedStudents.length > 0 && assignment.status !== "draft") {
      activity = "unassign";
      teams_user_ids = unCheckedStudents;
    } else if (checkedStudents.length > 0) {
      activity = "assign";
      teams_user_ids = checkedStudents;
    } else {
      return; // No student to assign
    }

    const payload = { activity, teams_user_ids };
    await dispatch(assignmentAssign(assignment.id, payload));
  };

  const extractOneNoteUrl = (description) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = description.match(urlRegex);
    if (urls) {
      const onenote = urls.find(url => url.includes('sharepoint.com'));
      if (onenote && isValidOneNote(onenote)) {
        return onenote;
      }
    }
    return null;
  };

  const validateRubrics = (data) => {
    if (data.grade_display_to_students === "rubrics") {
      if (isEmpty(data.rubrics)) {
        throw new Error("Rubrics is not added for grading type rubrics");
      } else if (data.rubrics.length !== data.assignment_objectives.length) {
        throw new Error(
          "Rubrics and assignment objectives length do not match"
        );
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      validateRubrics(data);
      setNextButtonLoading(true);
      setButtonDisabled(true);

      const oneNoteUrl = extractOneNoteUrl(data.description);
      if (oneNoteUrl) {
        data.onenote_url = oneNoteUrl;
      }

      const payload = formatPayload(data)
      const response = await dispatch(assignmentUpdate(assignment.id, payload, false))
      if (response.status !== 'draft') {
        await assignToStudents();
        navigateTo(
          `/assignments/${assignment.id}?refresh=true&templateFor=${
            assignment.templateFor || ""
          }`
        );
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setNextButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  const saveDraft = async (data, navigate = true) => {
    try {
      validateRubrics(data);
      setDraftButtonLoading(true);
      setButtonDisabled(true);

      const payload = formatPayload(data, { saveDraft: true });

      const response = await dispatch(assignmentUpdate(assignment.id, payload));
      if (navigate) {
        onUpdate(response);
        navigateTo(`/assignments/${assignment.id}`);
      } else {
        return response;
      }
    } catch (e) {
      if (!navigate) return Promise.reject(e);
      else enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setDraftButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  const saveTemplate = async (data) => {
    try {
      setTemplateCreateLoading(true);

      // If we are creating a template for the first time from an assignment, we first create a draft assignment
      // and create a template from the draft assignment .
      // otherwise if the content is assignment_template, it means we are editing an assignment template
      // in that case we do not need to create a draft before saving since the template was initially created
      // from a draft.
      const sourceAssignment =
        content === "assignment" ? await saveDraft(data, false) : assignment;
      const query = {
        assignment_id: sourceAssignment.id,
        assignment_type: "ms_teams",
        visibility: templateFor,
        template_name: templateName,
      };

      const response = await dispatch(createAssignmentTemplate(query));
      setSavedTemplate(response);
      setTemplateSharableCode(response.sharable_code);

      setBaseAssignmentObjectives(sourceAssignment.assignment_objectives);
      setTemplateModalState(1); // show the success page on the template modal
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setTemplateCreateLoading(false);
    }
  };

  const handleDeleteAssignment = async () => {
    try {
      setDeleting(true);
      await dispatch(assignmentDelete(assignment.id));
      setShowDeleteModal(true);
      enqueueSnackbar("Assignment was Deleted", { variant: "success" });
      navigateTo("/tab?activeTab=assignments");
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setDeleting(false);
    }
  };

  const handleDeleteAssignmentTemplate = async () => {
    try {
      setDeleting(true);
      await dispatch(deleteAssignmentTemplate(assignment.id));
      setShowDeleteModal(false);
      enqueueSnackbar("Assignment template was Deleted", {
        variant: "success",
      });
      navigateTo("/tab?activeTab=assignments_templates");
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setDeleting(false);
    }
  };

  const handleDelete = () => {
    const handlers = {
      assignment: handleDeleteAssignment,
      assignment_template: handleDeleteAssignmentTemplate,
    };
    handlers[content]();
  };

  const handleTemplateEdit = async (data) => {
    try {
      const payload = {
        ...data,
        visibility: templateFor,
        template_name: templateName,
      };
      payload.assignment_objectives = JSON.stringify(
        payload.assignment_objectives
      );
      delete payload.publish_at; // assignment templates does not have published at

      if (
        isPresent(payload["rubrics"]) &&
        payload.grade_display_to_students === "rubrics"
      ) {
        payload["rubrics"] = JSON.stringify(payload.rubrics);
      }

      setNextButtonLoading(true);
      setButtonDisabled(true);

      await dispatch(updateAssignmentTemplate(assignment.id, payload));

      enqueueSnackbar("Assignment Template was updated", {
        variant: "success",
      });
      navigateTo("/tab?activeTab=assignments_templates");
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setNextButtonLoading(false);
      setButtonDisabled(false);
    }
  };

  const onSubmitTemplateForm = () => {
    const submissionHandler =
      content === "assignment_template" ? handleTemplateEdit : saveTemplate;
    handleSubmit(submissionHandler, templateValidationError)();
  };

  const templateValidationError = (e) => {
    enqueueSnackbar(
      "Please fill out the assessment form correctly to be able to save it as a template",
      { variant: "error" }
    );
  };

  const draftValidationError = () => {
    setShowCancelModal(false);
    enqueueSnackbar(
      "Please fill out the assessment form correctly to be able to save it as a draft",
      { variant: "error" }
    );
  };

  const formatPayload = (payload, options = {}) => {
    const isRubric =
      isPresent(payload["rubrics"]) &&
      payload.grade_display_to_students === "rubrics";

    if (payload.assignment_objectives) {
      payload.show_assignment_objectives = true;
      payload["assignment_objectives_attributes"] =
        payload.assignment_objectives.map((item, index) => {
          item["order"] = index + 1;
          if (item.slug) item["id"] = item.slug;
          delete item.slug;
          return item;
        });

      if (isRubric && payload["rubrics"][0]["columns"].length) {
        const columns = payload["rubrics"][0]["columns"];
        payload["rubric_columns_attributes"] = columns.map((column) => {
          const result = {
            name: column.name,
            order: column.order,
            point: column.point,
          };

          if (column["id"]) result["id"] = column["id"];
          if (column["_destroy"]) result["_destroy"] = column["_destroy"];

          return result;
        });
      }
    }

    if (payload["due_at"] && Array.isArray(payload["due_at"]))
      payload["due_at"] = payload["due_at"][0].toJSON();
    if (payload["publish_at"] && Array.isArray(payload["publish_at"]))
      payload["publish_at"] = payload["publish_at"][0].toJSON();
    if (!payload["scheduled"]) delete payload.publish_at;

    delete payload.scheduled;

    payload.save_draft = options.saveDraft;

    return payload;
  };

  useEffect(() => {
    if (isPresent(assignment)) {
      const fieldsToUpdate = [
        "name",
        "description",
        "feedback_type",
        "max_marks",
        "graded",
        "due_at",
        "publish_at",
        "publish_at",
        "onenote_url",
        "rubrics",
        "visibility",
      ];
      if (assignment.graded) fieldsToUpdate.push("grade_display_to_students");

      /* Default dates should be set to one week from present time */
      const oneWeekFromNow = new Date();
      oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
      const assignmentClone = { ...assignment };
      assignmentClone["due_at"] = assignmentClone["due_at"]
        ? new Date(assignmentClone["due_at"].replace(/-/g, "/"))
        : oneWeekFromNow;
      assignmentClone["publish_at"] = assignmentClone["publish_at"]
        ? new Date(assignmentClone["publish_at"].replace(/-/g, "/"))
        : oneWeekFromNow;

      /** Update these fields with the value from the backend **/
      fieldsToUpdate.forEach((item) => setValue(item, assignmentClone[item]));
      setValue(
        "submission_type",
        assignmentClone.submission_type || "paper_submission"
      );

      setValue('rubrics_grade_display', assignmentClone.rubrics_grade_display || "points");
      if (assignment.publish_at) setValue("scheduled", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment]);

  return (
    <div className="container">
      <PermissionAlert />
      <BackButton underlyingMethod={() => setShowCancelModal(true)} />
      <Row>
        <Col className="px-1 px-md-3">
          <Form
            className="assignment-form"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <AssignmentBasicsForm
              formConfig={formConfig}
              assignment={assignment}
              content={content}
              formatPayload={formatPayload}
              baseAssignmentObjectives={baseAssignmentObjectives}
              onUpdate={onUpdate}
            />

            <AssignmentGradingForm
              formConfig={formConfig}
              assignment={assignment}
            />

            {content === "assignment" && (
              <AssignmentAssignAndSchedule
                formConfig={formConfig}
                content={content}
                assignment={assignment}
                onAssign={onAssign}
              />
            )}

            <div className="pt-4 pb-5 mb-4 pe-0 d-flex justify-content-between flex-wrap align-items-center">
              <div></div>
              <div className="text-end">
                {/**
                 * If we are editing or creating an assignment always show the next button to submit
                 * the form
                 **/}
                {content === "assignment" && (
                  <Button
                    disabled={buttonDisabled}
                    type="submit"
                    withLoader={true}
                    loading={nextButtonLoading}
                    loaderText="Loading"
                  >
                    Assign{" "}
                    <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                  </Button>
                )}

                {/* Show the Save Template button if we are editing a template */}
                {content === "assignment_template" && (
                  <Button
                    disabled={buttonDisabled}
                    type="button"
                    withLoader={true}
                    loading={nextButtonLoading}
                    loaderText="Loading"
                    onClick={() => setShowTemplateModal(true)}
                  >
                    Save template
                  </Button>
                )}

                {/* Dropdown button for other options */}
                <Button
                  disabled={buttonDisabled}
                  className="me-2"
                  onClick={() => toggleDropdown()}
                  outline={true}
                  color="white"
                >
                  <img src={DotMenu} alt="" />
                </Button>

                {/* Dropdown menu */}
                {dropDownOpen && (
                  <div className="bg-white rounded-3">
                    <ul className="list-unstyled text-start">
                      {content === "assignment" &&
                      <>
                      <li>
                        {/* show the save as draft button if we are editing a draft */}
                        {assignment?.status === "draft" && (
                            <Button
                              type="button"
                              color="white"
                              outline={true}
                              onClick={handleSubmit(
                                saveDraft,
                                draftValidationError
                              )}
                              className="me-2"
                              disabled={draftButtonLoading}
                              withLoader
                              loading={draftButtonLoading}
                              loaderText="Saving"
                            >
                              <img src={Bookmark} alt="" className="px-2"/>
                              {pathname !== "/assignments/new"
                                ? "Save draft"
                                : "Save as draft"}
                            </Button>
                          )}
                      </li>
                      <li>
                        {/**
                         * Show the save as template button if we are creating or editing an assignment
                         * This will create a template from the assignment
                         **/}
                          <Button
                            type="button"
                            outline={true}
                            color="white"
                            disabled={buttonDisabled}
                            withLoader={true}
                            loading={templateCreateLoading}
                            loaderText="Saving"
                            className="me-2"
                            onClick={() => setShowTemplateModal(true)}
                          >
                            <img src={Flash} alt="" className="px-2"/>
                            Save as template
                          </Button>

                      </li>
                      <hr className="my-1"/>
                      </>
                      }
                      <li>
                        {/**
                         *  We only show the delete button when we are editing an
                         *  assignment or an assignment template
                         **/}
                        {pathname !== "/assignments/new" && (
                          <Button
                            disabled={buttonDisabled}
                            className="me-2"
                            color="white"
                            onClick={() => setShowDeleteModal(true)}
                          >
                            <img src={RedBin} alt="" className="px-2"/>
                            Delete {deleteButtonText()}
                          </Button>
                        )}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </Col>
      </Row>
      <AssignmentTemplatesModal
        assignment={assignment}
        defaultAssignmentName={assignmentName}
        savedTemplate={savedTemplate}
        isOpen={showTemplateModal}
        templateCreateLoading={templateCreateLoading}
        templateModalState={templateModalState}
        sharableCode={templateSharableCode}
        onChangeTemplate={(visibility, templateName) => {
          setTemplateFor(visibility);
          setTemplateName(templateName);
        }}
        onClose={closeTemplateModal}
        onSubmit={onSubmitTemplateForm}
      />
      <DeleteModal
        toggle={toggleDeleteModal}
        show={showDeleteModal}
        onDelete={handleDelete}
        text="Are you sure you want to delete this item?"
        submitting={deleting}
      />
      <CancelModal
        show={showCancelModal}
        onClose={closeCancelModal}
        context={content}
        assignment={assignment}
        onSaveDraft={handleSubmit(saveDraft, draftValidationError)}
        submitting={draftButtonLoading}
      />
    </div>
  );
};

AssignmentForm.defaultProps = {
  onUpdate: undefined,
  content: "assignment",
};

AssignmentForm.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
  onUpdate: PropTypes.func,
  content: PropTypes.string,
};

export default AssignmentForm;
