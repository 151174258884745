import AssignmentDatesInfo from "modules/assignments/AssignmentDatesInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import {faEdit, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {DeleteModal} from "../modals";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {useAssignmentInfo} from "core/hooks";
import {calculateProgress} from "core/utils";
import {useDispatch} from "react-redux";
import {assignmentDelete} from "modules/assignments/action";

const TeacherAssignmentCard = ({ assignment, isDraft, isScheduled }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { progress } = assignment;
  const navigateTo = useNavigate();
  const sessionUser = JSON.parse(sessionStorage.getItem('user'));
  const role = sessionUser?.role;
  const isTeacher = role === 'teacher';

  const {
    submittedUserAssignments,
    reflectionUserAssignments,
    totalUserAssignments
  } = useAssignmentInfo(assignment);

  const assignmentProgress = isTeacher ? calculateProgress(progress?.submissions, progress?.assigned) : undefined;
  const dispatch = useDispatch();

  const navigateToEdit = () => navigateTo(`/assignments/${assignment.id}/edit`);

  const navigateToShow = () => navigateTo(`/assignments/${assignment.id}`);

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const OptionsDropdown = () => {
    return (
      <div className="dropdown d-inline-block">
        <button type="button" id={`options_${assignment?.id}`}
                className="btn btn-outline-white text-black-50"
                data-bs-toggle="dropdown">
          <FontAwesomeIcon className="d-inline-block" icon={faEllipsisV}/>
        </button>
        <ul className="dropdown-menu border-none shadow-sm" aria-labelledby={`options_${assignment?.id}`}>
          <li><a className="dropdown-item cursor-pointer" onClick={navigateToEdit}><FontAwesomeIcon
            icon={faEdit} className="me-1"/>Edit</a></li>
          <li><a className="dropdown-item cursor-pointer"
                 onClick={() => setShowDeleteModal(true)}><FontAwesomeIcon icon={faTrashAlt}
                                                                           className="me-1"/>Delete</a></li>
        </ul>
      </div>
    );
  }

  const handleAssignmentDelete = async () => {
    try {
      setDeletingTemplate(true);
      await dispatch(assignmentDelete(assignment.id, true));
      enqueueSnackbar('Assessment was deleted', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setDeletingTemplate(false);
      setShowDeleteModal(false);
    }
  }

  return (
    <div className="col-12">
      <div className="card mb-4 AssignmentCard">
        <div className="card-body p-0">
          <div className="d-none d-md-flex justify-content-end d-lg-none p-2">
            <OptionsDropdown />
          </div>
          <div className="row align-items-center px-1 px-sm-3 py-3">
            {/*Left*/}
            <div className='col-xl-7 col-lg-6 col-md-6'>
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <div className='font-size-20 flex-grow-1 font-weight-600'>
                  {assignment.name}
                </div>

                <div className="d-md-none">
                  <OptionsDropdown />
                </div>
              </div>
              <div className="mb-3">
                <AssignmentDatesInfo assignment={assignment} color='#667085'/>
              </div>
            </div>

            <div className="row align-items-center col-xl-5 col-lg-6 col-md-6">
              {/*Middle*/}
              <div className="d-flex align-items-end col-lg-8 mb-3 mb-sm-0">
                {/*Students Sunmitted*/}
                <div className="me-5 merriweather-font">
                  <div
                    className="font-size-24 font-weight-400 metrics-value">
                    {
                      (isDraft || isScheduled) ?
                        '-' :
                        `${submittedUserAssignments.length} / ${totalUserAssignments}`
                    }
                  </div>
                  <div className="small-texts metric-label">Submitted</div>
                </div>
                {/*Students Reflected*/}
                <div className="merriweather-font">
                  <div
                    className="font-size-24 font-weight-400 metrics-value">
                    {
                      (isDraft || isScheduled) ?
                        '-' :
                        `${reflectionUserAssignments.length} / ${totalUserAssignments}`
                    }
                  </div>
                  <div className="small-texts metric-label">Reflected</div>
                </div>
              </div>

              {/*Right*/}
              <div className="d-lg-flex ms-lg-0 justify-content-lg-end col-lg-4">
                <div className="mt-3 mt-lg-0">
                  <button className="btn new-purple-btn d-block w-100 d-lg-inline-block"
                          onClick={isDraft ? navigateToEdit : navigateToShow}>{isDraft ? 'Continue' : 'Open'}</button>
                </div>
                <div className="d-lg-inline-block d-none">
                  <OptionsDropdown />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <DeleteModal
        show={showDeleteModal}
        onDelete={handleAssignmentDelete}
        toggle={toggleDeleteModal}
        text="Are you sure you want to delete this assessment?"
        submitting={deletingTemplate}
      />

    </div>
  )
};

TeacherAssignmentCard.default = {
  isDraft: false,
  isScheduled: false,
}

TeacherAssignmentCard.propType = {
  assignment: PropTypes.shape({}).isRequired,
  isDraft: PropTypes.bool,
  isScheduled: PropTypes.bool,
};

export default TeacherAssignmentCard;