import PropTypes from 'prop-types';
import { Autolinker, SectionTitle } from "core/components";
import { isPresent } from "core/utils";
import Attachments from "modules/attachments/Attachments";
import React, {Fragment} from "react";

const FeedbackObjective = ({ objective, index, isTeacher }) => (
  <div className="card mt-3 bg-nurture-orange-light">
    <div className="card-header px-0 bg-nurture-orange-light border-bottom">
      <div key={objective.id} className='d-flex align-items-center mt-2 mb-3 px-3 rounded'>
        <div className='me-3'>
          <div className='avatar avatar-sm text-white'>
            <div className='avatar-title font-size-md rounded-circle bg-nurture-orange'>{objective.position || index + 1}</div>
          </div>
        </div>
        <div className='font-size-16 fs-6 flex-grow-1'>{objective.name}</div>
      </div>
    </div>

    {
      (objective.feedback?.action_feedback || objective.feedback?.process_feedback || !!objective.feedback?.next_steps || !!objective.feedback?.general_feedback) &&
      <div className="card-body">
        <Fragment>
          <SectionTitle className="mb-2" title={isTeacher ? 'How did the students do and how was their process' : 'How did you do?'} />
          <p>{objective.feedback?.action_feedback || 'No feedback'}</p>
        </Fragment>

        {
          objective.feedback.process_feedback &&
          <Fragment>
            <SectionTitle className="mb-2" title="How was your process?" />
            <p>{objective.feedback?.process_feedback}</p>
          </Fragment>
        }

        <Fragment>
          <SectionTitle className="mb-2" title={isTeacher ? 'What are the next steps?' : "Your next steps:"} />
          <p>{objective.feedback?.next_steps || 'No feedback'}</p>
        </Fragment>

        {/*<Fragment>*/}
        {/*  <SectionTitle className="mb-2" title="General feedback:" />*/}
        {/*  {*/}
        {/*    !!objective.feedback?.general_feedback ?*/}
        {/*      <Autolinker content={objective.feedback.general_feedback} className="" /> :*/}
        {/*      <div>No additional feedback</div>*/}
        {/*  }*/}
        {/*</Fragment>*/}
      </div>
    }

    {/*{isPresent(objective.attachments) && (*/}
    {/*  <div className="card-footer">*/}
    {/*    <Attachments attachments={objective.attachments} />*/}
    {/*  </div>*/}
    {/*)}*/}
  </div>
);

const GroupFeedBackObjectives = ({ objectives, isTeacher }) => (
  objectives.map((objective, index) => (
    <div key={objective.id}>{<FeedbackObjective objective={objective} index={index} isTeacher={isTeacher} />}</div>
  ))
);

GroupFeedBackObjectives.defaultProps = {
  isTeacher: false
}

GroupFeedBackObjectives.propTypes = {
  objectives: PropTypes.array.isRequired,
  isteacher: PropTypes.bool,
};

export default GroupFeedBackObjectives;
