import { getGraphData } from './api';
import { handleApiError } from "core/utils";
import * as t from './actionTypes';

export const fetchGraphDataStart = () => ({
  type: t.GRAPH_LOAD_START,
});

export const fetchGraphDataSuccess = (data) => ({
  type: t.GRAPH_LOAD_SUCCESS,
  data,
});

export const fetchGraphDataError = () => ({
  type: t.GRAPH_LOAD_ERROR,
});

export const fetchGraphData = (params, sendDispatch = true) => async dispatch => {

  if (sendDispatch) dispatch(fetchGraphDataStart());

  try {
    const response = await getGraphData(params);
    if (sendDispatch) dispatch(fetchGraphDataSuccess(response.data));
    return response;
  } catch (error) {
    if (sendDispatch) dispatch(fetchGraphDataError());
    return Promise.reject(handleApiError(error));
  }
};
