export const QUESTION_COUNT_OPTIONS = [
  { label: '1 question', value: 1 },
  { label: '3 questions', value: 3 },
  { label: '5 questions', value: 5 },
  { label: '7 questions', value: 7 },
  { label: '10 questions', value: 10 },
];

export const ASSESSMENT_TYPE_OPTIONS = [
  { label: 'Long Questions', value: 'long_questions' },
  { label: 'Short Questions', value: 'short_questions' },
  { label: 'Multiple Choice Questions', value: 'multiple_choice_questions' },
  { label: 'Essay Style', value: 'essay_style' },
];