import {
  ShimmerButton,
  ShimmerCategoryList,
  ShimmerTitle
} from "react-shimmer-effects";

const AssignmentNewSkeletalLoader = () => {
  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <ShimmerTitle line={1} gap={10} variant="primary" />
        </div>
        <div className="card-body">
          <ShimmerCategoryList title items={8} categoryStyle="STYLE_ONE" />
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-end">
            <div className="me-2">
              <ShimmerButton size="sm" />
            </div>
            <ShimmerButton size="sm" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentNewSkeletalLoader;