import './header.scss';
import PropTypes from "prop-types";

const Header = ({ title, subtitle, SideButton }) => {

  return (
    <div className="header">
      <div className={`header-body ${SideButton ? 'd-flex justify-content-between align-items-center' : ''}`}>
        <div>
          <h2 className="header-title">{title}</h2>
          <p className="header-subtitle">{subtitle}</p>
        </div>

        { SideButton }
      </div>
    </div>
  )
};

Header.defaultProps = {
  title: '',
  subtitle: '',
  sideButton: null
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sideButton: PropTypes.string
}

export default Header