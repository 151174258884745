import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Emoji from "core/components/emoji/Emoji";

const StudentConfidence = ({ setValue, control, name, confidenceOptions }) => (
  <div className="btn-group btn-block" role="group" aria-label="Basic radio toggle button group">
    {Object.keys(confidenceOptions).map(key => {
      const [_, studentConfidence] = confidenceOptions[key];
      return <Controller
        key={key}
        control={control}
        name={name}
        render={({ field: { onBlur, value } }) => {
          const isActive = (!!value && parseInt(value) === parseInt(key));
          return (
            <label className={`btn btn-outline-nurture-purple ${isActive ? 'active' : ''}`.trim()}>
              <input
                onBlur={onBlur}
                checked={isActive}
                value={value}
                type="radio"
                className="btn-check"
                onChange={() => setValue(name, key, { shouldValidate: true })}
                aria-label="Radio button for following text input"
              />
              <span className="h3">
                <Emoji className={`mb-3 ${isActive ? 'active' : ''}`.trim()} type={studentConfidence} disabled />
              </span>
              <br />
              <small>{studentConfidence}</small>
            </label>
          )
        }}
      />
    })}
  </div>
);

StudentConfidence.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default StudentConfidence;
