import React, { Fragment } from "react";
import { ShimmerButton } from "react-shimmer-effects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const StudentListsSkeletalLoader = () => {
  return (
    <Fragment>
      <div>
        <div className="d-flex justify-content-end mb-4">
          <div className="me-3">
            <ShimmerButton size="md" />
          </div>
          <div>
            <ShimmerButton size="md" />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="input-group input-group-flush">
                      <span className="input-group-text search" id="search">
                        <FontAwesomeIcon icon={faSearch} />
                      </span>
            <input
              type="search"
              className="form-control search"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search" />
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead>
                <tr>
                  <th>S/N</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                  [1, 2, 3, 4, 5, 6].map((student, index) => (
                    <tr>
                      <td><ShimmerButton size="sm" /></td>
                      <td><ShimmerButton size="sm" /></td>
                      <td><ShimmerButton size="sm" /></td>
                      <td><ShimmerButton size="sm" /></td>
                      <td><ShimmerButton size="sm" /></td>
                    </tr>
                  ))
                }
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StudentListsSkeletalLoader;
