import AssignmentTemplatesSelf from "assets/images/illustrations/assignment_templates_self.svg";
import AssignmentTemplatesOrg from "assets/images/illustrations/assignment_templates_org.svg";
import AssignmentTemplatesNurture from "assets/images/illustrations/assignment_templates_nurture.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { Button } from "core/components";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import {useCopyToClipboard, useSchoolConfig} from "core/hooks";
import {pluralize} from "core/utils";
import CheckMarkLightGreen from "assets/images/general_icons/check_mark_light_green.svg"
import XInCircleRed from "assets/images/general_icons/x_in_circle_red.svg"

const TemplateSummary = ({ savedTemplate, code, context, saveFeedback, onclose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const codeRef = useRef();
  const copyCodeToClipboard = useCopyToClipboard(codeRef)
  const { learningObjectiveLabel } = useSchoolConfig();

  const handleCopyToClipboard = async () => {
    await copyCodeToClipboard(code)
    enqueueSnackbar('Your code has been copied', { variant: 'success' });
  };

  const imagesHash = {
    private: AssignmentTemplatesSelf,
    org: AssignmentTemplatesOrg,
    public: AssignmentTemplatesNurture,
  };

  const subtitleText = {
    private: 'for Only you',
    org: 'for your School/Org.',
    public: 'to Nurture Space.'
  }

  return (
    <div className="template-summary mt-3 px-0 px-sm-4">
      <div className="d-md-flex flex-md-column justify-content-md-center text-center text-md-start">
        <div className="title font-size-20">{ savedTemplate.name }</div>
        <div className="subtitle font-size-15 text-muted">Has been saved as a template {subtitleText[savedTemplate.visibility]}</div>
      </div>

      <div className="d-flex align-items-center mt-3">
        <div className="me-4">
          <div className="d-flex align-items-center">
            <div><img width="20" src={CheckMarkLightGreen} /></div>
            <div className="ms-2">
              {savedTemplate.assignment_objectives?.length || 0}&nbsp;
              {pluralize(learningObjectiveLabel, savedTemplate.assignment_objectives?.length || 0)}
            </div>
          </div>
        </div>

        <div className="me-4">
          <div className="d-flex align-items-center">
            <div><img width="20" src={!!savedTemplate.rubrics ? CheckMarkLightGreen : XInCircleRed} /></div>
            <div className="ms-2">
              {
                !!savedTemplate.rubrics ?
                  'Rubric is included' :
                  'Rubric is not included'
              }
            </div>
          </div>
        </div>

        <div className="me-4">
          <div className="d-flex align-items-center">
            <div><img width="20" src={saveFeedback ? CheckMarkLightGreen : XInCircleRed} /></div>
            <div className="ms-2">
              {
                saveFeedback ?
                  'Feedback is included' :
                  'Feedback not included'
              }
            </div>
          </div>
        </div>
      </div>

      <hr className="light-divider" />

      <div className="mt-4">
        <div className="space-section p-3 rounded">

          Nurture Space Code : {
            !!code ? <span className="code">{code}</span> : 'N/A'
          }

          {
            !!code &&
              <>
                <input className="hidden-text" ref={codeRef} type="text" value={code} />
                <FontAwesomeIcon className="ms-3 font-size-20 cursor-pointer" icon={faCopy} onClick={handleCopyToClipboard} />
              </>
          }
        </div>
      </div>

      <div className="row mt-5">
        {
          context === 'assignment_creation' ?
            <>
              <div className="offset-lg-1 col-12 col-lg-5 mb-3">
                <div className="d-grid">
                  <NavLink to="/tab?activeTab=assignments_templates" className="btn btn-outline-nurture-purple py-3">
                    Go to assessment templates
                  </NavLink>
                </div>
              </div>
              <div className="offset-lg-0 col-12 col-lg-5 mb-3">
                <div className="d-grid">
                  <Button className="py-3" type="button" onClick={onclose}>Continue this assessment</Button>
                </div>
              </div>
            </> :

            <>
              <div className="offset-lg-1 col-12 col-lg-5 mb-3">
                <div className="d-grid">
                  <Button className="py-3" type="button" onClick={onclose}>Close</Button>
                </div>
              </div>
              <div className="offset-lg-0 col-12 col-lg-5 mb-3">
                <div className="d-grid">
                  <NavLink to="/tab?activeTab=assignments_templates" className="btn btn-outline-nurture-purple py-3">
                    Go to assessment templates
                  </NavLink>
                </div>
              </div>
            </>
        }
      </div>
    </div>
  );
};

TemplateSummary.defaultProps = {
  saveFeedback: false,
  context: 'assignment_creation'
}

TemplateSummary.propTypes = {
  templateFor: PropTypes.string.isRequired,
  code: PropTypes.any.isRequired,
  onclose: PropTypes.func.isRequired,
  saveFeedback: PropTypes.string,
  context: PropTypes.string,
}

export default TemplateSummary;
