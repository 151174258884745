import { useEffect, useState } from 'react';

import AssignmentForm from "./form/Form";
import {useDispatch, useSelector} from "react-redux";
import { assignmentCreate } from "./action";
import { attachmentFilestack } from "../attachments/actions";
import * as attachmentsSelector from "../attachments/selectors";
import { isEmpty } from "core/utils";
import * as assignmentSelector from "./selectors";
import AssignmentNewSkeletalLoader from "./skeletalLoaders/AssignmentNewSkeletalLoader";

const AssignmentsNew = () => {
    const [assignment, setAssignment] = useState({});

    const dispatch = useDispatch();
    const filestackCredentials = useSelector(attachmentsSelector.filestackCredentials);
    const loading = useSelector(assignmentSelector.loading);

    useEffect(() => {
        const createAssignment = async () => {
            const currentAssignment = await dispatch(assignmentCreate());
            setAssignment(currentAssignment);
        };
        // Because this is the new assignment page, we clear any current assignment that might have been in the redux store
        // so that the assessment form will know how to handle it
        // We also fetch filestack credentials
        createAssignment();
        if (isEmpty(filestackCredentials)) dispatch(attachmentFilestack())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdate = (assignment) => setAssignment(assignment)

    return (
      <>
        {
          loading ?
            <AssignmentNewSkeletalLoader /> :
            <AssignmentForm assignment={assignment} onUpdate={handleUpdate} />
        }
      </>
    );
};

export default AssignmentsNew;
