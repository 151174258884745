import { dateUtils } from "core/utils";
import './summaryCard.scss';
import PropTypes from "prop-types";
import SeparatorIcon from "assets/images/general_icons/separator.svg";

import { Emoji } from "core/components";
import AssessmentSubmissionTab from "./AssessmentSubmissionTab";
import {useState} from "react";
import {useAssignmentInfo} from "core/hooks";
import AssignmentDatesInfo from "../AssignmentDatesInfo";

const SummaryCard = ({ assignment, tab, onChangeTab }) => {
  const {
    submittedUserAssignments,
    reflectionUserAssignments,
    publishedUserAssignments,
    totalUserAssignments,
    averageConfidence,
    averageGrade

  } = useAssignmentInfo(assignment);

  return (
    <div className="card gradient-background mb-4">
      <div className="card-body">
        <div className="row mb-5">
          <div className="col-md-8">
            <h2 className='text-white AssignmentHeader merriweather-font'>{assignment?.name}</h2>
            <AssignmentDatesInfo assignment={assignment} color='#D6BBFB' />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 mb-3 mb-lg-0">
            <div className="d-flex flex-wrap">
              {/*Students Sunmitted*/}
              <div className="me-4 merriweather-font">
                <div
                  className="text-white font-size-24 font-weight-400">{submittedUserAssignments.length} / {totalUserAssignments}</div>
                <div className="small-texts">Submitted</div>
              </div>
              {/*Students Reflected*/}
              <div className="me-4 merriweather-font">
                <div
                  className="text-white font-size-24 font-weight-400">{reflectionUserAssignments.length} / {totalUserAssignments}</div>
                <div className="small-texts">Reflected</div>
              </div>
              {/*Average Confidence*/}
              <div className="me-4 d-flex flex-column justify-content-end">
                <div
                  className="text-white font-size-24 font-weight-400">
                  {averageConfidence ? <Emoji className='mb-1' level={parseInt(averageConfidence)} size="md"/> : '-'}
                </div>
                <div className="small-texts">Avg. Confidence</div>
              </div>
              {/*Average Grade*/}
              <div className="me-4 merriweather-font">
                <div
                  className="text-white font-size-24 font-weight-400">{averageGrade}</div>
                <div className="small-texts">Avg. Grade</div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <AssessmentSubmissionTab onChangeActiveTab={onChangeTab} activeTab={tab} />
          </div>
        </div>
      </div>
    </div>
  );
};

SummaryCard.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
  tab: PropTypes.oneOf(['assessment', 'submissions']).isRequired,
  onChangeTab: PropTypes.func.isRequired
};

export default SummaryCard;
