export const templatesFilterDropdown = [
  {text: 'All Templates'},
  {text: 'My Templates'},
  {text: 'School Templates'},
  {text: 'Nurture Spaces'}
];

export const sortFilterDropdown = [
  {text: 'Most Popular'},
  {text: 'Most Used'},
];