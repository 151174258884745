import PropTypes from 'prop-types';
import { autoEmbed, markdownToHtml, autolinker } from "core/utils";
import './autoLinker.scss';

const Autolinker = ({ content, className, ...rest }) => {
  const note = content ? markdownToHtml(autolinker(autoEmbed(content))) : '';

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: note }}
      {...rest}
    />
  );
};

Autolinker.defaultProps = {
  className: "pt-2 pb-2 px-3",
};

Autolinker.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Autolinker;
