import React, { useEffect } from 'react';
import { useFieldArray } from "react-hook-form";

import {faXmark, faPlus, faGripVertical, faAngleUp, faAngleDown} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormText } from 'reactstrap';
import { Button } from 'core/components';
import Input from './Input';
import { isPresent, singularize } from 'core/utils';
import { useSchoolConfig } from "core/hooks";
import {SortableContainer, SortableElement} from "react-sortable-hoc";

const LearningObjectiveField = ({learningObjective, fieldsTotal, formConfig, index, allowSort, position, handleDelete, handleKeyDown, jumpToIndex, ...otherProps}) => {
  const { formState: { errors }, register } = formConfig;

  const handleClick = (currentPosition, newPosition) => {
    jumpToIndex(currentPosition, newPosition)
  }

  const onDelete = (index) => {
    handleDelete(index)
  }

  return (
    <div className="py-2 px-1 px-md-3">
      <div key={learningObjective.id} className={`d-flex flex-wrap align-items-center ${allowSort ? 'cursor-move' : ''}`}>
        {
          allowSort &&
            <div className="col-auto px-2 d-none d-md-inline-block">
            <FontAwesomeIcon className="text-muted font-size-17" icon={faGripVertical} />
          </div>
        }
        <div className="col p-2">
          <Input
            style={{minWidth: '200px'}}
            {...register(`assignment_objectives.${position}.name`, { required: true })}
            error={!!(errors && errors[position]?.name?.message)}
            onKeyDown={handleKeyDown}
            {...otherProps}
          />

          {isPresent(errors) && (
            <p className="mt-1">
              <FormText color="danger">
                {errors[position]?.name?.message}
              </FormText>
            </p>
          )}
        </div>
        {
          allowSort &&
            <div className='col-auto px-2 order-2 order-md-1'>
              <div className="d-flex">
                {
                  position > 0 && // allow up if it is not the first item
                  <div className="rounded-circle border p-2 me-2 cursor-pointer" onClick={() => handleClick(position, position - 1)}>
                    <FontAwesomeIcon icon={faAngleUp} />
                  </div>
                }
                {
                  position < fieldsTotal - 1 && // allow down if it is not the last item
                  <div className="rounded-circle border p-2 me-2 me-md-0 cursor-pointer" onClick={() => handleClick(position, position + 1)}>
                    <FontAwesomeIcon icon={faAngleDown} />
                  </div>
                }
              </div>
            </div>
        }
        <div className="col-auto px-2 order-1 order-md-2">
          <button type="button" className="btn btn-link text-danger" onClick={() => onDelete(position)}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      </div>
    </div>
  )
}
const LearningObjectiveFieldSortable = SortableElement(LearningObjectiveField)

const FieldArray = ({ formConfig, fields, handleDelete, handleKeyDown, jumpToIndex, allowSort, ...otherProps }) => {
  const Element = allowSort ? LearningObjectiveFieldSortable : LearningObjectiveField

  return (
    <div>
      {fields.map((item, index) => (
        <Element
          key={`item-${index}`}
          formConfig={formConfig}
          learningObjective={item}
          index={index}
          position={index}
          fieldsTotal={fields.length}
          allowSort={allowSort}
          handleDelete={handleDelete}
          handleKeyDown={handleKeyDown}
          jumpToIndex={jumpToIndex}
          {...otherProps}
        />
      ))}
    </div>
  );
};
const FieldArraySortable = SortableContainer(FieldArray)

const NestedFields = ({ subject, onRemove, formConfig, allowSort, ...otherProps }) => {
  const Element = allowSort ? FieldArraySortable : FieldArray;
  const { control, watch } = formConfig;
  const { learningObjectiveLabel } = useSchoolConfig();
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: 'assignment_objectives'
  });

  useEffect(() => {
    if (isPresent(subject) && Array.isArray(subject)) {
      remove();
      subject.forEach(item => append({...item, slug: item.id || item.slug}, {shouldFocus: false}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  const watchFieldArray = watch('assignment_objectives');

  const handleAppend = () => append({ name: '' }, false);

  const handleDelete = async (index) => {
    if (watchFieldArray.length === 1) {
      alert(`There must be at least one ${ singularize(learningObjectiveLabel).toLowerCase() }`);
    } else {
      remove(index);
      if (onRemove && typeof onRemove == 'function') await onRemove(fields[index])
    }
  };

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAppend();
    }
  };

  const jumpToIndex = (currentIndex, targetIndex) => {
    swap(currentIndex, targetIndex)
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    swap(oldIndex, newIndex)
  };

  return (
    <>
      <Element
        handleKeyDown={checkKeyDown}
        fields={fields}
        formConfig={formConfig}
        allowSort={allowSort}
        handleDelete={handleDelete}
        onSortEnd={onSortEnd}
        jumpToIndex={jumpToIndex}
        distance={1}
        {...otherProps}
      />
      <Button
        outline
        onClick={handleAppend}
        className="btn-sm mt-2"
      >
        <FontAwesomeIcon icon={faPlus} /> Add another
      </Button>
    </>
  );
};

export default NestedFields;
