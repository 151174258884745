import oneNote from 'assets/images/file_icons/one-note.svg';
import youtube from 'assets/images/file_icons/youtube.svg';
import PropTypes from "prop-types";

const NoteDescription = ({ showNotes }) => (
  <div className='mt-2'>
    {
      showNotes &&
      <small className="form-text text-muted pt-2">Briefly describe how long you spent on this assessment and how easy or difficult you found it.</small>
    }
    <small className="form-text text-muted">You can paste OneNote <img src={oneNote} alt="One note icon" className='small-img' />, YouTube <img src={youtube} alt="Youtube icon" className='small-img' /> and other URLs here.</small>
  </div>
);

NoteDescription.defaultProps = {
  showNotes: true
};

NoteDescription.propTypes = {
  showNotes: PropTypes.bool
};

export default NoteDescription;
