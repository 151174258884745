import * as t from './actionTypes';

const initialState = {
  assignments: {},
  users: [],
  loading: false,
  error: false,
};

const graphData = (state = initialState, action) => {
  switch (action.type) {
    case t.GRAPH_LOAD_START:
      return {
        ...state,
        loading: true,
      };
    case t.GRAPH_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        assignments: action.data.assignment_data,
        users: action.data.users,
      };
    case t.GRAPH_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default graphData