import oneNote from 'assets/images/file_icons/one-note.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";

export const OneNote = ({ onenote_url }) => {

  return (
    <>
      <div className="card mb-0 mt-3 border">
        <div className="card-body">
          <div className="d-flex justify-content-between pt-1 align-items-center">
            <div className="me-4">
              <img src={oneNote} height={40} alt="" />
            </div>

            <div className="flex-grow-1">
              <h6 className="mb-0 fw-1 truncate">One Note Document</h6>
            </div>
          </div>

          <div className="mt-3">
            <a
              href={onenote_url}
              target="_blank"
              className="btn btn-nurture-purple me-1 w-100 w-sm-200px d-inline-block"
            >
              Open in Onenote
              <FontAwesomeIcon icon={faExternalLinkSquareAlt} className="ms-2" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default OneNote;