import PropTypes from "prop-types";

const StudentAssessmentStatusBadges = ({
   dueAt,
   studentSubmittedAt,
   feedbackPublishedAt,
   reflectionCompletedAt,
    isReviewed
 }) => {
  const now = new Date();
  const isDue = now > new Date(dueAt);
  const isSubmitted = !!studentSubmittedAt;
  const isFeedbackPublished = !!feedbackPublishedAt;
  const isReflectionCompleted = !!reflectionCompletedAt;

  let primaryBadge,
    secondaryBadge,
    primaryBadgeTheme,
    secondaryBadgeTheme;

  // Primary badge
  if (isReflectionCompleted) {
    primaryBadge = 'Reflection Completed';
    primaryBadgeTheme = 'success';
  } else if (isFeedbackPublished && isReviewed) {
    primaryBadge = 'Feedback Available';
    primaryBadgeTheme = 'purple';
  } else if (isSubmitted) {
    primaryBadge = 'Submitted';
    primaryBadgeTheme = 'info';
  } else if (isDue) {
    primaryBadge = 'Submission Overdue';
    primaryBadgeTheme = 'alert';
  } else {
    primaryBadge = 'Not Submitted';
    primaryBadgeTheme = 'warn';
  }

  // Secondary Badge
  if (isDue && !isSubmitted) {
    secondaryBadge = 'Submission Overdue';
    secondaryBadgeTheme = 'alert';
  } else if (isDue && isSubmitted) {
    secondaryBadge = 'Late Submission';
    secondaryBadgeTheme = 'warn';
  } else if (!isDue && !isSubmitted) {
    secondaryBadge = 'Not Submitted';
    secondaryBadgeTheme = 'warn';
  } else if (!isDue && isSubmitted) {
    secondaryBadge = null;
    secondaryBadgeTheme = null;
  }

  if (primaryBadge === secondaryBadge) {
    secondaryBadge = null;
    secondaryBadgeTheme = null;
  }

  const primaryBadgeStyle = {
    border: BADGE_THEME_MAP[primaryBadgeTheme]?.border,
    color: BADGE_THEME_MAP[primaryBadgeTheme]?.text,
    backgroundColor: BADGE_THEME_MAP[primaryBadgeTheme]?.background,
  };

  const secondaryBadgeStyle = {
    border: BADGE_THEME_MAP[secondaryBadgeTheme]?.border,
    color: BADGE_THEME_MAP[secondaryBadgeTheme]?.text,
    backgroundColor: BADGE_THEME_MAP[secondaryBadgeTheme]?.background,
  };

  return (
    <div className="d-flex flex-wrap me-0 me-md-2 mb-sm-0">
      <div className="p-1 font-size-14 rounded text-sm fw-semibold mb-2 me-2" style={primaryBadgeStyle}>
        {primaryBadge}
      </div>
      {secondaryBadge && (
        <div className="p-1 font-size-14 rounded text-sm mb-2 fw-semibold" style={secondaryBadgeStyle}>
          {secondaryBadge}
        </div>
      )}
    </div>
  );
}

const BADGE_THEME_MAP = {
  warn: {
    border: 'solid 2px #FEDF89',
    text: '#B54708',
    background: '#FFFAEB',
  },
  alert: {
    border: 'solid 2px #FECDCA',
    text: '#B42318',
    background: '#FEF3F2',
  },
  info: {
    border: 'solid 2px #B9E6FE',
    text: '#026AA2',
    background: '#F0F9FF',
  },
  purple: {
    border: 'solid 2px #D9D6FE',
    text: '#5925DC',
    background: '#F4F3FF',
  },
  success: {
    border: 'solid 2px #ABEFC6',
    text: '#067647',
    background: '#ECFDF3',
  },
};

StudentAssessmentStatusBadges.defaultProps = {
  studentSubmittedAt: undefined,
  feedbackPublishedAt: undefined,
  reflectionCompletedAt: undefined,
  isReviewed: false,
}

StudentAssessmentStatusBadges.propTypes = {
  dueAt: PropTypes.number.isRequired,
  studentSubmittedAt: PropTypes.number,
  feedbackPublishedAt: PropTypes.number,
  reflectionCompletedAt: PropTypes.number,
  isReviewed: PropTypes.bool
};

export default StudentAssessmentStatusBadges;
