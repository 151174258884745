import PeopleHappyImage from "assets/images/illustrations/people_happy.png";
import NurtureLogo from "assets/images/logo.png";
import BackButton from "core/components/BackButton";
import PropTypes from "prop-types";

const AssignmentError = ({ errorMessage }) => {
  return (
    <div className="container">
      <div className="col-md-2 offset-md-1">
        <BackButton />
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-md-10">
          <div className="card">
            <div className="card-header">
              <div className="alert alert-danger" role="alert">
                { errorMessage }
              </div>
            </div>
            <div className="card-body">
              <img src={NurtureLogo} alt="Nurture" className="my-3 nurture-logo" />
              <img src={PeopleHappyImage} alt="People Happy" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AssignmentError.defaultProps = {
  errorMessage: 'Something went wrong! please check your internet and try again.'
};

AssignmentError.propTypes = {
  errorMessage: PropTypes.string
};

export default AssignmentError;
