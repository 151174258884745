import React, { Fragment, useEffect, useState } from "react";
import Buttons from "./Buttons";
import EmptyList from "./EmptyList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Button, PermissionAlert } from "core/components";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { DeleteModal } from "core/components/modals";
import StudentListsSkeletalLoader from "modules/studentsList/skeletalLoaders/StudentListsSkeletalLoader";
import * as api from './api';
import { useSnackbar } from "notistack";
import {handleApiError} from "../../core/utils";

const StudentsList = () => {
  const [students, setStudents] = useState([])
  const [loading, setLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [studentToDelete, setStudentToDelete] = useState(null)

  const { enqueueSnackbar } = useSnackbar();

  const handleStudentDelete = async () => {
    try {
      setDeleting(true);
      const response = await api.studentDelete(studentToDelete.id)
      const deletedStudent = response.data
      const updatedStudents = [...students].filter((student) => student.id !== deletedStudent.id) || []
      setStudents(updatedStudents);
      setShowDeleteModal(false);
      enqueueSnackbar('Student was removed successfully', { variant: 'success' });
    } catch (e) {
      const formattedError = handleApiError(e);
      enqueueSnackbar(formattedError.message, { variant: 'error' });
    } finally {
      setDeleting(false);
    }
  };

  const initiateDelete = (student) => {
    setStudentToDelete(student);
    setShowDeleteModal(true);
  }

  const handleStudentAdd = (student) => {
    setStudents(prevState => [student, ...prevState])
  }

  const handleStudentsUpload = (students) => {
    setStudents(prevState => students);
  };

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const fetchStudents = async () => {
    try {
      setLoading(true);
      const response = await api.getStudentsList();
      setStudents(() => response.data)
    } catch (e) {
      const formattedError = handleApiError(e);
      enqueueSnackbar(formattedError.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchStudents();
  }, []);

  return (
    <div>
      {
        loading ?
          <StudentListsSkeletalLoader /> :
          <Fragment>
            <PermissionAlert />

            <Buttons onAdd={handleStudentAdd} onUpload={handleStudentsUpload} />
            {
              students.length > 0 ?
                <div className="card">
                  <Fragment>
                    <div className="card-header">
                      <div className="input-group input-group-flush">
                    <span className="input-group-text search" id="search">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                        <input
                          type="search"
                          className="form-control search"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="search" />
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                          <tr>
                            <th>S/N</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            students.map((student, index) => (
                              <tr>
                                <td>{ index + 1 }</td>
                                <td>{student.first_name}</td>
                                <td>{student.last_name}</td>
                                <td>{student.email}</td>
                                <td>
                                  <Button outline={true} color="danger" className="btn-sm" onClick={() => initiateDelete(student)}>
                                    Delete
                                    <FontAwesomeIcon className="ms-2" icon={faTrashCan} />
                                  </Button>
                                </td>
                              </tr>
                            ))
                          }
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </Fragment>
                </div> :
                <EmptyList />
            }
          </Fragment>
      }

      <DeleteModal
        show={showDeleteModal}
        onDelete={handleStudentDelete}
        toggle={toggleDeleteModal}
        text="Are you sure you want to remove this student from the students list"
        submitting={deleting} />
    </div>
  );
}

export default StudentsList;