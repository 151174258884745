import Hamburger from 'assets/images/hamburger.svg';
import NurtureLogo from 'assets/images/general_icons/nurture-logo.svg';
import WhiteTimes from 'assets/images/white_times.svg'
import React from "react";
import {TABS} from "../Tab";
import ColorfulStarOnGraph from "assets/images/general_icons/colorful-star-on-graph.svg";
import GreyStarOnGraph from "assets/images/general_icons/grey-star-on-graph.svg";
import ColorfulCheckInCircle from "assets/images/general_icons/colorful-check-in-circle.svg";
import GreyCheckInCircleOverlap from "assets/images/general_icons/grey-check-in-circle-overlap.svg";
import ColorfulFlash from "assets/images/general_icons/colorful-flash.svg";
import GreyFlash from "assets/images/general_icons/grey-flash.svg";
import {useSchoolConfig} from "core/hooks";
import {useNavigate} from "react-router-dom";
import RedLogout from "assets/images/general_icons/red-logout.svg";
import {envVariables, teamsEnv} from "core/utils";
import WhiteRound from "assets/images/general_icons/white_round.svg";
import {deleteCache} from "core/channel/action";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import GreySettings from "assets/images/general_icons/grey-settings.svg";
import GreyQuestionMarkInCircle from "assets/images/general_icons/grey-question-mark-in-circle.svg";

const HeaderMobile = ({ name, role, activeTab, onTabChanged }) => {
  const { trialEnded } = useSchoolConfig();
  const displayName = name ? ` ${name},` : ',';

  const user = JSON.parse(sessionStorage.getItem('user'));
  const isTeacher = user?.role === 'teacher';
  const navigateTo = useNavigate();
  const { tenantId, channelId } = teamsEnv;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const newAssignment = () => {
    makeBodyScrollable();
    navigateTo('/assignments/new');
  }

  const makeBodyScrollable = () => {
    const $ = window.jQuery;
    $('#sideNav').offcanvas('hide');

    const offcanvasElement = document.querySelector('#sideNav');
    // const bsOffcanvas = Offcanvas.getInstance(offcanvasElement); // Get the Bootstrap Offcanvas instance
    // bsOffcanvas.hide();

    offcanvasElement.addEventListener('hidden.bs.offcanvas', function () {
      // Remove the custom styles added by the offcanvas to the body on hide
      const body = document.querySelector('body');
      body.style.overflow = '';
      body.style.paddingRight = '';
    })

    document.querySelectorAll('.offcanvas-backdrop').forEach(backdrop => {
      backdrop.remove(); // Remove the backdrop element
    });
  }

  const handleTabClick = (tab) => {
    onTabChanged(tab);
    makeBodyScrollable();
  };

  const handleRefreshCache = async () => {
    try {
      await dispatch(deleteCache());
      enqueueSnackbar('Users refreshed successfully', { variant: 'success' });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar('Error occurred while refreshing, Try again!', { variant: 'error' });
    }
  }

  const logOut = () => {
    localStorage.removeItem('token');
    window.location.href = `${envVariables.apiBase}/i/teams/${tenantId}/${channelId}/logout`;
  }

  const greetingTextMap = {
    [TABS.dashboard]: `👋 Hello${displayName} here is your Dashboard`,
    [TABS.assignments_templates]: `👋 Hello${displayName} here are your Assessment Templates`,
    [TABS.assignments]: `👋 Hello${displayName} here are your Assessments`,
  }

  return (
    <div className="HeaderMobile">
      <div className="bg-white p-3 HeaderMobileNav mb-4 border">
        <div className="d-flex justify-content-between align-items-center">
          <img src={NurtureLogo} alt="nurture logo"/>

          <button className="btn btn-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#sideNav">
            <img src={Hamburger} alt="hamburger" />
          </button>
        </div>
      </div>

      <div className="mb-4">
        <div className='fs-3 small-screen-text d-flex align-items-center'>
          <div className='me-2 mt-5 mb-2'>{greetingTextMap[activeTab] }</div>
        </div>
      </div>

      <div className="offcanvas offcanvas-start" id="sideNav">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title"><img src={NurtureLogo} alt="nurture logo" /></h5>
          <button type="button" className="btn text-reset" data-bs-dismiss="offcanvas">
            <img src={WhiteTimes} />
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="d-flex flex-column justify-content-between h-100">
            {/*Top Section*/}
            <div>
              <div
                className={`HeaderMobile-nav mb-2 ${activeTab === TABS.dashboard ? 'active' : ''}`}
                onClick={() => handleTabClick(TABS.dashboard)}
              >
                <img src={activeTab === TABS.dashboard ? ColorfulStarOnGraph : GreyStarOnGraph} alt="Dashboard"/>
                <span className='ms-1'>Dashboard</span>
              </div>

              <div
                className={`HeaderMobile-nav mb-2 ${activeTab === TABS.assignments ? 'active' : ''}`}
                onClick={() => handleTabClick(TABS.assignments)}
              >
                <img src={activeTab === TABS.assignments ? ColorfulCheckInCircle : GreyCheckInCircleOverlap}
                     alt="Assessments"/>
                <span className='ms-1'>Assessments</span>
              </div>

              {
                isTeacher &&
                <div
                  className={`HeaderMobile-nav mb-2 ${activeTab === TABS.assignments_templates ? 'active' : ''}`}
                  onClick={() => handleTabClick(TABS.assignments_templates)}
                >
                  <img src={activeTab === TABS.assignments_templates ? ColorfulFlash : GreyFlash} alt="Templates"/>
                  <span className='ms-1'>Templates</span>
                </div>
              }

              {
                isTeacher &&
                <button
                  className={`btn new-purple-btn mt-4 mb-2 d-block w-100 ${trialEnded ? 'pointer-events-none' : ''}`}
                  onClick={newAssignment}
                >
                  New Assessment
                </button>
              }
            </div>

            {/*Bottom Section*/}
            <div className="d-flex flex-column justify-content-end">
              {
                isTeacher &&
                <>
                  <div
                    className={`HeaderMobile-nav mb-1 d-flex align-items-center`}
                    onClick={() => handleTabClick(TABS.classroom_settings)}
                  >
                    <img src={GreySettings} alt="GreySettings"/>
                    <span className='ms-1'>Classroom Settings</span>
                  </div>

                  <div
                    className={`HeaderMobile-nav mb-1 d-flex align-items-center`}
                    onClick={() => handleTabClick(TABS.help_resources)}
                  >
                    <img width="18" src={GreyQuestionMarkInCircle} alt="Dashboard"/>
                    <span className='ms-1'>Support</span>
                  </div>
                </>
              }

              <div
                className={`HeaderMobile-nav mb-1 d-flex align-items-center`}
                onClick={handleRefreshCache}
              >
                <img width="20" src={WhiteRound} alt="Dashboard"/>
                <span className='ms-1'>Reload Nurture</span>
              </div>

              <div
                className={`HeaderMobile-nav mb-1`}
                onClick={logOut}
              >
                <img src={RedLogout} alt="Dashboard"/>
                <span className='ms-1'>Logout</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderMobile;
