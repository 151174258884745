import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AssignmentSkeletalLoader from '../assignments/skeletalLoaders/AssignmentSkeletalLoader';
import * as assignmentSelector from '../assignments/selectors';
import * as assignmentActions from '../assignments/action';
import UserAssignment from './UserAssignment';
import { isPresent } from 'core/utils';
import { BackButton } from 'core/components';
import { useSnackbar } from 'notistack';

const StudentAssignment = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { assignmentId } = params;

  const getAssignment = async id => dispatch(assignmentActions.getAssignment(id, false));

  const assignment = useSelector(assignmentSelector.getAssignmentById(assignmentId));
  const loading = useSelector(assignmentSelector.loading);
  const completeAssignment = isPresent(assignment?.assignment_objectives);

  useEffect(
    () => {
      getAssignment(assignmentId)
        .then()
        .catch(() => {
          const text = "Error encountered while fetching assignment. Please try again later.";
          enqueueSnackbar(text, { variant: 'error' });
          navigate('/assignments');
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  );

  const sessionUser = JSON.parse(sessionStorage.getItem('user'));
  const role = sessionUser?.role;

  return loading ? <AssignmentSkeletalLoader /> : (
    <>
      {(completeAssignment && role === 'student') ?
        <>
          <div><BackButton /></div>
          <UserAssignment assignment={assignment} />
        </> : null
      }
    </>)
};

export default StudentAssignment;
