import { createSelector } from '@reduxjs/toolkit';

export const get = state => state.assignments;

export const loading = createSelector(get, state => state.loading);
export const error = createSelector(get, state => state.error);
export const meta = createSelector(get, state => state.meta);
export const getAssignmentById = id => createSelector(get, state => state.byId[id]);
export const getAssignments = createSelector(get, state => Object.values(state.byId)
  .sort((a, b) => (a.created_at < b.created_at) ? 1 : -1));
export const studentAssignments = createSelector(get, state => state.studentAssignments.assignments);
export const studentAssignmentsMeta = createSelector(get, state => state.studentAssignments.meta);
export const studentAssignmentsLoading = createSelector(get, state => state.studentAssignments.loading);

