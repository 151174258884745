import { envVariables, teamsEnv } from 'core/utils';

export const login = async onSuccess => {
  const { apiBase } = envVariables;
  const { tenantId } = teamsEnv;
  let userToken = null;

  const popup = window.open(`${apiBase}/i/teams/${tenantId}/auth/start?popup=true&origin=${window.location.host}`, "login", "height=535,width=600");

  window.addEventListener('message', event => {
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).

    if (event.origin !== apiBase || event.data.userSignedIn === undefined) return;

    if (event.data.userSignedIn) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user');

      userToken = event.data.userToken;
      localStorage.setItem('token', userToken);

      if (typeof onSuccess === 'function') onSuccess();
    } else {
      alert('Something went wrong from server side.');
    }

    popup.close();
  }, false);

  // https://gist.github.com/imshengli/ac8f705c2a4e1cab3662dd0bdab5a2d2
  const timer = setInterval(function () {
    if (popup.closed) {
      clearInterval(timer);
      if (userToken == null) {
        alert('User closed the popup without successful auth.');
      } else {
        if (typeof onSuccess === 'function') onSuccess();
      }
    }
  }, 1000);
};
