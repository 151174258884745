import { createRequest, composeRequestConfig } from 'core/utils/axios/api';
import { teamsEnv } from 'core/utils';

export const getStudentsList = () => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/students`;

  const config = composeRequestConfig({ url });
  return createRequest(config);
};

export const studentCreate = (payload) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/students`

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
};

export const studentDelete = (studentId) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/students/${studentId}`

  const config = composeRequestConfig({ url, method: 'delete' });
  return createRequest(config);
};

export const studentsCsvSample = () => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/students/csv_sample`

  const config = composeRequestConfig({ url });
  return createRequest(config);
};

export const studentsCsvUpload = (payload) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/students/csv_upload`

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
};