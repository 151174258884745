import PropTypes from 'prop-types';

const SectionTitle = ({ title, ...rest }) => (
  <div {...rest}>
    <span className='section-header-title pt-3 fs-6'>{title}</span>
  </div>
);

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionTitle;
