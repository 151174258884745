import React, {useRef, useState} from "react";
import { FormGroup, FormText, Label } from "reactstrap";
import { DateInput, Switch } from "core/form/fields";
import { Controller } from "react-hook-form";
import Card from "core/components/Card";
import StudentsTable from "modules/assignments/studentTable/StudentsTable";
import { pluralize } from "core/utils";
import PropTypes from "prop-types";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AssignmentAssignAndSchedule = ({ formConfig, content, assignment, onAssign }) => {
  const [checkAll, setCheckAll] = useState(true);
  const [accordionIconActive, setAccordionIconActive] = useState(false);
  const studentsCount = assignment?.students ? Object.keys(assignment?.students).length : 0
  const hiddenStudentRef = useRef();

  const headerText = '3. Assign and Schedule';

  const toggleAccordionIconState = () => setAccordionIconActive(!accordionIconActive);
  const toggleCheckedAll = () => {
    setCheckAll(!checkAll);

    const classLists = hiddenStudentRef.current.classList;

    if (!classLists.contains('show')) {
      hiddenStudentRef.current.classList.add('show');
      setAccordionIconActive(true)
    }
  }

  const { control, formState: { errors }, register, watch } = formConfig;
  const watchScheduled = watch('scheduled');

  return (
    <Card className="AssignAndSchedule" headerText={headerText} headerClass="big-text text-nurture-purple" withHeader>
      <div className="accordion mb-4" id="accordionExample">
        <div className="accordion-item">
          <div className="accordion-header d-flex align-items-center" id="headingOne">
            <div className="form-check font-size-16 ps-4">
              <input
                onClick={toggleCheckedAll}
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                defaultChecked={checkAll}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Send to all {studentsCount} {pluralize('student', studentsCount)} in this team
              </label>
            </div>
            <div className="flex-grow-1 text-end cursor-pointer" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                 aria-controls="collapseOne" onClick={toggleAccordionIconState} >
              <button className="bg-transparent" type="button" >
                <FontAwesomeIcon icon={accordionIconActive ? faAngleDown : faAngleRight} />
              </button>
            </div>
          </div>
          <div id="collapseOne" ref={hiddenStudentRef} className="accordion-collapse collapse" aria-labelledby="headingOne"
               data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <StudentsTable
                assignment={assignment}
                showButtons={false}
                onAssign={onAssign}
                checkAll={checkAll}
              />
            </div>
          </div>
        </div>
      </div>
      
      <hr />

      {/* Due at */}
      {
        content === 'assignment' &&
        <>
          <FormGroup>
            <Label>Due On</Label>
            <Controller
              name="due_at"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <DateInput
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errors={!!errors.due_at}
                  />
                )
              }}
            />
            {errors.due_at && (
              <p className="mt-1">
                <FormText color="danger">
                  {"Due at date is not valid"}
                </FormText>
              </p>
            )}
            <FormText color="muted">Assessments will be due at the end of the selected date.</FormText>
          </FormGroup>
        </>
      }
      
      {
        content === 'assignment' &&
        <>
          <h3 className="font-size-17 font-weight-600 mb-3">Schedule Assessment</h3>
          <FormGroup className="ps-3">
            <Switch label="This will publish the assessment on the given date." {...register('scheduled')} />
          </FormGroup>
          {watchScheduled && (
            <FormGroup>
              <Label>Publish On</Label>
              <Controller
                name="publish_at"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <DateInput
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      minDate={(new Date(Date.now() + 60 * 60 * 1000))} // 1hour from now
                      error={!!errors.publish_at?.message}
                    />
                  )
                }}
              />
              {errors.publish_at && (
                <p className="mt-1">
                  <FormText color="danger">
                    {errors.publish_at.message}
                  </FormText>
                </p>
              )}
            </FormGroup>
          )}
        </>
      }
    </Card>
  );
};

AssignmentAssignAndSchedule.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
  formConfig: PropTypes.any.isRequired,
  content: PropTypes.string.isRequired,
  onAssign: PropTypes.func.isRequired,
}

export default AssignmentAssignAndSchedule;
