import React, { useState, useEffect } from "react";

import '../assignment_templates.scss';
import { isEmpty } from "core/utils";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Button from "core/components/Button";
import TemplateVisibilities from "./templateVisibilities";
import TemplateSummary from "./templateSummary";
import CheckMarkNurturePurple from "assets/images/general_icons/check_mark_nurture_purple.svg";
import FeedbackPublishedText from "./FeedbackPublishedText";
import SourceTemplate from "./SourceTemplate";

const AssignmentTemplatesModal = ({
    assignment, defaultAssignmentName, savedTemplate, isOpen, templateCreateLoading, templateModalState,
    sharableCode, context, onChangeTemplate, onSubmit, onClose, onUpdate
  }) => {
  const [templateFor, setTemplateFor] = useState(assignment?.visibility || '');
  const [templateName, setTemplateName] = useState(assignment?.name || defaultAssignmentName);
  const [saveFeedback, setSaveFeedback] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [sourceTemplate, setSourceTemplate] = useState(assignment?.assignment_template);
  const [assignmentName, setAssignmentName] = useState(assignment?.name || defaultAssignmentName);
  const [templateSavedText, setTemplateSavedText] = useState('Assessment saved as a template');

  const isRubrics = !!assignment?.rubrics
  const showUpdateTemplate = sourceTemplate && context === 'feedback' && !isRubrics && sourceTemplate?.can_edit

  const closeModal = () => {
    if (templateCreateLoading) return;
    onClose();
  };

  const submitModal = () => onSubmit();

  const handleChangeVisibility = (visibility, templateName, saveFeedback) => {
    context === 'assignment_creation' ? onChangeTemplate(visibility, templateName) : onChangeTemplate(visibility, templateName, saveFeedback)
    setTemplateFor(visibility)
    setTemplateName(templateName)
    setSaveFeedback(saveFeedback)
  }

  const handleCreateNewFromSourceTemplate = () => {
    setSourceTemplate(false);
    if (showUpdateTemplate) setAssignmentName('(Copy) ' + assignmentName);
  }

  const handleUpdateFeedbackOnTemplate = (template) => {
    setTemplateSavedText('Feedback updated on this template');
    setSaveFeedback(true);
    onUpdate(template);
  };

  useEffect(() => {
    setSaveButtonDisabled(isEmpty(templateFor) || !templateName)
  }, [templateFor, templateName]);

  useEffect(() => {
    setTemplateName(assignment?.name || defaultAssignmentName);
    setAssignmentName(assignment?.name || defaultAssignmentName);
  }, [assignment, defaultAssignmentName])

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      size="lg"
      className="modal assignment-templates-modal modal-fullscreen-md-down modal-dialog-scrollable"
    >
      {/*<ModalHeader className="pt-5 pb-0">*/}
      {/*</ModalHeader>*/}
      <ModalBody className="modal-body">
        <div className="pt-4 pb-0">
          <div className="modal-title px-lg-4" id="exampleModalLabel">
            {
              templateModalState === 0 ?
                <div className="mb-2">
                  {
                    context === 'feedback' && <FeedbackPublishedText />
                  }
                  { !showUpdateTemplate && <h4 className="font-weight-100" style={{color: '#2C7BE5'}}>Save assignment as a template</h4> }
                </div> :
                <>
                  {
                    savedTemplate.visibility === 'public' ?
                      <div className="alert alert-warning">
                        <h4 className="d-flex align-items-center">
                          <img src={CheckMarkNurturePurple} />
                          <div className="ms-2 text-nurture-purple">Pending approval.</div>
                        </h4>

                        <p className="font-weight-100 font-size-16">
                          Our Nurture Experts will review this template and if approved, it will be available
                          on Nurture Space for any teacher to use. We will let you know when it is ready!
                        </p>
                        <p className="font-weight-100 font-size-16">
                          In the meantime, this template is saved to your personal templates.
                        </p>
                      </div> :
                      <div className="d-flex align-items-center">
                        <img src={CheckMarkNurturePurple} />
                        <div className="ms-2 text-nurture-purple">{ templateSavedText }</div>
                      </div>
                  }
                </>
            }
          </div>
        </div>

        {/*Creating a brand new template from an assignment, teacher is shown options for visibility and template name*/}
        {
          templateModalState === 0 && (!showUpdateTemplate) &&
            <TemplateVisibilities context={context} assignmentName={assignmentName} isRubrics={!!assignment?.rubrics} selected={templateFor} onChange={handleChangeVisibility} />
        }

        {/*If there is a source template present, teacher is shown option to create a new template or */}
        {/*update the source template (if the teacher own the source template)*/}
        {
          templateModalState === 0 && showUpdateTemplate &&
            <SourceTemplate sourceAssignment={assignment} onCancel={onClose} onCreate={handleCreateNewFromSourceTemplate} sourceTemplate={sourceTemplate} canEdit={showUpdateTemplate} onUpdate={handleUpdateFeedbackOnTemplate} />
        }


        {/*Template summary after template after been created or updated*/}
        {
          templateModalState !== 0 &&
            <TemplateSummary context={context} saveFeedback={saveFeedback} savedTemplate={savedTemplate} templateFor={templateFor} code={sharableCode} onclose={closeModal} />
        }

      </ModalBody>
      {
        templateModalState === 0 && !showUpdateTemplate &&
          <ModalFooter className="modal-footer">
            <button type="button" onClick={closeModal} disabled={templateCreateLoading} className="btn btn-light text-danger" data-bs-dismiss="modal">Close</button>
            <Button
              type="button"
              onClick={submitModal}
              disabled={saveButtonDisabled || templateCreateLoading}
              withLoader={true}
              loading={templateCreateLoading}
              loaderText='Saving'
              className="btn btn-nurture-purple">
              Save as template
            </Button>
          </ModalFooter>
      }
    </Modal>
  );
};

AssignmentTemplatesModal.defaultProps = {
  context: 'assignment_creation'
}

AssignmentTemplatesModal.propTypes = {
  context: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  templateModalState: PropTypes.number.isRequired,
};

export default AssignmentTemplatesModal;
