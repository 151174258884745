import PropTypes from 'prop-types';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Avatar, Button, Emoji } from 'core/components';
import { dateUtils, userFullName } from 'core/utils';

const AssignmentCardHeader = ({
  className,
  date,
  level,
  onEditButtonClicked,
  showEditButton,
  showEmoji,
  user,
  ...rest
}) => (
  <div className={`d-flex justify-content-between mb-2 ${className}`} {...rest}>
    <div className="d-flex align-items-center">
      <Avatar size="md" img={user?.avatar_url} />
      <div className="d-flex flex-column px-2">
        <div className='d-flex'>
          <h6 className="section-header-title mb-1">
            {userFullName(user)}
          </h6> &nbsp;
          {showEmoji && <small className='fs-6'><Emoji level={level} /></small>}

        </div>
        {date && (
          <small className="text-muted fw-light">
            <FontAwesomeIcon icon={faClock} />&nbsp;
            {dateUtils.fromNow(date)}
          </small>
        )}
      </div>
    </div>

    {showEditButton && (
      <div>
        <Button outline onClick={onEditButtonClicked} size="sm">Edit Submission</Button>
      </div>
    )}
  </div>
);

AssignmentCardHeader.defaultProps = {
  className: undefined,
  onEditButtonClicked: undefined,
  showEditButton: false,
  showEmoji: false,
  level: 3,
};

AssignmentCardHeader.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    avatar_url: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.any.isRequired,
  className: PropTypes.string,
  onEditButtonClicked: PropTypes.func,
  showEditButton: PropTypes.bool,
  showEmoji: PropTypes.bool,
  level: PropTypes.number,
}

export default AssignmentCardHeader;
