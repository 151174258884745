import { Fragment } from "react";
import {
  ShimmerSectionHeader,
  ShimmerSimpleGallery,
  ShimmerThumbnail,
  ShimmerTitle
} from "react-shimmer-effects";
import PropTypes from "prop-types";

const GraphSkeletalLoader = ({ loaded }) => {
  const BaseGraphSkeleton = () => (
    <div className="row">
      <div className='col-lg-9 mb-4'>
        <ShimmerThumbnail height={250} rounded />
        <div className='mt-2 d-flex justify-content-center'>
          <div className="w-50">
            <ShimmerTitle  line={1} variant="primary" />
          </div>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="ms-2">
          <ShimmerSimpleGallery row={1} col={1} imageType="circular" imageHeight={90} />
        </div>
        <div className="ms-2">
          <ShimmerThumbnail height={75} rounded />
        </div>
        <div className="ms-2">
          <ShimmerThumbnail height={75} rounded />
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      {
        loaded ?
          <BaseGraphSkeleton /> :
          <div className='card mb-5 pt-3'>
            <div className='card-header pb-0'>
              <div className='row mb-4 px-3 align-items-center'>
                <div className='d-md-flex col-md-9 align-items-center'>
                  <div className='me-3 avatars'>
                    <ShimmerSimpleGallery row={1} col={1} imageType="circular" imageHeight={90} />
                    <ShimmerSimpleGallery row={1} col={1} imageType="circular" imageHeight={90} />
                  </div>
                  <div className="w-75">
                    <ShimmerSectionHeader  />
                  </div>
                </div>

                <div className='col-md-3'>
                  <ShimmerThumbnail height={50} rounded />
                </div>
              </div>
            </div>
            <div className='card-body ps-3 px-lg-3'>
              <div className='row mt-2 pb-3 px-0 px-sm-4'>
                <BaseGraphSkeleton />
              </div>
            </div>
          </div>
      }

    </Fragment>
  )
};

GraphSkeletalLoader.defaultProps = {
  loaded: false
};

GraphSkeletalLoader.propTypes = {
  loaded: PropTypes.bool
};

export default GraphSkeletalLoader;
