import PropType from 'prop-types';

import avatar from 'assets/images/google_profile.jpg'
import './avatar.scss';

const Avatar = ({ img, size, ...rest }) => (
  <div className={`avatar avatar-${size} align-middle me-2`} {...rest}>
    <img src={img} alt="user avatar" className="avatar-img rounded-circle" />
  </div>
);

Avatar.defaultProps = {
  size: 'xs',
  img: avatar
};

Avatar.propTypes = {
  img: PropType.string,
  size: PropType.oneOf(['xs', 'sm', 'lg', 'xl', 'xxl']),
};

export default Avatar;
