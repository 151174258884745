const useCopyToClipboard = (ref) => {
  const copyToClipboard = async (code) => {
    try {
      await navigator.clipboard.writeText(code);
    } catch (e) {
      if (e.name === 'NotAllowedError') {
        // the microsoft teams app has block some features for cross origin iframe, the clipboard is one of them
        // if this error is raised, we will use the old deprecated approach for copying to clipboard
        if (!!ref) {
          ref.current.select()
          document.execCommand('copy');
        }
      }
      console.log(e) // The console.log here is intentional, since we are only catching a specific type of error
    }
  };

  return copyToClipboard;
};

export default useCopyToClipboard;
