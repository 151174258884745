import React, {useEffect, useState} from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import Button from "core/components/Button";
import Input from "core/form/fields/Input";

const AddColumnModal = ({ show, onAdd, toggle, ...otherProps }) => {
  const [columnValue, setColumnValue] = useState('');
  const [columnPoint, setColumnPoint] = useState(3);

  const handleSave = () => {
    onAdd({ title: columnValue, point: columnPoint });
  }

  useEffect(() => {
    if (!show) setColumnValue('')
  }, [show])

  return (
    <Modal isOpen={show} toggle={toggle} zIndex={21800000000} {...otherProps}>
      <ModalHeader toggle={toggle} charcode="x" tag="h4">Add a column</ModalHeader>
      <ModalBody>
        <Input label="Column name" value={columnValue} onChange={(e) => setColumnValue(e.currentTarget.value)} />
        <br />
        <Input type="number" label="Point" value={columnPoint} onChange={(e) => setColumnPoint(e.currentTarget.value)} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSave}>Add</Button>
      </ModalFooter>
    </Modal>
  );
};


AddColumnModal.defaultProps = {
  toggle: undefined,
};

AddColumnModal.propTypes = {
  onAdd: PropTypes.func.isRequired,
  toggle: PropTypes.func,
  show: PropTypes.bool.isRequired,
}

export default AddColumnModal;
