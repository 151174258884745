
export const studentsHash = (data) => {
  const allStudents = [{ value: 'all', label: 'All Students' }];

  data.forEach(student => {
    const formattedStudent = {
      value: student.id,
      label: `${student.first_name} ${student.last_name}`
    };

    allStudents.push(formattedStudent);
  })

  return allStudents;
};

const splitAvatarUrls = (data, number) => {
  const urlsList = data.map(student => student.avatar_url);

  const firstNItems = urlsList.slice(0, number);
  const remainingItemsCount = urlsList.length - firstNItems.length;

  return {
    firstNItems,
    remainingItemsCount,
  };
};

export const avatarUrls = (data, number) => splitAvatarUrls(data, number).firstNItems;

export const remainingItemsCount = (data, number) => splitAvatarUrls(data, number).remainingItemsCount;

export const classroomUsersFirstNames = (data, length) => {
  const slicedList = data.slice(0, length);

  const firstNames = slicedList.map(student => student.first_name);
  const extrasCount = data.length - slicedList.length;

  return { 
    firstNames: firstNames.join(', '),
    extrasCount,
  };
};
