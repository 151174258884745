import PropTypes from "prop-types";
import { isPresent } from "core/utils";
import { AssignmentSubcard, Autolinker, Header as SectionHeader, SectionTitle } from "core/components";
import TextHeader from "core/components/TextHeader";
import Grade from "../Grade";
import Reviewed from "./Reviewed";
import { emojiResponses } from "../constants";

const StudentReflection = ({
  assignment,
  feedbackPublished,
  graded,
  gradeType,
  maxMarks,
  onSuccess,
  score,
  studentReflection,
  userAssignment,
  hideReflection
}) => {
  const {
    id,
    reflection_submitted_at: reflectionSubmittedAt,
    status,
    student_reflection: userAssignmentStudentReflection,
    student_reflection_completed: studentReflectionCompleted,
    student_submission: studentSubmission,
    triple_response_questions: tripleResponseQuestions,
    user,
  } = userAssignment;

  const {
    emoji_response: emojiResponse,
    student_feedback_reflection_comment: studentFeedbackReflectionComment,
  } = userAssignmentStudentReflection;

  const isEmojiResponse = studentReflection === "emoji_response";
  const isTripleResponse = studentReflection === "triple_response";
  const isResponseDisabled = studentReflection === 'disabled';

  let showGrades = false;
  let showResponseForm = false;

  if (isResponseDisabled || studentReflectionCompleted) {
    showGrades = true;
  } else if (status === "reviewed" && feedbackPublished && !studentReflectionCompleted) {
    showResponseForm = true;
  }

  return (
    hideReflection ? null :
      <>
        {showGrades && (
          <>
            {graded && (
              <Grade
                maxMarks={maxMarks}
                score={score}
                gradeType={gradeType}
                assignment={assignment}
              />
            )}

            <div className="mb-0">
              <SectionHeader
                title="Your Reflection"
                subtitle="Your reflection on the assessment feedback."
              />
            </div>
            {studentReflectionCompleted && (
              <AssignmentSubcard
                user={user}
                date={reflectionSubmittedAt}
                level={studentSubmission?.confidence?.level}
                childrenWithHeader
                showEmoji={emojiResponse && studentSubmission?.confidence?.level}
              >
                {(isEmojiResponse && emojiResponse) && (
                  <div className="card-body">
                    You were <b>{emojiResponses[emojiResponse].join(' ')}</b> with the feedback.

                    {isPresent(studentFeedbackReflectionComment) && (
                      <div className="d-block mt-2 comment-body">
                        <p className="comment-text">{studentFeedbackReflectionComment}</p>
                      </div>
                    )}
                  </div>
                )}

                {isTripleResponse && (
                  Object.keys(tripleResponseQuestions).reverse().map((key, index) => {
                    const value = tripleResponseQuestions[key];
                    return (
                      <div key={index + 1}>
                        <SectionTitle title={value['question']} />
                        <Autolinker className="mb-3" content={userAssignmentStudentReflection[key]} />
                      </div>
                    );
                  })
                )
                }
                {
                  isTripleResponse && isPresent(studentFeedbackReflectionComment) &&
                  <>
                    <TextHeader className="font-weight-600">Additional Comments</TextHeader>
                    <Autolinker className="font-size-14 font-weight-100" content={studentFeedbackReflectionComment} />
                  </>
                }
              </AssignmentSubcard>
            )}
          </>
        )}

        {showResponseForm && (
          <Reviewed
            assignmentId={assignment.id}
            id={id}
            graded={graded}
            onSuccess={onSuccess}
            studentReflection={studentReflection}
            tripleQuestions={tripleResponseQuestions}
          />
        )}
      </>
  );
};

StudentReflection.defaultProps = {
  maxMarks: undefined,
  score: undefined,
};

StudentReflection.propTypes = {
  feedbackPublished: PropTypes.bool.isRequired,
  graded: PropTypes.bool.isRequired,
  gradeType: PropTypes.string.isRequired,
  hideReflection: PropTypes.bool.isRequired,
  maxMarks: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  score: PropTypes.number,
  studentReflection: PropTypes.string.isRequired,
  userAssignment: PropTypes.shape({}).isRequired,
};

export default StudentReflection;
