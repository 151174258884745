import { createRequest, composeRequestConfig } from 'core/utils/axios/api';
import { teamsEnv } from 'core/utils';

export const suggestComment = payload => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/ai/suggest_comment`;

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
};

export const generateAssignment = payload => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/ai/generate_assignment`;

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
};

export const generateRubrics = payload => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/ai/generate_rubrics`;

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
};

export const continueConversation = payload => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/ai/continue_conversation`;

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
};

export const aiFeedback = payload => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/ai/feedback`;

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
};
