import { Switch } from "core/form/fields";
import { isEmpty } from "core/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useSchoolConfig } from "core/hooks";
import PropTypes from "prop-types";

const CurriculumBoundarySwitch = ({ checked, labelClass, id, onCurriculumBoundarySwitchChanged }) => {
  const { channelOnboarding } = useSchoolConfig();
  const curriculumAttachments = channelOnboarding?.data?.curriculum_attachments
  // const embeddings_completed = channelOnboarding?.data?.embeddings_completed;
  const embeddings_completed = true;

  let text = 'Uses knowledge from the uploaded curriculum document.'
  if (isEmpty(curriculumAttachments)) {
    text = 'Upload your curriculum document in the classroom onboarding for Nurture AI to learn your curriculum.'
  } else if (!embeddings_completed) {
    text = 'Processing… Nurture AI is learning your curriculum. You can enable curriculum boundary in a few mins.'
  }

  return (
    <div className="mb-3 mt-4">
      <div className="form-check form-switch ps-3">
        <Switch
          disabled={isEmpty(curriculumAttachments) || !embeddings_completed}
          id={id}
          labelClass={labelClass}
          label={'Enable Curriculum Boundary'}
          checked={checked}
          value={5}
          onClick={onCurriculumBoundarySwitchChanged}
        />
      </div>
      <div className="alert alert-light p-2 mt-2 border-none">
        <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
        <small>{text}</small>
      </div>
    </div>
  );
};

CurriculumBoundarySwitch.defaultProps = {
  id: 'curriculum-boundary',
  labelClass: ''
}

CurriculumBoundarySwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onCurriculumBoundarySwitchChanged: PropTypes.func.isRequired,
  id: PropTypes.string,
  labelClass: PropTypes.string,
}

export default CurriculumBoundarySwitch;
