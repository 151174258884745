import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";

const CardWithAccordion = ({ id, title, defaultState, children }) => {
  const [accordionIconActive, setAccordionIconActive] = useState(false);
  const accordionBodyRef = useRef();


  const toggleAccordionIconState = () => setAccordionIconActive(!accordionIconActive)

  useEffect(() => {
    const classToAdd = {open: 'show', closed: 'collapse'}
    const classToRemove = {open: 'collapse', closed: 'show'}

    accordionBodyRef.current.classList.add(classToAdd[defaultState] || '')
    accordionBodyRef.current.classList.remove(classToRemove[defaultState] || '')
  }, []);

  return (
    <div className="accordion mb-4">
      <div className="accordion-item">
        <div className="accordion-header px-3 px-sm-4 py-4 border-bottom">
          <div className="d-flex align-items-center cursor-pointer" data-bs-toggle="collapse" data-bs-target={`#${id}`}
               aria-controls={id} onClick={toggleAccordionIconState}>
            <h5 className="text-nurture-purple">
              {title}
            </h5>
            <div className="flex-grow-1 text-end">
              <button className="bg-transparent text-muted border-none" type="button" >
                <FontAwesomeIcon icon={accordionIconActive ? faAngleDown : faAngleRight} />
              </button>
            </div>
          </div>
        </div>
        <div id={id} ref={accordionBodyRef} className="accordion-collapse" aria-labelledby="headingOne"
             data-bs-parent="#accordionExample">
          <div className="accordion-body p-0 p-sm-3">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

CardWithAccordion.defaultProps = {
  defaultState: 'open'
};

CardWithAccordion.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  defaultState: PropTypes.oneOf(['open', 'closed']),
};

export default CardWithAccordion;