import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as attachmentsSelector from "modules/attachments/selectors";
import * as assignmentSelector from "modules/assignments/selectors";
import AssignmentForm from "./form/Form";
import React, {useEffect, useState} from "react";
import { isEmpty } from "core/utils";
import { attachmentFilestack } from "../attachments/actions";
import {assignmentShowSuccess, getAssignment} from "./action";
import {useSnackbar} from "notistack";
import AssignmentError from "./AssignmentError";
import AssignmentNewSkeletalLoader from "./skeletalLoaders/AssignmentNewSkeletalLoader";

const AssignmentsEdit = () => {

  const { assignmentId } = useParams();
  const dispatch = useDispatch();
  const filestackCredentials = useSelector(attachmentsSelector.filestackCredentials);
  const assignment = useSelector(assignmentSelector.getAssignmentById(assignmentId));
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const fetchAssignment = async () => {
    try {
      const necessaryFields = [
        'assignment_objectives', 'attachments', 'feedback_completed', 'feedback_published_at', 'feedback_type',
        'grade_display_to_students', 'graded', 'max_marks', 'publish_at', 'show_assignment_objectives',
        'student_reflection', 'students', 'teacher_status'
      ]

      const refetchData = !assignment || necessaryFields.some((field, index) => !(field in assignment));

      if (refetchData) {
        setLoading(true)
        await dispatch(getAssignment(assignmentId))
      }

      setError(null)
    } catch (e) {
      setError(e.message)
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isEmpty(filestackCredentials)) dispatch(attachmentFilestack())
    // eslint-disable-next-line react-hooks/exhaustive-deps

    fetchAssignment();
  }, []);

  const handleUpdate = (assignment) => dispatch(assignmentShowSuccess(assignment));

  return (
    <>
      {
        loading ?
          <AssignmentNewSkeletalLoader /> :
          error ?
            <AssignmentError errorMessage={error} /> :
            <AssignmentForm assignment={assignment} onUpdate={handleUpdate}  />
      }
    </>
  )
}

export default AssignmentsEdit;