import { useState } from "react";
import FilterDropdown from "./FilterDropdown";
import { templatesFilterDropdown } from "./constants";
import SearchIcon from "assets/images/general_icons/search.svg";

const AssignmentTemplateFilters = ({ onFilter }) => {
  const [query, setQuery] = useState({});

  const map = {
    'All Templates': '',
    'School Templates': 'org',
    'My Templates': 'private',
    'Nurture Spaces': 'public'
  };

  const handleTemplateVisibiltyChanged = visibility => {
    const newState = {...query, visibility: map[visibility]}
    setQuery(newState);
    onFilter(newState);
  };
  const handleTemplateSearchChanged = (event) => {
    if (
      (event.type === 'keypress' && event.key === 'Enter') ||
      event.type === 'blur'
    ) {
      const newState = {...query,search: event.target.value}
      setQuery(newState);
      onFilter(newState);
    }
  };

  return (
    <div>
      <div className="row mx-0 px-0 mb-5">
        <div className="col-md-4 ps-0">
          <FilterDropdown id="template-filter" items={templatesFilterDropdown} onChange={handleTemplateVisibiltyChanged} />
        </div>
        <div className="col-md-8 ps-0 ps-md-2">
          <div className="input-group">
            <img src={SearchIcon} className="input-group-text bg-white border-none" />
            <input
              type="text"
              className="form-control border-none search-filter"
              placeholder="Search templates by name or code"
              aria-label="template"
              aria-describedby="basic-addon1"
              onKeyPress={handleTemplateSearchChanged}
              onBlur={handleTemplateSearchChanged}
            />
          </div>
        </div>
      </div>
      {/*<div className="d-flex justify-content-end">*/}
      {/*  <div className="me-2 font-size-16 pt-2">Sort By</div>*/}
      {/*  <div className="sort-filter">*/}
      {/*    <FilterDropdown id="sorting-filter" items={sortFilterDropdown} />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default AssignmentTemplateFilters;