import PropTypes from 'prop-types';
import React from "react";

const displayObjectives = objectives => (
  objectives.map((objective, index) => (
    <div key={objective.id} className='row mt-2'>
      <div className='col-auto'>
        <div className='avatar avatar-xs'>
          <div className='avatar-title font-size-xs rounded-circle bg-secondary-soft text-secondary'>{index + 1}</div>
        </div>
      </div>
      <div className='col p-0'>{objective.name}</div>
    </div>
  ))
);

const ObjectivesWithBackGround = (objectives) => {
  return objectives?.map((objective, index) => {
    return (
      <div key={index} className='d-flex align-items-center mt-2 mb-3 px-3 py-3 rounded'
           style={{backgroundColor: '#E4EBF6'}}>
        <div className='me-3'>
          <div className='avatar avatar-sm'>
            <div className='avatar-title font-size-md rounded-circle bg-secondary'>{index + 1}</div>
          </div>
        </div>
        <div className='col p-0 font-size-16'>{objective.name}</div>
      </div>
    )
  })
}

const LearningObjectives = ({ items, sectionTitle, withBackground }) => {
  return (
    <>
      {
        withBackground ?
          <>{ObjectivesWithBackGround(items)}</> :
          <div className='px-3 my-2'>
            <span className='section-header-title pt-3 fs-6'>{sectionTitle}</span>
            {displayObjectives(items)}
          </div>
      }
    </>
  )
};

LearningObjectives.defaultProps = {
  withBackground: false
}

LearningObjectives.propTypes = {
  items: PropTypes.array.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  withBackground: PropTypes.string,
};

export default LearningObjectives;
