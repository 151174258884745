import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers';

const store = () => {
  const storeObj = configureStore({
    reducer: rootReducer,
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => storeObj.replaceReducer(rootReducer));
  }

  return storeObj;
}

export default store;
