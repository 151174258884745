import { closeEditor, updateCellValue } from 'ka-table/actionCreators';
import {useEffect, useState} from "react";
import { useSnackbar } from "notistack";

const CustomEditor = ({column, rowKeyValue, dispatch, value}) => {
  const [editorValue, setValue] = useState(value);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const newValue = e.currentTarget.value

    setValue(newValue)
    if (!newValue) {
      enqueueSnackbar('This field cannot be empty', { variant: 'error' })
      return
    }
    dispatch(updateCellValue(rowKeyValue, column.key, newValue));
  }

  useEffect(() => {
    setValue(value)
  }, [value]);

  return (
    <div className='custom-editor'>
      <textarea
        className='form-control'
        style={{resize: 'none'}}
        rows="4"
        value={editorValue}
        onChange={handleChange} />
    </div>
  );
};

export default CustomEditor;