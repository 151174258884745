export const assignmentObjectiveData = {

}

export const FIELDS = [
  {
    name: 'action_feedback',
    label: "How did the students do and how was their process",
    hint: "Describe how well or badly the students have completed this goal. How did the students approach this and how could they improve their process.",
    ai_hint: "student_performance"
  },
  // {
  //   name: 'process_feedback',
  //   label: "How was their process?",
  //   hint: "How did the students approach this and how could they improve their process."
  // },
  {
    name: 'next_steps',
    label: "What are the next steps?",
    hint: "List actions the students can take to help them improve their performance.",
    ai_hint: "next_steps"
  }
]

export const FEEDBACK_PRESETS = {
  action_feedback: [
    'Of these two options/methods, which one do you think is better suited to this question? ... or ...',
    'Remember to back up your views with evidence from …',
    'What made you decide to do it that way?',
    'Describe something that would happen when ...',
    'It’s good to see that you remembered to …',
    'Explain to me why you think this is the most efficient solution/method?',
    'I’m impressed with the effort you’re making…',
    'Can you explain why this might not always or will always be the case?',
    'Say more about how ...',
    'I have high expectations and I know that you can reach them :)'
  ],
  process_feedback: [
    'Remember the intention of this assessment was ...',
    'Tell us more. What could you add to this answer? ',
    'Explain why you think this.',
    'Try one of these instead of ... ',
    'You have clearly stated one way correctly. Are there any others?',
    "I know you're able to improve this further by adding ...",
    'How do you know…?',
    'You gave a very accurate description of ... To improve your work, you need to include all the key terms. Look back and check the list to see which ones you have left out.',
    'You understood the goal of the assessment well - what do you think could improve your answer further?',
    'What do you think made this such a good answer?'
  ],
  next_steps: [
    "Here's the key thing that you misunderstood ... ",
    "Here's something to think about ... I know you're good enough to do it :)",
    "I have high expectations and I know that you can reach them :) What is the key thing you could improve on?"
  ]
}
