import React, { useEffect, useState } from 'react';
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { BackButton, Header, PermissionAlert } from "core/components";
import { isEmpty } from "core/utils";
import { getAssignment } from "./action";
import SummaryCard from "./summaryCard/SummaryCard";
import TheAssignment from "./theAssignment/TheAssignment";
import Submissions from "./submissions/Submissions";
import Feedbacks from "./feedback/Feedbacks";
import { attachmentFilestack } from "modules/attachments/actions";
import * as assignmentSelector from "../assignments/selectors";
import * as attachmentsSelector from "../attachments/selectors";
import AssignmentSkeletalLoader from "./skeletalLoaders/AssignmentSkeletalLoader";
import AssignmentError from "./AssignmentError";
import { linkOptions, textOptions } from "../assignmentTemplates/constants";
import {TABS} from "./summaryCard/AssessmentSubmissionTab";
// import LearningObjectives from "./objectives/LearningObjectives";

const TeacherShow = () => {
  const [tab, setTab] = useState(TABS.SUBMISSIONS);
  // The template here means that while creating this assessment, we created a template off it
  const [template, setTemplate] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);


  const { assignmentId } = useParams();
  const dispatch = useDispatch();
  const filestackCredentials = useSelector(attachmentsSelector.filestackCredentials);

  const assignment = useSelector(assignmentSelector.getAssignmentById(assignmentId));
  // const assignmentsError = useSelector(assignmentSelector.error);
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();

  // const isDraftAssignment = assignment?.status === 'draft';
  // const assignToStudentText = isDraftAssignment ?
  //   'You cannot assign students to assignments that are in draft' :
  //   'Give this assessment to students in your classroom.';

  useEffect(() => {
    fetchAssignment();
    if (isEmpty(filestackCredentials)) dispatch(attachmentFilestack())
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const templateFor = searchParams.get('templateFor')
    if (templateFor) setTemplate(templateFor)
  }, [])

  const fetchAssignment = async () => {
    try {
      const necessaryFields = [
        'assignment_objectives', 'attachments', 'feedback_completed', 'feedback_published_at', 'feedback_type',
        'grade_display_to_students', 'graded', 'max_marks', 'publish_at', 'show_assignment_objectives',
        'student_reflection', 'students', 'teacher_status'
      ]

      const refetchData = !assignment || necessaryFields.some((field, index) => !(field in assignment));

      if (refetchData || searchParams.get('refresh')) {
        setLoading(true)
        await dispatch(getAssignment(assignmentId))
      }

      setError(null)
    } catch (e) {
      setError(e.message)
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const AlertText = () => {
    if (Object.keys(textOptions).includes(template)) {
      return (
        <div className="alert alert-info" role="alert">
          {textOptions[template]}
          <NavLink to="/tab?activeTab=assignments_templates"
            className="alert-link ms-2 font-weight-100">{linkOptions[template]}
          </NavLink>
        </div>
      );
    }

    return null;
  }

  return (
    <>
      {
        loading ?
          <AssignmentSkeletalLoader /> :
          error ?
            <AssignmentError errorMessage={error} /> :
            <div className="container">
              <PermissionAlert />
              <AlertText />
              <BackButton />
              {assignment && (
                <>
                  <div className="mb-5">
                    <SummaryCard assignment={assignment} tab={tab} onChangeTab={newTab => setTab(newTab)} />
                  </div>
                  {tab === TABS.SUBMISSIONS && (
                      <>
                        <Submissions assignment={assignment} />
                        <Feedbacks assignment={assignment} />
                      </>
                    )
                  }

                  {tab === TABS.ASSESSMENT &&
                    <TheAssignment assignment={assignment} isTeacher={true} />
                  }
                </>
              )}
            </div>
      }
    </>
  );
};

export default TeacherShow;
