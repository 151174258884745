export const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
export const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

export const toggleLabel = (ctx, event) =>  {
  const hovered = ctx.hovered;
  ctx.hovered = !hovered;

  if (hovered) {
    ctx.hoverPosition = (event.x / ctx.chart.chartArea.width * 100) + '%';
  }
  ctx.chart.update();
};
