import * as api from './api';
import * as t from './actionTypes';
import { handleApiError } from "core/utils";
import { ASSIGNMENT_LOAD_SUCCESS } from "../assignments/actionTypes";

export const allAssignmentTemplatesStart = () => ({
  type: t.ASSIGNMENT_TEMPLATES_LOAD_START,
});

export const allAssignmentTemplatesSuccess = (assignmentTemplates, meta) => ({
  type: t.ASSIGNMENT_TEMPLATES_LOAD_SUCCESS,
  assignmentTemplates,
  meta
});

export const allAssignmentTemplatesError = error => ({
  type: t.ASSIGNMENT_TEMPLATES_LOAD_ERROR,
  error,
});

export const createAssignmentTemplateSuccess = assignmentTemplate => ({
  type: t.ASSIGNMENT_TEMPLATES_CREATE_SUCCESS,
  assignmentTemplate
});

export const deleteAssignmentTemplateSuccess = assignmentTemplateId => ({
  type: t.ASSIGNMENT_TEMPLATES_DELETE_SUCCESS,
  assignmentTemplateId,
});

export const useAssignmentTemplateSuccess = assignment => ({
  type: ASSIGNMENT_LOAD_SUCCESS,
  assignment,
});

export const allAssignmentTemplates = (query = {}) => async dispatch => {
  if (!query.page) dispatch(allAssignmentTemplatesStart());

  try {
    const response = await api.getAssignmentTemplates(query);

    dispatch(allAssignmentTemplatesSuccess(response.data, response.meta));
    return response.data;
  } catch (error) {
    dispatch(allAssignmentTemplatesError(error?.message || 'Something went wrong'))
    return Promise.reject(handleApiError(error));
  }
};

export const createAssignmentTemplate = (query = {}) => async dispatch => {
  try {
    const response = await api.createAssignmentTemplate(query);

    dispatch(createAssignmentTemplateSuccess(response.data));
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};

export const showAssignmentTemplate = (assignmentTemplateId) => async dispatch => {
  try {
    const { data } = await api.showAssignmentTemplate(assignmentTemplateId);

    return data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};

export const updateAssignmentTemplate = (assignmentTemplateId, payload) => async dispatch => {
  try {
    const { data } = await api.updateAssignmentTemplate(assignmentTemplateId, payload);

    return data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};

export const deleteAssignmentTemplate = (assignmentTemplateId) => async dispatch => {
  try {
    await api.deleteAssignmentTemplate(assignmentTemplateId);

    dispatch(deleteAssignmentTemplateSuccess(assignmentTemplateId));
    return true;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};

export const createAssignmentFromAssignmentTemplate = (id) => async dispatch => {
  try {
    const response = await api.createAssignmentFromAssignmentTemplate(id);

    dispatch(useAssignmentTemplateSuccess(response.data));
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};

export const updateAssignmentTemplateFeedback = (id, payload) => async dispatch => {
  try {
    const response = await api.updateAssignmentTemplateFeedback(id, payload);

    dispatch(useAssignmentTemplateSuccess(response.data));
    return response.data;
  } catch (error) {
    console.log(error)
    return Promise.reject(handleApiError(error));
  }
};

