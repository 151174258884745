import React from "react";
import './assignmentBadge.scss'
import PropTypes from "prop-types";

const AssignmentBadge = ({ container = 'div', className, children, ...rest  }) => {

  const classNames = `Badge ${className}`
  const Container = () => React.createElement(container, {...rest, className: classNames}, children)

  return (
    <Container />
  );
};

AssignmentBadge.defaultProps = {
  container: 'div',
  className: '',
  children: null
};

AssignmentBadge.propTypes = {
  container: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

export default AssignmentBadge;
