import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './header.scss';
import { useSchoolConfig } from "../../core/hooks";
import {useNavigate} from "react-router-dom";
import HeaderNew from "./HeaderNew/HeaderNew";

const HeaderWeb = ({ name, role, activeTab, onTabChanged }) => {
  const displayName = name ? `, ${name}!` : '!';
  const navigateTo = useNavigate();


  const newAssignment = () => navigateTo('/assignments/new');

  return (
    <div className="container mb-5">
      <div className="pb-3 mb-4">
        <div className='fs-3 small-screen-text d-flex align-items-center'>
          <div className='me-2'>👋 Hello{displayName}</div>
        </div>
      </div>

      <div className=''>
        <HeaderNew activeTab={activeTab} onTabChange={onTabChanged} onNewAssignment={newAssignment} />
      </div>
    </div>
  );
};

HeaderWeb.defaultProps = {
  name: undefined,
  role: undefined,
  activeTab: '',
  onTabChanged: undefined,
};

HeaderWeb.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  activeTab: PropTypes.string,
  onTabChanged: PropTypes.func,
};

export default HeaderWeb;
