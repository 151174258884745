import {
  ShimmerButton,
  ShimmerCategoryItem,
  ShimmerPostItem,
  ShimmerSectionHeader,
  ShimmerSocialPost,
  ShimmerThumbnail,
  ShimmerTitle
} from "react-shimmer-effects";

const AssignmentSkeletalLoader = () => {
  return (
    <div className="container">
      <ShimmerThumbnail className="mb-5 shimmer-card" height={100} rounded />
        <div className="row">
          <div className="col-lg-8">
            <div className="mb-5 pb-5">
              <ShimmerSectionHeader className="shimmer-card" line={1} variant={"primary"} />
              <ShimmerSocialPost className="shadow-sm" type="both" />
            </div>

            <div className="mb-5 pb-5">
              <ShimmerSectionHeader className="shimmer-card" line={1} variant={"primary"} />
              <div className="row">
                <div className="col-lg-6">
                  <ShimmerPostItem card title cta imageType="circular" imageWidth={60} imageHeight={60} contentCenter />
                </div>
                <div className="col-lg-6">
                  <ShimmerPostItem card title cta imageType="circular" imageWidth={60} imageHeight={60} contentCenter />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <ShimmerSectionHeader className="shimmer-card" line={1} variant={"primary"} />
            <div className="card">
              <div className="card-body">
                <ShimmerTitle line={1} variant="primary" />
                <ShimmerCategoryItem hasImage imageType="circular" imageWidth={50} imageHeight={50} title />
              </div>
              <div className="card-footer">
                <div className="d-flex justify-content-end">
                  <div className="me-2">
                    <ShimmerButton size="sm" />
                  </div>
                  <ShimmerButton size="sm" />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default AssignmentSkeletalLoader;
