const displayConfidence = confidence => {


  if (confidence <= 20) return '(😓 Low)';

  if (confidence <= 60) return '(😊 Neutral)';


  return '(😎 High)';
};

export default displayConfidence;
