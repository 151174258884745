import {Autolinker, Avatar, Button} from "core/components";
import TextHeader from "core/components/TextHeader";
import { isEmpty, isPresent, userFullName } from "core/utils";
import TimeFromNow from "core/components/TimeFromNow";
import Attachment from "modules/attachments/Attachment";
import EmptyFeedback from "./EmptyFeedback";
import PropTypes from "prop-types";
import { useSchoolConfig } from "core/hooks";
import GroupFeedBackObjectives from "./GroupFeedbackObjectives";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import DownloadFeedbackButton from "./DownloadFeedbackButton";

const Feedback = ({ isTeacher, assignment, submission, onEdit }) => {
  const { learningObjectiveLabel } = useSchoolConfig();

  const isEmptyFeedback = isEmpty(submission?.teacher_feedback?.feedback_objectives) &&
    isEmpty(submission?.teacher_feedback?.personal_feedback) &&
    isEmpty(submission?.teacher_feedback?.personal_feedback_attachments)


  const showObjectives = assignment?.feedback_type === 'individual_feedback' &&
    !!assignment?.show_assignment_objectives &&
    !!submission?.teacher_feedback?.feedback_objectives?.length

  const FeedbackAlert = () => {
    if (isEmptyFeedback && isPresent(submission.submitted_at)) {
      return <div className="alert alert-light">
        You don't need any feedback for this assessment. Well done! 🎉
      </div>
    } else if (isEmptyFeedback) {
      return <div className="alert alert-light">
        You don't have any feedback for this assessment.
      </div>
    }

    return null
  }

  return (
    <>
      {
        isPresent(assignment?.feedback_published_at) ?
          <div className="card py-3">
            <div className="card-body pb-0">
              <div className="row align-items-center">
                <div className="col-auto">
                  <Avatar img={assignment?.user?.avatar_url} size="md" />
                </div>
                <div className="col-auto ms-n2">
                  <TextHeader>{userFullName(assignment?.user)}</TextHeader>
                  {
                    isPresent(assignment?.feedback_published_at) &&
                    <TimeFromNow time={assignment?.feedback_published_at} />
                  }
                </div>
                {
                  isTeacher &&
                    <div className="col-auto flex-grow-1 d-flex justify-content-sm-end mt-3 mt-sm-0">
                      <DownloadFeedbackButton submission={submission} assignment={assignment} showButton={true} />
                      <Button className="btn-sm ms-2" outline onClick={onEdit}>
                        <FontAwesomeIcon icon={faEdit} className="me-1" />
                        Edit
                      </Button>
                    </div>
                }
              </div>
            </div>
            <div className="card-body pb-0">
              <FeedbackAlert />
              <Autolinker content={submission?.teacher_feedback?.personal_feedback} />
              {
                showObjectives &&
                <>
                  <TextHeader className="fw-bold">{learningObjectiveLabel} that need attention</TextHeader>
                  <ul className="mb-4">
                    {
                      submission?.teacher_feedback?.feedback_objectives.map((objective, index) => (
                        <li key={index}>{objective.name}</li>
                      ))
                    }
                  </ul>
                </>
              }
              {
                submission?.teacher_feedback?.personal_feedback_attachments?.map((attachment, index) => (
                  <Attachment attachment={attachment} allowDelete={false} key={index} />
                ))
              }
              {
                assignment?.feedback_type === 'group_feedback' &&
                  <GroupFeedBackObjectives objectives={submission?.teacher_feedback?.feedback_objectives} isTeacher={true} />
              }
            </div>
          </div> :
          <EmptyFeedback />
      }
    </>
  );
}

Feedback.defaultProps = {
  isTeacher: true,
  onEdit: () => {}
};

Feedback.propTypes = {
  isTeacher: PropTypes.bool,
  assignment: PropTypes.shape({}).isRequired,
  submission: PropTypes.shape({}).isRequired,
  onEdit: PropTypes.func,
};

export default Feedback;