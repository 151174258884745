/**
 * This component is based off the IonRangeSlider
 * https://www.npmjs.com/package/react-ion-slider
 **/

import IonRangeSlider from "react-ion-slider";
import PropTypes from "prop-types";

const RangeSlider = ({min, max, step, grid, grid_num, from, skin, postfix, ...rest}) => {
  return (
    <IonRangeSlider
      min={min}
      max={max}
      step={step}
      grid={grid}
      grid_num={grid_num}
      postfix={postfix}
      from={from}
      skin={skin}
      {...rest}
    />
  );
};

RangeSlider.defaultProps = {
  min: 1,
  max: 10,
  step: 1,
  grid: true,
  grid_num: 9,
  from: 6,
  skin: 'round'
}

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  grid: PropTypes.bool,
  grid_num: PropTypes.number,
  postfix: PropTypes.string,
  from: PropTypes.number,
  skin: PropTypes.oneOf(['round', 'flat'])
};

export default RangeSlider;