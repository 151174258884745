import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {useForm} from 'react-hook-form';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useLocation, useNavigate} from "react-router-dom";

import {Avatar, Button} from 'core/components';

import './cardTable.scss';
import { useDispatch } from "react-redux";
import { assignmentAssign, assignmentShowSuccess } from "../action";
import { isPresent } from "core/utils";
import AssignmentBadge from "core/components/assignmentBadge/AssignmentBadge";
import * as api from "../api";
import orderBy from "lodash/orderBy";

const StudentsTable = ({ assignment, showButtons, onAssign, checkAll }) => {
  const [studentsList, setStudentsList] = useState([]);
  const [assignButtonText, setAssignButtonText] = useState('Assign')
  const [unAssignButtonText, setUnAssignButtonText] = useState('Unassign')
  const [disabled, setDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const navigateTo = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isAssigned = (student) => student.status === 'assigned'

  const isStudentSelected = studentsList.filter(student => student.isChecked).length > 0

  useEffect(() => {
    if (isPresent(assignment?.students)) {
      const students = [];

      for (const id in assignment.students) {
        students.push({ userId: id, displayName: assignment.students[id].name, status: assignment.students[id].status })
      }

      const studentsCloned = [...students].map(student => {
        return {...student, isChecked: true}
      });

      setStudentsList(() => sortAlphabetically(studentsCloned));
    }
  }, [assignment]);

  useEffect(() => {
    setDisabled(!isStudentSelected) // Disable buttons if no student is selected
    if (typeof onAssign === 'function') {
      onAssign(selectedFormData())
    }
  }, [studentsList]);

  useEffect(() => {
    setStudentsList(prevState => sortAlphabetically(prevState.map(student => ({ ...student, isChecked: checkAll }))));
  }, [checkAll])

  const { handleSubmit, register } = useForm({});
  const selectedFormData = () => studentsList.filter(student =>
    student.isChecked).map(student => student.userId);

  const handleAssign = () => {
    setAssignButtonText('Assigning...');
    setDisabled(true);
    sendRequest('assign').finally(() => {
      setAssignButtonText('Assign');
      setDisabled(!isStudentSelected);
    });
  };

  const handleUnassign = () => {
    setUnAssignButtonText('Unassigning...');
    setDisabled(true);
    sendRequest('unassign').finally(() => {
      setUnAssignButtonText('Unassign');
      setDisabled(!isStudentSelected);
    });
  };

  const sendRequest = async activity => {
    try {
      const teams_user_ids = selectedFormData();
      const payload = {activity: activity, teams_user_ids: teams_user_ids}

      await dispatch(assignmentAssign(assignment.id, payload))
      if (pathname === '/assignments/new' || pathname.split('/').pop() === 'edit') {
        navigateTo(`/assignments/${assignment.id}?refresh=true&templateFor=${assignment.templateFor || ''}`)
      } else {
        const studentsListClone = [...studentsList];

        /**
         * When the teacher assigns to student on the assessment show page, there are some changes that occur on the
         * assignment on the backend, so update the assessment in the store to match the
         * assignment state in the backend
         */
        const { data: updatedAssignment } = await api.getAssignment(assignment.id);
        dispatch(assignmentShowSuccess(updatedAssignment));
        setStudentsList(oldState => sortAlphabetically(updatedStudents(studentsListClone, teams_user_ids, activity)));

        enqueueSnackbar(`Students were ${activity}ed`, { variant: 'success' })
      }
      return Promise.resolve(true)
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const updatedStudents = (studentList, studentChanged, activity) => {
    return studentList.map(student => (
      studentChanged.includes(student.userId) ?
        {...student, status: `${activity}ed`} :
        student
      )
    )}

  const handleClickAll = (event) => {
    setStudentsList(() => sortAlphabetically(studentsList.map(student => ({ ...student, isChecked: event.target.checked }))));
  };

  const handleCheck = (event, index) => {
    studentsList[index].isChecked = event.target.checked;
    setStudentsList(sortAlphabetically([...studentsList]));
  };

  const handleStudentSearch = (e) => {
    const studentName = e.target.value
    let filteredStudents = [];

    for (const id in (assignment?.students || {})) {
      filteredStudents.push({ userId: id, displayName: assignment.students[id].name, status: assignment.students[id].status })
    }

    if (!!studentName) {
      filteredStudents = [...filteredStudents].filter(student => student.displayName.toLowerCase().includes(studentName.toLowerCase()));
    }

    filteredStudents = [...filteredStudents].map(student => {
      return {...student, isChecked: true}
    });

    setStudentsList(prevState => sortAlphabetically(filteredStudents));
  };

  const sortAlphabetically = (arr) => {
    return orderBy(arr, ['displayName'], ['asc'])
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleAssign)}>
        <div className="card">
          <div className="card-header">
            <div className="input-group input-group-flush">
              <span className="input-group-text search" id="search">
                <FontAwesomeIcon icon={faSearch} />
              </span>
              <input
                type="search"
                className="form-control search"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search"
                onChange={handleStudentSearch} />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-sm table-hover table-nowrap card-table">
              {
                showButtons &&
                <thead>
                <tr>
                  <th className="p-3">
                    <div className="form-check font-size-16">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onClick={handleClickAll}
                             defaultChecked
                             {...register('checkAll')} />
                    </div>
                  </th>
                  <th className="text-muted"><b>Name</b></th>
                </tr>
                </thead>
              }

              <tbody className="list">
                {studentsList.map((student, index) => (
                  <tr key={student.userId}>
                    <td className="p-3">
                      <div className="form-check font-size-16">
                        <input className="form-check-input" type="checkbox" value={student.userId} checked={student.isChecked}
                               onClick={(event) => handleCheck(event, index)}
                               {...register('teams_user_id')} />
                      </div>
                    </td>

                    <td className="pt-3 pb-3 student-name">
                      <Avatar />
                      <span className="text-reset">{student.displayName}</span>
                      {
                        isAssigned(student) &&
                        <AssignmentBadge container="span" className="badge-soft-primary ms-2">Assigned</AssignmentBadge>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {
            showButtons &&
            <div className="card-footer table-card-footer text-end pt-0 pb-3">
              <Button
                outline
                className="btn btn-sm"
                color="nurture-orange"
                onClick={handleSubmit(handleUnassign)}
                disabled={disabled}
              >
                {unAssignButtonText}
              </Button>&nbsp;
              <Button
                type="submit"
                className="btn btn-sm"
                disabled={disabled}
              >
                {assignButtonText}
              </Button>
            </div>
          }
        </div>
      </form>
    </>
  );
};

StudentsTable.defaultProps = {
  showButtons: true,
  checkAll: true,
  onAssign: undefined,
};

StudentsTable.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
  showButtons: PropTypes.bool,
  onAssign: PropTypes.func,
  checkAll: PropTypes.bool,
};

export default StudentsTable;
