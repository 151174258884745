
const ProgressBar = ({ value }) => (
  <div className="progress flex-grow-1" style={{ height: '4px', marginLeft: '25px' }}>
    <div
      className="progress-bar progress-nurture-green"
      role="progressbar"
      style={{ width: `${value}%` }}
      aria-valuenow={value}
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
);

ProgressBar.defaultProps = {
  value: '25',
};

export default ProgressBar;
