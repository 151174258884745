import IntercomSupport from 'assets/images/intercom_support.svg'
import ClockIcon from 'assets/images/general_icons/clock.svg';
import PointerInSquare from 'assets/images/general_icons/pointer_in_square.svg';
import EmailInSquare from 'assets/images/general_icons/email_in_square.svg';
import YoutubeInSquare from 'assets/images/general_icons/youtube_in_square.svg';
import MicrosoftLearnInSquare from 'assets/images/general_icons/microsoft_learn_in_square.svg';
import WebinarInSquare from 'assets/images/general_icons/webinar_in_square.svg';
import { useIntercom } from 'react-use-intercom';
import './NurtureResources.scss';

const NurtureResources = () => {
  const YOUTUBE_TUTORIALS = 'https://www.youtube.com/playlist?list=PLPXrfOZRCSXwWKubNyOyK2oakly3h94Dv';
  const MICROSOFT_LEARN_COURSE = 'https://learn.microsoft.com/en-us/training/modules/close-feedback-loop-microsoft-teams-with-nurture/';
  const WEBINAR = 'https://gonurture.com/nurture-webinars/library';

  const { show } = useIntercom();

  const goToLink = (link) => {
    window.open(link, '_blank');
  }

  return (
    <div className="NurtureResources">
      <div className="mb-5">
        <div className="mb-4 font-size-24">Nurture Support</div>
        
        {/* Support on Intercom */}
        <div className="card card-hover cursor-pointer" onClick={show}>
          <div className="card-body">
            <div className="row align-items-center flex-wrap">
              <div className="col-sm-10">
                <div className="row align-items-center flex-wrap mb-sm-0">
                  <div className="col-md-4 mb-3 mb-md-0">
                    <img src={IntercomSupport} className="me-3" />
                  </div>
                  <div className="col-md-8">
                    <div className="mb-2 font-size-18">Support on Intercom</div>
                    <div className="font-size-14 text-muted mb-2 d-flex align-items-center">
                      <img src={ClockIcon} className="me-2" /> Typically 2-3 minute response
                    </div>
                    <div>
                      Use Intercom to message one of the Nurture team for a fast response.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 cursor-pointer text-center">
                <img src={PointerInSquare} />
              </div>
            </div>
          </div>
        </div>

        {/* Email Support */}
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center flex-wrap">
              <div className="col-sm-10">
                <div className="row align-items-center flex-wrap mb-sm-0">
                  <div className="col-md-4 mb-3 mb-md-0">
                    <img src={EmailInSquare} className="me-3" />
                  </div>
                  <div className="col-md-8">
                    <div className="mb-2 font-size-18">Email Support</div>
                    <div className="font-size-14 text-muted mb-2 d-flex align-items-center">
                      <img src={ClockIcon} className="me-2" /> Typically 10-15 minute response
                    </div>
                    <div>
                      Please email <strong>support@gonurture.com</strong> with any questions or queries you may have.
                      Attaching screenshots to add further context to your issue will help solve it faster.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 cursor-pointer text-center">
                {/*<img src={PointerInSquare} />*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <div className="mb-4 font-size-24">Nurture Resources</div>

        {/* Nurture Video Tutorials */}
        <div className="card card-hover cursor-pointer" onClick={() => goToLink(YOUTUBE_TUTORIALS)}>
          <div className="card-body">
            <div className="row align-items-center flex-wrap">
              <div className="col-sm-10">
                <div className="row align-items-center flex-wrap mb-sm-0">
                  <div className="col-md-4 mb-3 mb-md-0">
                    <img src={YoutubeInSquare} className="me-3" />
                  </div>
                  <div className="col-md-8">
                    <div className="mb-2 font-size-18">Nurture Video Tutorials</div>
                    {/*<div className="font-size-14 text-muted mb-2"><img src={ClockIcon} className="me-1" /> Typically 10-15 minute response</div>*/}
                    <div>
                      A playlist of video tutorials guiding you from installing Nurture right through to publishing
                      feedback on your first assessment and more.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 cursor-pointer text-center">
                <img src={PointerInSquare} />
              </div>
            </div>
          </div>
        </div>

        {/* Microsoft Learn Course */}
        <div className="card card-hover cursor-pointer" onClick={() => goToLink(MICROSOFT_LEARN_COURSE)}>
          <div className="card-body">
            <div className="row align-items-center flex-wrap">
              <div className="col-sm-10">
                <div className="row align-items-center flex-wrap mb-sm-0">
                  <div className="col-md-4 mb-3 mb-md-0">
                    <img src={MicrosoftLearnInSquare} className="me-3" />
                  </div>
                  <div className="col-md-8">
                    <div className="mb-2 font-size-18">Microsoft Learn Course</div>
                    {/*<div className="font-size-14 text-muted mb-2"><img src={ClockIcon} className="me-1" /> Typically 10-15 minute response</div>*/}
                    <div>
                      Complete our course ‘Close the feedback loop in Microsoft Teams with Nurture’ and earn
                      credits towards your Microsoft Innovative Educator Expert badge.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 cursor-pointer text-center">
                <img src={PointerInSquare} />
              </div>
            </div>
          </div>
        </div>

        {/* Webinars */}
        <div className="card card-hover cursor-pointer" onClick={() => goToLink(WEBINAR)}>
          <div className="card-body">
            <div className="row align-items-center flex-wrap">
              <div className="col-sm-10">
                <div className="row align-items-center flex-wrap mb-sm-0">
                  <div className="col-md-4 mb-3 mb-md-0">
                    <img src={WebinarInSquare} className="me-3" />
                  </div>
                  <div className="col-md-8">
                    <div className="mb-2 font-size-18">Webinars</div>
                    {/*<div className="font-size-14 text-muted mb-2"><img src={ClockIcon} className="me-1" /> Typically 10-15 minute response</div>*/}
                    <div>
                      Register for future webinars and access our library of webinar recordings on the Nurture website here.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 cursor-pointer text-center">
                <img src={PointerInSquare} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NurtureResources;