import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
import NurtureAiAvatar from "assets/images/general_icons/nurture_ai.svg";
import {Autolinker, Avatar, Button, Loader} from "../index";
import {envVariables, isEmpty, isPresent, pusher, teamsEnv, userFullName} from "../../utils";
import TextArea from "../../form/fields/Textarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCancel, faPaperPlane, faRedo, faWarning} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {continueConversation} from "./action";
import metricService from "../../../lib/metricService";
import {resetStartTime, setStartTime, streamResponse, trackDuration} from "./helpers";
import Attachment from "../../../modules/attachments/Attachment";

const NurtureAIAssistantCommentSuggestion = ({ submission, assignment, teacherAction, externalTeacherAction, onGradeSuggestion, onPersonalCommentSuggestion, onLearningObjectivesSuggestion, onRubricsGraded }) => {
  const [conversations, setConversations] = useState([]);
  const [fieldValue, setFieldValue] = useState('');
  const [thinking, setThinking] = useState(false);
  const [loadingText, setLoadingText] = useState('Processing chat...');
  const [intervalID, setIntervalID] = useState();
  const [error, setError] = useState('');
  const [actions, setActions] = useState([]);
  const [actionsTimerID, setActionsTimerID] = useState();
  const [currentMessage, setCurrentMessage] = useState('');
  const [streaming, setStreaming] = useState(false);

  const chatRef = useRef();

  let firstTokenSent = false;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const LOADING_TEXTS = ['Processing chat...', 'Generating Ideas...', 'Almost done...'];


  const streamQuery = `user_assignment_id=${submission.id}`

  const toggleLoadingText = () => {
    setLoadingText(LOADING_TEXTS[0]);
    let currentIndex = 0
    return setInterval(() => {
      if (currentIndex < LOADING_TEXTS.length - 1) {
        setLoadingText(LOADING_TEXTS[currentIndex + 1])
        currentIndex += 1
      }
    }, 5000);
  }

  const getAttachmentFromAiFileId = (fileId) => {
    return submission?.student_submission?.attachments.find((attachment) => attachment.ai_file_id === fileId)
  }

  const replaceWithFileId = (text) => {
    // Regular expression to match the different variations of the prefix
    const regex = /nurture[_-]?attachment[_-]?(file-\w+)/g;

    // Replace each match with just the extracted file ID
    return text.replace(regex, (match, fileId) => {
      const attachment = getAttachmentFromAiFileId(fileId);
      return '<b>' + attachment?.name + '</b>  <a target="_blank" href="' + attachment?.view_url + '">view</a>';
    });
  }

  const scrollToRecentChat = () => {
    setTimeout(() => {
      // Auto scroll to the latest chat
      const elem = chatRef.current
      elem.scrollTop = elem.scrollHeight
    }, 500) // wait 500ms
  }

  const handleUserConversation = async ({message = null, actions = null}) => {
    setError('');

    if (!message && isEmpty(actions)) {
      enqueueSnackbar('Cannot make request please try again', { variant: 'warning' });
      return
    }

    setIntervalID(toggleLoadingText())

    try {
      setFieldValue('');
      if (message) setConversations((prev) => [...prev, ...[{message, role: 'user'}]]);
      setThinking(true);
      scrollToRecentChat();

      let data = {
        user_assignment_id: submission.id,
        run_in_background: true,
        stream: true
      };

      if (isPresent(message)) data['message'] = message
      if (isPresent(actions)) data['teacher_actions'] = actions

      setStartTime();
      await dispatch(continueConversation(data));
      setActions([]);
      streamResponse(streamQuery, handleStreamEvent);
    } catch (e) {
      resetStartTime();
      enqueueSnackbar(e.message, { variant: 'error' });
      setError(e.message)
      setThinking(false);
      clearInterval(intervalID);
    }
  }

  const handleStreamEvent = async (event) => {
    const messageEvent = JSON.parse(event.data);

    switch (messageEvent.event) {
      case 'thread.message.delta':
        if (!firstTokenSent) {
          trackDuration('assistant_chat', 'ai_first_token_latency');
          firstTokenSent = true;
        }

        setStreaming(true);
        const content = messageEvent?.data.delta.content[0].text.value;
        typeOutMessage(content);
        break;

      case 'thread.message.completed':
        handleMessageCompleted(messageEvent);
        break;

      case 'assistant.grade':
        const grade = messageEvent?.data.grade;
        if (grade) onGradeSuggestion(grade);
        console.log(messageEvent);
        break;

      case 'assistant.personal_feedback':
        const feedback = messageEvent?.data.feedback;
        onPersonalCommentSuggestion(feedback);
        console.log(messageEvent);
        break;

      case 'assistant.learning_objectives_feedback':
        const learningObjectivesFeedback = messageEvent?.data.learning_objectives;
        onLearningObjectivesSuggestion(learningObjectivesFeedback);
        console.log(messageEvent);
        break;

      case 'assistant.grade_rubrics':
        const rubricGrades = messageEvent?.data.rubrics_grade;
        const finalResult = typeof rubricGrades === 'string' ?
          JSON.parse(rubricGrades) :
          rubricGrades
        onRubricsGraded(finalResult);
        console.log(messageEvent);
        break;

      case 'thread.error':
        handleErrorEvent(messageEvent);
        break;

      case 'thread.run.failed':
        handleErrorEvent(messageEvent);
        break;
    }
  }

  const typeOutMessage = (text) => {
    setCurrentMessage((prev) => prev + text);
    scrollToRecentChat();
  };

  const handleMessageCompleted = (messageEvent) => {
    setConversations(prev => {
      return [...prev, ...[{message: messageEvent.data.content[0].text.value, role: 'assistant'}]]
    });

    setError('');
    setThinking(false);
    setCurrentMessage('');
    setStreaming(false);
    firstTokenSent = false;
    metricService.track({ event: 'ai_success', properties: { scope: 'assistant_chat' } });
    trackDuration('assistant_chat');
    scrollToRecentChat();
  }

  const handleErrorEvent = (event) => {
    firstTokenSent = false;
    setError('Error');
    enqueueSnackbar('Error occurred while generating feedback', { variant: 'error' });
    setThinking(false);
    setStreaming(false);
    resetStartTime();
    metricService.track({ event: 'ai_failed', properties: { scope: 'assistant_chat' } });
  }

  const retry = async () => {
    setError('');
    firstTokenSent = false;
    setFieldValue('');
    setThinking(true);
    setStartTime();
    setActions([]);
    streamResponse(streamQuery, handleStreamEvent);
  }

  const cancelError = () => {
    setError('');
  }

  const handleFieldValueChange = (e) => setFieldValue(e.currentTarget.value)

  const handleUserInputKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleUserConversation({ message: fieldValue })
    }
  }

  const cleanUp = (message) => {
    return message.replace(/【[0-9]{1,2}:[0-9]{1,2}†source】/g, '');
  };

  useEffect(() => {
    setConversations(() => submission.conversations || []);

    scrollToRecentChat();
  }, [submission]);

  useEffect(() => {
    if (isPresent(teacherAction)) {
      clearTimeout(actionsTimerID);

      setActions((prev) => {
        const updatedActions =  [...prev, teacherAction]

        const timerId = setTimeout(() => {
          handleUserConversation({ actions: updatedActions });
        }, 5000);

        setActionsTimerID(timerId);
        return updatedActions;
      });
    }
  }, [teacherAction]);

  useEffect(() => {
    if (isPresent(externalTeacherAction)) {
      handleUserConversation({actions: [externalTeacherAction] })
    }
  }, [externalTeacherAction])

  return (
    <div className="bg-white p-3 pb-2 NurtureAIAssistantCommentSuggestion h-100 d-flex flex-column justify-content-between">
      <div className="chats flex-grow-1" ref={chatRef}>
        {
          conversations.map((message, index) => {
            return (
              <div className="d-flex mt-3">
                <div>
                  <Avatar size="xs" img={message.role === 'assistant' ? NurtureAiAvatar : assignment?.user?.avatar_url} />
                </div>
                <div className="ms-2">
                  <div className="font-weight-600 font-size-18 mb-2">
                    { message.role === 'assistant' ? 'Nurture Assistant' : `${userFullName(assignment.user)} (You)` }
                  </div>
                  {/*{*/}
                  {/*  index === 0 && !!submission.submitted_at &&*/}
                  {/*  <div className="d-flex align-items-center">*/}
                  {/*    <div className="font-size-13">*/}
                  {/*      { submission.user.first_name } had {submission?.student_submission?.confidence?.emoji}&nbsp;*/}
                  {/*      <strong>{ submission?.student_submission?.confidence?.text }</strong>&nbsp; on this assessment*/}
                  {/*      {*/}
                  {/*        submission?.student_submission?.notes ?*/}
                  {/*          <span>, and said:&nbsp; <strong>'{ submission?.student_submission?.notes }'</strong></span> :*/}
                  {/*          ' and did not say anything'*/}
                  {/*      }*/}
                  {/*      &nbsp; for their personal comment. I have analysed their work*/}
                  {/*      { submission?.student_submission?.attachments?.length > 0 && <> in these attachments:</> }*/}

                  {/*      {*/}
                  {/*        submission?.student_submission?.attachments?.length > 0 &&*/}
                  {/*        <div className="mt-3">*/}
                  {/*          <ul>*/}
                  {/*            {*/}
                  {/*              submission?.student_submission?.attachments.map((attachment) => <li className="font-weight-600">'{attachment.name}'</li>)*/}
                  {/*            }*/}
                  {/*          </ul>*/}
                  {/*        </div>*/}
                  {/*      }*/}

                  {/*      <div>Here is a feedback I suggest for { submission.user.first_name }</div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*}*/}

                  {
                    <div className={`${message.role === 'assistant' ? '' : ''} mt-2`}>
                      <Autolinker className="font-size-13" content={ message.role === 'assistant' ? replaceWithFileId(cleanUp(message.message)) : cleanUp(message.message) } />
                    </div>
                  }
                </div>
              </div>
            );
          })
        }

        {
          currentMessage &&
          <div className="d-flex mt-3">
            <div>
              <Avatar size="xs" img={NurtureAiAvatar} />
            </div>
            <div className="ms-2">
              <div className="font-weight-600 font-size-18 mb-2">
                 Nurture Assistant
              </div>
                <div className='mt-2'>
                  <Autolinker className="font-size-13" content={cleanUp(currentMessage)} />
                </div>
            </div>
          </div>
        }

        {
          thinking && !streaming &&
          <div className="d-flex mt-3">
            <div><Avatar size="xs" img={NurtureAiAvatar}/></div>
            <div className="ms-2">
              <div className="font-weight-600 font-size-18 mb-2">
                Nurture Assistant
              </div>

              {
                <div className="mt-2">
                  <Autolinker className="font-size-13" content={loadingText}/>
                  <Loader loading={thinking} type="ring" color="#35294b"/>
                </div>
              }
            </div>
          </div>
        }

        {
          error &&
          <div className="d-flex mt-3">
            <div><Avatar size="xs" img={NurtureAiAvatar}/></div>
            <div className="ms-2 flex-grow-1">
              <div className="font-weight-600 font-size-18 mb-2">
                Nurture Assistant
              </div>

              <div className="alert alert-danger">
                <div className="mb-2"><FontAwesomeIcon icon={faWarning} className="me-1"/> An error occurred while
                  generating feedback
                </div>
                <div>
                  <Button onClick={cancelError} className="btn-sm me-2" color="danger"><FontAwesomeIcon icon={faCancel}
                                                                                                        className="me-1"/>Cancel</Button>
                  <Button onClick={retry} className="btn-sm" color="nurture-green"><FontAwesomeIcon icon={faRedo}
                                                                                                    className="me-1"/>Retry</Button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>


      <div className="chatField-container">
        <div className="mt-4 chatField">
          <TextArea
            readOnly={thinking}
            placeholder="e.g I think the student did well on this learning outcome"
            onChange={handleFieldValueChange}
            onKeyUp={handleUserInputKeyUp}
            value={fieldValue} rows="4"/>
          <div className="d-flex justify-content-end mt-2">
            <Button disabled={thinking}
                    onClick={() => handleUserConversation({message: fieldValue})}>Send <FontAwesomeIcon
              icon={faPaperPlane}/> </Button>
          </div>
        </div>
        <div className="font-size-13 text-muted">
          The Nurture assistant may provide inaccurate feedback. It’s important to check over its suggestions.
        </div>
      </div>
    </div>
  );
};

NurtureAIAssistantCommentSuggestion.propTypes = {
  submission: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
  teacherAction: PropTypes.object.isRequired,
  externalTeacherAction: PropTypes.object.isRequired,
  onGradeSuggestion: PropTypes.func.isRequired,
  onPersonalCommentSuggestion: PropTypes.func.isRequired,
  onLearningObjectivesSuggestion: PropTypes.func.isRequired,
  onRubricsGraded: PropTypes.func.isRequired,
};

export default NurtureAIAssistantCommentSuggestion;
