import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import Button from "core/components/Button";

const ConfirmModal = ({ show, onConfirm, toggle, text, title, ...otherProps }) => (
  <Modal isOpen={show} toggle={toggle} zIndex={21800000000} {...otherProps}>
    <ModalHeader toggle={toggle} charcode="x" tag="h4">{ title }</ModalHeader>
    <ModalBody>{ text }</ModalBody>
    <ModalFooter>
      <Button color="white" onClick={toggle} disabled={otherProps.submitting}>Cancel</Button>{' '}
      <Button
        onClick={onConfirm}
        withLoader={true}
        loading={otherProps.submitting}
        loaderText="Deleting"
        disabled={otherProps.submitting}>
        Proceed
      </Button>
    </ModalFooter>
  </Modal>
);

ConfirmModal.defaultProps = {
  title: 'Confirm',
  text: 'Are you sure you want to continue?',
  toggle: undefined,
};

ConfirmModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  toggle: PropTypes.func,
  show: PropTypes.bool.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
}

export default ConfirmModal;
