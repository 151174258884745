import { createRequest, composeRequestConfig } from 'core/utils/axios/api';
import { teamsEnv } from 'core/utils';

export const attachmentNew = () => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/attachments/new`;

  const config = composeRequestConfig({ url });
  return createRequest(config);
};

export const attachmentCreate = (payload) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/attachments`;

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
};

export const attachmentDelete = (attachmentId) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/attachments/${attachmentId}`;

  const config = composeRequestConfig({ url, method: 'delete' });
  return createRequest(config);
};

export const attachmentShow = (attachmentId) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/attachments/${attachmentId}`;

  const config = composeRequestConfig({ url });
  return createRequest(config);
};

export const download = attachmentId => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/attachments/${attachmentId}/download`;
  const config = composeRequestConfig({ url });
  return createRequest(config);
};
