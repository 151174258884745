import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import StudentReflectionModal from "modules/assignments/studentReflection/studentReflectionModal";
import { Button } from "core/components";
import PropTypes from "prop-types";
import { useSchoolConfig } from "core/hooks";
import AssignmentTemplatesModal from "../../assignmentTemplates/assignmentTemplatesModal";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {createAssignmentTemplate} from "../../assignmentTemplates/action";
import {maxBy} from "lodash";

const FeedbackFooter = ({ assignment }) => {
  const { learningObjectiveLabel } = useSchoolConfig();
  const [showStudentReflectionModal, setShowStudentReflectionModal] = useState(false);

  const [savedTemplate, setSavedTemplate] = useState({});
  const [templateCreateLoading, setTemplateCreateLoading] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [templateModalState, setTemplateModalState] = useState(0);
  const [templateSharableCode, setTemplateSharableCode] = useState('');
  const [templateFor, setTemplateFor] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [saveFeedback, setSaveFeedback] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const saveTemplate = async () => {
    try {
      setTemplateCreateLoading(true)

      // If we are creating a template for the first time from an assignment, we first create a draft assignment
      // and create a template from the draft assignment .
      // otherwise if the content is assignment_template, it means we are editing an assignment template
      // in that case we do not need to create a draft before saving since the template was initially created
      // from a draft.
      const query = {
        assignment_id: assignment.id,
        assignment_type: 'ms_teams',
        visibility: templateFor,
        template_name: templateName,
        save_feedback: saveFeedback
      };

      const response = await dispatch(createAssignmentTemplate(query));
      setSavedTemplate(response);
      setTemplateSharableCode(response.sharable_code)

      setTemplateModalState(1); // show the success page on the template modal
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setTemplateCreateLoading(false);
    }
  };

  const handleTemplateUpdated = (template) => {
    setSavedTemplate(template);
    setTemplateSharableCode(template.sharable_code)

    setTemplateModalState(1); // show the success page on the template modal
  }

  const handleTemplateModalChanged = (visibility, templateName, saveFeedback) => {
    setTemplateFor(visibility);
    setTemplateName(templateName);
    setSaveFeedback(saveFeedback);
  }

  const closeStudentReflectionModal = () => {
    setShowStudentReflectionModal(false);
  };

  const closeTemplateModal = () => {
    setShowTemplateModal(false);
    setTemplateModalState(0);
    setTemplateSharableCode(null);
  };

  const handlePublished = () => {
    setShowTemplateModal(true);
    setShowStudentReflectionModal(false);
  };

  const recentlyUpdatedAssignmentObjective = maxBy(assignment?.assignment_objectives, 'updated_at')

  const feedbackUpdatedAfterPublishing = !!assignment?.feedback_published_at && (recentlyUpdatedAssignmentObjective?.updated_at > assignment?.feedback_published_at)
  const feedbackPublishText = assignment?.feedback_completed && feedbackUpdatedAfterPublishing ? 'Re-Publish Feedback' : 'Publish Feedback'

  if (!!assignment?.feedback_published_at && !feedbackUpdatedAfterPublishing) {
    return (
      <>
        <div className="alert alert-light mb-0">
          <div className="row align-items-center">
            <div className="col">
              <small>
                Your feedback has been sent to all students who require them! You can continue to edit any feedback
                and assign new students to {learningObjectiveLabel.toLowerCase()} if required.
              </small>
            </div>
            <div className="col-auto">
              <div className="avatar avatar-lg">
                <div className="avatar-title bg-info rounded-circle text-white">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <AssignmentTemplatesModal
          assignmentName={assignment.name}
          assignment={assignment}
          savedTemplate={savedTemplate}
          context="feedback"
          isOpen={showTemplateModal}
          templateCreateLoading={templateCreateLoading}
          templateModalState={templateModalState}
          sharableCode={templateSharableCode}
          onChangeTemplate={handleTemplateModalChanged}
          onClose={closeTemplateModal}
          onUpdate={handleTemplateUpdated}
          onSubmit={saveTemplate} />
      </>
    )
  } else if (assignment?.feedback_completed) {
    return (
      <div className="alert alert-primary mb-0">
        <div className="row">
          <div className="col">
            <small className="text-dark">
              One last step! Once you are happy with your correction and feedback, push the button
              to send your feedback to your students.
            </small>
          </div>
          <div className="col-auto">
            <Button color='light' onClick={() => setShowStudentReflectionModal(true)}>{ feedbackPublishText }</Button>
          </div>
        </div>
        <StudentReflectionModal
          assignment={assignment}
          isOpen={showStudentReflectionModal}
          onClose={closeStudentReflectionModal}
          onPublished={handlePublished}
        />
      </div>
    )
  } else {
    return (
      <div className="alert alert-light">
        <div className="row">
          <div className="col">
            <small className="text-muted">
              None of the student submission has been marked as reviewed. There has to be at least on submission marked
              as reviewed for you to be able to publish the feedback.
            </small>
          </div>
          <div className="col-auto">
            <Button disabled type="button" color="light" className="btn-sm">Publish Feedback</Button>
          </div>
        </div>
      </div>
    )
  }
}

FeedbackFooter.defaultProps = {
  publishing: false,
  onPublishFooter: () => null
};

FeedbackFooter.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
  publishing: PropTypes.bool,
  onPublishFooter: PropTypes.func
}

export default FeedbackFooter;