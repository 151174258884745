import PropTypes from 'prop-types';
import {Autolinker} from 'core/components';
import {isEmpty, isPresent, learningObjectivesHelper} from 'core/utils';
import Attachments from 'modules/attachments/Attachments';
import GroupFeedBackObjectives from './GroupFeedbackObjectives';
import NoFeedback from './NoFeedback';
import PendingReview from '../PendingReview';
import React, {Fragment} from "react";
import { useSchoolConfig } from "core/hooks";
import RubricStudentFeedbackTable from "./RubricStudentFeedbackTable";
import RubricTable from "./RubricTable";

const FeedBack = ({ assignment }) => {
  const {
    feedback_published_at: feedbackPublishedAt,
    feedback_type,
    user_assignment,
  } = assignment;

  const showRubricsPoints = assignment.rubrics_grade_display !== 'labels' && user_assignment.student_reflection_completed

  const assignment_objectives = assignment?.assignment_objectives.map((item, index) => ({...item, position: index + 1})) || []

  const { teacher_feedback } = user_assignment;
  const { learningObjectiveLabel } = useSchoolConfig();
  const { objectivesThatDoNotNeedFeedback, objectivesThatNeedsFeedback } = learningObjectivesHelper;

  const groupFeedback = feedback_type === 'group_feedback';
  const objectivesWithoutFeedback = objectivesThatDoNotNeedFeedback(assignment_objectives, user_assignment) || [];
  const objectivesWithFeedback = objectivesThatNeedsFeedback(assignment_objectives, user_assignment) || [];

  return (
    <>
      {isPresent(feedbackPublishedAt) && (
        <div>
          {
            assignment.grade_display_to_students !== 'rubrics' &&
              <NoFeedback userAssignment={user_assignment} />
          }

          {teacher_feedback.personal_feedback && (
            <div className="mt-4 mb-5">
              <div className="mb-2 font-size-14 font-weight-600 text-muted">PERSONAL FEEDBACK</div>
              <Autolinker className="mb-3" content={teacher_feedback.personal_feedback} />

              {isPresent(teacher_feedback.personal_feedback_attachments) && (
                <Attachments attachments={teacher_feedback.personal_feedback_attachments} allowDelete={false} />
              )}
            </div>
          )}

          {/*{show_assignment_objectives && isPresent(teacher_feedback.feedback_objectives) && (*/}
          {/*  <FeedbackObjectives objectives={teacher_feedback.feedback_objectives} />*/}
          {/*)}*/}

          {
            groupFeedback &&
              <>
                {
                  assignment.grade_display_to_students !== 'rubrics' &&
                    <div className="my-4">
                      <div className="mb-4 font-size-14 font-weight-600 text-muted text-uppercase">{ learningObjectiveLabel } FEEDBACK</div>
                      <div className="p-2 mb-2 text-white bg-nurture-orange">What you can improve on</div>
                      <GroupFeedBackObjectives objectives={objectivesWithFeedback} />

                      {
                        !!objectivesWithoutFeedback.length &&
                        <Fragment>
                          <div className="mt-4 p-2 mb-2 text-white bg-nurture-green-variant">What you did well in</div>
                          {
                            objectivesWithoutFeedback.map((objective, index) => {
                              return (
                                <div key={objective.id} className='d-flex align-items-center mt-2 mb-3 px-3 py-3 rounded bg-nurture-light-green-variant'>
                                  <div className='me-3'>
                                    <div className='avatar avatar-sm'>
                                      <div className='avatar-title font-size-md rounded-circle bg-nurture-green-variant'>{objective.position}</div>
                                    </div>
                                  </div>
                                  <div className='font-size-16 flex-grow-1'>{objective.name}</div>
                                </div>
                              )
                            })
                          }
                        </Fragment>
                      }
                    </div>
                }

                {
                  assignment.grade_display_to_students === 'rubrics' &&
                    <RubricTable assignment={assignment} hidePoints={!showRubricsPoints} showScoreSelection={true} />
                    // <RubricStudentFeedbackTable showRubricsPoints={showRubricsPoints} assignment={assignment} />
                }
              </>
          }
        </div>
      )}

      {isEmpty(feedbackPublishedAt) && (
        <PendingReview
          title="Feedback not published"
          subtitle="Your teacher has reviewed your work but has not yet published your feedback. You can no longer edit your submission but check again soon for your feedback and complete your reflection."
        />
      )}

    </>
  );
};

FeedBack.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
};

export default FeedBack;
