const tenantId = new URLSearchParams(window.location.search).get("tenant_id");
const channelId = new URLSearchParams(window.location.search).get("channel_id");
const token = new URLSearchParams(window.location.search).get("user_token");

const teamsVariables = {
  tenantId,
  channelId,
  token,
};

export default teamsVariables;
