import React from "react";
import { Link } from "react-router-dom";

import './emptyPage.scss';
import EmptyAssignmentImage from 'assets/images/illustrations/empty_assignments.png';
import { useSchoolConfig } from "core/hooks";
import PermissionButton from "core/components/PermissionButton";
import NewAssignmentButton from "../core/NewAssignmentButton";
import TeacherRequestButton from "../core/components/TeacherRequestButton";

const EmptyPage = ({ authed, role }) => {
  let url = '/login';
  let buttonText = 'Go to Login';
  let headerText = "Looks like you are not logged in 😢. Let's fix that!";
  let subtext = 'Click the button below to access login';

  if (authed) {
    url = '/assignments/new';
    buttonText = 'New Assessment'
    headerText = 'Looks like there are no assessments in this channel yet.';
    subtext = role === "teacher" ?
      'Create a new assessment and assign it to your students.' :
      'You will be able to access assessments when your teacher creates and assigns an assessment to you'
  }

  return (
    <div className="row text-center">
      <div className="col-12">
        <div className="row justify-content-center">
          <div className="col-12 col-xl-10">
            <div className="row">
              <div className="col-sm-12 col-lg-6 offset-lg-3 image-container">
                <img src={EmptyAssignmentImage} alt="Empty Page" className="img-fluid" />
              </div>
            </div>

            <h2 className="mb-2">{headerText}</h2>

            <p className="text-muted"> {subtext}</p>

            {
              role === "teacher" &&
                <NewAssignmentButton />
            }

            <div className="mt-4">
              <TeacherRequestButton />
            </div>

            <div className="mt-2">
              <PermissionButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyPage;
