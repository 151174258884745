import PropTypes from 'prop-types';

const SectionHeader = ({ title, subtitle, titleClassName, subtitleClassName, ...rest }) => (
  <div className='d-flex flex-column mb-0' {...rest}>
    <div className={`section-header-title ${titleClassName}`}>{title}</div>
    <div className='text text-muted'>{subtitle}</div>
  </div>
);

SectionHeader.defaultProps = {
  titleClassName: "fs-5",
  subtitleClassName: undefined,
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitleClassName: PropTypes.string,
  titleClassName: PropTypes.string,
};

export default SectionHeader;
