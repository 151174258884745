import { createRequest, composeRequestConfig } from 'core/utils/axios/api';
import { teamsEnv } from 'core/utils';

export const getAssignments = params => {
  const page = params.page ? `?page=${params.page}` : '';
  const studentId = params.studentId ? `${page ? '&' : '?'}student_id=${params.studentId}` : '';
  const url = `/api/v1/ms_teams/${params.tenantId}/channels/${params.channelId}/assignments${page}${studentId}`;

  const config = composeRequestConfig({ url });
  return createRequest(config);
};

export const getAssignment = id => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/assignments/${id}`;

  const config = composeRequestConfig({ url });
  return createRequest(config);
};

export const assignmentCreate = () => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/assignments`

  const config = composeRequestConfig({ url, method: 'post' });
  return createRequest(config);
};

export const assignmentUpdate = (assignmentId, payload) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/assignments/${assignmentId}`

  const config = composeRequestConfig({ url, method: 'patch', payload });
  return createRequest(config);
};

export const assignmentDelete = (assignmentId) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/assignments/${assignmentId}`

  const config = composeRequestConfig({ url, method: 'delete' });
  return createRequest(config);
};

export const assignmentAssign = (assignmentId, payload) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/assignments/${assignmentId}/bulk_action`

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
};

export const submissionShow = (assignmentId, submissionId) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/assignments/${assignmentId}/submissions/${submissionId}`

  const config = composeRequestConfig({ url });
  return createRequest(config);
}

export const submissionUpdate = (assignmentId, submissionId, payload) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/assignments/${assignmentId}/submissions/${submissionId}`

  const config = composeRequestConfig({ url, method: 'patch', payload });
  return createRequest(config);
}

export const assignmentObjectiveUpdate = (assignmentId, objectiveId, payload) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/assignments/${assignmentId}/assignment_objectives/${objectiveId}`

  const config = composeRequestConfig({ url, method: 'patch', payload });
  return createRequest(config);
}

export const assignmentPublishFeedback = (assignmentId, payload) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/assignments/${assignmentId}/publish_feedback`

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
}

export const updateOfflineSubmissionMade = (assignmentId, submissionId, payload) => {
  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/assignments/${assignmentId}/submissions/${submissionId}/offline_submission_made`

  const config = composeRequestConfig({ url, method: 'post', payload });
  return createRequest(config);
}
