import { Modal, ModalBody } from "reactstrap";
import EmptySearch from "assets/images/illustrations/empty_search.png";
import Button from "core/components/Button";
import React  from "react";
import PropTypes from "prop-types";

const NoMatchModal = ({ show, context, closeModal, onContinue, ...otherProps }) => {

  const handleContinue = () => onContinue();

  return (
    <Modal isOpen={show} zIndex={21800000000} {...otherProps}>
      <ModalBody>
        <div className="p-3">
          <h4 className="text-center border-bottom mb-4 pb-3">Curriculum Reference Missing</h4>
          <div className="text-center mb-4">
            <img width={400} src={EmptySearch} className="img-fluid" />
          </div>
          <p className="text-center">
            We could not find any references in your curriculum boundary document that are related to your request.
            Would you like to continue anyway and generate {context === 'assessment' ? 'an assessment' : 'the rubrics'} without linking it to your curriculum?
          </p>
          <div className="mt-4 d-flex justify-content-center">
            <Button className='me-2 text-primary' color="transparent" onClick={() => closeModal()}>Try again</Button>
            <Button
              withLoader={true}
              onClick={handleContinue}>
              Continue
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

NoMatchModal.defaultProps = {
  context: 'assessment'
}

NoMatchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  context: PropTypes.func,
};

export default NoMatchModal;