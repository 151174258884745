import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormGroup } from 'reactstrap';
import { useSnackbar } from 'notistack';

import { Textarea, TextInput } from 'core/form/fields';
import { filestack, isEmpty, isPresent, validUrl } from 'core/utils';
import { Alert, Button, Card, Divider } from 'core/components';
import StudentConfidence from './EmojiFields';
import NoteDescription from './NoteDescription';
import oneNote from 'assets/images/file_icons/one-note.svg';
import { confidenceOptions } from './constants';
import { submitAssignment } from './redux/action';
import * as attachmentSelector from 'modules/attachments/selectors';
import { attachmentCreate, attachmentFilestack } from 'modules/attachments/actions';
import Attachments from 'modules/attachments/Attachments';

const schema = yup.object().shape({
  'studentNotes': yup.string(),
  'studentConfidence': yup.number().oneOf([1, 2, 3]).required(),
  'onenoteUrl': yup.string(),
});

const Form = ({ assignmentId, onCancel, onSuccess, userAssignment, submissionType }) => {
  const { id, onenote_url, student_submission: { attachments, confidence, notes } } = userAssignment;
  const OnenoteLabel = <>Link a OneNote Document (Optional) <img src={oneNote} alt="One note icon" className='small-img ms-2' /></>;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    studentNotes: notes || '',
    studentConfidence: confidence.level || '',
    onenoteUrl: onenote_url || '',
  };

  const [studentNotesHasUrl, setStudentNotesHasUrl] = useState(false);
  const [validOnenoteUrl, setValidOnenoteUrl] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [attachmentButtonDisabled, setAttachmentButtonDisabled] = useState(false);
  const [userAssignmentAttachments, setUserAssignmentAttachments] = useState(attachments);

  const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const filestackCredentials = useSelector(attachmentSelector.filestackCredentials);

  const teacherReviewed = userAssignment.status === 'reviewed'

  useEffect(() => {
    if (validUrl(watchStudentNotes)) {
      setStudentNotesHasUrl(true);
    } else {
      setStudentNotesHasUrl(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(filestackCredentials.data)) dispatch(attachmentFilestack())
    // eslint-disable-next-line react-hooks/exhaustive-deps

    handleOneNoteChange(onenote_url);
  }, []);

  const onSubmit = async data => {
    const params = {
      assignmentId,
      id,
    };

    const formattedData = {
      student_notes: data.studentNotes,
      student_confidence: data.studentConfidence,
      onenote_url: data.onenoteUrl,
    };

    try {
      setDisabled(true);
      const response = await dispatch(submitAssignment(params, formattedData));
      enqueueSnackbar('Your assignment has been submitted!', { variant: 'success' })
      onSuccess(response);
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    } finally {
      setDisabled(false);
    }
  };

  const uploadAttachment = payload => dispatch(attachmentCreate(payload));
  const handleUploadSuccess = async file => {
    const payload = { user_assignment_id: id, ...file };

    try {
      setAttachmentButtonDisabled(true)
      const uploadedFile = await uploadAttachment(payload);
      setUserAssignmentAttachments(current => [...current, uploadedFile]);
      setAttachmentButtonDisabled(false)
    } catch (error) {
      setAttachmentButtonDisabled(false)
      enqueueSnackbar(error?.message, { variant: 'error' })
    }
  };

  const onUploaderButtonClick = () => {
    setAttachmentButtonDisabled(true);
    const filePicker = filestack(filestackCredentials, handleUploadSuccess);
    filePicker.open();
    setAttachmentButtonDisabled(false);
  };

  const handleStudentNotesChange = () => {
    const isValidUrl = validUrl(watchStudentNotes);
    if (isValidUrl) {
      setStudentNotesHasUrl(true);
    } else {
      setStudentNotesHasUrl(false);
    }
  };

  const handleOneNoteChange = (onenoteUrl = null) => {
    const isValidUrl = validUrl(onenoteUrl || watchOneNoteUrl);
    const textInclusion = (onenoteUrl || watchOneNoteUrl).includes('sharepoint.com')
    if (isValidUrl && textInclusion) {
      setValidOnenoteUrl(true);
    } else {
      setValidOnenoteUrl(false);
    }
  }


  const handleOnAttachmentDelete = deletedAttachment => {
    const undeletedAttachments = userAssignmentAttachments.filter(attachment => attachment.id !== deletedAttachment.id);

    setUserAssignmentAttachments(undeletedAttachments);
  };

  const watchStudentNotes = watch('studentNotes', '');
  const watchOneNoteUrl = watch('onenoteUrl', '');

  return (
    <Card className="mt-4" cardBodyClassName="p-0 pt-4" >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='px-custom'>
          <FormGroup>
            <Controller
              name="studentNotes"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Textarea
                  ref={ref}
                  label="Notes"
                  rows="4"
                  value={value}
                  onChange={onChange}
                  onKeyUp={() => handleStudentNotesChange()}
                  placeholder="Use this space to explain how long this assessment took to complete, how easy or difficult it was, etc."
                  onBlur={onBlur}
                />
              )}
            />
            {errors.studentNotes && (
              <small className="form-text text-danger mt-1">Please fill in the student notes</small>
            )}
            <NoteDescription />
          </FormGroup>
          {/*<FormGroup>*/}
          {/*  <Controller*/}
          {/*    name="onenoteUrl"*/}
          {/*    control={control}*/}
          {/*    render={({ field: { onChange, onBlur, value, ref } }) => (*/}
          {/*      <TextInput*/}
          {/*        ref={ref}*/}
          {/*        value={value}*/}
          {/*        onKeyUp={() => handleOneNoteChange()}*/}
          {/*        label={OnenoteLabel}*/}
          {/*        onChange={onChange}*/}
          {/*        placeholder="Paste OneNote link in here"*/}
          {/*        onBlur={onBlur}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  />*/}
          {/*  {errors.onenoteUrl && (*/}
          {/*    <small className="form-text text-danger mt-1">Please fill valid onenote url</small>*/}
          {/*  )}*/}
          {/*</FormGroup>*/}
          <div className="mb-3">
            <label className="mb-0 d-block">How confident were your answers?</label>
            <small className="text-muted">
              How well would you rate your confidence for this assessment?
            </small>
          </div>

          <FormGroup>
            <StudentConfidence
              name='studentConfidence'
              control={control}
              setValue={setValue}
              confidenceOptions={confidenceOptions}
            />
            {errors.studentConfidence && (
              <small className="form-text text-danger mt-1">Please choose a confidence level</small>
            )}
          </FormGroup>

          <div className="attachments-list edit">
            {isPresent(userAssignmentAttachments) && (
              <Attachments attachments={userAssignmentAttachments} onDelete={handleOnAttachmentDelete} allowDelete />
            )}
          </div>
        </div>
        <Divider className="my-3" />
        <div className=" py-2 px-custom">
          {
            teacherReviewed &&
            <Alert allowDismiss={false} text="You cannot make a submission after your work has been reviewed" type="warning" />
          }
          <div className="row flex-wrap">
            <div className="col-md">
              <Button
                className="upload-files w-100 w-md-200px mt-2"
                onClick={onUploaderButtonClick}
                outline
                disabled={attachmentButtonDisabled || disabled || teacherReviewed}
              >
                <FontAwesomeIcon icon={faPaperclip} />&nbsp;
                Add attachments
              </Button>

            </div>
            <div className="col-md-auto row mx-0 flex-wrap px-0">
              {userAssignment?.status === 'submitted' &&  (
                <div className="col-md mt-2 pe-md-0">
                  <Button outline color="nurture-orange" className="w-100 w-md-200px" onClick={onCancel}>Cancel</Button>
                </div>
              )}
              <div className="col-md mt-2">
                <Button className="w-100 w-md-200px" type="submit" disabled={attachmentButtonDisabled || disabled || teacherReviewed}>Submit Assignment</Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
};

Form.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  userAssignment: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  submissionType: PropTypes.string.isRequired
};

export default Form;
