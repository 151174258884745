export const referencesText = (references) => {
  const referencedItems = Object.values(references).flat(Infinity);
  const uniquePages = Array.from(new Set(referencedItems.map(item => item.page)));

  const formattedPages = uniquePages.map((page, index) => {
    if (uniquePages.length === 1) {
      return page; // Only one page
    } else if (index === uniquePages.length - 1) {
      return `& ${page}`; // Last page preceded by an ampersand
    } else if (index === uniquePages.length - 2) {
      return `${page}`; // Second to last page without a comma
    } else {
      return `${page},`; // Other pages separated by a comma
    }
  });

  return formattedPages.join(' ');
}