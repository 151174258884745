import PropType from 'prop-types';
import avatar from 'assets/images/google_profile.jpg'
import './avatars.scss';

const Avatars = ({ imgSize, urls, remainingItemsCount, ...rest }) => (
  <div className={`avatars avatars-${imgSize}`}>
    {urls.map((url, index) =>
      <div key={index + 1} className='avatar' {...rest}>
        <img src={url || avatar} alt="user avatar" />
      </div>
    )}

    {remainingItemsCount > 0 && (
      <div className='d-flex align-items-center justify-content-center avatar product-dark-color'>
        <span className='fs-3 fw-bold'>+{remainingItemsCount}</span>
      </div>
    )}
  </div>
);

Avatars.defaultProps = {
  imgSize: 'md',
};

Avatars.propTypes = {
  urls: PropType.array.isRequired,
  imgSize: PropType.oneOf(['xs', 'md']),
  remainingItemsCount: PropType.number.isRequired,
};

export default Avatars;
