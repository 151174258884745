import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Input from "core/form/fields/Input";

const EditColumnFields = ({ column, onEdit }) => {
  const [columnValue, setColumnValue] = useState('');
  const [columnPoint, setColumnPoint] = useState(3);

  const handleUpdate = () => {
    onEdit({ ...column, newTitle: columnValue, newPoint: columnPoint });
  }

  useEffect(() => {
    setColumnValue(column.title);
    setColumnPoint(column.point);
  }, [column]);

  return (
    <div>
      <Input label="Criteria" value={columnValue} onChange={(e) => setColumnValue(e.currentTarget.value)} onBlur={handleUpdate} />
      <br />
      <Input type="number" label="Point" value={columnPoint} onChange={(e) => setColumnPoint(e.currentTarget.value)} onBlur={handleUpdate} />
    </div>
  );
};


EditColumnFields.defaultProps = {
  column: {},
};

EditColumnFields.propTypes = {
  onEdit: PropTypes.func.isRequired,
  column: PropTypes.shape({}),
}

export default EditColumnFields;
