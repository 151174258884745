import axios from 'axios';
import {envVariables, isEmpty, teamsEnv} from '..';
import refreshToken from './refresh_token';

const url = process.env.REACT_APP_API_URL;

// Default config options
const defaultOptions = {
  baseURL: url,
  'Content-Type': 'application/json',
  headers: {},
  timedOut: 3000,
};

// Create instance
const instance = axios.create(defaultOptions);

// Add a request interceptor
instance.interceptors.request.use(
  request => {
    const userToken = localStorage.getItem('token');

    request.headers.Authorization = `Bearer ${userToken}`;

    return request;
  },
  error => Promise.reject(error),
);

// Add a response interceptor
instance.interceptors.response.use(
  response => response.data,
  async error => {
    const config = error?.config;
    if (error?.response) {
      if (error.response?.status === 401 && !config._retry) {
        localStorage.removeItem('token');
        window.location.reload();
        return;
      }
      if (error.code === 'ECONNABORTED')
        throw new Error('Network timeout, please try again');
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return Promise.reject(error);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      error.message =
        'This request is taking too long, please check your network';
      throw error;
    } else {
      // Something happened in setting up the request that triggered an Error
      throw error;
    }
  }
);

export default instance;

export const createRequest = config => instance(config);

export const composeRequestConfig = config => {
  const { method = 'get', payload, params, headers, ...rest } = config;

  const requestConfig = { method, ...rest };
  if (payload && (!isEmpty(payload) || payload instanceof FormData)) {
    requestConfig.data = payload;
  }

  if (params && !isEmpty(params)) {
    requestConfig.params = params;
  }

  if (headers) {
    requestConfig.headers = headers;
  }
  return requestConfig;
};
