import { Navigate, Outlet } from 'react-router-dom';
import { isJwtExpired } from 'core/utils';
import AdminConsentRedirect from "./auth/AdminConsentRedirect";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');

  if (isJwtExpired(token)) {
    return <Navigate to={`/authenticate`} replace />;
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    const adminConsent = urlParams.get('admin_consent');
    const state = urlParams.get('state');

    if (adminConsent && state === '12345') {
      return <AdminConsentRedirect />
    }
  }


  return children ? children : <Outlet />;
};

export default ProtectedRoute;
