import PropTypes from "prop-types";

const TextHeader = ({children, ...rest}) => {
  return <h4 style={{fontSize: '16px'}} {...rest}>{children}</h4>
}

TextHeader.defaultProps = {
  children: undefined
}

TextHeader.propTypes = {
  children: PropTypes.node
}

export default TextHeader;