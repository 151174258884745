import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Scrolls to the top of the page if the url changes. Ensures we are always at the top
 * on the new page
 * @returns {null}
 * @constructor
 */
const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location]);

  return null;
};

export default ScrollToTop;
