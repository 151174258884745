import getFileExtension from "./file_extension";

const VALID_FORMATS = [
  'docx', 'pptx', 'xlsx', 'pdf', 'doc', // Documents
  'jpg', 'jpeg', 'png', // Images
  'gif', 'tiff', 'mp4', 'mov', 'webm', // videos
  '.wav', '.ogg', '.m4a', '.aac', 'mp3' // audio
];

const validPdfTronFormat = (filename) => VALID_FORMATS.includes(getFileExtension(filename));

export default validPdfTronFormat;
