import { userFullName } from "core/utils";

const objectiveNeedFeedback = (submission, objective) => {
  const user = submission?.user;

  return objective.feedback_objectives?.some(item => {
    if (user?.id) {
      return item.user_assignment?.user?.id === user.id
    } else if (user?.first_name) {
      return userFullName(user) === userFullName(item.user_assignment?.user)
    } else {
      return false
    }
  });
};

const objectivesThatNeedsFeedback = (objectives, submission) => {
  return (objectives || []).filter((objective) => {
    return objectiveNeedFeedback(submission, objective);
  }) || [];
};

const objectivesThatDoNotNeedFeedback = (objectives, submission) => {
  return (objectives || []).filter((objective) => {
    return !objectiveNeedFeedback(submission, objective);
  }) || [];
};

export default {
  objectiveNeedFeedback: objectiveNeedFeedback,
  objectivesThatNeedsFeedback: objectivesThatNeedsFeedback,
  objectivesThatDoNotNeedFeedback: objectivesThatDoNotNeedFeedback
}