const useSchoolConfig = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const learningObjectiveLabel = user?.learning_objective_label || 'Learning Objectives';
  const msTeamsPermissionMissing = !!user?.ms_teams_permission_missing
  const trialEnds = user?.trial_ends
  const nurtureAIEnabled = user?.nurture_ai_enabled
  const curriculumBoundaryEnabled = user?.curriculum_boundary_enabled
  const rubricsEnabled = user?.rubrics_enabled
  const nurtureAssistantEnabled = user?.nurture_assistant_enabled
  const customRubricsEnabled = user?.custom_rubrics_enabled
  const streamJSONEnabled = user?.stream_json_enabled
  const channelOnboarding = user?.channel_onboarding

  const trialEnded = !!trialEnds && new Date() > new Date(trialEnds)

  return {
    user,
    learningObjectiveLabel,
    msTeamsPermissionMissing,
    trialEnds,
    nurtureAIEnabled,
    curriculumBoundaryEnabled,
    rubricsEnabled,
    trialEnded,
    nurtureAssistantEnabled,
    channelOnboarding,
    customRubricsEnabled,
    streamJSONEnabled
  };
};

export default useSchoolConfig;
