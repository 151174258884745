import { FormGroup, FormText, Label, Row } from "reactstrap";
import oneNote from 'assets/images/file_icons/one-note.svg';
import {DateInput, NestedFields, RadioButton, TextInput} from "core/form/fields";
import NoteDescription from "modules/userAssignments/NoteDescription";
import Attachment from "modules/attachments/Attachment";
import { Button, Card, MarkdownEditorField, NurtureAI, NurtureAIAssignmentGenerator } from "core/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Controller } from "react-hook-form";
import { isPresent, singularize } from "core/utils";
import React, { Fragment, useEffect, useState } from "react";
import { useSchoolConfig } from "core/hooks";
import filestackPicker from "core/utils/filestack";
import { attachmentCreate } from "modules/attachments/actions";
import { useDispatch, useSelector } from "react-redux";
import * as attachmentsSelector from "modules/attachments/selectors";
import { useSnackbar } from "notistack";
import { assignmentUpdate } from "modules/assignments/action";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";

const AssignmentBasicsForm = ({ assignment, formConfig, content, onUpdate, formatPayload, baseAssignmentObjectives }) => {
  const [attachments, setAttachments] = useState([]);
  const [assignmentObjectives, setAssignmentObjectives] = useState([]);
  const [description, setDescription] = useState(assignment.description || '');
  const [suggestionEnded, setSuggestionEnded] = useState(false);

  const { learningObjectiveLabel, nurtureAIEnabled } = useSchoolConfig();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const allowLearningObjectivesSorting = assignment?.status === 'draft' || pathname === '/assignments/new' || content === 'assignment_template'

  const assignmentTemplateVisibilities = [
    { label: 'Private', value: 'private' },
    { label: 'Org', value: 'org' },
    { label: 'Public', value: 'public' },
  ];

  const filestackCredentials = useSelector(attachmentsSelector.filestackCredentials);
  const { control, formState: { errors }, register, watch, setValue, trigger } = formConfig;

  const assignmentHeader = '1. Laying the foundations';

  const onUploaderButtonClick = () => {
    const filePicker = filestackPicker(filestackCredentials, handleUploadSuccess);
    filePicker.open()
  }

  const handleAttachmentDelete = deletedAttachment => {
    const attachmentsClone = [...attachments];
    const updatedAttachments = attachmentsClone?.filter((attachment) => attachment.id !== deletedAttachment.id) || []
    if (isPresent(onUpdate)) onUpdate({ ...assignment, attachments: updatedAttachments })
    enqueueSnackbar('Attachment was deleted', { variant: 'success' })
    setAttachments(updatedAttachments)
  }

  const handleUploadSuccess = async (file) => {
    try {
      const payload = { [`${content}_id`]: assignment.id, ...file } // could be assignment_id or assignment_template_id
      const attachment = await dispatch(attachmentCreate(payload))

      const updatedAttachments = [...assignment.attachments, attachment]
      if (isPresent(onUpdate)) onUpdate({ ...assignment, attachments: updatedAttachments })
      enqueueSnackbar('Attachment has been uploaded', { variant: 'success' })
      setAttachments((state) => [...state, attachment])
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const removeAssignmentObjective = async data => {
    const oldAssignmentObjectives = [...assignmentObjectives];
    try {
      if (!data.slug) return true
      const payload = {
        assignment_objectives: [{ ...data, _destroy: true }]
      };
      const requestData = { ...formatPayload(payload), assignment_objective_delete: true };
      const response = await dispatch(assignmentUpdate(assignment.id, requestData))
      setAssignmentObjectives(response.assignment_objectives)
    } catch (e) {
      setAssignmentObjectives(oldAssignmentObjectives)
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const handleDescriptionChange = (value) => {
    setDescription(value)
    setValue('description', value)
    trigger('description')
  }

  const handleAssignmentSuggestion = (result) => {
    if (result.name) setValue('name', result.name);
    if (result.description) handleDescriptionChange(result.description);
    if (result.learningObjectives?.length) setAssignmentObjectives(result.learningObjectives.map(data => ({name: data})));
    setSuggestionEnded(true);
  };


  useEffect(() => {
    if (isPresent(assignment)) {
      setAttachments(assignment.attachments || []);
      setAssignmentObjectives(assignment.assignment_objectives);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment]);

  useEffect(() => {
    if (baseAssignmentObjectives.length > 0) {
      setAssignmentObjectives(baseAssignmentObjectives)
    }
  }, [baseAssignmentObjectives])

  return (
    <Card className="mb-5 px-1 px-md-3 p-3" headerText={assignmentHeader} headerClass="big-text text-nurture-purple" withHeader>
      {/* Nurture Assistant */}
      {
        nurtureAIEnabled &&
        <NurtureAI buttonText="Generate Assessment" display="modal" className="mb-3"
                   done={suggestionEnded} onBegin={() => setSuggestionEnded(false)}
                   subtitle="Generate ideas for your assessment with Nurture Assistant">
          <NurtureAIAssignmentGenerator onSuggest={handleAssignmentSuggestion} extras={{assignment_id: assignment.id}} />
        </NurtureAI>
      }
      
      {/* Assignment Name */}
      <FormGroup>
        <TextInput
          label="Assessment Name"
          placeholder="Assessment name"
          error={!!errors.name?.message}
          {...register('name')}
          required
        />
        {errors.name && (
          <p className="mt-1">
            <FormText color="danger">
              {errors.name.message}
            </FormText>
          </p>
        )}
      </FormGroup>

      {/* Assignment Description */}
      <div className='block mb-3'>
        <div>Description</div>
        <div className='required ms-1'>*</div>
      </div>
      <MarkdownEditorField
        initialValue={description}
        error={!!errors.description?.message}
        onChange={handleDescriptionChange}
      />
      {errors.description && (
        <p className="mt-1">
          <FormText color="danger">
            {errors.description.message}
          </FormText>
        </p>
      )}
      <NoteDescription showNotes={false} />

      <hr />

      {/* Attachments */}
      <FormGroup className="mt-3">
        {
          attachments?.map((attachment, index) => {
            return (
              <Attachment
                key={index}
                attachment={attachment}
                attachable={assignment}
                attachableName="assignment"
                onDelete={handleAttachmentDelete}
              />
            )
          })
        }
        <Button outline className="btn-sm mt-3" onClick={onUploaderButtonClick}>
          <FontAwesomeIcon icon={faPaperclip} />&nbsp;
          Add attachments
        </Button>
      </FormGroup>

      {/* Learning Objectives */}
      <Fragment>
        <h3 className="font-size-17 font-weight-600 mt-4">{learningObjectiveLabel}</h3>
        <div className="text-muted mb-4 mt-2 subtext">
          You will be giving feedback for each of these {learningObjectiveLabel.toLowerCase()} when your students submit their work.
        </div>

        <FormGroup>
          <NestedFields
            formConfig={formConfig}
            subject={assignmentObjectives}
            allowSort={allowLearningObjectivesSorting}
            placeholder={singularize(learningObjectiveLabel)}
            onRemove={removeAssignmentObjective}
          />
        </FormGroup>
      </Fragment>
    </Card>
  );
};

AssignmentBasicsForm.propTypes = {
  assignment: PropTypes.shape({}).isRequired,
  formConfig: PropTypes.any.isRequired,
  content: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  formatPayload: PropTypes.func.isRequired,
  baseAssignmentObjectives: PropTypes.any.isRequired,
};

export default AssignmentBasicsForm;
