import { Controller } from 'react-hook-form';
import { FormGroup } from 'reactstrap';
import { Textarea } from 'core/form/fields';

const TripleResponse = ({ control, errors, tripleResponseQuestions }) => {

  return (
    <div className='px-custom'>
      {/* Triple response 3 */}
      <FormGroup>
        <Controller
          name="tripleResponseQuestionThree"
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Textarea
              ref={ref}
              label={tripleResponseQuestions.triple_response_question_three.question}
              rows="2"
              value={value}
              onChange={onChange}
              placeholder={tripleResponseQuestions.triple_response_question_three.question || tripleResponseQuestions.triple_response_question_three.placeholder}
              onBlur={onBlur}
              required
            />
          )}
        />
        {errors.tripleResponseQuestionThree && (
          <small className="form-text text-danger mt-1">Please fill in this field</small>
        )}
      </FormGroup>

      {/* Triple response 2 */}
      <FormGroup>
        <Controller
          name="tripleResponseQuestionTwo"
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Textarea
              ref={ref}
              label={tripleResponseQuestions.triple_response_question_two.question}
              rows="2"
              value={value}
              onChange={onChange}
              placeholder={tripleResponseQuestions.triple_response_question_two.question || tripleResponseQuestions.triple_response_question_two.placeholder}
              onBlur={onBlur}
              required
            />
          )}
        />

        {errors.tripleResponseQuestionTwo && (
          <small className="form-text text-danger mt-1">Please fill in this field</small>
        )}
      </FormGroup>

      {/* Triple response 1 */}
      <FormGroup>
        <Controller
          name="tripleResponseQuestionOne"
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Textarea
              ref={ref}
              label={tripleResponseQuestions.triple_response_question_one.question}
              rows="2"
              value={value}
              onChange={onChange}
              placeholder={tripleResponseQuestions.triple_response_question_one.question || tripleResponseQuestions.triple_response_question_one.placeholder}
              onBlur={onBlur}
              required
            />
          )}
        />
        {errors.tripleResponseQuestionOne && (
          <small className="form-text text-danger mt-1">Please fill in this field</small>
        )}
      </FormGroup>
    </div>
  )
};

export default TripleResponse;
