import React from "react";
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack'
import { CloseSnackBar } from 'core/components';
import "./App.css";
import configureStore from './store'
import Routes from './routes';
import { IntercomProvider } from "react-use-intercom";
import { envVariables  } from "core/utils";

const storeObj = configureStore();

const App = () => {
  return (
    <Provider store={storeObj} >
      <SnackbarProvider
        classes={{containerRoot: "z-alert"}}
        autoHideDuration={8000}
        maxSnack={1}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        disableWindowBlurListener={true}
        action={<CloseSnackBar />}
      >
        <IntercomProvider appId={envVariables.intercom.app_id}>
          <Routes />
        </IntercomProvider>
      </SnackbarProvider>
    </Provider >
  );
};

export default App;
