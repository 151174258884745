import PropTypes from 'prop-types';

const CardComponent = ({
  cardBodyClassName,
  className,
  children,
  withHeader,
  headerText,
  headerClass,
  withFooter,
  footerText,
}) => (
  <div className={`card ${className}`}>
    {withHeader && (
      <div className='card-header'>
        <div className={`m-0 card-title ${headerClass}`}>{headerText}</div>
      </div>
    )}

    <div className={`card-body ${cardBodyClassName}`}>{children}</div>

    {withFooter && (
      <div className='card-footer'>{footerText}</div>
    )}
  </div>
);

CardComponent.defaultProps = {
  withHeader: false,
  headerText: undefined,
  withFooter: false,
  footerText: undefined,
  className: "py-3 px-3",
  cardBodyClassName: undefined,
  headerClass: '',
};

CardComponent.propTypes = {
  cardBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
  headerClass: PropTypes.string,
  headerText: PropTypes.any,
  footerText: PropTypes.any,
};

export default CardComponent;
