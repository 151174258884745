import * as t from './actionTypes';
import { combineReducers } from 'redux';

const loading = (state = false, action) => {
  switch (action.type) {
    case t.ASSIGNMENT_LOAD_START:
    case t.ASSIGNMENTS_LOAD_START:
    case t.ASSIGNMENT_CREATE_START:
    case t.ASSIGNMENT_UPDATE_START:
      return true;
    case t.ASSIGNMENT_LOAD_SUCCESS:
    case t.ASSIGNMENTS_LOAD_SUCCESS:
    case t.ASSIGNMENT_CREATE_SUCCESS:
    case t.ASSIGNMENT_CREATE_ERROR:
    case t.ASSIGNMENT_LOAD_ERROR:
    case t.ASSIGNMENTS_LOAD_ERROR:
    case t.ASSIGNMENT_ASSIGN_SUCCESS:
    case t.ASSIGNMENT_DELETE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case t.ASSIGNMENT_LOAD_SUCCESS:
      return {
        ...state,
        [action.assignment.id]: action.assignment,
      };
    case t.ASSIGNMENTS_LOAD_SUCCESS:
      return action.assignments.reduce(
        (map, assignment) => ({
          ...map,
          [assignment.id]: assignment,
        }), state,
      );
    case t.ASSIGNMENTS_LOAD_START:
      return {};
    case t.ASSIGNMENT_DELETE_SUCCESS:
      const oldState = {...state};
      delete oldState[action.id];
      return oldState;
    default:
      return state;
  }
};

const meta = (state = {}, action) => {
  switch (action.type) {
    case t.ASSIGNMENTS_LOAD_ERROR:
    case t.ASSIGNMENTS_LOAD_START:
      return {};

    case t.ASSIGNMENTS_LOAD_SUCCESS:
      return {...state, ...action.meta};
    default:
      return state;
  }
}

const error = (state = null, action) => {
  switch (action.type) {
    case t.ASSIGNMENT_LOAD_ERROR:
    case t.ASSIGNMENTS_LOAD_ERROR:
      return action.error;

    case t.ASSIGNMENT_LOAD_SUCCESS:
    case t.ASSIGNMENTS_LOAD_SUCCESS:
    case t.ASSIGNMENT_DELETE_SUCCESS:
      return null;
    default:
      return state;
  }
}

const initialState = {
  loading: false,
  assignments: [],
  meta: {},
  error: false,
};

const studentAssignments = (state = initialState, action) => {
  switch (action.type) {
    case t.STUDENT_ASSIGNMENTS_LOAD_START:
      return {
        ...state,
        loading: true,
        assignments: [],
        meta: {}
      };
    case t.STUDENT_ASSIGNMENTS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        assignments: [...state.assignments, ...action.assignments],
        meta: {...state.meta, ...action.meta},
      };
    case t.STUDENT_ASSIGNMENTS_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default combineReducers({
  loading,
  byId,
  meta,
  error,
  studentAssignments,
});
