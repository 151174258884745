import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { calculateProgress } from 'core/utils';
import './assignmentCard.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import {faEdit, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import React, {useState} from "react";
import {DeleteModal} from "../modals";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {assignmentDelete} from "modules/assignments/action";
import AssignmentDatesInfo from "modules/assignments/AssignmentDatesInfo";
import {useAssignmentInfo} from "../../hooks";
import TeacherAssignmentCard from "./TeacherAssignmentCard";
import StudentAssignmentCard from "./StudentAssignmentCard";

const AssignmentCard = ({ assignment, isDraft, isScheduled }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { progress } = assignment;
  const navigateTo = useNavigate();
  const sessionUser = JSON.parse(sessionStorage.getItem('user'));
  const role = sessionUser?.role;
  const isTeacher = role === 'teacher';

  const {
    submittedUserAssignments,
    reflectionUserAssignments,
    totalUserAssignments
  } = useAssignmentInfo(assignment);

  const assignmentProgress = isTeacher ? calculateProgress(progress?.submissions, progress?.assigned) : undefined;
  const dispatch = useDispatch();

  const navigateToEdit = () => navigateTo(`/assignments/${assignment.id}/edit`);

  const navigateToShow = () => navigateTo(`/assignments/${assignment.id}`);

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const handleAssignmentDelete = async () => {
    try {
      setDeletingTemplate(true);
      await dispatch(assignmentDelete(assignment.id, true));
      enqueueSnackbar('Assessment was deleted', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setDeletingTemplate(false);
      setShowDeleteModal(false);
    }
  }

  return (
    <div>
      {
        isTeacher &&
          <TeacherAssignmentCard isDraft={isDraft} isScheduled={isScheduled} assignment={assignment} />
      }

      {
        !isTeacher &&
          <StudentAssignmentCard assignment={assignment} />
      }
    </div>
  );
};

AssignmentCard.propType = {
  assignment: PropTypes.shape({}),
};

export default AssignmentCard;
