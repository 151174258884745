import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button } from "core/components";
import {updateAssignmentTemplate, updateAssignmentTemplateFeedback} from "../action";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

const SourceTemplate = ({ canEdit, sourceTemplate, sourceAssignment, onCancel, onCreate, onUpdate }) => {
  const [updateButtonLoading, setUpdateButtonLoading] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const handleUpdate = async () => {
    try {
      setUpdateButtonLoading(true);
      const payload = { assignment_id: sourceAssignment.id, assignment_type: 'ms_teams', save_feedback: true };
      const response = await dispatch(updateAssignmentTemplateFeedback(sourceTemplate.id, payload));
      onUpdate(response);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setUpdateButtonLoading(false);
    }
  }

  return (
    <div className="px-lg-4">
      {
        canEdit &&
          <div>
            <p className="text-nurture-blue font-size-18 mb-4">It looks like you changed the learning objective feedback.</p>
            <p className="text-nurture-blue font-size-18 mb-4">Would you like to update it on this template?</p>
            <p className="mb-4">"{sourceTemplate.name}"</p>
          </div>
      }

      {
        !canEdit &&
          <div>
            <p className="text-nurture-blue font-size-18 mb-4">It looks like you changed the learning objective feedback from the feedback imported with this template:</p>
            <div className="quote-template p-3 p-lg-4 border rounded-1 bg-light mb-4">
              <div>"{sourceTemplate.name}"</div>
              <div className="text-muted">by { sourceTemplate.template_owner_name } </div>
            </div>
            <p className="text-nurture-blue font-size-18 mb-4">Would you like to create a new template with this feedback?</p>
          </div>
      }

      <hr className="light-divider" />

      {/*Buttons*/}
      <div className="d-lg-flex justify-content-lg-end mt-4">
        <div className="me-lg-3 mb-3 d-grid">
          <Button color="transparent" className="text-danger" onClick={onCancel}>Cancel</Button>
        </div>
        <div className="mb-3 d-grid">
          <Button outline onClick={onCreate}>
            Create new template
          </Button>
        </div>
        {
          canEdit &&
            <div className="ms-lg-3 mb-3 d-grid">
              <Button withLoader disabled={updateButtonLoading} loading={updateButtonLoading} onClick={handleUpdate}>Yes, Update template</Button>
            </div>
        }
      </div>
    </div>
  );
};

SourceTemplate.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  sourceAssignment: PropTypes.shape({}).isRequired,
  sourceTemplate: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default SourceTemplate;