import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as attachmentsSelector from "modules/attachments/selectors";
import { isEmpty } from "core/utils";
import { attachmentFilestack } from "modules/attachments/actions";
import { showAssignmentTemplate } from "./action";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import AssignmentNewSkeletalLoader from "../assignments/skeletalLoaders/AssignmentNewSkeletalLoader";
import AssignmentForm from "../assignments/form/Form";

const AssignmentTemplateEdit = () => {
  const [assignmentTemplate, setAssignmentTemplate] = useState({});
  const [assignmentTemplateLoading, setAssignmentTemplateLoading] = useState(true);
  const dispatch = useDispatch();
  const filestackCredentials = useSelector(attachmentsSelector.filestackCredentials);
  const { enqueueSnackbar } = useSnackbar();
  const { assignmentTemplateId } = useParams();

  const fetchAssignmentTemplate = async () => {
    try {
      const template = await dispatch(showAssignmentTemplate(assignmentTemplateId));
      setAssignmentTemplate(template)
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setAssignmentTemplateLoading(false)
    }
  };

  useEffect(() => {
    // Because this is the new assignment page, we clear any current assignment that might have been in the redux store
    // so that the assessment form will know how to handle it
    // We also fetch filestack credentials
    fetchAssignmentTemplate();
    if (isEmpty(filestackCredentials)) dispatch(attachmentFilestack())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        assignmentTemplateLoading ?
          <AssignmentNewSkeletalLoader /> :
          <AssignmentForm assignment={assignmentTemplate} content="assignment_template" />
      }
    </>
  )
};

export default AssignmentTemplateEdit;
