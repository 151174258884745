import React from "react";
import {
  HashRouter,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";

import ProtectedRoute from "modules/ProtectedRoute";
import Privacy from "modules/Privacy";
import TermsOfUse from "modules/TermsOfUse";
import Tab from "modules/Tab";
import TabConfig from "modules/TabConfig";
import ManualLogin from "core/channel/ManualLogin";
import AssignmentsNew from "./modules/assignments/assignmentsNew";
import Assignment from "modules/assignments/Show";
import GroupFeedback from "modules/assignments/feedback/groupFeedback";
import AssignmentsEdit from "modules/assignments/assignmentsEdit";
import ScrollToTop from "core/components/ScrollToTop";
import AssignmentTemplateEdit from "./modules/assignmentTemplates/AssignmentTemplateEdit";
import Authenticate from "./modules/auth/Authenticate";


const AppRoutes = () =>
  <HashRouter>
    <ScrollToTop />
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Navigate to='/tab' />} />
        <Route path="/tab" element={<Tab />} />
        <Route path="assignments">
          <Route index element={<Tab />} />
          <Route path="new" element={<AssignmentsNew />} />
          <Route path=":assignmentId" element={<Assignment />} />
          <Route path=":assignmentId/edit" element={<AssignmentsEdit />} />
          <Route path=":assignmentId/feedback/:id/edit" element={<GroupFeedback />} />
        </Route>
        <Route path="assignments_templates">
          <Route path=":assignmentTemplateId/edit" element={<AssignmentTemplateEdit />} />
        </Route>
      </Route>
      <Route path="/login" element={<ManualLogin />} />
      <Route path="/authenticate" element={<Authenticate />} />

      <Route path="/privacy" element={<Privacy />} />
      <Route path="/termsofuse" element={<TermsOfUse />} />
      <Route path="/config" element={<TabConfig />} />
    </Routes>
  </HashRouter>

export default AppRoutes;
