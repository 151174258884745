import { Link } from 'react-router-dom';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "./Button";
import PropTypes from "prop-types";

const BackButton = ({ underlyingMethod }) => {
  return (
    <>
      {
        !underlyingMethod &&
        <Link
          className="btn btn-outline-nurture-purple mb-4"
          to="/assignments"
        >
          <FontAwesomeIcon icon={faCaretLeft} />&nbsp;  Back
        </Link>
      }

      {
        underlyingMethod &&
        <Button
          className="mb-4"
          outline={true}
          onClick={underlyingMethod}
        >
          <FontAwesomeIcon icon={faCaretLeft} />&nbsp;  Back
        </Button>
      }
    </>
  )
}


BackButton.defaultProps = {
  underlyingMethod: undefined,
}

BackButton.propTypes = {
  underlyingMethod: PropTypes.func,
}

export default BackButton;
