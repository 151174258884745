import * as api from './api';
import * as t from './actionTypes';
import { handleApiError } from "core/utils";
import {ASSIGNMENT_DELETE_SUCCESS} from "./actionTypes";

export const allAssignmentsStart = type => ({
  type,
});

export const allAssignmentsSuccess = (type, assignments, meta) => ({
  type,
  assignments,
  meta,
});

export const allAssignmentsError = (type, error) => ({
  type,
  error,
});

export const assignmentCreateStart = () => ({
  type: t.ASSIGNMENT_CREATE_START,
});

export const assignmentCreateSuccess = () => ({
  type: t.ASSIGNMENT_CREATE_SUCCESS,
});

export const assignmentCreateError = () => ({
  type: t.ASSIGNMENT_CREATE_ERROR,
});

export const assignmentUpdateStart = () => ({
  type: t.ASSIGNMENT_UPDATE_START,
})

export const assignmentAssignStart = () => ({
  type: t.ASSIGNMENT_ASSIGN_START,
});

export const assignmentAssignSuccess = (assignment) => ({
  type: t.ASSIGNMENT_LOAD_SUCCESS,
  assignment,
});

export const assignmentShowSuccess = assignment => ({
  type: t.ASSIGNMENT_LOAD_SUCCESS,
  assignment,
});

export const assignmentShowError = error => ({
  type: t.ASSIGNMENT_LOAD_ERROR,
  error,
});

export const assignmentUpdateSuccess = assignment => ({
  type: t.ASSIGNMENT_LOAD_SUCCESS,
  assignment,
});

export const assignmentDeleteSuccess = id => ({
  type: t.ASSIGNMENT_DELETE_SUCCESS,
  id,
});

export const allAssignments = (params) => async dispatch => {
  if (!params.page) dispatch(allAssignmentsStart(t.ASSIGNMENTS_LOAD_START));
  try {
    const { data, meta } = await api.getAssignments(params);
    dispatch(allAssignmentsSuccess(t.ASSIGNMENTS_LOAD_SUCCESS, data, meta));
    return data;
  } catch (error) {
    const errorMessage = handleApiError(error);
    dispatch(allAssignmentsError(t.ASSIGNMENTS_LOAD_ERROR, errorMessage))
    return Promise.reject(errorMessage);
  }
};

export const studentAssignments = (params) => async dispatch => {
  if (!params.page) dispatch(allAssignmentsStart(t.STUDENT_ASSIGNMENTS_LOAD_START));
  try {
    const { data, meta } = await api.getAssignments(params);

    dispatch(allAssignmentsSuccess(t.STUDENT_ASSIGNMENTS_LOAD_SUCCESS, data, meta));
    return data;
  } catch (error) {
    dispatch(allAssignmentsError(t.STUDENT_ASSIGNMENTS_LOAD_ERROR))
    return Promise.reject(handleApiError(error));
  }
};

export const getAssignmentStart = () => ({
  type: t.ASSIGNMENT_LOAD_START,
});

export const getAssignmentSuccess = assignment => ({
  type: t.ASSIGNMENT_LOAD_SUCCESS,
  assignment,
});


export const getAssignment = (id) => async dispatch => {
  dispatch(getAssignmentStart());
  try {
    const response = await api.getAssignment(id);
    const { data } = response;
    dispatch(getAssignmentSuccess(data));

    return data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};
/** This method calls the endpoint to create an empty assignment or fetch an already existing
 * empty assignment.
 * It also dispatches the empty assignment created/fetched to the redux store
 *
 * @returns {(function(*): Promise<*>)|*}
 */
export const assignmentCreate = () => async dispatch => {
  dispatch(assignmentCreateStart());

  try {
    const response = await api.assignmentCreate();

    const { data } = response
    data?.assignment_objectives?.map(item => {
      item['slug'] = item.id
      delete item.id
      return item
    });

    dispatch(assignmentCreateSuccess());

    return data;

  } catch (error) {
    dispatch(assignmentCreateError());
    return Promise.reject(handleApiError(error));
  }
}

export const assignmentUpdate = (assignmentId, payload, dispatchToStore = true) => async dispatch => {
  try {
    const response = await api.assignmentUpdate(assignmentId, payload);
    if (dispatchToStore) dispatch(assignmentUpdateSuccess(response.data));

    return response.data;
  } catch (e) {
    return Promise.reject(handleApiError(e));
  }
}

export const assignmentDelete = (assignmentId, dispatchToStore = false) => async dispatch => {
  try {
    const response = await api.assignmentDelete(assignmentId);
    if (dispatchToStore) dispatch(assignmentDeleteSuccess(assignmentId));
    return response.data;
  } catch (e) {
    return Promise.reject(handleApiError(e));
  }
}

export const assignmentAssign = (assignmentId, payload) => async dispatch => {
  dispatch(assignmentAssignStart());

  try {
    const response = await api.assignmentAssign(assignmentId, payload);
    return dispatch(assignmentAssignSuccess(response.data));
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
}

export const submissionShow = async (assignmentId, submissionId) => {
  try {
    const response = await api.submissionShow(assignmentId, submissionId);
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
}

export const submissionUpdate = async (assignmentId, submissionId, payload) => {
  try {
    const response = await api.submissionUpdate(assignmentId, submissionId, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
}

export const assignmentObjectiveUpdate = async (assignmentId, assignmentObjectiveId, payload) => {
  try {
    const response = await api.assignmentObjectiveUpdate(assignmentId, assignmentObjectiveId, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
}

export const assignmentPublishFeedback = (assignmentId, payload) => async dispatch => {
  try {
    const response = await api.assignmentPublishFeedback(assignmentId, payload);
    dispatch(assignmentShowSuccess(response.data));
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
};

export const updateOfflineSubmissionMade = async (assignmentId, submissionId, payload) => {
  try {
    const response = await api.updateOfflineSubmissionMade(assignmentId, submissionId, payload);
    return response.data;
  } catch (error) {
    return Promise.reject(handleApiError(error));
  }
}
