import { useState } from 'react';
import PropType from 'prop-types';
import { useDispatch } from 'react-redux';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormGroup } from 'reactstrap';

import { Button, Card, Divider } from 'core/components';
import { Textarea } from 'core/form/fields';
import StudentConfidence from '../EmojiFields';
import TripleResponse from './TripleResponseFields';
import { emojiResponses } from '../constants';
import { submitAssignment } from '../redux/action';
import { isEmpty, isPresent } from 'core/utils';
import { useSnackbar } from 'notistack';

const ReviewedAssignment = ({ assignmentId, graded, id, onSuccess, studentReflection, tripleQuestions }) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const emojiSchema = yup.object().shape({
    'emojiResponse': yup.number().oneOf([1, 2, 3]).required(),
    'studentFeedbackReflectionComment': yup.string(),
  });

  const tripleResponseSchema = yup.object().shape({
    'tripleResponseQuestionOne': yup.string().required(),
    'tripleResponseQuestionTwo': yup.string().required(),
    'tripleResponseQuestionThree': yup.string().required(),
    'studentFeedbackReflectionComment': yup.string(),
  });

  const title = 'Your Marks';
  const subtitle = 'Unlock your marks for this assessment by completing the feedback reflection below.';

  const isEmojiResponse = studentReflection === 'emoji_response';
  const isTripleResponse = studentReflection === 'triple_response';

  const defaultValues = {
    studentFeedbackReflectionComment: '',
    emojiResponse: '',
  };

  let schema;

  if (isEmojiResponse) {
    schema = emojiSchema;
  } else if (isTripleResponse) {
    schema = tripleResponseSchema;
  };

  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const submitWork = async (params, payload) => dispatch(submitAssignment(params, payload));
  const onSubmit = async data => {
    const params = {
      assignmentId,
      id,
    };

    const tripleResponseData = {
      triple_response_question_one: data.tripleResponseQuestionOne,
      triple_response_question_two: data.tripleResponseQuestionTwo,
      triple_response_question_three: data.tripleResponseQuestionThree,
    };

    const formattedData = {
      ...(isPresent(data.emojiResponse) && { emoji_response: data.emojiResponse }),
      ...(isEmpty(data.emojiResponse) && { ...tripleResponseData }),
      student_feedback_reflection_comment: data.studentFeedbackReflectionComment,
      emoji_response: data.emojiResponse
    };

    try {
      setSubmitting(true);
      const response = await submitWork(params, formattedData);
      onSuccess(response);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card
      className=""
      cardBodyClassName="px-0"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="mb-0">
        <div className='mt-4'>
          {graded && (
            <div className='px-custom mb-4 mt-2'>
              <h6 className="fw-3">{title}</h6>
              <div className="text text-muted">{subtitle}</div>
            </div>
          )}

          {isEmojiResponse && (
            <div className='px-custom'>
              <div className="mb-3">
                <label className="mb-0 d-block">How do you feel about the feedback?</label>
                <span className="text text-muted form-text">Did you feel it was what you expected?</span>
              </div>

              <FormGroup>
                <StudentConfidence
                  control={control}
                  setValue={setValue}
                  name="emojiResponse"
                  confidenceOptions={emojiResponses}
                />
                {errors.emojiResponse && (
                  <small className="form-text text-danger mt-1">Please select a reaction</small>
                )}
              </FormGroup>
            </div>
          )}

          {isTripleResponse && (
            <TripleResponse errors={errors} control={control} tripleResponseQuestions={tripleQuestions} />
          )}

          <FormGroup className='pt-3 px-custom'>
            <label className="mb-2">Add additional comments (Optional)</label>
            <Controller
              name="studentFeedbackReflectionComment"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Textarea
                  ref={ref}
                  rows="4"
                  value={value}
                  onChange={onChange}
                  placeholder="Add any additional comments..."
                  onBlur={onBlur}
                />
              )}
            />
          </FormGroup>
        </div>
        <Divider className="mb-3 mt-5" />
        <div className="d-flex justify-content-md-end px-custom pt-2 pb-2">
          <Button className="w-100 w-md-200px" type="submit" disabled={submitting}>{graded ? 'Unlock grade' : 'Submit'}</Button>
        </div>
      </form>
    </Card>
  )
};

ReviewedAssignment.propTypes = {
  assignmentId: PropType.string.isRequired,
  id: PropType.string.isRequired,
  graded: PropType.bool.isRequired,
  onSuccess: PropType.func.isRequired,
  studentReflection: PropType.string.isRequired,
};

export default ReviewedAssignment;
