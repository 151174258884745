import {composeRequestConfig, createRequest} from "core/utils/axios/api";
import {userFullName} from "../core/utils";

const metricService = {
  track: ({event, properties = {}}) => {
    const url = `/api/v1/metrics/track`;
    let userId = null;

    let user = sessionStorage.getItem('user');
    user = user && JSON.parse(user);

    if  (user) {
      userId = user.id;
    }


    const payload = { metric: event, user_id: userId, properties: properties };
    const config = composeRequestConfig({ url, method: 'post', payload });
    return createRequest(config);
  },
  setUser: ({userId, properties }) => {
    const url = `/api/v1/metrics/set_user`;
    const payload = { user_id: userId, properties: properties };
    const config = composeRequestConfig({ url, method: 'post', payload });
    return createRequest(config);
  }
};

export default metricService;
