const rubricsScoreLabel = (score, rubrics) => {
  const rubricsCount = rubrics?.length;
  const averageScorePerObjective = Math.round(score / rubricsCount);

  let result;
  let rubricsColumns = rubrics[0]?.columns
  let minDiff = Infinity;

  for (let i = 0; i < rubricsColumns.length; i++) {
    const currentColumn = rubricsColumns[i]
    const currentDiff = Math.abs(averageScorePerObjective - currentColumn.point)

    if (currentDiff < minDiff) {
      minDiff = currentDiff
      result = currentColumn
    }
  }

  return result?.name
};

export default rubricsScoreLabel;
