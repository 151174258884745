import Pusher from "pusher-js";
import { envVariables } from "core/utils";

const { pusher: p } = envVariables

Pusher.logToConsole = true;
// Pusher.log = function(message) { console.log(message) };

const pusher = new Pusher(p.key, {
  app_id: p.app_id,
  secret: p.secret,
  cluster: p.cluster
})

export default pusher;