import PropTypes from "prop-types";

const AssessmentSubmissionTab = ({ activeTab, onChangeActiveTab }) => {
  return (
    <div className='AssessmentSubmissionTab'>
      <div className={`assessment ${activeTab === 'assessment' ? 'active' : ''}`} onClick={() =>onChangeActiveTab('assessment')}>Assessment</div>
      <div className={`submissions ${activeTab === 'submissions' ? 'active' : ''}`} onClick={() =>onChangeActiveTab('submissions')}>Submissions</div>
    </div>
  );
}

export const TABS = {
  ASSESSMENT: 'assessment',
  SUBMISSIONS: 'submissions'
}

AssessmentSubmissionTab.propTypes = {
  activeTab: PropTypes.oneOf([TABS.ASSESSMENT, TABS.SUBMISSIONS]).isRequired,
  onChangeActiveTab: PropTypes.func.isRequired
}

export default AssessmentSubmissionTab