import PropTypes from "prop-types";
import { Button } from "core/components";
import { useSchoolConfig } from "core/hooks";
import { singularize } from "core/utils";

const FeedbackButton = ({ objective, onClick }) => {
  const { learningObjectiveLabel } = useSchoolConfig();
  const handleClick = () => onClick(objective);

  return (
    objective.feedback_objectives?.length && objective.feedback_completed ?
      <div className="col-auto">
        <Button onClick={handleClick} type="button" size="sm" outline={true}>Edit Feedback</Button>
      </div> :
      objective.feedback_objectives?.length ?
        <div className="col-auto">
          <Button color="nurture-purple" onClick={handleClick} type="button" size="sm">Give Feedback</Button>
        </div> :
        <div className="col-auto"
          data-bs-toggle="tooltip"
          title={`Students don't need feedback for this ${ singularize(learningObjectiveLabel).toLowerCase() }. You can review student submissions to mark them for feedback.`}
        >
          <Button color="nurture-purple" type="button" disabled size="sm">
            Give Feedback
          </Button>
        </div>
  );
}

FeedbackButton.defaultProps = {
  onClick: () => null
};

FeedbackButton.propTypes = {
  objective: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func
}

export default FeedbackButton;