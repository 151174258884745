import 'ka-table/style.css';

import React, { Fragment, useEffect, useState } from 'react';

import { kaReducer, Table } from 'ka-table';
import { DataType, EditingMode, SortingMode } from 'ka-table/enums';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomEditor from "./CustomEditor";
import Button from "../Button";
import AddColumnModal from "./AddColumnModal";
import { insertColumn } from "ka-table/actionCreators";
import orderBy from "lodash/orderBy";
import { useSnackbar } from "notistack";
import EditColumnFields from "./EditColumnFields";
import { isPresent, pluralize } from "core/utils";
import { toRubricsFormat } from "./utils";
import ConfirmModal from "../modals/Confirm";

const columnsByOrder = (columns) => orderBy(columns, ['order'], ['asc']);

const transformData = (baseData) => {
  return baseData?.map((item) => {
    const result = {};

    result['Learning Outcome'] = item['Learning Outcome'];
    columnsByOrder(item.columns).forEach((column) => {
      result[column['name']] = column['value']
    })

    return result
  }) || [];
};

const EditableTable = ({ defaultData, columnReordering, learningObjectives, onUpdated }) => {
  const ACTION_UPDATE_DATA = 'ACTION_UPDATE_DATA';
  const ACTION_DELETE_COLUMNS = 'ACTION_DELETE_COLUMNS';
  const ACTION_EDIT_COLUMN = 'ACTION_EDIT_COLUMN';

  // filter out any possible deleted column
  const filteredData = defaultData.map(item => {
    const filteredColumns = item.columns.filter((column) => !column.hasOwnProperty('_destroy') )
    return {...item, columns: filteredColumns}
  })
  const transformedData = transformData(filteredData);
  const columns = [...filteredData][0]?.columns.map((item, index) => ({ key: item.name, title: item.name, type: DataType.String, width: 200, isEditable: true, point: item.point || index })) || [];
  columns.unshift({ key: 'Learning Outcome', title: 'Learning Outcome', type: DataType.String, width: 200, isEditable: false })
  const data = transformedData.map((item, index) => ( { ...item, id: index } ));
  const sortingMode = SortingMode.None
  const editingMode = EditingMode.None
  const rowKeyField = 'id'

  const childComponents = {
    table: {
      elementAttributes: () => ({
        className: 'table table-striped table-hover table-bordered'
      })
    },
    tableHead: {
      elementAttributes: () => ({
        className: 'cursor-move'
      })
    },
    tableBody: {
      elementAttributes: () => ({
        className: 'cursor-pointer'
      })
    },
    cellEditor: {
      content: (props) => {
        return <CustomEditor dispatch={dispatch} {...props} />
      }
    },
    cellText: {
      content: (props) => {
        if (props.field === 'Learning Outcome') {
          return props.value
        } else {
          return <CustomEditor dispatch={dispatch} {...props} />
        }
      }
    }
  };
  if (columnReordering) {
    childComponents['headCellContent'] = {
      content: (props) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              {
                props.column.title === 'Learning Outcome' ?
                  <span>{props.column.title}</span> :
                  <EditColumnFields column={props.column} onEdit={handleEditColumn} />
              }

            </div>
            {
              props.column.title !== 'Learning Outcome' &&
                <div>
                  <FontAwesomeIcon onClick={() => deletableColumnAction(props.column)} icon={faTrash} className="text-danger cursor-pointer" />
                </div>
            }
          </div>
        );
      }
    }
  }

  const [showAddColumnModal, setShowAddColumnModal] = useState(false);
  const [tableProps, changeTableProps] = useState({
    columns, data, childComponents, columnReordering, sortingMode, rowKeyField, editingMode
  });
  const [baseData, setBaseData] = useState([...defaultData]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletableColumn, setDeletableColumn] = useState(null);


  const { enqueueSnackbar } = useSnackbar();

  const handleAddColumn = (column) => {
    dispatch(insertColumn({ key: column.title, title: column.title, type: DataType.String, width: 200, isEditable: true, point: column.point || 3 }, tableProps.columns.length))

    const newData = tableProps.data.map((item) => {
      item[column.title] = ''
      return item
    });

    dispatch({ type: ACTION_UPDATE_DATA, data: newData })

    setShowAddColumnModal(false);
  };

  const handleEditColumn = (column) => {
    dispatch({ type: ACTION_EDIT_COLUMN, column: column });
  };

  const deleteColumn = () => {
    dispatch({ type: ACTION_DELETE_COLUMNS, title: deletableColumn?.title })
    setShowConfirmModal(false)
  }

  const deletableColumnAction = (column) => {
    setDeletableColumn((_prev) => column);
    setShowConfirmModal(true);
  }

  const dispatch = (action) => { // dispatch has an *action as an argument
    switch (action.type) {
      case ACTION_UPDATE_DATA:
        changeTableProps((prevState => {
          return {...prevState, data: action.data}
        }));
        break;
      case ACTION_DELETE_COLUMNS:
        console.log(action)
        changeTableProps((prevState => {
          const updatedColumns = prevState.columns.filter(item => item.title !== action.title)
          const updatedData = prevState.data.map((item) => {
            delete item[action.title]
            return item
          })
          return {...prevState, columns: updatedColumns, data: updatedData}
        }));
        setBaseData((prev) => {
          const updatedData = prev.map((item) => {
            let columns = item.columns;
            const index = columns.findIndex((column) => column.name === action.title); // index of column to delete
            if (index === -1) return item;

            const column = columns[index];
            if (column.id) {
              columns[index] = { ...column, _destroy: true };
            } else {
              columns.splice(index, 1);
            }

            // Reset order after deleting
            const unDeletedColumns = columns
              .filter((column) => !column.hasOwnProperty('_destroy') )
              .map((column, index) => ({ ...column, order: index }));

            const deletedColumns = columns.filter((column) => column.hasOwnProperty('_destroy') )

            return { ...item, columns: columnsByOrder(deletedColumns.concat(unDeletedColumns)) }
          });

          console.log('updatedData', updatedData)

          onUpdated(updatedData);
          return updatedData;
        })
        break;
      case ACTION_EDIT_COLUMN:
        changeTableProps((prevState => {
          const updatedColumns = prevState.columns.map(item => {
            if (item.title === action.column.title) {
              item['key'] = action.column.newTitle
              item['title'] = action.column.newTitle
              item['point'] = action.column.newPoint
            }
            return item
          })
          const updatedData = prevState.data.map((item) => {
            if (action.column.newTitle !== action.column.title) {
              item[action.column.newTitle] = item[action.column.title]
              delete item[action.column.title]
            }
            return item
          });
          return {...prevState, columns: updatedColumns, data: updatedData}
        }));
        setBaseData((prev) => {
          const updatedData = prev.map((item) => {
            let columns = item.columns;
            const index = columns.findIndex((column) => column.name === action.column.title); // index of column to delete
            if (index === -1) return item;

            const column = columns[index];
            columns[index] = { ...column, name: action.column.newTitle, point: action.column.newPoint }

            return { ...item, columns: columns }
          });

          onUpdated(updatedData);
          return updatedData;
        })
        break;
      default:
        if (action.type === 'ReorderColumns') {
          if (action.columnKey === 'Learning Outcome' || action.targetColumnKey === 'Learning Outcome') {
            enqueueSnackbar('You cannot move the Learning Outcome column', { variant: 'error' })
            return
          } else {
            setBaseData((prev) => {
              let result = [...prev]
              result = result.map(item => {
                const sourceColumnOrder = item.columns.find((column) => column.name === action.columnKey).order;
                const targetColumnOrder = item.columns.find((column) => column.name === action.targetColumnKey).order;
                const updatedColumns = item.columns.map(column => {
                  if (column.name === action.columnKey) {
                    column.order = targetColumnOrder
                  } else if (column.name === action.targetColumnKey) {
                    column.order = sourceColumnOrder
                  }

                  return column
                });

                return {...item, columns: columnsByOrder(updatedColumns)}
              })

              onUpdated(result)
              return result
            })
          }
        }

        changeTableProps((prevState) => kaReducer(prevState, action));

        if (action.type === 'InsertColumn') {
          setBaseData((prev) => {
            const updatedBaseData = prev?.map(item => ({ ...item, columns: [...item.columns, { name: action.column.title, value: '', order: item.columns.length, point: action.column.point }] }))

            onUpdated(updatedBaseData)
            return updatedBaseData
          })
        }

        if (action.type === 'UpdateCellValue') {
          const updatedData = [...tableProps.data]
          updatedData[action.rowKeyValue][action.columnKey] = action.value

          setBaseData((prev) => {
            const result = [...prev]
            result[action.rowKeyValue]['columns'] =  result[action.rowKeyValue]['columns'].map((column) => {
              if (column.name === action.columnKey) return {...column, value: action.value}
              else return column
            })

            onUpdated(result)
            return result
          });
        }
    }
  };

  useEffect(() => {
    if (isPresent(defaultData)) {
      changeTableProps((prev) => {
        return {...prev, columns: columns, data: data }
      });
      setBaseData(() => defaultData);
    } else {
      changeTableProps((prev) => {
        const columns = ['Fail', 'Pass', 'Merit'].map((item, index) => {
          return { key: item, title: item, type: DataType.String, width: 200, isEditable: true, point: index + 1 }
        });
        columns.unshift({ key: 'Learning Outcome', title: 'Learning Outcome', type: DataType.String, width: 200, isEditable: false })

        const data = learningObjectives.map((item, index) => ({"Learning Outcome": item.name, Fail: '', Pass: '', Merit: '', id: index}));
        return {...prev, columns: columns, data: data}
      });
      setBaseData(() => {
        const data = learningObjectives.map((item, index) => ({"Learning Outcome": item.name, Fail: '', Pass: '', Merit: ''}));
        return toRubricsFormat(data)
      });
    }
  }, [defaultData]);

  useEffect(() => {
    if (isPresent(defaultData)) {
      // deleting a learning objective
      if (learningObjectives.length < tableProps.data.length) {
        if (learningObjectives.length === 1 && learningObjectives[0].name === '' && !learningObjectives[0].slug) return

        const index = tableProps.data.findIndex((item) => { // index of deleted item
          return !learningObjectives.find((objective) => objective.name === item['Learning Outcome'])
        })
        changeTableProps(prev => {
          let newData = [...prev.data]
          newData.splice(index, 1)
          newData = newData.map((data, index) => ({...data, id: index}))
          return { ...prev, data: newData }
        });

        setBaseData((prev) => {
          let newData = [...prev]
          newData.splice(index, 1)
          newData = newData.map((data, index) => ({...data, index: index}))
          onUpdated(newData)
          return newData
        });
      }


      // adding a learning objective
      if (learningObjectives.length > tableProps.data.length) {
        const addedObjective = learningObjectives[learningObjectives.length - 1]
        changeTableProps(prev => {
          let newRow = { 'Learning Outcome': addedObjective.name, id: learningObjectives.length }
          prev.columns.forEach(column => newRow[column.title] = '')
          const newData = [...prev.data]
          newData.push(newRow)

          return { ...prev, data: newData }
        });

        setBaseData(prev => {
          let newRow = { 'Learning Outcome': addedObjective.name, index: learningObjectives.length }
          newRow['columns'] = prev[0].columns.map(column => ({...column, value: '', cell_id: null}))
          const newData = [...prev]
          newData.push(newRow)

          onUpdated(newData)
          return newData
        });
      }


      // updating a learning objective or swapping learning objectives
      if (learningObjectives.length === tableProps.data.length) {
        // data that changed or are swapped
        const changedData = tableProps.data.filter((item, lIndex) => item['Learning Outcome'] !== learningObjectives[lIndex].name)

        // Updating a learning objective
        if (changedData.length <= 1) {
          const index = tableProps.data.findIndex((item, lIndex) => item['Learning Outcome'] !== learningObjectives[lIndex].name)
          if (index === -1) return
          changeTableProps((prev) => {
            const newData = prev.data.map((item, dIndex) => {
              return (dIndex === index) ? { ...item, 'Learning Outcome': learningObjectives[index].name } : item
            });

            return {...prev, data: newData}
          });

          setBaseData((prev) => {
            const newData = prev.map((item, dIndex) => {
              return (dIndex === index) ? { ...item, 'Learning Outcome': learningObjectives[index].name } : item
            });

            onUpdated(newData);
            return newData;
          })
        } else { // Swapping learning objectives
          changeTableProps((prev) => {
            const newData = [...prev.data]

            changedData.forEach(data => {
              // index of changed data in the gloabal learning objectives
              const index = learningObjectives.findIndex((item, lIndex) => data['Learning Outcome'] === item.name)
              newData[index] = data
            });

            return {...prev, data: newData}
          });

          setBaseData((prev) => {
            const newData = [...prev]
            const changedData = newData.filter((item, lIndex) => item['Learning Outcome'] !== learningObjectives[lIndex].name)

            changedData.forEach(data => {
              // index of changed data in the gloabal learning objectives
              const index = learningObjectives.findIndex((item, lIndex) => data['Learning Outcome'] === item.name)
              newData[index] = data
            });


            onUpdated(newData);
            return newData;
          })
        }
      }
    }
  }, [learningObjectives, ...learningObjectives.map(item => item.name)]);

  return (
    <Fragment>
      <div className="text-end p-3">
        <Button className="btn-sm" color="nurture-green" onClick={() => setShowAddColumnModal(true)}>Add Column</Button>
      </div>
      <Table
        {...tableProps}
        dispatch={dispatch}
      />

      <AddColumnModal show={showAddColumnModal} onAdd={handleAddColumn} toggle={() => setShowAddColumnModal(!showAddColumnModal)} />
      <ConfirmModal
        show={showConfirmModal}
        onConfirm={deleteColumn}
        title="Delete?"
        text="Deleting this column will clear all the data in it, are you sure you want to continue?"
        toggle={() => setShowConfirmModal(!showConfirmModal)}
      />
    </Fragment>
  );
};

EditableTable.defaultProps = {
  columnReordering: true,
  defaultData: []
}

EditableTable.propTypes = {
  defaultData: PropTypes.arrayOf(PropTypes.shape({})),
  columnReordering: PropTypes.bool,
  onUpdated: PropTypes.func.isRequired,
  learningObjectives: PropTypes.array.isRequired
};

export default EditableTable;