import low from 'assets/images/emoji_icons/low.svg';
import lowDisabled from 'assets/images/emoji_icons/low_disabled.svg';
import neutral from 'assets/images/emoji_icons/neutral.svg';
import neutralDisabled from 'assets/images/emoji_icons/neutral_disabled.svg';
import high from 'assets/images/emoji_icons/high.svg';
import highDisabled from 'assets/images/emoji_icons/high_disabled.svg';
import PropTypes from 'prop-types';
import './emoji.scss';

const Emoji = ({ type, disabled, size, level, className, ...rest }) => {
  const options = ['Low', 'Neutral', 'High']

  const emojiHash = {
    high: disabled ? highDisabled : high,
    happy: disabled ? highDisabled : high,
    low: disabled ? lowDisabled : low,
    disappointed: disabled ? lowDisabled : low,
    neutral: disabled ? neutralDisabled : neutral,
  };

  const iconType = (type || options[level - 1])?.toLowerCase();

  const disabledClass = disabled ? 'emoji-disabled' : '';
  const typeClass = `emoji-${iconType}`

  const inlineStyles = {
    backgroundImage: `url(${emojiHash[iconType]})`,
    backgroundSize: 'cover'
  };

  return (
    <>
      <div
        className={`emoji emoji-${size} ${disabledClass} ${typeClass} ${className}`}
        style={inlineStyles}
        {...rest} />
    </>
  );
};

Emoji.defaultProps = {
  type: undefined,
  disabled: false,
  size: 'sm',
  className: '',
  level: 3
};

Emoji.propTypes = {
  type: PropTypes.oneOf(['High', 'Low', 'Neutral', 'Happy', 'Disappointed']),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  className: PropTypes.string,
  level: PropTypes.oneOf([1 ,2, 3])
};

export default Emoji;
