import { createRequest, composeRequestConfig } from 'core/utils/axios/api';
import { teamsEnv } from 'core/utils';

export const getGraphData = (params) => {
  const paramsCount = params?.count === 'all' ? null : params?.count;
  const paramsStudentId = params?.studentId === 'all' ? null : params?.studentId;

  const count = paramsCount ? `?assignment_count=${paramsCount}` : '';
  const studentId = paramsStudentId ? `${paramsCount ? '&' : '?'}student_id=${paramsStudentId}` : '';

  const url = `/api/v1/ms_teams/${teamsEnv.tenantId}/channels/${teamsEnv.channelId}/analytics${count}${studentId}`;
  const config = composeRequestConfig({url});

  return createRequest(config);
};
